import { graphql } from "../../generated"

export const LINK_WELL = graphql(`
    mutation LinkWell($LinkWellInput: LinkWellInput!) {
        linkWell(input: $LinkWellInput) {
            well {
                ...WellIdentificationFields
                alert {
                    ...WellAlertFields
                }
            }
        }
    }
`)
