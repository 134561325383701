import { graphql } from "../../generated"

export const ACTIVATE_ORGANIZATIONS = graphql(`
    mutation ActivatOrganizations(
        $OrganizationsActivateInput: OrganizationsActivateInput!
    ) {
        organizationsActivate(input: $OrganizationsActivateInput) {
            organizations {
                ...OrganizationWithDescendantsFields
            }
        }
    }
`)
