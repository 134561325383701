import { FunctionComponent, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ModalHeader from "../shared/modalHeader"
import ModalFooter from "../shared/modalFooter"
import { useViewerContext } from "../../context/ViewerContext"
import { OrganizationOptionType } from "../../types/ViewerTypes"
import Select, { GroupBase, StylesConfig } from "react-select"
import styles from "./inviteUsersModal.module.scss"
import Input from "../shared/input"
import {
    disableSubmitInviteUser,
    getInviteUserFormInitialOrgState,
    getRoleInvitationOptions,
    validateInviteUserInputData,
} from "./inviteUserModalUtil"
import { InviteUserModalStateInterface } from "./inviteUserModalTypes"
import { USER_INVITE } from "../../graphql/mutations/add_user"
import { useMutation } from "@apollo/client"
import Alert from "../shared/alert"
import { GET_USERS } from "../../graphql/queries/users"
import {
    AddUsersMutationVariables,
    SubjectRole,
    ZoneInfo,
} from "../../generated/graphql"
import { TrexNavigator } from "../../classes/navigator/navigator"
import { canRoleInviteUser } from "../../util/rolePermissions/canRole"
import { sortArrayByKey } from "../../util/arrays/array"

const InviteUsersModal = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { getViewer } = useViewerContext()
    const { role: viewerRole, organizationAndDescendants } = getViewer()

    /** Modal state */
    const [inviteUserModalState, setInviteUserModalState] =
        useState<InviteUserModalStateInterface>({
            newUserState: {
                firstName: "",
                lastName: "",
                email: "",
                inviteeUserRole: SubjectRole.OrgMember,
                organization: getInviteUserFormInitialOrgState(
                    organizationAndDescendants
                ),
                timezone: ZoneInfo.CentralTimeZone,
            },
            errorState: {
                email: undefined,
                firstName: undefined,
                lastName: undefined,
                organization: undefined,
                inviteeUserRole: undefined,
            },
            disableSubmit: true,
        })
    const [inviteUserErrorBanner, setInviteUserErrorBanner] = useState(false)

    const [inviteUserMutation, { loading: mutationLoading }] =
        useMutation(USER_INVITE)

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target
        const updatedNewUserState = {
            ...inviteUserModalState.newUserState,
            [name]: value,
        }
        const disableSubmit = disableSubmitInviteUser(updatedNewUserState)

        setInviteUserModalState((prevState) => ({
            ...prevState,
            newUserState: updatedNewUserState,
            disableSubmit: disableSubmit,
        }))
    }

    const handleSetInviteUserOrganization = (
        value: OrganizationOptionType | null
    ) => {
        if (!value) {
            throw new Error(
                "Error: handleSetInviteUserOrganization. Should not be setting organization to null."
            )
        }

        const updatedNewUserState = {
            ...inviteUserModalState.newUserState,
            organization: value,
        }

        const disableSubmit = disableSubmitInviteUser(updatedNewUserState)

        setInviteUserModalState((prevState) => ({
            ...prevState,
            newUserState: updatedNewUserState,
            disableSubmit: disableSubmit,
        }))
    }

    const handleInputBlur = () => {
        const updatedErrorData = validateInviteUserInputData(
            inviteUserModalState.newUserState
        )
        setInviteUserModalState((prevState) => ({
            ...prevState,
            errorState: updatedErrorData,
        }))
    }

    const handleSubmitInviteUser = async () => {
        const { organization, inviteeUserRole } =
            inviteUserModalState.newUserState
        if (organization === undefined || inviteeUserRole === undefined) {
            throw new Error(
                "Organization || role === undefined when handleSubmitInviteUser was called."
            )
        }
        const canActiveUserInvite = canRoleInviteUser(viewerRole)

        if (!canActiveUserInvite) {
            throw new Error(
                `${inviteeUserRole} does not have permission to invite new users.`
            )
        }

        const inviteUserVariables: AddUsersMutationVariables = {
            UsersAddInput: {
                users: [
                    {
                        identity: {
                            email: inviteUserModalState.newUserState.email,
                            firstName:
                                inviteUserModalState.newUserState.firstName,
                            lastName:
                                inviteUserModalState.newUserState.lastName,
                            zoneInfo:
                                inviteUserModalState.newUserState.timezone,
                        },
                        organizationID: organization.value,
                        role: inviteeUserRole,
                    },
                ],
            },
        }

        await inviteUserMutation({
            variables: inviteUserVariables,
            refetchQueries: [GET_USERS],
        })
            .then(() => {
                navigate(-1)
            })
            .catch((error) => {
                console.error("Invite user mutation errror ", error)
                setInviteUserErrorBanner(true)
            })
    }

    return (
        <div className={styles.container}>
            <ModalHeader
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: location.key,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
                title="Invite Users"
            />
            <InviteUsersBody
                modalState={inviteUserModalState}
                handleInputChange={handleInputChange}
                handleOrganizationChange={handleSetInviteUserOrganization}
                organizations={organizationAndDescendants}
                handleInputBlur={handleInputBlur}
                viewerRole={viewerRole}
            />
            {inviteUserErrorBanner && (
                <Alert
                    isCloseable={false}
                    message={
                        <div>
                            There was a problem adding the user. Click{" "}
                            <u
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/dashboard")}
                            >
                                here
                            </u>{" "}
                            or close the modal to be redirected to the
                            dashboard.
                        </div>
                    }
                    alertType="danger"
                />
            )}
            <ModalFooter
                advanceText="Submit"
                handleAdvanceClick={handleSubmitInviteUser}
                disableAdvance={
                    inviteUserModalState.disableSubmit ||
                    mutationLoading ||
                    inviteUserErrorBanner
                }
            />
        </div>
    )
}

export default InviteUsersModal

interface InviteUserBodyPropsInterface {
    organizations: OrganizationOptionType[]
    modalState: InviteUserModalStateInterface
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void
    handleOrganizationChange: (value: OrganizationOptionType | null) => void
    handleInputBlur: () => void
    viewerRole: SubjectRole
}

const InviteUsersBody: FunctionComponent<InviteUserBodyPropsInterface> = ({
    organizations,
    handleOrganizationChange,
    handleInputChange,
    modalState,
    handleInputBlur,
    viewerRole,
}) => {
    const { newUserState, errorState } = modalState

    const sortedOrganizations = sortArrayByKey(
        organizations,
        (obj) => obj.label,
        false
    )

    return (
        <div className={styles.bodyContainer}>
            <div className={styles.organizationContainer}>
                <div className={styles.organizationTitle}>Organization *</div>
                <Select
                    options={sortedOrganizations}
                    isSearchable={true}
                    onChange={handleOrganizationChange}
                    value={newUserState.organization}
                    styles={reactSelectStyles}
                    placeholder={"- Select -"}
                    onBlur={handleInputBlur}
                />
            </div>
            <div className={styles.instructions}>
                The new user will receive emailed instructions on how to login.
            </div>
            <div className={styles.rowContainer}>
                <div className={styles.row_leftContainer}>
                    <Input
                        orientation="vertical"
                        type="text"
                        value={newUserState.firstName}
                        onChange={handleInputChange}
                        label="First Name *"
                        disabled={false}
                        name="firstName"
                    />
                </div>
                <div className={styles.row_rightContainer}>
                    <Input
                        orientation="vertical"
                        type="text"
                        value={newUserState.lastName}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        label="Last Name *"
                        disabled={false}
                        name="lastName"
                    />
                </div>
            </div>
            <Input
                orientation="vertical"
                type="text"
                value={newUserState.email}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                label="Email * "
                disabled={false}
                name="email"
                message={errorState.email}
                specializedClass={errorState.email ? "danger" : "default"}
            />
            <div className={styles.rowContainer}>
                <div className={styles.row_leftContainer}>
                    <Input
                        onChange={handleInputChange}
                        orientation="vertical"
                        type="select"
                        options={getRoleInvitationOptions(viewerRole)}
                        disabled={false}
                        value={newUserState.inviteeUserRole}
                        label="Role *"
                        name="inviteeUserRole"
                        onBlur={handleInputBlur}
                    />
                </div>
                {/* <div className={styles.row_rightContainer}>
                    <Input
                        orientation="vertical"
                        type="text"
                        value={newUserState.mobileNumber}
                        onChange={handleInputChange}
                        label="Mobile Number "
                        disabled={true}
                        name="mobileNumber"
                        message={
                            errorState.mobileNumber
                                ? errorState.mobileNumber
                                : "Area code and phone number"
                        }
                        specializedClass={
                            errorState.mobileNumber ? "danger" : "default"
                        }
                        onBlur={handleInputBlur}
                    />
                </div> */}
            </div>
            <div className={styles.rowContainer}>
                <div className={styles.row_leftContainer}>
                    <Input
                        onChange={handleInputChange}
                        orientation="vertical"
                        type="select"
                        options={[
                            {
                                label: "Central Time Zone",
                                value: ZoneInfo.CentralTimeZone,
                            },
                            {
                                label: "Eastern Time Zone",
                                value: ZoneInfo.EasternTimeZone,
                            },
                            {
                                label: "Mountain Time Zone",
                                value: ZoneInfo.MountainTimeZone,
                            },
                            {
                                label: "Pacific Time Zone",
                                value: ZoneInfo.PacificTimeZone,
                            },
                        ]}
                        disabled={false}
                        value={newUserState.timezone}
                        label="Time Zone *"
                        name="timezone"
                        onBlur={handleInputBlur}
                    />
                </div>
            </div>
        </div>
    )
}

const reactSelectStyles: StylesConfig<
    OrganizationOptionType,
    false,
    GroupBase<OrganizationOptionType>
> = {
    control: (provided, state) => ({
        ...provided,
        boxSizing: "border-box",
        outline: "none",
        padding: ".0 .25rem",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: state.isFocused ? "#64b5f6" : "#cfd8dc",
        borderRadius: "4px",
        backgroundColor: "white",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#37474f",
        height: "32px",
        boxShadow: state.isFocused
            ? "0px 0px 0px 3.2px rgba(0, 123, 255, 0.25)"
            : "none",
        width: "339px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? "#64b5f6"
            : state.isFocused
            ? "#f5f5f5"
            : "transparent",

        color: state.isSelected ? "white" : "inherit",
    }),
}
