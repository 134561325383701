import { FunctionComponent } from "react"
import { useAuth } from "react-oidc-context"
import { useLocation } from "react-router-dom"

interface PrivateRouteProps {
    children?: React.ReactNode
}

const PrivateAuthenticatedRoutes: FunctionComponent<PrivateRouteProps> = ({
    children,
}) => {
    const auth = useAuth()
    const { search, pathname } = useLocation()
    // path + search parameters
    const pathReference = pathname.concat(search)

    if (auth.isLoading) {
        return <></>
    }

    if (!auth.isAuthenticated) {
        if (!auth.user) {
            auth.signinRedirect({ state: pathReference }).catch(() => {
                throw new Error("unable to redirect")
            })
        } else {
            auth.signinSilent()
                .then((res) => {
                    if (!res) {
                        auth.signinRedirect({ state: pathReference })
                    }
                })
                .catch((err) => {
                    auth.signinRedirect().catch(() => {
                        throw new Error("unable to redirect", err)
                    })
                })
        }
        return <></>
    }

    // we know we are not loading, and that we are authenticated
    return <>{children}</>
}

export default PrivateAuthenticatedRoutes
