import { FunctionComponent, ReactNode } from "react"
import { LabelValueOptionType } from "../../../../../../types/commonTypes"
import { ActionMeta, SingleValue } from "react-select"
import classNames from "classnames"
import styles from "./form.module.scss"
import MutationErrorBanner from "../../../../../shared/graphQlResponse"
import Select from "react-select"
import { baseReactSelectStyles } from "../../../../../../styles/reactSelect"
import ModalFooter from "../../../../../shared/modalFooter"

interface TransferSubscriptionFormComponentI {
    toUserOptions: LabelValueOptionType[]
    selectedUser: LabelValueOptionType | undefined
    mutationLoading: boolean
    mutationErrorBanner: {
        showErrorBanner: boolean
        errMessage: ReactNode
    }
    formDescription: string | ReactNode
    handleSubmitTransfer: () => void
    handleSetSelectedUser: (
        newValue: SingleValue<LabelValueOptionType>,
        actionMeta: ActionMeta<LabelValueOptionType>
    ) => void
}

const TransferSubscriptionForm: FunctionComponent<
    TransferSubscriptionFormComponentI
> = ({
    toUserOptions,
    selectedUser,
    mutationLoading,
    mutationErrorBanner,
    formDescription,
    handleSubmitTransfer,
    handleSetSelectedUser,
}) => {
    const areFormInputsDisabled =
        mutationLoading || mutationErrorBanner.showErrorBanner

    const isSubmitDisabled =
        mutationLoading || mutationErrorBanner.showErrorBanner || !selectedUser

    return (
        <>
            <div
                className={classNames(
                    styles.bodyContainer,
                    mutationLoading && styles.bodyContainerLoading,
                    mutationErrorBanner.showErrorBanner &&
                        styles.bodyContainerError
                )}
            >
                <div className={classNames(styles.row, styles.bodyText)}>
                    {formDescription}
                </div>
                <Select
                    options={toUserOptions}
                    onChange={handleSetSelectedUser}
                    value={selectedUser}
                    isSearchable={true}
                    isDisabled={areFormInputsDisabled}
                    isClearable={true}
                    placeholder={"Select User"}
                    styles={baseReactSelectStyles}
                />
                <div className={styles.infoText}>
                    Subscriptions updated via this form WILL inherit the current
                    well selection, but they will NOT include: channel
                    selection, silence settings, and the “Do Not Disturb
                    Bypass”. These settings must be updated under the
                    transferred subscription.
                </div>
            </div>
            {mutationErrorBanner.showErrorBanner && (
                <MutationErrorBanner message={mutationErrorBanner.errMessage} />
            )}
            <ModalFooter
                advanceText="Save"
                disableAdvance={isSubmitDisabled}
                handleAdvanceClick={handleSubmitTransfer}
            />
        </>
    )
}

export default TransferSubscriptionForm
