import { graphql } from "../../generated"

export const ALERT_EVENT_FIELDS_FRAGMENT = graphql(`
    fragment AlertEventFields on AlertEvent {
        well {
            ...WellIdentificationFields
        }
        alertRule {
            ...AlertRuleFields
        }
        triggeredTime {
            ...DateTimeFields
        }
        triggeredValue
        resolvedTime {
            ...DateTimeFields
        }
        resolvedValue
    }
`)
