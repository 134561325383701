import { FunctionComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquareList } from "@fortawesome/pro-solid-svg-icons"
import "./emptyState.scss"

const EmptyState: FunctionComponent = () => {
    return (
        <div className="action-panel-empty-container">
            <FontAwesomeIcon
                icon={faSquareList}
                className="action-panel-empty-fai"
            />
            <div className="action-panel-empty-heading-text">
                Add/View Well Information
            </div>
            <div className="action-panel-empty-subtext">
                You will need your well&apos;s name <br /> and
                latitude/longitude
            </div>
        </div>
    )
}

export default EmptyState
