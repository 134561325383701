import { graphql } from "../../generated"

export const DEACTIVATE_USER = graphql(`
    mutation DeactivateUsers($UsersDeactivateInput: UsersDeactivateInput!) {
        usersDeactivate(input: $UsersDeactivateInput) {
            users {
                ...SubjectFields
            }
        }
    }
`)
