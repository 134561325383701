import React, { FunctionComponent } from "react"
import classnames from "classnames"
import "./dropDownMenu.scss"
import NavigationControls from "../navigationControls"
import { LabelValueOptionType } from "../../../types/commonTypes"

export interface DropdownProps {
    children: React.ReactNode | React.ReactNode[] | string | number
    options: LabelValueOptionType[]
    dropDownOptionsContainerClass: "dropDown-options-container"
    dropDownOptionClass: "dropDown-option"
    positionClass:
        | "navigation"
        | "add-well-display-api-menu"
        | "navigationMobile"
        | "iwp-sub-nav"
        | "iwp-sub-nav-mobile"
    menuOpen: boolean
    handleDropDownMenuClick: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void
    handleDropDownOptionClick: (event: React.MouseEvent<HTMLDivElement>) => void
    dropDownButtonClass?: "dropDown-button-class"
    handleDocumentClick?: ((event: MouseEvent) => void) | false
    menuRef?: React.RefObject<HTMLDivElement>
    id?: string
}

interface NavigatonalControlDropDownPropsInterface extends DropdownProps {
    navControlsCustomClass?: string
}

const Dropdown: FunctionComponent<DropdownProps> = ({
    children,
    options,
    dropDownButtonClass,
    dropDownOptionsContainerClass,
    dropDownOptionClass,
    positionClass,
    menuOpen,
    handleDropDownMenuClick,
    handleDropDownOptionClick,
    menuRef,
    id,
}) => {
    return (
        <div ref={menuRef} style={{ position: "relative" }}>
            <button
                className={dropDownButtonClass}
                onClick={handleDropDownMenuClick}
                id={id}
            >
                {children}
            </button>
            {menuOpen && (
                <div
                    className={classnames(
                        dropDownOptionsContainerClass,
                        positionClass
                    )}
                >
                    {options.map((option) => (
                        <div
                            className={classnames(
                                dropDownOptionClass,
                                option.disabled && "drop-down-disabled"
                            )}
                            key={option.value}
                            id={`${id}_${option.value}`}
                            onClick={
                                option.disabled
                                    ? undefined
                                    : handleDropDownOptionClick
                            }
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

const NavigationControlDropdown: FunctionComponent<
    NavigatonalControlDropDownPropsInterface
> = ({
    children,
    options,
    dropDownOptionsContainerClass,
    dropDownOptionClass,
    positionClass,
    menuOpen,
    handleDropDownMenuClick,
    handleDropDownOptionClick,
    menuRef,
    id,
}) => {
    return (
        <div ref={menuRef} style={{ position: "relative" }}>
            <NavigationControls
                condition={menuOpen ? "selected" : "default"}
                handleClick={handleDropDownMenuClick}
                usage="nav-bar"
                disabled={false}
            >
                {children}
            </NavigationControls>
            {menuOpen && (
                <div
                    className={classnames(
                        dropDownOptionsContainerClass,
                        positionClass
                    )}
                >
                    {options.map((option) => (
                        <div
                            className={dropDownOptionClass}
                            key={option.value}
                            id={`${id}_${option.value}`}
                            onClick={handleDropDownOptionClick}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Dropdown
export { NavigationControlDropdown }
