import { ReactNode } from "react"
import { AlertRuleMetric } from "../../generated/graphql"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    IconDefinition,
    faArrowDown,
    faArrowUp,
    faBatteryBolt,
    faBatteryFull,
    faBellSlash,
    faBoltLightning,
    faClockRotateLeft,
    faPlugCircleBolt,
    faRouter,
    faStopwatch,
    faTriangleExclamation,
    faWaveform,
    faWifiSlash,
} from "@fortawesome/pro-solid-svg-icons"
import {
    faGaugeMax,
    faMessageSlash,
    faSquareQuestion,
} from "@fortawesome/pro-regular-svg-icons"

// types
type AlertNotificationPriorityKey =
    | "unread_unresolved"
    | "unread_resolved"
    | "read"
    | "silenced"

// maps
const alertNotificationPriorityMap: Record<
    AlertNotificationPriorityKey,
    number
> = {
    unread_unresolved: 3,
    unread_resolved: 2,
    silenced: 1,
    read: 0,
}

const alertNotificatioPriorityColorMap = {
    unread_unresolved: "#D40000", // red
    unread_resolved: "#FFD700", // yellow
    read: "#546E7A", // gray
    silenced: "#546E7A", // gray
} as const

const alertRuleMetricIconMap: Record<
    AlertRuleMetric,
    IconDefinition | undefined
> = {
    [AlertRuleMetric.LossOfPowerActive]: faBoltLightning,
    [AlertRuleMetric.LossOfPowerCount]: faBoltLightning,
    [AlertRuleMetric.LossOfPowerWindow]: faBoltLightning,
    [AlertRuleMetric.UnexpectedDowntimeWindow]: faArrowDown,
    [AlertRuleMetric.UnexpectedRuntimeWindow]: faArrowUp,
    [AlertRuleMetric.LossOfConnectivityActive]: faRouter,
    [AlertRuleMetric.LossOfConnectivityCount]: faRouter,
    [AlertRuleMetric.LossOfConnectivityWindow]: faRouter,
    [AlertRuleMetric.OutOfControlActive]: faTriangleExclamation,
    [AlertRuleMetric.SensorDisconnectDsActive]: faWifiSlash,
    [AlertRuleMetric.SensorDisconnectDsCount]: faWifiSlash,
    [AlertRuleMetric.SensorDisconnectDsWindow]: faWifiSlash,
    [AlertRuleMetric.SensorDisconnectPrActive]: faWifiSlash,
    [AlertRuleMetric.SensorDisconnectPrCount]: faWifiSlash,
    [AlertRuleMetric.SensorDisconnectPrWindow]: faWifiSlash,
    [AlertRuleMetric.RuntimeRange]: faWaveform,
    [AlertRuleMetric.RuntimeFluctuation]: faWaveform,
    [AlertRuleMetric.RunLimitMaxCount]: faGaugeMax,
    [AlertRuleMetric.RunLimitMinCount]: faGaugeMax,
    [AlertRuleMetric.PumpingSpeedRange]: faStopwatch,
    [AlertRuleMetric.PumpingSpeedFluctuation]: faStopwatch,
    [AlertRuleMetric.SensorBatteryVoltageDsWindow]: faBatteryBolt,
    [AlertRuleMetric.SensorBatteryVoltagePrWindow]: faBatteryBolt,
    [AlertRuleMetric.SensorDriverDroppedSamplesDsWindow]: faMessageSlash,
    [AlertRuleMetric.SensorDriverDroppedSamplesPrWindow]: faMessageSlash,
    [AlertRuleMetric.SensorChargingStateDsActive]: faPlugCircleBolt,
    [AlertRuleMetric.SensorChargingStatePrActive]: faPlugCircleBolt,
    [AlertRuleMetric.SensorFullyChargedDsActive]: faBatteryFull,
    [AlertRuleMetric.SensorFullyChargedPrActive]: faBatteryFull,
    [AlertRuleMetric.SensorPowerLastAvailableDs]: faClockRotateLeft,
    [AlertRuleMetric.SensorPowerLastAvailablePr]: faClockRotateLeft,
    [AlertRuleMetric.Unspecified]: faSquareQuestion,
}

// functions
const getIconForAlertRuleMetric = (
    alertRuleMetic: AlertRuleMetric,
    resolved: boolean,
    wasSilenced: boolean,
    notificationRead: boolean
): ReactNode => {
    if (wasSilenced) {
        return (
            <FontAwesomeIcon
                icon={faBellSlash}
                color={alertNotificatioPriorityColorMap.read}
            />
        )
    }

    const icon = alertRuleMetricIconMap[alertRuleMetic]
    if (!icon) {
        return <></>
    }

    const alertNotificationPriorityKey = getAlertNotificationPriorityKey(
        notificationRead,
        resolved,
        wasSilenced
    )

    const color = alertNotificatioPriorityColorMap[alertNotificationPriorityKey]
    return <FontAwesomeIcon icon={icon} color={color} />
}

const getAlertNotificationPriorityKey = (
    notificationRead: boolean,
    resolved: boolean,
    notificationSilenced: boolean
): AlertNotificationPriorityKey => {
    if (notificationSilenced) {
        return "silenced"
    }

    if (notificationRead) {
        return "read"
    }

    return resolved ? "unread_resolved" : "unread_unresolved"
}

const getAlertNotificationPriority = (
    notificationRead: boolean,
    resolved: boolean,
    notificationSilenced: boolean
) => {
    const key = getAlertNotificationPriorityKey(
        notificationRead,
        resolved,
        notificationSilenced
    )

    return alertNotificationPriorityMap[key]
}

export { getIconForAlertRuleMetric, getAlertNotificationPriority }
