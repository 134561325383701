import { graphql } from "../../generated"

export const GET_SUBSCRIPTIONS_FOR_ORG = graphql(`
    query GetSubscriptionsForOrg($input: AlertSubscriptionsForOrgsInput!) {
        subscriptionsForOrgs(input: $input) {
            subscriptions {
                ...SubscriptionFieldsForAdminSubscriptionTables
            }
        }
    }
`)
