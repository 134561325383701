import { getFragmentData } from "../generated"
import { ControlSettingFieldsFragment } from "../generated/graphql"
import {
    CONTROL_SETTING_CONSTANT_IDLE_FIELDS_FRAGMENT,
    CONTROL_SETTING_OFF_FIELDS_FRAGMENT,
    CONTROL_SETTING_ON_FIELDS_FRAGMENT,
    CONTROL_SETTING_TIMER_FIELDS_FRAGMENT,
    CONTROL_SETTING_UNSPECIFIED_FIELDS_FRAGMENT,
} from "../graphql/fragments/wellControlSetting"
import { ControlSettingFragmentFieldsInterface } from "../types/controlSettingTypes"

/**
 *
 * Accepts controlSettingFields and returns the runMode and fragment fields for each supported well control fragment.
 * This is useful because components can pass a well control query result and be returned the runMode and the fragment fields depenent on the fragment type,
 * The run mode   is commonly displayed in the UI.
 * The fragment fields are needed for display and various methods in well controls and well index table.
 *  Fragment fields can be accessed by type narrowing result.__typename
 *
 * @param {ControlSettingFieldsFragment} controlSettingFragment - The control setting fragment queried from well control settings.
 * @returns {ControlSettingFragmentFieldsInterface}
 * @throws {Error}
 *
 */
export const getWellControlSettingFragmentFieldsUnion = (
    controlSettingFragment: ControlSettingFieldsFragment
): ControlSettingFragmentFieldsInterface => {
    if (!controlSettingFragment.__typename) {
        throw new Error(" parse well controls.  unexpected typename undefined.")
    }

    switch (controlSettingFragment.__typename) {
        case "WellControlSettingConstantIdle":
            return {
                runMode: "Smart",
                controlSettingFragmentFields: getFragmentData(
                    CONTROL_SETTING_CONSTANT_IDLE_FIELDS_FRAGMENT,
                    controlSettingFragment
                ),
            }

        case "WellControlSettingTimer":
            return {
                runMode: "Timer",
                controlSettingFragmentFields: getFragmentData(
                    CONTROL_SETTING_TIMER_FIELDS_FRAGMENT,
                    controlSettingFragment
                ),
            }

        case "WellControlSettingOff":
            return {
                runMode: "Off",
                controlSettingFragmentFields: getFragmentData(
                    CONTROL_SETTING_OFF_FIELDS_FRAGMENT,
                    controlSettingFragment
                ),
            }

        case "WellControlSettingOn":
            return {
                runMode: "On",
                controlSettingFragmentFields: getFragmentData(
                    CONTROL_SETTING_ON_FIELDS_FRAGMENT,
                    controlSettingFragment
                ),
            }

        case "WellControlSettingUnspecified":
            return {
                runMode: "Off",
                controlSettingFragmentFields: getFragmentData(
                    CONTROL_SETTING_UNSPECIFIED_FIELDS_FRAGMENT,
                    controlSettingFragment
                ),
            }
    }
}
