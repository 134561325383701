import { graphql } from "../../generated"

export const IDENTITY_FIELDS_FRAGMENT = graphql(`
    fragment IdentityFields on Identity {
        email
        emailVerified
        telephoneNumber {
            ...NorthAmericanPhoneNumberFields
        }
        telephoneNumberVerified
        active
        firstName
        lastName
        fullName
        zoneInfo
        smsConsent
        emailConsent
    }
`)

export const IDENTITY_NORTH_AMERICAN_PHONE_NUMBER_FRAGMENT = graphql(`
    fragment NorthAmericanPhoneNumberFields on NorthAmericanPhoneNumber {
        concise: formatted(format: CONCISE)
        formatted
        areaCode
        centralOfficeCode
        lineNumber
    }
`)
