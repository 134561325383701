import { FunctionComponent, ReactNode } from "react"
import styles from "./adminTableOperation.module.scss"
import Button from "../../../shared/button"

interface AdminTableOperationsPropsInterface {
    handleButtonClick?: () => void
    label: string
    showButton: boolean
}

const AdminTableOperations: FunctionComponent<
    AdminTableOperationsPropsInterface
> = ({ handleButtonClick, label, showButton }) => {
    let button: ReactNode = null

    if (showButton && handleButtonClick) {
        button = (
            <Button
                handleButtonClick={handleButtonClick}
                status="primary"
                condition="default"
            >
                {label}
            </Button>
        )
    } else {
        button = <></>
    }

    return <div className={styles.container}>{button}</div>
}

export default AdminTableOperations
