import {
    AddWellInput,
    AddWellsMutationVariables,
    ApiNumberInput,
} from "../../generated/graphql"
import { OrganizationOptionType } from "../../types/ViewerTypes"
import { AddWellsWellDataInterface } from "../../types/addWellsTypes"
import { isWellAPINumberFormatValid } from "../wellAttributes/formValidation"
import {
    ADD_WELLS_ALREADY_EXISTS_IN_DISPLAY_PANEL,
    ADD_WELLS_API_INCORRECT_FORMAT,
} from "./errorMessages"

export const validateWellApiNumberInput = (
    wellApiNumber: string,
    displayPanelArray: AddWellsWellDataInterface[]
) => {
    const isFormatCorrect = isWellAPINumberFormatValid(wellApiNumber)

    if (isFormatCorrect === false) {
        return ADD_WELLS_API_INCORRECT_FORMAT
    }

    const isAlreadyAdded = displayPanelArray.findIndex(
        (obj) => obj.WellAttributesForm.apiNumber === wellApiNumber
    )

    if (isAlreadyAdded !== -1) {
        return ADD_WELLS_ALREADY_EXISTS_IN_DISPLAY_PANEL
    }

    return "validated"
}

/**
 * canMoveOnToConfirmation
 * can user navigate from 'addNew' UI interface to 'confirmation interface'
 */
export const canMoveOnToConfirmation = (
    displayPanelLength: number,
    selectedOrganization: OrganizationOptionType | undefined
) => {
    if (displayPanelLength > 0 && selectedOrganization !== undefined) {
        return true
    }
    return false
}

/** Format add wells display panel into variables for addWellsMutation. */
export const formatAddWellsMutaionVariables = (
    array: AddWellsWellDataInterface[],
    organizationId: string
) => {
    const resultArray: Array<AddWellInput> = []

    for (let i = 0; i < array.length; i++) {
        let apiNumberVariable: ApiNumberInput
        const apiNumber = array[i].WellAttributesForm.apiNumber

        if (apiNumber === false) {
            apiNumberVariable = {
                by: {
                    unknown: true,
                },
            }
        } else {
            apiNumberVariable = {
                by: {
                    format: apiNumber,
                },
            }
        }

        const obj: AddWellInput = {
            apiNumber: apiNumberVariable,
            name: array[i].WellAttributesForm.WellName,
            organizationID: organizationId,
            location: {
                by: {
                    coordinates: {
                        latitude: array[i].WellAttributesForm.Latitude,
                        longitude: array[i].WellAttributesForm.Longitude,
                    },
                },
            },
        }

        resultArray.push(obj)
    }

    const result: AddWellsMutationVariables = {
        addWellsInput: {
            wells: resultArray,
        },
    }
    return result
}
