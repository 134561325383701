import { FunctionComponent, ReactNode } from "react"
// components
import Button from "../shared/button"
// scss & fai
import styles from "./paginator.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons"

interface PaginationPropsInterface {
    handlePaginationNext: () => void
    handlePaginationPrevious: () => void
    totalPages: number | undefined
    currentPage: number
    disabled: boolean
}

const Paginator: FunctionComponent<PaginationPropsInterface> = ({
    handlePaginationNext,
    handlePaginationPrevious,
    totalPages,
    currentPage,
    disabled,
}) => {
    let pageCount: ReactNode
    switch (totalPages) {
        case 0:
            pageCount = `Page 0  of 0`
            break
        default:
            pageCount = `Page ${currentPage} of ${totalPages}`
    }

    return (
        <div className={styles.container}>
            <div className={styles.arrowContainer}>
                <Button
                    disabled={disabled}
                    handleButtonClick={handlePaginationPrevious}
                    status="secondary"
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
            </div>
            <div className={styles.textContainer}>{pageCount}</div>
            <div className={styles.arrowContainer}>
                <Button
                    disabled={disabled}
                    handleButtonClick={handlePaginationNext}
                    status="secondary"
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
        </div>
    )
}

export default Paginator
