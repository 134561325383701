import { FunctionComponent } from "react"
import styles from "./cell.module.scss"
import MultiSelect, {
    MultiSelectOptionT,
} from "../../../../shared/input/react-select/multiSelect"
import { SubscriptionsAdminWellNameSep } from "../../adminSubscriptionsViews/tableTypes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBellSlash } from "@fortawesome/pro-solid-svg-icons"
import { unixMsToLocalDateTimeDisplay } from "../../../../../util/datesAndTimes/datesAndTimes"
import { NOTIFICATIONS_ADMIN_UPDATE_SUBSCRIPTION_CHILD } from "../../../../.."
import { Link } from "react-router-dom"
import ToolTip_Popover from "../../../../shared/toolTip/toolTip_popover"

interface SubscriberReceiveNotificationsCellI {
    accept: boolean | "DISABLED"
    subscriptionId: string
}

const SubscriberReceiveNotificationsCell: FunctionComponent<
    SubscriberReceiveNotificationsCellI
> = ({ accept, subscriptionId }) => {
    if (accept === "DISABLED") {
        return (
            <span className={styles.receiveNotificationsDisabled}>
                DISABLED
            </span>
        )
    }

    return (
        <Link
            className={styles.receiveNotifications}
            to={`${subscriptionId}/${NOTIFICATIONS_ADMIN_UPDATE_SUBSCRIPTION_CHILD}`}
        >
            <span>{accept ? "ON" : "OFF"}</span>
        </Link>
    )
}

interface SubscriberWellsCellI {
    wells: string
}
const SubscriberWellsCell: FunctionComponent<SubscriberWellsCellI> = ({
    wells,
}) => {
    const wellsArray = wells.split(SubscriptionsAdminWellNameSep)
    const multiSelectOptions: MultiSelectOptionT[] = wellsArray.map((well) => {
        return { label: well, value: well, isDisabled: true, isSelected: true }
    })

    return (
        <MultiSelect
            onChange={() => {
                return
            }}
            options={multiSelectOptions}
            menuPortalTarget={document.body}
            isClearable={false}
            styles={{
                container: (provided) => ({
                    ...provided,
                    width: 400,
                }),
                control: (provided) => ({
                    ...provided,
                    width: "100%", // This ensures the control fills the container
                    minHeight: "30px",
                    height: "30px",
                    background: "#CACACA",
                    color: "#fff",
                    fontWeight: "500",
                    borderColor: "#909090",
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    height: "30px",
                    padding: "0 6px",
                }),
                input: (provided) => ({
                    ...provided,
                    margin: "0px",
                }),
                indicatorSeparator: () => ({
                    display: "none",
                }),
                indicatorsContainer: (provided) => ({
                    ...provided,
                    height: "30px",
                }),
                option: (provided) => ({
                    ...provided,
                    backgroundColor: "#fff",
                    color: "#000",
                    ":hover": {
                        backgroundColor: "none",
                    },
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    color: "#1E88E5",
                    ":hover": {
                        color: "#1E88E5",
                    },
                }),
            }}
        />
    )
}

interface SubscriptionShowSilencedCellI {
    cellValue: string
    silenced: number | false
}

const SubscriptionShowSilenceCell: FunctionComponent<
    SubscriptionShowSilencedCellI
> = ({ cellValue, silenced }) => {
    return (
        <ToolTip_Popover
            triggerChildren={
                <div className={styles.subscriptionCellContainer}>
                    {silenced && (
                        <FontAwesomeIcon
                            className={styles.silencedIcon}
                            icon={faBellSlash}
                        />
                    )}
                    {cellValue}
                </div>
            }
            content={
                <div>
                    <span>{cellValue}</span>
                    {silenced && (
                        <>
                            <br />
                            <span>Silenced until:</span>
                            <span className={styles.dateTimeDisplay}>
                                {unixMsToLocalDateTimeDisplay(silenced, "-")}
                            </span>
                        </>
                    )}
                </div>
            }
            popover={{
                removePopoverButtonStyling: true,
                side: "top",
                triggerStyles: styles.popoverTriggerStyles,
            }}
            tooltip={{
                side: "top",
            }}
        />
    )
}

export {
    SubscriberReceiveNotificationsCell,
    SubscriberWellsCell,
    SubscriptionShowSilenceCell,
}
