import { FunctionComponent, useState, useEffect } from "react"
import { useAddWellsContext } from "../../../context/addWellsContext"
// components
import DropDown from "../../shared/dropDown"
import { DisplayPanelEmptyState } from "."
// scss & FAI
import styles from "./listView.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons"
// utils
import { findIndexByKeyInArray } from "../../../util/arrays/array"

const ListView: FunctionComponent = () => {
    const {
        addWellsState: { displayPanelArray, actionPanel },
        handleSetActionPanelToPreview,
        handleDeleteWellFromDisplayPanel,
    } = useAddWellsContext()

    /** State used to set active styling to display panel rows & open/close menus. */
    const [listViewState, setListViewState] = useState({
        openMenu: "allClosed",
        activeStyling: "allClosed",
    })

    // component handlers
    /** close all menus on clicks outside of display panel rows */
    const handleDocumentClick = () => {
        // updated state based on previous state in queue. For details on this syntax see : https://react.dev/learn/queueing-a-series-of-state-updates
        setListViewState((prevState) => ({
            ...prevState,
            openMenu: "allClosed",
        }))
    }

    /**
     * handle click on display panel row.
     * setting active styling for the the selected row & close open menus.
     * set action panel to preview selected well.
     */
    const handleRowClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        const currentTarget = (e.currentTarget as HTMLElement).id
        // find the index of the clicked well.
        const index = findIndexByKeyInArray(
            displayPanelArray,
            (obj) => obj.addWellModalId,
            currentTarget
        )

        if (index !== undefined) {
            handleSetActionPanelToPreview(displayPanelArray[index])
            setListViewState({
                ...listViewState,
                activeStyling: currentTarget,
                openMenu: "allClosed",
            })
        } else {
            throw new Error(
                " Unexpected behavior add wells panel display: handleRowClick"
            )
        }
    }

    /**
     * handle  clicks on the drop down menu of display rows
     * clicking on the menu will open the menu
     * once the menu is open, clicking delete will delete a well
     * click anywhere outside of the the menu to close the menu
     */
    const handleWellMenuClick = (
        e:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation() //stop handleDocumentClick document click from firing
        const currentTarget = (e.currentTarget as HTMLElement).id

        // when the menu is clicked on.
        // the id on the menu is the addWellModalId
        // findWellIndexInDisplayPanel will return the index of the clicked well.
        // the active styling and open menu state is set for the selected well.
        // the well is set to preview in the action panel.
        const index = findIndexByKeyInArray(
            displayPanelArray,
            (obj) => obj.addWellModalId,
            currentTarget
        )
        if (index !== undefined) {
            handleSetActionPanelToPreview(displayPanelArray[index])
            setListViewState({
                openMenu: currentTarget,
                activeStyling: currentTarget,
            })

            return
        }

        // when delete is clicked.
        // the id on the menu delete option is ${addWellModalId}_Delete.
        // when the delete button is clicked, deleteIndex will be equal to the index of the clicked delete menu
        // the well data is deleted, and open menu and active styling are closed.

        const deleteIndex = displayPanelArray.findIndex((well) => {
            return `${well.addWellModalId}_delete` === currentTarget
        })

        if (deleteIndex > -1) {
            handleDeleteWellFromDisplayPanel(displayPanelArray[deleteIndex])
            setListViewState({
                ...listViewState,
                openMenu: "allClosed",
                activeStyling: "allClosed",
            })
            return
        }
    }

    // effects
    useEffect(() => {
        // add event listner to document. Used to close row menu on clicks outside menu
        document.addEventListener("click", handleDocumentClick)
        // remove event listner when component is unmounted
        return () => {
            document.removeEventListener("click", handleDocumentClick)
        }
    }, [displayPanelArray])

    useEffect(() => {
        if (actionPanel === "empty") {
            setListViewState((prevState) => ({
                ...prevState,
                openMenu: "allClosed",
                activeStyling: "allClosed",
            }))
        }
    }, [actionPanel])

    if (displayPanelArray.length === 0 && actionPanel === "empty") {
        return <DisplayPanelEmptyState />
    }

    // display panel row
    const addedWellSites = displayPanelArray.map(
        ({ addWellModalId, WellAttributesForm: { apiNumber, WellName } }) => {
            const wellStylingStatus =
                addWellModalId === listViewState.activeStyling ? true : false
            const menu =
                listViewState.openMenu === addWellModalId ? true : false

            return (
                <Row
                    key={addWellModalId}
                    apiNumber={apiNumber}
                    menu={menu}
                    wellStylingStatus={wellStylingStatus}
                    handleRowClick={handleRowClick}
                    handleWellMenuClick={handleWellMenuClick}
                    wellName={WellName}
                    addWellModalId={addWellModalId}
                />
            )
        }
    )

    return (
        <div className={styles.container}>
            <div className={styles.title}>Added Well Sites</div>
            <div className={styles.arrayContainer}>{addedWellSites}</div>
        </div>
    )
}

export default ListView

interface RowProps {
    wellStylingStatus: boolean
    apiNumber: string | false
    menu: boolean
    handleRowClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    handleWellMenuClick: (
        e:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void
    wellName: string
    addWellModalId: string
}

const Row: FunctionComponent<RowProps> = ({
    wellStylingStatus,
    apiNumber,
    handleRowClick,
    menu,
    handleWellMenuClick,
    wellName,
    addWellModalId,
}) => {
    return (
        <div
            className={
                wellStylingStatus
                    ? styles.activeStyling
                    : styles.inactiveStyling
            }
            id={addWellModalId}
            onClick={handleRowClick}
        >
            <div className={styles.apiNumber}>
                {apiNumber || wellName || ""}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <DropDown
                    options={[{ label: "Delete", value: "delete" }]}
                    dropDownButtonClass="dropDown-button-class"
                    dropDownOptionsContainerClass="dropDown-options-container"
                    dropDownOptionClass="dropDown-option"
                    positionClass="add-well-display-api-menu"
                    handleDropDownMenuClick={handleWellMenuClick}
                    handleDropDownOptionClick={handleWellMenuClick}
                    menuOpen={menu}
                    id={addWellModalId}
                >
                    <FontAwesomeIcon
                        color={wellStylingStatus ? "#fff" : "#1E88E5"}
                        icon={faEllipsis}
                    />
                </DropDown>
            </div>
        </div>
    )
}
