import { objectHasNonUndefinedFields } from "../../../../util/objects/objects"
import { isValidPhoneNumber } from "../../../../util/users"
import {
    UpdateProfilePhoneNumberFormErrorI,
    UpdateProfilePhoneNumberFormI,
} from "./types"

const getErrorsFromNotificationsPhoneNumberForm = (
    form: UpdateProfilePhoneNumberFormI
): UpdateProfilePhoneNumberFormErrorI => {
    const errors: UpdateProfilePhoneNumberFormErrorI = {
        phoneNumber: undefined,
    }

    const validPhoneNumber = isValidPhoneNumber(form.phoneNumber)
    if (!validPhoneNumber) {
        errors.phoneNumber = "Please enter a valid phone number."
    }

    return errors
}

const isNotificationsPhoneNumberFormDisabled = (
    form: UpdateProfilePhoneNumberFormI
): boolean => {
    const errors = getErrorsFromNotificationsPhoneNumberForm(form)
    return objectHasNonUndefinedFields(errors)
}

export {
    getErrorsFromNotificationsPhoneNumberForm,
    isNotificationsPhoneNumberFormDisabled,
}
