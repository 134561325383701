import { OrganizationsAddMutationVariables } from "../generated/graphql"
import {
    AddOrganizationModalStateInterface,
    AddedOrganizationInterface,
} from "../types/adminOrganizationTypes/addOrganizationModalTypes"

const disableSubmitAddOrganization = (
    organization: AddedOrganizationInterface
) => {
    const { organizationName } = organization

    if (organizationName.length === 0) return true

    return false
}

const getAddOrganiztionMutationVariables = (
    addOrganizationModalState: AddedOrganizationInterface
) => {
    /** If parent organization id is undefined */
    if (!addOrganizationModalState.parentOrganizationId?.value) {
        const addOrganizationMutationVariables: OrganizationsAddMutationVariables =
            {
                OrganizationsAddInput: {
                    organizations: [
                        {
                            name: addOrganizationModalState.organizationName,
                        },
                    ],
                },
            }
        return addOrganizationMutationVariables
    } else {
        /** parent organization id has been changed */
        const addOrganizationMutationVariables: OrganizationsAddMutationVariables =
            {
                OrganizationsAddInput: {
                    organizations: [
                        {
                            name: addOrganizationModalState.organizationName,
                            parentOrganizationId:
                                addOrganizationModalState.parentOrganizationId
                                    .value,
                        },
                    ],
                },
            }
        return addOrganizationMutationVariables
    }
}

const initialAddOrganizationModalState: AddOrganizationModalStateInterface = {
    disableSubmit: true,
    organizationState: {
        organizationName: "",
        parentOrganizationId: undefined,
        organizationActivationStatus: true,
    },
}

export {
    disableSubmitAddOrganization,
    initialAddOrganizationModalState,
    getAddOrganiztionMutationVariables,
}
