import { useQuery } from "@apollo/client"
import { FunctionComponent, ReactNode } from "react"
import { Outlet } from "react-router-dom"
import {
    EmptyState,
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../shared/graphQlResponse"
import styles from "./myNotifications.module.scss"
import { getFragmentData } from "../../../../generated"
import { GET_NOTIFICATIONS_FOR_USER } from "../../../../graphql/queries/notificationsForUser"
import { NOTIFICATION_FRAG_MY_NOTIFICATIONS } from "../../../../graphql/fragments/notifications"
import { getMyNotificationsTableFromNotificationFrag } from "./table/tableUtil"
import MyNotificationsTable from "./table/table"

const MyNotifications: FunctionComponent = () => {
    const { error, loading, data } = useQuery(GET_NOTIFICATIONS_FOR_USER, {
        fetchPolicy: "network-only",
    })

    const renderContent = (): ReactNode => {
        if (error) {
            console.error("error", error)
            return (
                <ErrorMessage
                    outSideDivStyle={styles.error}
                    message="There was a problem fetching your notifications."
                />
            )
        }

        if (loading) {
            return <SyncLoaderComponent outSideDivStyle={styles.loader} />
        }

        if (data) {
            const notifications = data.notificationsForUser.notifications.map(
                (n) => getFragmentData(NOTIFICATION_FRAG_MY_NOTIFICATIONS, n)
            )

            const formatted =
                getMyNotificationsTableFromNotificationFrag(notifications)

            if (formatted.length === 0) {
                return <NotificationsEmptyState />
            }
            return <MyNotificationsTable data={formatted} />
        }

        return <></>
    }

    return (
        <>
            <Outlet />
            <div className={styles.container}>{renderContent()}</div>
        </>
    )
}
export default MyNotifications

const NotificationsEmptyState: FunctionComponent = () => {
    return (
        <EmptyState outSideDivStyle={styles.emptyStateContainer}>
            <div className={styles.emptyTitle}>
                There are no notifications to view.
            </div>
        </EmptyState>
    )
}
