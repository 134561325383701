import { graphql } from "../../generated"

export const USER_INVITE = graphql(`
    mutation AddUsers($UsersAddInput: UsersAddInput!) {
        usersAdd(input: $UsersAddInput) {
            users {
                ...SubjectFields
            }
        }
    }
`)
