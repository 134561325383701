import { FunctionComponent } from "react"

//types
import { AddWellsWellDataInterface } from "../../../types/addWellsTypes"
// scss
import styles from "./confirm.module.scss"
import { useAddWellsContext } from "../../../context/addWellsContext"

const AddWellsConfirmation: FunctionComponent = () => {
    const {
        addWellsState: { displayPanelArray },
    } = useAddWellsContext()

    return (
        <>
            <div className={styles.bodyHeader}>
                Please double check your data before submitting it. If any
                changes are needed, click Previous.
            </div>
            <div className={styles.container}>
                <WellApiConfirmationRow confirmAddedWells={displayPanelArray} />
            </div>
        </>
    )
}

interface WellApiConfirmationRowInterface {
    confirmAddedWells: AddWellsWellDataInterface[]
}

const WellApiConfirmationRow: FunctionComponent<
    WellApiConfirmationRowInterface
> = ({ confirmAddedWells }) => {
    return (
        <>
            {confirmAddedWells.map((well) => {
                return (
                    <div
                        className={styles.rowContainer}
                        key={well.addWellModalId}
                    >
                        <div className={styles.apiRow}>
                            API #: {well.WellAttributesForm.apiNumber || "N/A"}
                        </div>
                        <div className={styles.row}>
                            <div className={styles.rowHeading}>Well Name:</div>
                            <div className={styles.rowTextWellName}>
                                {well.WellAttributesForm.WellName}
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.rowHeading}>Latitude:</div>
                            <div className={styles.rowText}>
                                {well.WellAttributesForm.Latitude}
                            </div>
                            <div className={styles.rowHeading}>Longitude:</div>
                            <div className={styles.rowText}>
                                {well.WellAttributesForm.Longitude}
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default AddWellsConfirmation
