import { FunctionComponent } from "react"
import { useAuth } from "react-oidc-context"
import { useNavigate } from "react-router-dom"
import OutsideAppLoader from "../shared/outsideAppLoader"

const Login: FunctionComponent = () => {
    const navigate = useNavigate()
    const auth = useAuth()

    // if Auth is done loading
    if (!auth.isLoading) {
        // and user is authenticated => Navigate to dashboard.
        if (auth.isAuthenticated) {
            setTimeout(() => {
                navigate("/dashboard")
            }, 3000)
            return (
                <OutsideAppLoader
                    message={
                        "You are already logged in. Returning to your dashboard."
                    }
                />
            )
        } else if (!auth.isAuthenticated) {
            // and user is not authenticated...
            if (!auth.user) {
                // there is no user, redirect to signin
                auth.signinRedirect().catch(() => {
                    throw new Error("unable to redirect")
                })
            } else {
                // there is a user, try to refresh
                auth.signinSilent()
                    .then((res) => {
                        // refresh fails, redirect to signin
                        if (!res) {
                            auth.signinRedirect()
                        }
                    })
                    // catch any other errors, redirect to signin
                    // invalid/revoked tokens do NOT trigger catch,
                    // have to check res directly from signinSilent calls
                    .catch((err) => {
                        console.log("unable to redirect", err)
                        auth.signinRedirect()
                    })
            }
            return <OutsideAppLoader />
        }
    }
    return <></>
}

export default Login
