import { FunctionComponent } from "react"
import styles from "./unauthorizedModal.module.scss"
import Button from "../shared/button"
import { useNavigate } from "react-router-dom"
import Modal from "../shared/modalContainer"

const UnauthorizedModal: FunctionComponent = () => {
    return (
        <Modal>
            <UnauthorizedModalBody />
        </Modal>
    )
}

const UnauthorizedModalBody = () => {
    const navigate = useNavigate()

    return (
        <div className={styles.container}>
            <div className={styles.text}>
                Your user role does not grant you access to this page.
            </div>
            <Button
                handleButtonClick={() => {
                    navigate("/dashboard")
                }}
                status="primary"
                condition="default"
            >
                Return
            </Button>
        </div>
    )
}

export default UnauthorizedModal
export { UnauthorizedModalBody }
