import React from "react"
import styles from "./outsideAppLoader.module.scss"

interface OutsideAppLoaderPropsI {
    message?: React.ReactNode
}

const OutsideAppLoader: React.FC<OutsideAppLoaderPropsI> = ({ message }) => {
    return (
        <div className={styles.container}>
            <img
                src="/petroPowerLogo.png"
                alt="PetroPower Trex Branding Image"
            />
            <div className={styles.message}>{message}</div>
        </div>
    )
}

export default OutsideAppLoader
