import { graphql } from "../../generated"

export const WELL_CONTROL_OBSERVATION_STATE_FIELDS_FRAGMENT = graphql(`
    fragment WellControlObservationStateFields on WellControlObservationState {
        state
    }
`)

export const WELL_CONTROL_STATE_FIELDS_FRAGMENT = graphql(`
    fragment WellControlStateFields on WellState {
        observation {
            ...WellControlObservationStateFields
        }
    }
`)
