import {
    NOTIFICATIONS_RULES_ABS_ROUTE,
    NOTIFICATIONS_SUBSCRIPTION_ABS_ROUTE,
    NOTIFICATIONS_SUBSCRIPTION_BY_RULE_ABS_ROUTE,
    NOTIFICATIONS_SUBSCRIPTION_BY_USER_ABS_ROUTE,
} from "../.."
import { NavigateFunction } from "react-router-dom"

type NavigateToUrlType = { navigateTo: string }

type NavigateBackType = {
    navigateTo: -1
    locationKey: string
    pathName: string // provide the path name so that we can navigate back to the root route when navigateTo is -1 and locationKey is "default"
}

export type TrexNavigatorOptions = NavigateBackType | NavigateToUrlType

class TrexNavigator {
    /**
     * This class should be used to navigate in trex ngweb.
     * The consumer should use react router - useNavigate & useLocation (location.key) hooks in the constructor parameters for navigate and locationKey when necessary.
     * - More configurable navigation options are available on navigate , which can be added to this class to satisfy future use cases.
     *
     * If the consumer of the class wants to construct -1 as the navigateTo , they must provide a location key to assure there
     * is a previous location in the history stack that is within the trex application. When location.key === 'default', the consumer will be redirected to /dashboard.
     *
     * See useNavigate & useLocation documentation for details
     *
     */

    private navigationOptions: TrexNavigatorOptions
    private navigate: NavigateFunction

    constructor(
        navigationOptions: TrexNavigatorOptions,
        navigate: NavigateFunction
    ) {
        this.navigationOptions = navigationOptions
        this.navigate = navigate
    }

    // used to navigate back to the base route when locationKey is "default"
    private getBaseRoute(path: string): string {
        // handle notification routes so we can more accurately navigate back to the base navigation route
        const notificationRoutes = [
            NOTIFICATIONS_RULES_ABS_ROUTE,
            NOTIFICATIONS_SUBSCRIPTION_BY_RULE_ABS_ROUTE,
            NOTIFICATIONS_SUBSCRIPTION_BY_USER_ABS_ROUTE,
            // this route needs to be last in the array to avoid matching with other routes
            NOTIFICATIONS_SUBSCRIPTION_ABS_ROUTE,
        ]

        const matchedNotificationRoute = notificationRoutes.find((route) =>
            path.includes(route)
        )
        if (matchedNotificationRoute) {
            return matchedNotificationRoute
        }

        // handle other routes
        const defaultRoute = "/dashboard"
        const match = path.match(/^\/([^/]+)/)
        return match ? `/${match[1]}` : defaultRoute
    }

    public trexNavigate() {
        switch (this.navigationOptions.navigateTo) {
            case -1:
                if (this.navigationOptions.locationKey === "default") {
                    const navTo = this.getBaseRoute(
                        this.navigationOptions.pathName
                    )
                    this.navigate(navTo)
                } else {
                    this.navigate(-1)
                }
                break
            default:
                this.navigate(this.navigationOptions.navigateTo)
        }
    }
}

export { TrexNavigator }
