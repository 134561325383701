import { graphql } from "../../generated"

export const GET_USER_SETTINGS_DND_FRAGMENT = graphql(`
    fragment UserSettingsDnDSetting on DnDSetting {
        id
        subjectID
        active
        fromTime {
            hour
            minute
            second
        }
        toTime {
            hour
            minute
            second
        }
    }
`)
