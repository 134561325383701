import { graphql } from "../../../generated"

export const GET_USER_DND_SETTINGS_BY_SUBJECT_ID = graphql(`
    query GetDndSettingsBySubjectID(
        $getDndSettingsBySubjectIDInput: DnDSettingsBySubjectIDInput!
    ) {
        dnDSettingBySubjectID(input: $getDndSettingsBySubjectIDInput) {
            settings {
                ...UserSettingsDnDSetting
            }
        }
    }
`)
