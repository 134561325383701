import { graphql } from "../../generated"

export const ALERT_RULE_DELETE = graphql(`
    mutation rulesDelete($input: RulesDeleteInput!) {
        rulesDelete(input: $input) {
            IDs
            __typename
        }
    }
`)
