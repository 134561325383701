import React, { FunctionComponent, useState } from "react"
import styles from "./form.module.scss"
import ModalFooter from "../../../shared/modalFooter"
import { useNavigate } from "react-router-dom"
import Input from "../../../shared/input"
import { USER_SETTINGS_ABS_ROUTE } from "../../../.."
import { UpdateProfileEmailFormI, UpdateProfileEmailFormErrorI } from "../types"
import MutationErrorBanner from "../../../shared/graphQlResponse"
import { getErrorsFromNotificationsEmailForm } from "../updateProfileEmailForm/validation"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons"
import { useMutation } from "@apollo/client"
import { UPDATE_USERS } from "../../../../graphql/mutations/update_users"
import { UPDATE_CONSENT } from "../../../../graphql/mutations/verify"
import { AttributeType } from "../../../../generated/graphql"
import { Toggle } from "../../../shared/selectionControls"
import { VIEWER } from "../../../../graphql/queries/viewer"
import { objectHasNonUndefinedFields } from "../../../../util/objects/objects"

const UpdateEmailFormComponent: FunctionComponent<{
    userId: string
    email: string
    emailVerified: boolean
    emailConsent: boolean
}> = ({ userId, email, emailVerified, emailConsent }) => {
    const navigate = useNavigate()

    // mutations
    const [updateProfileMutation, { loading: updateProfileMutationLoading }] =
        useMutation(UPDATE_USERS)

    const [updateConsentMutation] = useMutation(UPDATE_CONSENT)

    // form state
    const [form, setForm] = useState<UpdateProfileEmailFormI>({
        email: email,
        allowNotifications: emailConsent,
        dirty: false,
    } as UpdateProfileEmailFormI)

    const [formErrors, setFormErrors] = useState<UpdateProfileEmailFormErrorI>({
        email: undefined,
    })

    const [mutationSuccess] = useState(false)
    const [mutationErrorBanner, setMutationErrorBanner] = useState(false)

    // form handlers
    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = e.target.value.trimStart().trimEnd()
        const updatedForm = {
            ...form,
            email: targetValue,
            dirty: true,
        }

        // if there is a form error and user corrects it, we can clear it on valid input instead of waiting for blur.
        // if there is no current  error we should wait for blur.
        let updatedErrors = { ...formErrors }
        if (formErrors.email) {
            updatedErrors = getErrorsFromNotificationsEmailForm(updatedForm)
        }

        setForm(updatedForm)
        setFormErrors(updatedErrors)
    }

    // blur handlers
    const handleEmailBlur = () => {
        const updatedErrors = getErrorsFromNotificationsEmailForm(form)
        setFormErrors(updatedErrors)
    }

    // submission handler
    const handleSubmit = async () => {
        try {
            if (emailConsent !== form.allowNotifications) {
                await updateConsentMutation({
                    variables: {
                        ConsentUpdateInput: {
                            consent: form.allowNotifications,
                            type: AttributeType.Email,
                        },
                    },
                    refetchQueries: [{ query: VIEWER }],
                })
            }

            if (email !== form.email) {
                await updateProfileMutation({
                    variables: {
                        UserUpdateInput: {
                            userID: userId,
                            email: form.email,
                        },
                    },
                })
            }

            navigate(USER_SETTINGS_ABS_ROUTE)
        } catch (err) {
            console.error("update email mutation error: ", err)
            setMutationErrorBanner(true)
        }
    }

    const handleDisableSubmit = () => {
        const errs = getErrorsFromNotificationsEmailForm(form)

        return (
            updateProfileMutationLoading ||
            mutationErrorBanner ||
            !form.dirty ||
            objectHasNonUndefinedFields(errs)
        )
    }

    // show submission component on mutation success
    if (mutationSuccess) {
        return <UpdateEmailSuccessComponent />
    }
    // if no mutation success, show form
    return (
        <>
            <div className={styles.container}>
                <div>
                    <Input
                        type="text"
                        label={
                            <>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    color="#B0B0B0"
                                    style={{ marginRight: "8px" }}
                                />
                                Email Address
                            </>
                        }
                        orientation="horizontal"
                        value={form?.email}
                        onChange={handleChangeEmail}
                        onBlur={handleEmailBlur}
                        message={
                            formErrors.email ? formErrors.email : undefined
                        }
                        specializedClass={
                            formErrors.email ? "danger" : "default"
                        }
                        customLabelClass={styles.emailInputLabel}
                    />
                    {emailVerified && (
                        <Toggle
                            toggleLabel="Allow Notifications"
                            checked={form.allowNotifications}
                            handleToggleClick={() =>
                                setForm({
                                    ...form,
                                    allowNotifications:
                                        !form.allowNotifications,
                                    dirty: true,
                                })
                            }
                        />
                    )}
                </div>
            </div>
            {mutationErrorBanner && (
                <>
                    <MutationErrorBanner
                        message={
                            <div>
                                There was problem updating your email
                                preferences. Click{" "}
                                <u
                                    style={{
                                        cursor: "pointer",
                                        marginRight: "4px",
                                    }}
                                    onClick={() =>
                                        navigate(USER_SETTINGS_ABS_ROUTE)
                                    }
                                >
                                    here
                                </u>
                                or close the modal to be redirected.
                            </div>
                        }
                    />
                </>
            )}
            <ModalFooter
                disableAdvance={handleDisableSubmit()}
                advanceText="Save"
                handleAdvanceClick={handleSubmit}
                disablePrevious={
                    updateProfileMutationLoading || mutationErrorBanner
                }
                previousText="Cancel"
                handlePreviousClick={() => {
                    navigate(USER_SETTINGS_ABS_ROUTE)
                }}
                previousButtonCondition="default"
            />
        </>
    )
}

export default UpdateEmailFormComponent

const UpdateEmailSuccessComponent: FunctionComponent = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className={styles.container}>
                <div className={styles.boldHeading}>
                    We have sent a verification email to your inbox with further
                    instructions to verify.
                </div>
                <div className={styles.heading}>
                    If you did not receive an email after a few minutes, please
                    check your spam folder before trying again.
                </div>
            </div>
            <ModalFooter
                disableAdvance={false}
                advanceText="Close"
                handleAdvanceClick={() => {
                    navigate(USER_SETTINGS_ABS_ROUTE)
                }}
            />
        </>
    )
}
