import { useState } from "react"
import styles from "./wellControlsSmartTimer.module.scss"
import Input from "../../shared/input"
import { useWellControlsContext } from "../../../context/wellControlsContext"
import { useViewerContext } from "../../../context/ViewerContext"
import { SubjectRole } from "../../../generated/graphql"
import { Toggle } from "../../shared/selectionControls"

const WellControlsSmartTimer = () => {
    const {
        wellControlState: {
            smartTimerState,
            smartTimerErrorsState,
            useSmartSettingOverride,
        },
        handleSmartTimerChange,
        handleSmartTimerBlur,
        toogleUseSmartSettingOverride,
    } = useWellControlsContext()

    const { getViewer } = useViewerContext()
    const { role } = getViewer()

    const { smartIdleHours, smartIdleMinutes, targetEmptyRatio } =
        smartTimerState

    const [displayOverrideSettings, setDisplayOverrideSettings] =
        useState<boolean>(useSmartSettingOverride)

    // handlers
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleSmartTimerChange(e, smartTimerState)
    }

    /**  Only PPO Admin has permissions to override settings */
    if (role !== SubjectRole.PpoAdmin) {
        return <></>
    } else {
        /** Else Return OverRide Settings */
        return (
            <div className={styles.container}>
                <div style={{ marginBottom: "8px" }}>
                    <Toggle
                        checked={displayOverrideSettings}
                        handleToggleClick={() => {
                            const useOverRideToggle = !displayOverrideSettings
                            setDisplayOverrideSettings(useOverRideToggle)
                            toogleUseSmartSettingOverride(useOverRideToggle)
                        }}
                        toggleLabel="Use Override Settings"
                    />
                </div>
                {displayOverrideSettings && (
                    <>
                        <div className={styles.inputRow}>
                            <div className={styles.input}>
                                <Input
                                    orientation="vertical"
                                    type="number"
                                    value={smartIdleHours}
                                    onChange={handleInputChange}
                                    onBlur={handleSmartTimerBlur}
                                    id="smartIdleHours"
                                    min={0}
                                    inputCustomClass="well-control-smart-timer-input"
                                    disabled={false}
                                    label="Idle hrs:"
                                    message={
                                        smartTimerErrorsState.smartIdleHours
                                    }
                                />
                            </div>
                            <div className={styles.input}>
                                <Input
                                    orientation="vertical"
                                    type="number"
                                    value={smartIdleMinutes}
                                    onChange={handleInputChange}
                                    onBlur={handleSmartTimerBlur}
                                    id="smartIdleMinutes"
                                    min={0}
                                    inputCustomClass="well-control-smart-timer-input"
                                    disabled={false}
                                    label="Idle mins:"
                                    message={
                                        smartTimerErrorsState.smartIdleMinutes
                                    }
                                />
                            </div>
                            <div className={styles.input}>
                                <Input
                                    orientation="vertical"
                                    type="number"
                                    value={targetEmptyRatio}
                                    onChange={handleInputChange}
                                    onBlur={handleSmartTimerBlur}
                                    id="targetEmptyRatio"
                                    min={0}
                                    max={1}
                                    inputCustomClass="well-control-smart-timer-input"
                                    disabled={false}
                                    label="Target empty ratio:"
                                    message={
                                        smartTimerErrorsState.targetEmptyRatio
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

export default WellControlsSmartTimer
