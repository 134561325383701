import { FunctionComponent, useContext } from "react"
import OutsideAppLoader from "../shared/outsideAppLoader"
import { useAuth } from "react-oidc-context"
import { ConfigContext } from "../../context/ConfigContexts"

const LoggedOut: FunctionComponent = () => {
    const auth = useAuth()
    const config = useContext(ConfigContext)

    try {
        setTimeout(() => {
            auth.signoutRedirect({
                extraQueryParams: {
                    client_id: config.oidcClientID,
                    redirect_uri: config.redirectLoggedInURL,
                    response_type: "code",
                },
            })
        }, 500)
    } catch (error) {
        console.log(error)
    }
    return <OutsideAppLoader />
}

export default LoggedOut
