import { useRef, FunctionComponent, ReactNode } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquareCheck } from "@fortawesome/pro-solid-svg-icons"
import {
    faSquare,
    faCircle,
    faCircleDot,
} from "@fortawesome/pro-regular-svg-icons"
import classnames from "classnames"
import styles from "./selectionControls.module.scss"
import Switch from "react-switch"

interface CheckBoxPropsInterface {
    checkboxLabel: string
    checked: boolean
    handleCheckBoxClick: (
        e: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => void
    id?: string
    disabled?: boolean
}

interface OneWayCheckBoxPropsI extends CheckBoxPropsInterface {
    finalDisplay: ReactNode
}

interface RadioPropsInterface {
    radioLabel: string
    checked: boolean
    handleRadioClick: () => void
    id?: string
    disabled?: boolean
}

interface TogglePropsInterface {
    toggleLabel: string
    customToggleLabelClass?: string
    checked: boolean
    handleToggleClick: () => void
    id?: string
    disabled?: boolean
}

const Checkbox: FunctionComponent<CheckBoxPropsInterface> = ({
    checkboxLabel,
    checked,
    handleCheckBoxClick,
    id,
    disabled,
}) => {
    const checkboxRef = useRef<HTMLInputElement>(null)

    return (
        <div className={styles.checkBoxWrapper}>
            <FontAwesomeIcon
                className={classnames(
                    disabled && styles.checkboxDisabled,
                    styles.fontAwesomeCheckBox
                )}
                icon={checked === true ? faSquareCheck : faSquare}
            />
            <input
                ref={checkboxRef}
                onClick={(e) => {
                    // used to blur input on click
                    // needed for when a user tabs to check box and then clicks it.
                    checkboxRef.current?.blur()
                    handleCheckBoxClick(e)
                }}
                className={styles.inputCheckbox}
                type="checkbox"
                id={id}
                disabled={disabled}
            />
            <label
                className={classnames(
                    disabled && styles.checkboxDisabled,
                    styles.checkboxLabel
                )}
            >
                {checkboxLabel}
            </label>
        </div>
    )
}

const OneWayCheckBox: FunctionComponent<OneWayCheckBoxPropsI> = ({
    checkboxLabel,
    checked,
    handleCheckBoxClick,
    id,
    disabled,
    finalDisplay,
}) => {
    if (checked) {
        return (
            <div className={styles.checkBoxWrapper}>
                {
                    <div className={styles.oneWayCheckBoxFinal}>
                        {finalDisplay}
                    </div>
                }
            </div>
        )
    }

    const checkboxRef = useRef<HTMLInputElement>(null)

    return (
        <div className={styles.checkBoxWrapper}>
            <FontAwesomeIcon
                className={classnames(
                    disabled && styles.checkboxDisabled,
                    styles.fontAwesomeCheckBox
                )}
                icon={faSquare}
            />
            <input
                ref={checkboxRef}
                onClick={(e) => {
                    // used to blur input on click
                    // needed for when a user tabs to check box and then clicks it.
                    checkboxRef.current?.blur()
                    handleCheckBoxClick(e)
                }}
                className={styles.inputCheckbox}
                type="checkbox"
                id={id}
                disabled={disabled}
            />
            <label
                className={classnames(
                    disabled && styles.checkboxDisabled,
                    styles.checkboxLabel
                )}
            >
                {checkboxLabel}
            </label>
        </div>
    )
}

const Radio: FunctionComponent<RadioPropsInterface> = ({
    radioLabel,
    checked,
    handleRadioClick,
    id,
    disabled,
}) => {
    const radioRef = useRef<HTMLInputElement>(null)

    const onClickHandler = () => {
        radioRef.current?.blur()
        handleRadioClick()
    }

    return (
        <div className={styles.radioWrapper}>
            <FontAwesomeIcon
                className={classnames(
                    disabled && styles.radioDisabled,
                    styles.fontAwesomeRadio
                )}
                icon={checked === true ? faCircleDot : faCircle}
            />
            <input
                ref={radioRef}
                onClick={onClickHandler}
                className={styles.inputRadio}
                type="radio"
                id={id}
                disabled={disabled}
            />
            <label
                className={classnames(
                    disabled && styles.radioDisabled,
                    styles.radioLabel
                )}
            >
                {radioLabel}
            </label>
        </div>
    )
}

const ToggleSelectionControl: FunctionComponent<TogglePropsInterface> = ({
    toggleLabel,
    checked,
    handleToggleClick,
    disabled,
    id,
    customToggleLabelClass,
}) => {
    return (
        <>
            <label>
                <Switch
                    checked={checked}
                    onChange={handleToggleClick}
                    disabled={disabled}
                    onColor="#1E88E5"
                    onHandleColor="#fff"
                    offColor="#D3D3D3"
                    offHandleColor="#1E88E5"
                    handleDiameter={12}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={16}
                    width={28}
                    className={styles.reactSwitch}
                    id={id}
                />
                {toggleLabel && (
                    <span
                        className={classnames(
                            styles.toggleLabel,
                            customToggleLabelClass && customToggleLabelClass
                        )}
                    >
                        {toggleLabel}
                    </span>
                )}
            </label>
        </>
    )
}

export { Checkbox, OneWayCheckBox, Radio, ToggleSelectionControl as Toggle }
