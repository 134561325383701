import { FunctionComponent } from "react"
import styles from "./adminUsersOperation.module.scss"
import Input from "../../../shared/input"
import { FetchUserInputValueType } from "../../../../pages/admin/users/userTableTypes"

interface AdminUsersOperationPropsI {
    canAccessOperation: boolean
    inputChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
    fetchUsers: FetchUserInputValueType
}

const AdminUsersOperation: FunctionComponent<AdminUsersOperationPropsI> = ({
    canAccessOperation,
    inputChange,
    fetchUsers,
}) => {
    if (!canAccessOperation) {
        return <></>
    }

    return (
        <div className={styles.container}>
            <div className={styles.inputContainer}>
                <Input
                    onChange={inputChange}
                    options={[
                        { label: "Active Users", value: "active" },
                        { label: "Inactive Users", value: "inactive" },
                    ]}
                    orientation="horizontal"
                    type="select"
                    disabled={false}
                    value={fetchUsers}
                />
            </div>
        </div>
    )
}

export default AdminUsersOperation
