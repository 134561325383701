import { FunctionComponent } from "react"
import { dateToLocalDateTimeDisplay } from "../../../../../../util/datesAndTimes/datesAndTimes"
import { OneWayCheckBox } from "../../../../../shared/selectionControls"
import {
    NOTIFICATION_MARK_ALL_AS_READ,
    NOTIFICATION_MARK_AS_READ,
} from "../../../../../../graphql/mutations/notification_read"
import { useMutation } from "@apollo/client"
import { useAppAlertBanner } from "../../../../../../context/alertBannerContext"
import { AlertRuleMetric } from "../../../../../../generated/graphql"
import { getIconForAlertRuleMetric } from "../../../../../../util/alertRuleMetric/alertRuleMetric"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faCheckDouble } from "@fortawesome/pro-solid-svg-icons"
import ToolTip from "../../../../../shared/toolTip/tooltip"

const MyNotificationsTimeStampsCell: FunctionComponent<{
    unixMilliseconds: number | undefined
}> = ({ unixMilliseconds }) => {
    if (typeof unixMilliseconds !== "number") {
        return <> - </>
    }

    const dateObj = new Date(unixMilliseconds)
    const displayDate = dateToLocalDateTimeDisplay(dateObj, "-")

    return <>{`${displayDate}`}</>
}

interface MyNotificationsMarkAsReadI {
    notificationId: string
    read: boolean
}

const MyNotificationsMarkAsRead: FunctionComponent<
    MyNotificationsMarkAsReadI
> = ({ notificationId, read }) => {
    // alert banner context
    const alertBannerContext = useAppAlertBanner()

    // mutation
    const [markNotificationAsRead] = useMutation(NOTIFICATION_MARK_AS_READ)

    // submit handler
    const handleSubmitIsRead = async () => {
        await markNotificationAsRead({
            variables: {
                input: {
                    id: notificationId,
                },
            },
            update(cache, { data }) {
                cache.modify({
                    id: `AlertNotification:${data?.notificationMarkAsRead.id}`,
                    fields: {
                        read() {
                            return true
                        },
                    },
                })
            },
        }).catch((error) => {
            console.log("mutation error", error)
            const { showAppAlert } = alertBannerContext
            showAppAlert({
                message: `Failed to mark notification as read.`,
                alertType: "danger",
            })
        })
    }

    return (
        <>
            <OneWayCheckBox
                checkboxLabel=""
                checked={read}
                handleCheckBoxClick={handleSubmitIsRead}
                finalDisplay={
                    <FontAwesomeIcon
                        icon={faCheck}
                        color="#455A64"
                        style={{ paddingLeft: "10px" }}
                    />
                }
            />
        </>
    )
}

interface MyNotificationsMarkAllAsReadI {
    allNotificationsRead: boolean
}

const MyNotificationsMarkAllAsRead: FunctionComponent<
    MyNotificationsMarkAllAsReadI
> = ({ allNotificationsRead }) => {
    // alert banner context
    const alertBannerContext = useAppAlertBanner()

    // mutation
    const [markAllNotificationsAsRead] = useMutation(
        NOTIFICATION_MARK_ALL_AS_READ
    )

    // submit handler
    const handleSubmitAllRead = async () => {
        await markAllNotificationsAsRead({
            update(cache, { data }) {
                data?.notificationsMarkAllRead.ids.forEach((id: string) => {
                    cache.modify({
                        id: `AlertNotification:${id}`,
                        fields: {
                            read() {
                                return true
                            },
                        },
                    })
                })
            },
        }).catch((error) => {
            console.log("mutation error", error)
            const { showAppAlert } = alertBannerContext
            showAppAlert({
                message: `Failed to mark all notifications as read.`,
                alertType: "danger",
            })
        })
    }

    return (
        <ToolTip
            tooltipContent={
                allNotificationsRead
                    ? "All notifications have been marked as read."
                    : "Mark all notifications as read."
            }
            triggerChildren={
                <div>
                    <OneWayCheckBox
                        checkboxLabel=""
                        checked={allNotificationsRead}
                        handleCheckBoxClick={handleSubmitAllRead}
                        finalDisplay={
                            <FontAwesomeIcon
                                icon={faCheckDouble}
                                color="#0DA700"
                                style={{ paddingLeft: "10px" }}
                            />
                        }
                    />
                </div>
            }
        />
    )
}

const AlertMetricStatusIcon: FunctionComponent<{
    alertRuleMetric: AlertRuleMetric
    resolved: boolean
    wasSilenced: boolean
    notificationRead: boolean
}> = ({ alertRuleMetric, resolved, wasSilenced, notificationRead }) => {
    return (
        <>
            {getIconForAlertRuleMetric(
                alertRuleMetric,
                resolved,
                wasSilenced,
                notificationRead
            )}
        </>
    )
}

export {
    MyNotificationsTimeStampsCell,
    MyNotificationsMarkAsRead,
    MyNotificationsMarkAllAsRead,
    AlertMetricStatusIcon,
}
