import { getFragmentData } from "../../../../../generated"
import { GetAlertRulesQuery } from "../../../../../generated/graphql"
import { IDENTITY_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/Identity"
import { ALERT_RULE_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRule"
import { ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleMetricDefinition"
import { SUBJECT_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/subject"
import { TableStateType } from "../../../../table/tableTypes"
import { getTableStateFromUrlParams } from "../../../../table/tableUtil"
import { METRIC_COLUMN_ID, AUTHOR_COLUMN_ID } from "../column"
import {
    AlertRulesTableDataI,
    AlertRulesUrlToStateMappings,
} from "../tableTypes"

const alertRulePageSizeSelectorArray = [15, 25, 50]

const alertRulesTableUrlMapping: AlertRulesUrlToStateMappings = {
    [METRIC_COLUMN_ID]: (value: string, state: TableStateType) => {
        state.filter.push({ id: METRIC_COLUMN_ID, value })
    },
    [AUTHOR_COLUMN_ID]: (value: string, state: TableStateType) => {
        state.filter.push({ id: AUTHOR_COLUMN_ID, value })
    },
}

const getAlertRulesStateFromUrlQueryParams = (
    searchParams: URLSearchParams,
    customMapping: AlertRulesUrlToStateMappings
): TableStateType => {
    const defaultState: TableStateType = {
        pageIndex: 0,
        size: alertRulePageSizeSelectorArray[0],
        sorting: [],
        search: "",
        filter: [],
    }

    return getTableStateFromUrlParams(
        searchParams,
        defaultState,
        alertRulePageSizeSelectorArray,
        customMapping
    )
}

const formatAlertRulesData = (
    data: GetAlertRulesQuery
): AlertRulesTableDataI[] => {
    const formattedData: AlertRulesTableDataI[] = []
    if (data.rules.rules) {
        const { rules } = data.rules
        for (let i = 0; i < rules.length; i++) {
            const rule = getFragmentData(ALERT_RULE_FIELDS_FRAGMENT, rules[i])

            const alertRuleMetricDefinitionFragment = getFragmentData(
                ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT,
                rule.metric
            )

            const createdByFragment = getFragmentData(
                SUBJECT_FIELDS_FRAGMENT,
                rule.createdBy
            )

            const identityFragment = getFragmentData(
                IDENTITY_FIELDS_FRAGMENT,
                createdByFragment.identity
            )

            const formattedRule: AlertRulesTableDataI = {
                id: rule.id,
                ruleName: rule.name,
                metric: alertRuleMetricDefinitionFragment.displayName,
                description: rule.description,
                ruleAuthor: identityFragment?.fullName || "",
            }
            formattedData.push(formattedRule)
        }
    }
    return formattedData
}

export {
    alertRulePageSizeSelectorArray,
    getAlertRulesStateFromUrlQueryParams,
    formatAlertRulesData,
    alertRulesTableUrlMapping,
}
