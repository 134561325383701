import { FunctionComponent, useEffect, useState } from "react"
import NavigationControls from "../../../shared/navigationControls"
import styles from "./IwpSubPageNavigation.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Dropdown from "../../../shared/dropDown"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { WellIdentificationFieldsFragment } from "../../../../generated/graphql"
import { SubNavDropDownI, IwpSubNavNavControlsI } from "../subNavigationTypes"

interface IwpSubPageNavigationI {
    wellIdentificationfragment: WellIdentificationFieldsFragment
    windowWidth: number
    mobileBreakPoint: number
    subNavControls: IwpSubNavNavControlsI
    setSubNavControls: React.Dispatch<
        React.SetStateAction<IwpSubNavNavControlsI>
    >
    dropDownMenus: SubNavDropDownI
    setDropDownMenus: React.Dispatch<React.SetStateAction<SubNavDropDownI>>
}

const IwpSubPageNavigation: FunctionComponent<IwpSubPageNavigationI> = ({
    wellIdentificationfragment,
    windowWidth,
    mobileBreakPoint,
    subNavControls,
    setSubNavControls,
    dropDownMenus,
    setDropDownMenus,
}) => {
    const [mobileView, setMobileView] = useState(
        windowWidth > mobileBreakPoint ? false : true
    )

    useEffect(() => {
        if (windowWidth > mobileBreakPoint) {
            setMobileView(false)
        }
        if (windowWidth < mobileBreakPoint) {
            setMobileView(true)
        }
    }, [windowWidth])

    if (mobileView) {
        return (
            <div className={styles.navControlsContainer}>
                <MobileView
                    wellIdentificationfragment={wellIdentificationfragment}
                    setSubNavControls={setSubNavControls}
                    dropDownMenus={dropDownMenus}
                    setDropDownMenus={setDropDownMenus}
                />
            </div>
        )
    } else {
        return (
            <div className={styles.navControlsContainer}>
                <DesktopView
                    wellIdentificationfragment={wellIdentificationfragment}
                    subNavControls={subNavControls}
                    setSubNavControls={setSubNavControls}
                />
            </div>
        )
    }
}

export default IwpSubPageNavigation

interface DesktopI {
    subNavControls: IwpSubNavNavControlsI
    setSubNavControls: React.Dispatch<
        React.SetStateAction<IwpSubNavNavControlsI>
    >
    wellIdentificationfragment: WellIdentificationFieldsFragment
}

const DesktopView: FunctionComponent<DesktopI> = ({
    subNavControls,
    setSubNavControls,
    wellIdentificationfragment,
}) => {
    const navigate = useNavigate()

    return (
        <>
            <div className={styles.navControlOverview}>
                <NavigationControls
                    usage="sub-nav"
                    condition={
                        subNavControls.wellDetails ? "selected" : "default"
                    }
                    disabled={false}
                    handleClick={(e) => {
                        e.stopPropagation()
                        setSubNavControls({
                            wellAttributes: false,
                            wellDetails: true,
                        })
                        navigate(
                            `/wellDetails/${wellIdentificationfragment.id}`
                        )
                    }}
                >
                    Overview
                </NavigationControls>
            </div>
            <div className={styles.navControlSettings}>
                <NavigationControls
                    usage="sub-nav"
                    condition={
                        subNavControls.wellAttributes ? "selected" : "default"
                    }
                    disabled={false}
                    handleClick={(e) => {
                        e.stopPropagation()
                        setSubNavControls({
                            wellAttributes: true,
                            wellDetails: false,
                        })
                        navigate(
                            `/wellAttributes/${wellIdentificationfragment.id}`
                        )
                    }}
                >
                    Attributes
                </NavigationControls>
            </div>
        </>
    )
}

interface MobileViewI {
    wellIdentificationfragment: WellIdentificationFieldsFragment
    setSubNavControls: React.Dispatch<
        React.SetStateAction<IwpSubNavNavControlsI>
    >
    dropDownMenus: SubNavDropDownI
    setDropDownMenus: React.Dispatch<React.SetStateAction<SubNavDropDownI>>
}

const MobileView: FunctionComponent<MobileViewI> = ({
    wellIdentificationfragment,
    setSubNavControls,
    dropDownMenus,
    setDropDownMenus,
}) => {
    const navigate = useNavigate()
    const dropDownMenuId = "mobileMenu"

    return (
        <>
            <Dropdown
                options={[
                    { value: "overview", label: "Overview" },
                    { value: "attributes", label: "Attributes" },
                ]}
                dropDownButtonClass="dropDown-button-class"
                dropDownOptionsContainerClass="dropDown-options-container"
                dropDownOptionClass="dropDown-option"
                positionClass={"iwp-sub-nav-mobile"}
                menuOpen={dropDownMenus.subPageMenu}
                handleDropDownMenuClick={(e) => {
                    e.stopPropagation()
                    const updatedDropDownMenus = {
                        ...dropDownMenus,
                        subPageMenu: !dropDownMenus.subPageMenu,
                        configurationMenu: false,
                    }
                    setDropDownMenus(updatedDropDownMenus)
                }}
                handleDropDownOptionClick={(e) => {
                    e.stopPropagation()
                    const targetId = (e.currentTarget as HTMLElement).id
                    switch (targetId) {
                        case `${dropDownMenuId}_overview`:
                            navigate(
                                `/wellDetails/${wellIdentificationfragment.id}`
                            )
                            setSubNavControls({
                                wellAttributes: false,
                                wellDetails: true,
                            })
                            break
                        case `${dropDownMenuId}_attributes`:
                            setSubNavControls({
                                wellAttributes: true,
                                wellDetails: false,
                            })
                            navigate(
                                `/wellAttributes/${wellIdentificationfragment.id}`
                            )
                            break

                        default:
                            throw new Error(
                                "Unexpected Behavior in well control drop down function"
                            )
                    }
                    setDropDownMenus({
                        configurationMenu: false,
                        subPageMenu: false,
                    })
                }}
                id={dropDownMenuId}
            >
                <FontAwesomeIcon color="#1e88e5" icon={faChevronDown} />
            </Dropdown>
        </>
    )
}
