import { OrganizationWithDescendantsFieldsFragment } from "../generated/graphql"
import { OrganizationOptionType } from "../types/ViewerTypes"

export const createUserOrganizationObject = (
    organization: OrganizationWithDescendantsFieldsFragment
): OrganizationOptionType[] => {
    const organizations: OrganizationOptionType[] = []

    const rootOrg = organization
    const rootOrgFields: OrganizationOptionType = {
        value: rootOrg.id,
        label: rootOrg.name,
    }

    const descendants = rootOrg.descendants
    organizations.push(rootOrgFields)

    const transformedDescendants = descendants.map((descendant) => {
        if (!descendant) {
            throw new Error(
                "CreateOrganizationsArray error. Descendant should not be null value."
            )
        }
        const { id, name } = descendant
        return { value: id, label: name }
    })

    organizations.push(...transformedDescendants)

    return organizations
}
