import React, { useState, FunctionComponent } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./form.module.scss"
import { USER_SETTINGS_ABS_ROUTE } from "../../../.."
import Input from "../../../shared/input"
import MutationErrorBanner from "../../../shared/graphQlResponse"
import ModalFooter from "../../../shared/modalFooter"
import { getErrorsFromNotificationsPhoneNumberForm } from "../updatePhoneNumberFormUtil/validation"
import {
    UpdateProfilePhoneNumberFormErrorI,
    UpdateProfilePhoneNumberFormI,
} from "../updatePhoneNumberFormUtil/types"
import { UPDATE_USERS } from "../../../../graphql/mutations/update_users"
import { useMutation } from "@apollo/client"
import { Toggle } from "../../../shared/selectionControls"
import { UPDATE_CONSENT } from "../../../../graphql/mutations/verify"
import { AttributeType } from "../../../../generated/graphql"
import { VIEWER } from "../../../../graphql/queries/viewer"
import { objectHasNonUndefinedFields } from "../../../../util/objects/objects"

const UpdatePhoneNumberFormComponent: FunctionComponent<{
    userId: string
    phoneNumber: string | undefined
    phoneNumberVerified: boolean
    smsConsent: boolean
}> = ({ userId, phoneNumber, smsConsent, phoneNumberVerified }) => {
    const navigate = useNavigate()
    // mutations
    const [updateProfileMutation, { loading: updateProfileMutationLoading }] =
        useMutation(UPDATE_USERS)

    const [updateSmsConsentMutation] = useMutation(UPDATE_CONSENT)

    // form state
    const [form, setForm] = useState<UpdateProfilePhoneNumberFormI>({
        allowNotifications: smsConsent,
        phoneNumber: phoneNumber || "",
        dirty: false,
    } as UpdateProfilePhoneNumberFormI)

    const [formErrors, setFormErrors] =
        useState<UpdateProfilePhoneNumberFormErrorI>({ phoneNumber: undefined })

    const [mutationErrorBanner, setMutationErrorBanner] = useState(false)

    // form handlers
    const handleChangePhoneNumber = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const targetValue = e.target.value
        const cleanedValue = targetValue.replace(/\D/g, "") // Remove non-digit characters
        const formattedValue = cleanedValue.slice(0, 10) // Keep only the first 10 digits
        const updatedForm = {
            ...form,
            phoneNumber: formattedValue,
            dirty: true,
        }

        // if there is a form error and user corrects it, we can clear it on valid input instead of waiting for blur.
        // if there is no current error we should wait for blur.
        let updatedErrors = { ...formErrors }
        if (formErrors.phoneNumber) {
            updatedErrors =
                getErrorsFromNotificationsPhoneNumberForm(updatedForm)
        }

        setForm(updatedForm)
        setFormErrors(updatedErrors)
    }

    // blur handlers
    const handlePhoneNumberBlur = () => {
        const updatedErrors = getErrorsFromNotificationsPhoneNumberForm(form)
        setFormErrors(updatedErrors)
    }

    // submission handler
    const handleSubmit = async () => {
        try {
            if (smsConsent !== form.allowNotifications) {
                await updateSmsConsentMutation({
                    variables: {
                        ConsentUpdateInput: {
                            consent: form.allowNotifications,
                            type: AttributeType.Sms,
                        },
                    },
                    refetchQueries: [{ query: VIEWER }],
                })
            }

            if (form.phoneNumber !== phoneNumber) {
                await updateProfileMutation({
                    variables: {
                        UserUpdateInput: {
                            userID: userId,
                            phoneNumber: `+1${form.phoneNumber}`,
                        },
                    },
                })
            }

            navigate(USER_SETTINGS_ABS_ROUTE)
        } catch (err) {
            console.error("update phone number modal mutation error", err)
            setMutationErrorBanner(true)
        }
    }

    const handleDisableSubmit = () => {
        const errs = getErrorsFromNotificationsPhoneNumberForm(form)
        return (
            mutationErrorBanner ||
            updateProfileMutationLoading ||
            !form.dirty ||
            objectHasNonUndefinedFields(errs)
        )
    }

    // if no mutation success, show form
    return (
        <>
            <div className={styles.container}>
                <div>
                    <Input
                        type="text"
                        label="Phone Number"
                        orientation="horizontal"
                        value={form?.phoneNumber}
                        onChange={handleChangePhoneNumber}
                        onBlur={handlePhoneNumberBlur}
                        message={
                            formErrors.phoneNumber
                                ? formErrors.phoneNumber
                                : undefined
                        }
                        specializedClass={
                            formErrors.phoneNumber ? "danger" : "default"
                        }
                        customLabelClass={styles.phoneNumberInputLabel}
                        phoneNumber
                    />
                    {phoneNumberVerified && (
                        <Toggle
                            toggleLabel="Allow Notifications"
                            checked={form?.allowNotifications}
                            handleToggleClick={() => {
                                setForm({
                                    ...form,
                                    dirty: true,
                                    allowNotifications:
                                        !form.allowNotifications,
                                })
                            }}
                        />
                    )}
                </div>
            </div>
            {mutationErrorBanner && (
                <>
                    <MutationErrorBanner
                        message={
                            <div>
                                There was a problem updating your phone number
                                preferences. Click{" "}
                                <u
                                    style={{
                                        cursor: "pointer",
                                        marginRight: "4px",
                                    }}
                                    onClick={() =>
                                        navigate(USER_SETTINGS_ABS_ROUTE)
                                    }
                                >
                                    here
                                </u>
                                or close the modal to be redirected.
                            </div>
                        }
                    />
                </>
            )}
            <ModalFooter
                disableAdvance={handleDisableSubmit()}
                advanceText="Save"
                handleAdvanceClick={handleSubmit}
                disablePrevious={
                    updateProfileMutationLoading || mutationErrorBanner
                }
                previousText="Cancel"
                handlePreviousClick={() => {
                    navigate(USER_SETTINGS_ABS_ROUTE)
                }}
                previousButtonCondition="default"
            />
        </>
    )
}

export default UpdatePhoneNumberFormComponent
