import React, { FunctionComponent } from "react"
import Input from "../../shared/input"
import "./wellControlsConfirmation.scss"
import { useWellControlsContext } from "../../../context/wellControlsContext"
import { updateTimerState } from "../../../util/wellControlUtil/wellControlsUtils"
import { WellRunModeType } from "../../../types/controlSettingTypes"
import { SubjectRole } from "../../../generated/graphql"
import {
    WellControlSmartTimerInterface,
    WellControlTimerInterface,
    WellControlUiStateInterface,
} from "../../../types/wellControlModalTypes"
import { useViewerContext } from "../../../context/ViewerContext"

interface WellControlsConfirmationProps {
    changeFrom: WellRunModeType
}

const WellControlsConfirmation: FunctionComponent<
    WellControlsConfirmationProps
> = ({ changeFrom }) => {
    const {
        handleReasonForControlChange,
        wellControlState: {
            UiState,
            timerState,
            smartTimerState,
            useSmartSettingOverride,
        },
    } = useWellControlsContext()
    const { getViewer } = useViewerContext()
    const { role: userRole } = getViewer()

    return (
        <div className="confirmation-container">
            <div className="confirmation-description-flex-row-top">
                <div className="confirmation-text-flex-column">
                    <div className="confirmation-text-flex-column-heading">
                        From
                    </div>
                    <div className="confirmation-text-flex-column-value">
                        {changeFrom}
                    </div>
                </div>
            </div>
            <div className="confirmation-description-flex-row">
                <div className="confirmation-text-flex-column">
                    <div className="confirmation-text-flex-column-heading">
                        To
                    </div>
                    <div className="confirmation-text-flex-column-value">
                        {UiState.selectedRunMode}
                    </div>
                </div>
                <TimerAndSmartDetails
                    UiState={UiState}
                    timerState={timerState}
                    smartTimerState={smartTimerState}
                    userRole={userRole}
                    useSmartSettingOverride={useSmartSettingOverride}
                />
            </div>
            <div className="confirmation-input-container">
                <Input
                    value={UiState.reasonForControlChange}
                    onChange={handleReasonForControlChange}
                    label="Reason(s) for Change"
                    orientation="vertical"
                    type="textArea"
                    inputCustomClass="well-control-timer-cycle-textArea-confirmation"
                    disabled={false}
                />
            </div>
        </div>
    )
}

export default WellControlsConfirmation

interface TimerAndSmartDetailsI {
    UiState: WellControlUiStateInterface
    timerState: WellControlTimerInterface
    smartTimerState: WellControlSmartTimerInterface
    userRole: SubjectRole
    useSmartSettingOverride: boolean
}

const TimerAndSmartDetails: FunctionComponent<TimerAndSmartDetailsI> = ({
    UiState,
    smartTimerState,
    timerState,
    userRole,
    useSmartSettingOverride,
}) => {
    switch (UiState.selectedRunMode) {
        case "On":
        case "Off":
            return <></>
        case "Timer": {
            const { runForTime } = updateTimerState(timerState)

            return (
                <>
                    <div className="confirmation-text-flex-column">
                        <div className="confirmation-text-flex-column-heading">
                            Timer Cycle
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="confirmation-timer-update-value">
                                {timerState.timerCycleHours} hr
                            </div>
                            <div className="confirmation-timer-update-value">
                                {timerState.timerCycleMinutes} min
                            </div>
                        </div>
                    </div>
                    <div className="confirmation-text-flex-column">
                        <div className="confirmation-text-flex-column-heading">
                            Runtime
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="confirmation-timer-update-value">
                                {runForTime.hours} hr
                            </div>
                            <div className="confirmation-timer-update-value">
                                {runForTime.minutes} min
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        case "Smart": {
            if (userRole === SubjectRole.PpoAdmin && useSmartSettingOverride) {
                return (
                    <>
                        <div className="confirmation-smart-text-flex-column">
                            <div className="confirmation-text-flex-column-heading">
                                Constant Idle:
                            </div>
                            <div style={{ display: "flex" }}>
                                <div className="confirmation-timer-update-value">
                                    {smartTimerState.smartIdleHours} hr
                                </div>
                                <div className="confirmation-timer-update-value">
                                    {smartTimerState.smartIdleMinutes} min
                                </div>
                            </div>
                        </div>
                        <div className="confirmation-smart-text-flex-column">
                            <div className="confirmation-text-flex-column-heading">
                                Target Ratio:
                            </div>
                            <div style={{ display: "flex" }}>
                                <div className="confirmation-timer-update-value">
                                    {smartTimerState.targetEmptyRatio}
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        }
    }

    return <></>
}
