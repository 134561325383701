import React, {
    createContext,
    useContext,
    useState,
    useMemo,
    useCallback,
    FunctionComponent,
} from "react"
import { createPortal } from "react-dom"
import {
    AlertComponentI,
    BaseAlertComponentI,
    AlertControlledComp,
} from "../../components/shared/alert"

type AppAlertBannerType = Omit<AlertComponentI, "isCloseable">

// context interface && create context
interface AppAlertBannerContextI {
    showAppAlert: (alertProps: AppAlertBannerType) => void
    closeAppAlert: () => void
}

const AppAlertBannerContext = createContext<AppAlertBannerContextI | undefined>(
    undefined
)

// create context provider
interface AppAlertBannerProviderProps {
    children: React.ReactNode
}

export const AppAlertBannerProvider: FunctionComponent<
    AppAlertBannerProviderProps
> = ({ children }) => {
    // provider state
    const [showAppAlertState, setShowAppAlertState] = useState<boolean>(false)
    const [alertState, setAlertState] = useState<AppAlertBannerType>({
        message: "",
        alertType: "info",
    })

    const showAppAlert = useCallback((appAlertBanner: AppAlertBannerType) => {
        setAlertState(appAlertBanner)
        setShowAppAlertState(true)
    }, [])

    const closeAppAlert = useCallback(() => {
        setShowAppAlertState(false)
    }, [])

    const contextValue = useMemo(() => {
        return { showAppAlert, closeAppAlert }
    }, [showAppAlert, closeAppAlert])

    return (
        <AppAlertBannerContext.Provider value={contextValue}>
            {showAppAlertState && (
                <AlertBannerPortal
                    alertType={alertState.alertType}
                    message={alertState.message}
                    customAlertClass={alertState.customAlertClass}
                    isCloseable={true}
                    onClose={closeAppAlert}
                    isVisible={true}
                />
            )}
            {children}
        </AppAlertBannerContext.Provider>
    )
}

const AlertBannerPortal: FunctionComponent<BaseAlertComponentI> = ({
    alertType,
    message,
    customAlertClass,
    onClose,
    isVisible,
    isCloseable,
}) => {
    const portalRoot = document.getElementById("app-alert-banner")

    if (!portalRoot) {
        console.error(
            "Error: Unable to render app alert banner. Portal root element not found."
        )
        return null
    }

    return createPortal(
        <div
            style={{
                position: "fixed",
                top: "10px",
                right: "25px",
                zIndex: 1000,
            }}
        >
            <AlertControlledComp
                alertType={alertType}
                isCloseable={isCloseable}
                message={message}
                onClose={onClose}
                customAlertClass={customAlertClass}
                isVisible={isVisible}
            />
        </div>,
        portalRoot
    )
}

export const useAppAlertBanner = (): AppAlertBannerContextI => {
    const context = useContext(AppAlertBannerContext)
    if (!context) {
        throw new Error(
            "useAppAlertBanner must be used within an AlertBannerProvider"
        )
    }
    return context
}
