import { FunctionComponent, useEffect, useState } from "react"
import styles from "./updateWellAttributesModal.module.scss"
import { WellAttributesFormI } from "../../../util/wellAttributes/wellAttributesTypes"
import ModalFooter from "../../shared/modalFooter"
import ModalHeader from "../../shared/modalHeader"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { TrexNavigator } from "../../../classes/navigator/navigator"
import {
    getWellAttributesFormErrors,
    isWellAttributesFormValid,
} from "../../../util/wellAttributes/formValidation"
import { parseInputToFloat } from "../../../util/InputValidation/inputValidation"
import {
    formateWellUpdateAttributesVariables,
    getInitialWellAttributesFormFromLocationState,
    getNoApiNumberCheckBoxStatus,
} from "./updateWellAttributesModalUtil"
import { objectHasNonUndefinedFields } from "../../../util/objects/objects"
import { formatWellApiNumberInput } from "../../../util/wellAttributes/formatWellApiNumberHelper"
import { useMutation } from "@apollo/client"
import UpdateWellAttributesForm from "./form"
import { UpdateWellAttributesComponentI } from "./updateWellAttributesModalTypes"
import { WELL_UPDATE_ATTRIBUTES } from "../../../graphql/mutations/update_well_attributes"
import { WellUpdateAttributesMutationVariables } from "../../../generated/graphql"

const UpdateWellAttributes: FunctionComponent = () => {
    /** Hooks */
    const navigate = useNavigate()
    const location = useLocation()
    const locationState = location.state as WellAttributesFormI
    const { wellId: urlParamWellId } = useParams()
    if (!urlParamWellId) {
        throw new Error("wellId not resolved from url.")
    }

    /** Mutations */
    const [wellUpdateAttributes, { loading: mutationLoading }] = useMutation(
        WELL_UPDATE_ATTRIBUTES
    )

    /** Component State */
    const initialWellAttributesForm =
        getInitialWellAttributesFormFromLocationState(locationState)

    const [
        updateWellAttributesComponentState,
        setUpdateWellAttributesComponentState,
    ] = useState<UpdateWellAttributesComponentI>({
        wellAttributesForm: initialWellAttributesForm,
        wellAttributesFormErrors: {
            WellName: undefined,
            Latitude: undefined,
            Longitude: undefined,
            apiNumber: undefined,
        },
        disableUpdate: true,
    })

    const [
        updateWellAttributesFormErrorBanner,
        setUpdateWellAttributesFormErrorBanner,
    ] = useState(false)

    /** Effect hooks */
    useEffect(() => {
        // This effect hook is used if there is no location state to populate the form.
        // Users will be redirected back to there attributes page

        if (locationState === null) {
            navigate(`/wellAttributes/${urlParamWellId}`)
        }
    }, [locationState])

    /** Handlers */
    const handleFormChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => {
        let updatedWellAttributesForm: WellAttributesFormI

        if (e.type === "change") {
            updatedWellAttributesForm = handleInputChange(
                e as React.ChangeEvent<HTMLInputElement>,
                updateWellAttributesComponentState.wellAttributesForm
            )
        } else {
            // e.type === click
            updatedWellAttributesForm = handleCheckBoxClick(
                updateWellAttributesComponentState.wellAttributesForm
            )
        }

        // Errors Form : If there are currently errors...
        // ... Remove them if user is correcting them.
        // Newly created errors should not update error state.
        // They  will disable submit button and will be visible when the input is blurred.

        let errorsForm = {
            ...updateWellAttributesComponentState.wellAttributesFormErrors,
        }

        if (objectHasNonUndefinedFields(errorsForm) === true) {
            errorsForm = getWellAttributesFormErrors(updatedWellAttributesForm)
        }

        // determine if the form should be disabled.
        const isFormValid = isWellAttributesFormValid(updatedWellAttributesForm)
        const disableFormSubmit = !isFormValid

        // set new component state
        setUpdateWellAttributesComponentState((prevFormState) => ({
            ...prevFormState,
            wellAttributesForm: updatedWellAttributesForm,
            disableUpdate: disableFormSubmit,
            wellAttributesFormErrors: errorsForm,
        }))
    }

    const handleInputBlur = () => {
        const updatedWellAttributesFormErros = getWellAttributesFormErrors(
            updateWellAttributesComponentState.wellAttributesForm
        )

        setUpdateWellAttributesComponentState((prevFormState) => ({
            ...prevFormState,
            wellAttributesFormErrors: updatedWellAttributesFormErros,
        }))
    }

    const submitUpdateWellAttributesMutation = async () => {
        /**
         * Validation is completed on input change.
         * Initially the form is set to false. Users cannot submit before making a change
         * */
        const canSubmitUpdatedWellAttributes = isWellAttributesFormValid(
            updateWellAttributesComponentState.wellAttributesForm
        )

        if (!canSubmitUpdatedWellAttributes) {
            // this is a saftey check
            // the inputchange handlers should not allow a subject to submit a form with errors.
            setUpdateWellAttributesFormErrorBanner(true)
        } else {
            const wellUpdateAttributesVariables: WellUpdateAttributesMutationVariables =
                formateWellUpdateAttributesVariables(
                    urlParamWellId,
                    wellAttributesForm
                )

            await wellUpdateAttributes({
                variables: wellUpdateAttributesVariables,
            })
                .then(() => {
                    // Upon successful mutation. Navigate back to the attrributes page
                    navigate(`/wellAttributes/${urlParamWellId}`)
                })
                .catch((error) => {
                    console.error(
                        "Update Well Attributes mutation error:",
                        error
                    )
                    setUpdateWellAttributesFormErrorBanner(true)
                })
        }
    }

    const { wellAttributesForm, disableUpdate, wellAttributesFormErrors } =
        updateWellAttributesComponentState

    return (
        <div className={styles.modalContainer}>
            <ModalHeader
                title="Update Well Attributes"
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: location.key,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
            />
            <UpdateWellAttributesForm
                disableUpdate={disableUpdate}
                handleInputBlur={handleInputBlur}
                handleFormChange={handleFormChange}
                wellAttributesForm={wellAttributesForm}
                wellAttributesFormErrors={wellAttributesFormErrors}
                updateWellAttributesFormErrorBanner={
                    updateWellAttributesFormErrorBanner
                }
            />
            <ModalFooter
                advanceText="Update Well"
                disableAdvance={
                    disableUpdate ||
                    updateWellAttributesFormErrorBanner ||
                    mutationLoading
                }
                handleAdvanceClick={submitUpdateWellAttributesMutation}
            />
        </div>
    )
}

export default UpdateWellAttributes

// private helpers
const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    form: WellAttributesFormI
): WellAttributesFormI => {
    const { name } = event.target
    let updatedAttribute: string | number

    switch (name as keyof WellAttributesFormI) {
        case "apiNumber":
            updatedAttribute = formatWellApiNumberInput(event)
            break
        case "WellName":
            updatedAttribute = event.target.value
            break
        case "Latitude":
        case "Longitude":
            updatedAttribute = parseInputToFloat(event)
            break
    }

    return {
        ...form,
        [name]: updatedAttribute,
    }
}

const handleCheckBoxClick = (
    form: WellAttributesFormI
): WellAttributesFormI => {
    const isChecked = getNoApiNumberCheckBoxStatus(form)

    return {
        ...form,
        apiNumber: isChecked ? "" : false,
    }
}
