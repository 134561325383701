import { FunctionComponent, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styles from "./addOrganizationsModal.module.scss"
import ModalHeader, {
    ModalHeaderComponentInterface,
} from "../shared/modalHeader"
import ModalFooter, {
    ModalFooterComponentInterface,
} from "../shared/modalFooter"
import Input from "../shared/input"
import {
    AddOrganizationModalStateInterface,
    AddedOrganizationInterface,
} from "../../types/adminOrganizationTypes/addOrganizationModalTypes"
import {
    initialAddOrganizationModalState,
    disableSubmitAddOrganization,
    getAddOrganiztionMutationVariables,
} from "../../util/addOrganizationModalUtils"
import Alert from "../shared/alert"
import { useMutation } from "@apollo/client"
import { ADD_ORGANIZATIONS } from "../../graphql/mutations/add_organization"
import { GET_ORGANIZATIONS } from "../../graphql/queries/organizations"
import { OrganizationsAddMutationVariables } from "../../generated/graphql"
import Select, { GroupBase, StylesConfig } from "react-select"
import { useViewerContext } from "../../context/ViewerContext"
import { OrganizationOptionType } from "../../types/ViewerTypes"
import { TrexNavigator } from "../../classes/navigator/navigator"

const AddOrganizationModal: FunctionComponent = () => {
    const navigate = useNavigate()
    const { key: locationKey, pathname } = useLocation()
    const { getViewer } = useViewerContext()
    const { organizationAndDescendants } = getViewer()

    /**
     * State
     */
    const [addOrganizationModalState, setAddOrganizationModalState] =
        useState<AddOrganizationModalStateInterface>(
            initialAddOrganizationModalState
        )

    const [addOrganizationErrorBanner, setAddOrganizationErrorBanner] =
        useState(false)

    /**
     * Mutations
     */

    const [addOrganizationMutation, { loading: mutationLoading }] =
        useMutation(ADD_ORGANIZATIONS)

    /**
     * Handlers
     */
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target

        const newOrganization: AddedOrganizationInterface = {
            ...addOrganizationModalState.organizationState,
            [name]: value,
        }

        const disableSubmit = disableSubmitAddOrganization(newOrganization)

        setAddOrganizationModalState((prevState) => ({
            ...prevState,
            organizationState: newOrganization,
            disableSubmit: disableSubmit,
        }))
    }

    const handleParentOrgChange = (value: OrganizationOptionType | null) => {
        if (!value) {
            throw new Error(
                "Error:  handleChangeParentOrganization. Should not be setting organization to null."
            )
        }

        const updatedOrganization: AddedOrganizationInterface = {
            ...addOrganizationModalState.organizationState,
            parentOrganizationId: value,
        }

        setAddOrganizationModalState((prevState) => ({
            ...prevState,
            organizationState: updatedOrganization,
        }))
    }

    const handleSubmitAddOrganization = async () => {
        // variables
        const addOrganizationMutationVariables: OrganizationsAddMutationVariables =
            getAddOrganiztionMutationVariables(
                addOrganizationModalState.organizationState
            )

        // mutation
        await addOrganizationMutation({
            variables: addOrganizationMutationVariables,
            refetchQueries: [GET_ORGANIZATIONS],
        })
            .then(() => {
                navigate(-1)
            })
            .catch((error) => {
                console.error("Add Organization mutation errror ", error)
                setAddOrganizationErrorBanner(true)
            })
    }

    /**
     * Props
     */
    const modalHeaderProps: ModalHeaderComponentInterface = {
        title: "Add Organization",
        trexNavigator: new TrexNavigator(
            { navigateTo: -1, locationKey: locationKey, pathName: pathname },
            navigate
        ),
    }

    const modalFooterProps: ModalFooterComponentInterface = {
        advanceText: "Submit",
        disableAdvance:
            addOrganizationModalState.disableSubmit ||
            mutationLoading ||
            addOrganizationErrorBanner,
        handleAdvanceClick: handleSubmitAddOrganization,
    }

    return (
        <div className={styles.container}>
            <ModalHeader {...modalHeaderProps} />
            <AddOrganizationBody
                handleInputChange={handleInputChange}
                modalState={addOrganizationModalState}
                organizations={organizationAndDescendants}
                handleParentOrgChange={handleParentOrgChange}
            />
            {addOrganizationErrorBanner && (
                <Alert
                    isCloseable={false}
                    message={
                        <div>
                            There was a problem adding the organization. Click{" "}
                            <u
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/admin/organizations")}
                            >
                                here
                            </u>{" "}
                            or close the modal to be redirected to the admin
                            page.
                        </div>
                    }
                    alertType="danger"
                />
            )}
            <ModalFooter {...modalFooterProps} />
        </div>
    )
}

interface AddUserBodyComponent {
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void
    modalState: AddOrganizationModalStateInterface
    organizations: OrganizationOptionType[]
    handleParentOrgChange: (value: OrganizationOptionType | null) => void
}

const AddOrganizationBody: FunctionComponent<AddUserBodyComponent> = ({
    handleInputChange,
    modalState,
    organizations,
    handleParentOrgChange,
}) => {
    const {
        organizationState: { parentOrganizationId, organizationName },
    } = modalState

    return (
        <>
            <div className={styles.bodyContainer}>
                <Input
                    orientation="vertical"
                    type="text"
                    value={organizationName}
                    label="Organization Name *"
                    disabled={false}
                    name="organizationName"
                    onChange={handleInputChange}
                />

                <div className={styles.parentOrganizationIdLabel}>
                    Parent Organization ID
                </div>
                <Select
                    options={organizations}
                    isSearchable={true}
                    onChange={handleParentOrgChange}
                    value={parentOrganizationId}
                    styles={reactSelectStyles}
                    placeholder={"- Select -"}
                />
            </div>
        </>
    )
}

export default AddOrganizationModal

const reactSelectStyles: StylesConfig<
    OrganizationOptionType,
    false,
    GroupBase<OrganizationOptionType>
> = {
    control: (provided, state) => ({
        ...provided,
        boxSizing: "border-box",
        outline: "none",
        padding: ".0 .25rem",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: state.isFocused ? "#64b5f6" : "#cfd8dc",
        borderRadius: "4px",
        backgroundColor: "white",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#37474f",
        height: "32px",
        boxShadow: state.isFocused
            ? "0px 0px 0px 3.2px rgba(0, 123, 255, 0.25)"
            : "none",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? "#64b5f6"
            : state.isFocused
            ? "#f5f5f5"
            : "transparent",

        color: state.isSelected ? "white" : "inherit",
    }),
}
