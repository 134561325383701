import { graphql } from "../../generated"

export const ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT = graphql(`
    fragment AlertRuleMetricDefinitionFields on AlertRuleMetricDefinition {
        metric
        displayName
        description
        templates {
            ...AlertRuleTemplateFields
        }
    }
`)
