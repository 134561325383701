import React, { FunctionComponent, useRef } from "react"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import styles from "./wellIndexMenu.module.scss"
import { useNavigate } from "react-router-dom"
import { useViewerContext } from "../../../../context/ViewerContext"
import { getWellConfigurationMenuOptionsByRole } from "../../../../util/rolePermissions/getByRole"
import RadixDropDownMenu from "../../../shared/dropDown/radixDropDownMenu"
import Button from "../../../shared/button"

interface WellIndexDropDownMenuI {
    wellId: string
    wellLinkedStatus: boolean
}
const WellIndexDropDownMenu: FunctionComponent<WellIndexDropDownMenuI> = ({
    wellId,
    wellLinkedStatus,
}) => {
    const ref = useRef<HTMLButtonElement>(null)

    WellIndexDropDownMenu.displayName = "WellIndexDropDownMenu"
    const navigate = useNavigate()

    const { role: userRole } = useViewerContext().getViewer()

    const menuOptions = getWellConfigurationMenuOptionsByRole(
        userRole,
        wellLinkedStatus
    )

    const dropDownOptions = menuOptions.map(({ label, value }) => {
        return (
            <DropdownMenu.Item
                key={value}
                onClick={() => {
                    if (value === "wellControls") {
                        navigate(`controls/${wellId}/edit`)
                    } else if (value === "link") {
                        navigate(`controls/${wellId}/link`)
                    } else if (value === "unlink") {
                        navigate(`controls/${wellId}/unlink`)
                    }
                }}
                className={styles.DropdownMenuItem}
            >
                {label}
            </DropdownMenu.Item>
        )
    })

    if (dropDownOptions.length === 0) {
        return <></>
    }

    return (
        <RadixDropDownMenu
            triggerChildren={
                <Button
                    status="secondary"
                    customButtonClassName="button-table-row-outlined"
                    condition="default"
                    disabled={false}
                    ref={ref}
                >
                    Actions
                </Button>
            }
        >
            {dropDownOptions}
        </RadixDropDownMenu>
    )
}

export default WellIndexDropDownMenu
