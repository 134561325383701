import React, { FunctionComponent, ChangeEvent, ReactNode } from "react"
import classnames from "classnames"
import "./input.scss"
import { LabelValueOptionType } from "../../../types/commonTypes"
import { LabelDurationValueInputT } from "../../../util/duration/types"

interface InputBaseProps {
    orientation: "horizontal" | "vertical"
    disabled?: boolean
    label?: string | ReactNode
    customLabelClass?: string
    message?: string
    id?: string
    specializedClass?:
        | "default"
        | "success"
        | "danger"
        | "readOnly"
        | "noMessage"
    inputCustomClass?: string
    name?: string
    messageCustomClass?: string
}

interface InputNumberProps extends InputBaseProps {
    type: "number"
    value: number
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    min?: number
    max?: number
    inputLabelRight?: string | JSX.Element
    customNestedLabelClass?: string
    handleClickNestedLabel?: () => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
}

interface InputTextProps extends InputBaseProps {
    type: "text"
    value: string
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
    inputLabelRight?: string | JSX.Element
    customNestedLabelClass?: string
    handleClickNestedLabel?: () => void
    placeHolder?: string
    phoneNumber?: boolean
}

interface InputRangeProps extends InputBaseProps {
    type: "range"
    value: number
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
    min?: number
    max?: number
}

interface InputTextAreaProps extends InputBaseProps {
    type: "textArea"
    value: string
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void
}

interface InputSelectProps extends InputBaseProps {
    type: "select"
    value: string | number | undefined
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLSelectElement, Element>) => void
    options:
        | LabelValueOptionType[]
        | string[]
        | number[]
        | LabelDurationValueInputT[]
    optionClassName?: string
    inputLabelRight?: string
}

interface InputDataListProps extends InputBaseProps {
    type: "datalist"
    datalistId: string
    options: string[]
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
    customNestedLabelClass?: string
    placeHolder?: string
    value?: string
}

interface InputTimePickerProps extends InputBaseProps {
    type: "time"
    value?: string
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
    min?: number
    max?: number
}

type InputProps =
    | InputNumberProps
    | InputTextProps
    | InputTextAreaProps
    | InputSelectProps
    | InputRangeProps
    | InputDataListProps
    | InputTimePickerProps

const Input: FunctionComponent<InputProps> = ({
    type,
    orientation,
    onChange,
    label,
    message,
    id,
    specializedClass,
    inputCustomClass,
    customLabelClass,
    disabled,
    name,
    messageCustomClass,
    ...otherProps
}) => {
    const { phoneNumber } = otherProps as InputTextProps
    const classType = type === "number" || type === "text" ? "input" : type

    const className = classnames(
        "base-input-class",
        phoneNumber && "input-phone-number",
        orientation && orientation === "horizontal"
            ? `horizontal-${classType}-class`
            : `vertical-${classType}-class`,
        specializedClass === "success" && "input-success",
        specializedClass === "danger" && "input-danger",
        specializedClass === "readOnly" && "input-readOnly",
        specializedClass === "noMessage" && "input-noMessage",
        inputCustomClass && inputCustomClass
    )
    const labelClassName = classnames(
        orientation === "horizontal"
            ? "horizontal-label-class"
            : "vertical-label-class",
        customLabelClass && customLabelClass
    )

    const messageClass = classnames(
        "messageClass",
        messageCustomClass && messageCustomClass,
        specializedClass === "danger" && "message-danger",
        specializedClass === "readOnly" && "message-readOnly",
        specializedClass === "noMessage" && "message-noMessage"
    )

    if (type === "number") {
        const {
            min,
            max,
            inputLabelRight,
            customNestedLabelClass,
            handleClickNestedLabel,
            onBlur,
            value,
        } = otherProps as InputNumberProps

        const customNestedLabel = classnames(
            "nestedLabelRight",
            customNestedLabelClass
        )

        if (orientation === "horizontal") {
            return (
                <div className="input-horizontal-flex-wrapper">
                    {label && <label className={labelClassName}>{label}</label>}
                    <div style={{ position: "relative" }}>
                        <input
                            min={min}
                            max={max}
                            type={type}
                            id={id}
                            className={className}
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                            name={name}
                            onBlur={onBlur}
                        />
                        {/* If using label - use padding on input to get increment/ decrement arrows to show before label */}
                        {inputLabelRight && (
                            <div
                                onClick={handleClickNestedLabel}
                                className={customNestedLabel}
                            >
                                {inputLabelRight}
                            </div>
                        )}
                        {message ? (
                            <div className={messageClass}>{message} </div>
                        ) : (
                            <div className={messageClass} />
                        )}
                    </div>
                </div>
            )
        }

        return (
            <>
                {label && <label className={labelClassName}>{label}</label>}
                <input
                    min={min}
                    max={max}
                    type={type}
                    id={id}
                    className={className}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    name={name}
                    onBlur={onBlur}
                />
                {message ? (
                    <div className={messageClass}>{message} </div>
                ) : (
                    <div className={messageClass} />
                )}
            </>
        )
    } else if (type === "range") {
        const { min, max, onBlur, value } = otherProps as InputRangeProps

        if (orientation === "horizontal") {
            return (
                <div className="input-horizontal-flex-wrapper">
                    {label && <label className={labelClassName}>{label}</label>}
                    <div>
                        <input
                            min={min}
                            max={max}
                            type={type}
                            id={id}
                            className={className}
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                            name={name}
                            onBlur={onBlur}
                        />
                        {message ? (
                            <div className={messageClass}>{message} </div>
                        ) : (
                            <div className={messageClass} />
                        )}
                    </div>
                </div>
            )
        }

        return (
            <>
                {label && <label className={labelClassName}>{label}</label>}
                <div>
                    <input
                        min={min}
                        max={max}
                        type={type}
                        id={id}
                        className={className}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        name={name}
                        onBlur={onBlur}
                    />
                    {message ? (
                        <div className={messageClass}>{message} </div>
                    ) : (
                        <div className={messageClass} />
                    )}
                </div>
            </>
        )
    } else if (type === "text") {
        const {
            inputLabelRight,
            customNestedLabelClass,
            handleClickNestedLabel,
            placeHolder,
            onBlur,
            value,
            phoneNumber,
        } = otherProps as InputTextProps

        if (orientation === "horizontal") {
            const customNestedLabel = classnames(
                "nestedLabelRight",
                customNestedLabelClass
            )

            return (
                <div className="input-horizontal-flex-wrapper">
                    {label && <label className={labelClassName}>{label}</label>}
                    <div style={{ position: "relative" }}>
                        <div className="input-and-label-wrapper">
                            {phoneNumber && (
                                <div className="input-phone-number-prefix">
                                    +1
                                </div>
                            )}
                            <input
                                type={type}
                                id={id}
                                className={className}
                                value={value}
                                onChange={onChange}
                                disabled={disabled}
                                name={name}
                                placeholder={placeHolder}
                                onBlur={onBlur}
                            />
                            {inputLabelRight && (
                                <div
                                    onClick={() => {
                                        if (
                                            handleClickNestedLabel !==
                                                undefined &&
                                            !disabled
                                        ) {
                                            handleClickNestedLabel()
                                        }
                                    }}
                                    className={customNestedLabel}
                                >
                                    {inputLabelRight}
                                </div>
                            )}
                        </div>
                        {message ? (
                            <div className={messageClass}>{message} </div>
                        ) : (
                            <div className={messageClass} />
                        )}
                    </div>
                </div>
            )
        }

        return (
            <>
                {label && <label className={labelClassName}>{label}</label>}{" "}
                <input
                    type={type}
                    id={id}
                    className={className}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    name={name}
                    onBlur={onBlur}
                />
                {message ? (
                    <div className={messageClass}>{message} </div>
                ) : (
                    <div className={messageClass} />
                )}
            </>
        )
    } else if (type === "textArea") {
        const { onBlur, value } = otherProps as InputTextAreaProps

        if (orientation === "horizontal") {
            return (
                <div className="input-horizontal-flex-wrapper">
                    {label && <div className={labelClassName}>{label}</div>}
                    <div>
                        <textarea
                            id={id}
                            className={className}
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                            name={name}
                            onBlur={onBlur}
                        />
                        {message ? (
                            <div className={messageClass}>{message} </div>
                        ) : (
                            <div className={messageClass} />
                        )}
                    </div>
                </div>
            )
        }
        return (
            <>
                {label && <label className={labelClassName}>{label}</label>}{" "}
                <textarea
                    id={id}
                    className={className}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    name={name}
                    onBlur={onBlur}
                />
                {message ? (
                    <div className={messageClass}>{message} </div>
                ) : (
                    <div className={messageClass} />
                )}
            </>
        )
    } else if (type === "select") {
        const { options, optionClassName, onBlur, value } =
            otherProps as InputSelectProps

        const optionsElements: React.ReactNode = options.map((option) => {
            const value = typeof option === "object" ? option.value : option
            const label = typeof option === "object" ? option.label : option
            const disabled =
                typeof option === "object" ? option.disabled : false

            return (
                <option
                    className={optionClassName}
                    key={value}
                    value={value}
                    disabled={disabled}
                >
                    {label}
                </option>
            )
        })

        if (orientation === "horizontal") {
            return (
                <div className="input-horizontal-flex-wrapper">
                    {label && (
                        <label className={labelClassName}> {label}</label>
                    )}
                    <div style={{ position: "relative" }}>
                        <select
                            className={classnames(
                                className,
                                "customSelectArrow"
                            )}
                            value={value}
                            onChange={onChange}
                            id={id}
                            disabled={disabled}
                            name={name}
                            onBlur={onBlur}
                        >
                            {optionsElements}
                        </select>
                        {message ? (
                            <div className={messageClass}>{message} </div>
                        ) : (
                            <div className={messageClass} />
                        )}
                    </div>
                </div>
            )
        }
        return (
            <>
                {label && <label className={labelClassName}>{label}</label>}
                <select
                    className={classnames(className, "customSelectArrow")}
                    value={value}
                    onChange={onChange}
                    id={id}
                    disabled={disabled}
                    name={name}
                    onBlur={onBlur}
                >
                    {optionsElements}
                </select>
                {message ? (
                    <div className={messageClass}>{message} </div>
                ) : (
                    <div className={messageClass} />
                )}
            </>
        )
    } else if (type === "datalist") {
        const { options, onBlur, datalistId, placeHolder, value } =
            otherProps as InputDataListProps

        if (orientation === "horizontal") {
            return (
                <div className="input-horizontal-flex-wrapper">
                    {label && <label className={labelClassName}>{label}</label>}
                    <div style={{ position: "relative" }}>
                        <input
                            type="text"
                            id={id}
                            className={className}
                            onChange={onChange}
                            disabled={disabled}
                            name={name}
                            placeholder={placeHolder}
                            onBlur={onBlur}
                            list={datalistId}
                            value={value}
                        />
                        {message ? (
                            <div className={messageClass}>{message} </div>
                        ) : (
                            <div className={messageClass} />
                        )}
                    </div>
                    {datalistId && options && (
                        <datalist id={datalistId}>
                            {options.map((option) => (
                                <option key={option} value={option} />
                            ))}
                        </datalist>
                    )}
                </div>
            )
        }
        return (
            <>
                {label && <label className={labelClassName}>{label}</label>}
                <input
                    type="text"
                    id={id}
                    className={className}
                    onChange={onChange}
                    disabled={disabled}
                    name={name}
                    placeholder={placeHolder}
                    onBlur={onBlur}
                    list={datalistId}
                />
                {message ? (
                    <div className={messageClass}>{message} </div>
                ) : (
                    <div className={messageClass} />
                )}
                {datalistId && options && (
                    <datalist id={datalistId}>
                        {options.map((option) => (
                            <option key={option} value={option} />
                        ))}
                    </datalist>
                )}
            </>
        )
    } else if (type === "time") {
        const { value, onBlur, min, max } = otherProps as InputTimePickerProps

        return (
            <>
                <TimePicker
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    orientation={orientation}
                    id={id}
                    label={label}
                    labelClassName={labelClassName}
                    messageClass={messageClass}
                    disabled={disabled}
                    name={name}
                    message={message}
                    className={className}
                    min={min}
                    max={max}
                />
            </>
        )
    }

    return <></>
}

export default Input

interface TimePickerProps {
    value?: string
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
    disabled?: boolean
    orientation: "horizontal" | "vertical"
    label?: string | ReactNode
    message?: string | null
    id?: string
    labelClassName: string
    messageClass: string
    name?: string
    className: string
    min?: number
    max?: number
}

const TimePicker: FunctionComponent<TimePickerProps> = ({
    value,
    onChange,
    onBlur,
    disabled,
    orientation,
    label,
    message,
    id,
    labelClassName,
    messageClass,
    name,
    className,
    max,
    min,
}) => {
    // parse components
    const timePickerComponent = (
        <input
            type="time"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            id={id}
            disabled={disabled}
            name={name}
            className={className}
            min={min}
            max={max}
            step={60} // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time#step
        />
    )
    const labelComponent = <label className={labelClassName}> {label}</label>
    const messageComponent = message ? (
        <div className={messageClass}>{message} </div>
    ) : (
        <div className={messageClass} />
    )

    // return input based on orientation
    if (orientation === "horizontal") {
        return (
            <>
                <div className="input-horizontal-flex-wrapper">
                    {labelComponent}
                    <div style={{ position: "relative" }}>
                        {timePickerComponent}
                        {messageComponent}
                    </div>
                </div>
            </>
        )
    }

    if (orientation === "vertical") {
        return (
            <>
                {labelComponent}
                {timePickerComponent}
                {messageComponent}
            </>
        )
    }

    return <></>
}
