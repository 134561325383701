import { graphql } from "../../generated"

export const GET_CONTROL_SETTINGS_HISTORY = graphql(`
    query GetControlSettingsHistory(
        $wellControlInput: WellControlSettingsByWellID!
        $historyCount: Int!
        $wellInput: GetWellInput
    ) {
        controlHistory: wellControlSettings(
            last: $historyCount
            input: $wellControlInput
        ) {
            nodes {
                ...ControlSettingFields
            }
        }
        well(input: $wellInput) {
            ...WellIdentificationFields
            configRevision {
                ...WellConfigurationRevisionParameters
            }
        }
    }
`)
