import { FunctionComponent, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ModalFooter from "../../../../shared/modalFooter"
import { useMutation } from "@apollo/client"
import { SUBSCRIPTION_ADD } from "../../../../../graphql/mutations/subscription_add"
import MutationErrorBanner from "../../../../shared/graphQlResponse"
import ManageSubscriptionForm from "../form"
import { getSubscriptionAddMutationVariables } from "../manageSubscriptionFormUtil/api"
import { MultiValue, SingleValue } from "react-select"
import { LabelValueOptionType } from "../../../../../types/commonTypes"
import { GET_SUBSCRIPTIONS_FOR_USER } from "../../../../../graphql/queries/subscriptionsForUser"
import { MultiSelectOptionT } from "../../../../shared/input/react-select/multiSelect"
import ManageSubscriptionsActions from "../../manageSubscriptions/actions"
import {
    ManageSubscriptionFormI,
    ManageSubscriptionSubcriberOptionsT,
    ManageSubscriptionViewerActionT,
} from "../manageSubscriptionFormUtil/types"
import { navigateAfterMutation } from "../../../navigate"
import { GET_SUBSCRIPTIONS_FOR_ORG } from "../../../../../graphql/queries/subscriptionsForOrg"
import { useNotificationsContext } from "../../../context/notificationsContext"

interface AddSubscriptionFormComponentI {
    ruleOptions: LabelValueOptionType[]
    wellOptions: LabelValueOptionType[]
    subscriberOptions: ManageSubscriptionSubcriberOptionsT[]
    viewerAction: ManageSubscriptionViewerActionT
}

const AddSubscriptionForm: FunctionComponent<AddSubscriptionFormComponentI> = ({
    ruleOptions,
    wellOptions,
    viewerAction,
    subscriberOptions,
}) => {
    const navigate = useNavigate()
    const notificationsContext = useNotificationsContext()
    const { pathname } = useLocation()

    const [addSubscriptionMutation, { loading: mutationLoading }] =
        useMutation(SUBSCRIPTION_ADD)

    // form state
    const initSelectedWells: MultiSelectOptionT[] = wellOptions.map((well) => {
        return {
            label: well.label,
            value: well.value,
            isDisabled: false,
            isSelected: false,
        }
    })

    const initialSubscriber: ManageSubscriptionSubcriberOptionsT = {
        label: subscriberOptions[0]?.label,
        id: subscriberOptions[0]?.id,
        email: subscriberOptions[0]?.email,
        emailVerified: subscriberOptions[0]?.emailVerified,
        emailConsent: subscriberOptions[0]?.emailConsent,
        phone: subscriberOptions[0]?.phone,
        phoneVerified: subscriberOptions[0]?.phoneVerified,
        phoneConsent: subscriberOptions[0]?.phoneConsent,
    }

    const [addSubscriptionForm, setAddSubscriptionForm] =
        useState<ManageSubscriptionFormI>({
            rule: {
                label: ruleOptions[0].label,
                value: ruleOptions[0].value,
            },
            wellOptions: initSelectedWells,
            // includeNewWells: false,
            enableEmail: false,
            enableText: false,
            byPassDnd: false,
            subscriber: initialSubscriber,
            initialSilenceUnixMs: 0,
            silenceForm: {
                timeValue: 0,
                timeUnit: "s",
            },
        })

    const [mutationErrorBanner, setMutationErrorBanner] =
        useState<boolean>(false)

    // handlers:
    const handleRuleNameChange = (e: SingleValue<LabelValueOptionType>) => {
        if (e?.value && e.label) {
            setAddSubscriptionForm({
                ...addSubscriptionForm,
                rule: {
                    label: e.label,
                    value: e.value,
                },
            })
        }
    }

    const handleSubscriberChange = (e: SingleValue<LabelValueOptionType>) => {
        if (e?.value && e.label) {
            // find subscriber option by id
            const updatedSubscriber = subscriberOptions.find(
                (sub) => sub.id === e.value
            )
            if (!updatedSubscriber) {
                return
            }

            setAddSubscriptionForm({
                ...addSubscriptionForm,
                subscriber: {
                    ...updatedSubscriber,
                },
            })
        }
    }

    const handleSelectedWellsChange = (
        newValue: MultiValue<MultiSelectOptionT>
    ) => {
        const updatedWellOptions = addSubscriptionForm.wellOptions.map(
            (well) => {
                // look for value in new value - if it exists.set isSelected to true, else set false
                const isWellInNewValue = newValue.findIndex(
                    (option) => option.value === well.value
                )

                if (isWellInNewValue > -1) {
                    return { ...well, isSelected: true }
                }

                // well is not in new value
                return { ...well, isSelected: false }
            }
        )

        setAddSubscriptionForm({
            ...addSubscriptionForm,
            wellOptions: updatedWellOptions,
        })
    }

    const handleUpdateSubscriptionPreferences = (
        inputId: keyof ManageSubscriptionFormI,
        value: boolean
    ) => {
        setAddSubscriptionForm({
            ...addSubscriptionForm,
            [inputId]: value,
        })
    }

    const handleSelectAllWells = (updatedSelect: boolean) => {
        const updatedWellOptions = addSubscriptionForm.wellOptions.map(
            (well) => {
                return { ...well, isSelected: updatedSelect }
            }
        )

        setAddSubscriptionForm({
            ...addSubscriptionForm,
            wellOptions: updatedWellOptions,
        })
    }

    // submit handlers
    const handleSubmitAddSubscription = () => {
        const mutationVariables =
            getSubscriptionAddMutationVariables(addSubscriptionForm)

        addSubscriptionMutation({
            variables: {
                AlertSubscriptionsAddInput: { inputs: mutationVariables },
            },
            refetchQueries: [
                GET_SUBSCRIPTIONS_FOR_USER,
                {
                    query: GET_SUBSCRIPTIONS_FOR_ORG,
                    variables: {
                        input: {
                            orgIDs: [
                                notificationsContext.selectedOrganization.value,
                            ],
                        },
                    },
                },
            ],
        })
            .then(() => {
                navigate(navTo.to, navTo.options)
            })
            .catch((error) => {
                console.error("Add subscription mutation error:", error)
                setMutationErrorBanner(true)
            })
    }

    // constants
    const formType = viewerAction === "admin" ? "AddAdmin" : "AddSelf"
    const selectedSubscribedWells = addSubscriptionForm.wellOptions.filter(
        (wl) => {
            return wl.isSelected
        }
    )
    // subscriber options must be converted to a type that works with react-select
    const formSubscriberOptions = subscriberOptions.map((sub) => {
        return {
            label: sub.label,
            value: sub.id,
        }
    })
    const navTo = navigateAfterMutation(pathname)

    return (
        <>
            <ManageSubscriptionForm
                handleRuleNameChange={handleRuleNameChange}
                handleSelectedWellsChange={handleSelectedWellsChange}
                handleUpdateSubscriptionPreferences={
                    handleUpdateSubscriptionPreferences
                }
                form={addSubscriptionForm}
                ruleOptions={ruleOptions}
                wellOptions={wellOptions}
                handleSelectAllWells={handleSelectAllWells}
                formType={formType}
                handleSubscriberChange={handleSubscriberChange}
                subscriberOptions={formSubscriberOptions}
            />
            {!mutationErrorBanner && (
                <ManageSubscriptionsActions
                    alertRuleId={addSubscriptionForm.rule.value}
                    formType={formType}
                    selectedSubscribedWells={selectedSubscribedWells}
                />
            )}
            {mutationErrorBanner && (
                <>
                    <MutationErrorBanner
                        message={
                            <div>
                                There was problem adding your subscription.
                                Click{" "}
                                <u
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        navigate(navTo.to, navTo.options)
                                    }
                                >
                                    here
                                </u>{" "}
                                or close the modal to be redirected.
                            </div>
                        }
                    />
                </>
            )}
            <ModalFooter
                advanceText="Submit"
                disableAdvance={mutationLoading || mutationErrorBanner}
                handleAdvanceClick={handleSubmitAddSubscription}
                disablePrevious={mutationLoading}
                handlePreviousClick={() => navigate(-1)}
                previousButtonCondition="default"
                previousText="Cancel"
            />
        </>
    )
}

export default AddSubscriptionForm
