import { FunctionComponent, ReactElement } from "react"
import { useWellControlsContext } from "../../../context/wellControlsContext"
// component
import WellControlsTimer from "./wellControlsTimer"
import Input from "../../shared/input"
import WellControlsSmartTimer from "./wellControlsSmartTimer"
// scss
import styles from "./wellControlRunMode.module.scss"
import { NormalDistributionParametersFieldsFragment } from "../../../generated/graphql"

interface WellControlRunModePropsI {
    normalDistributionParametersFragment: NormalDistributionParametersFieldsFragment
}

const WellControlRunMode: FunctionComponent<WellControlRunModePropsI> = ({
    normalDistributionParametersFragment,
}) => {
    const { mean } = normalDistributionParametersFragment
    // If the mean is [0,0] then the well has not been tuned yet and smart mode should be disabled.
    // For more context see TREX-820 => [https://petropower.atlassian.net/jira/software/c/projects/TREX/boards/14?issueParent=14906&selectedIssue=TREX-820]
    let disableSmartMode = false
    if (mean[0] === 0 && mean[1] === 0) {
        disableSmartMode = true
    }

    const {
        wellControlState: { UiState },
        handleRunModeChange,
    } = useWellControlsContext()

    let runModeDisplay: ReactElement
    switch (UiState.selectedRunMode) {
        case "On":
            runModeDisplay = <div className={styles.runModeOn}>Running</div>
            break
        case "Off":
            runModeDisplay = (
                <>
                    <div className={styles.runModeStopped}>Stopped</div>
                </>
            )
            break
        case "Smart":
            runModeDisplay = (
                <>
                    <div className={styles.runModeOn}>Smart</div>
                    <WellControlsSmartTimer />
                </>
            )

            break
        case "Timer":
            runModeDisplay = <WellControlsTimer />
            break
    }

    return (
        <>
            <div className={styles.container}>
                <Input
                    orientation="horizontal"
                    label="Run Mode"
                    specializedClass="default"
                    type="select"
                    value={UiState.selectedRunMode}
                    onChange={handleRunModeChange}
                    options={[
                        { label: "On", value: "On" },
                        { label: "Off", value: "Off" },
                        { label: "Timer", value: "Timer" },
                        {
                            label: "Smart",
                            value: "Smart",
                            disabled: disableSmartMode,
                        },
                    ]}
                    disabled={false}
                />
            </div>
            {runModeDisplay}
        </>
    )
}

export default WellControlRunMode
