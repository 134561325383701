import { FunctionComponent } from "react"
import Button from "../../../../shared/button"
import "./previewFooters.scss"

interface AddedFooterPropsInterface {
    onCloseFooterClick: () => void
    onEditFooterClick: () => void
}

interface UnaddedFooterPropsInterface {
    onCancelFooterClick: () => void
    onAddFooterClick: () => void
    disabled: boolean
}

const PreviewAddedFooter: FunctionComponent<AddedFooterPropsInterface> = ({
    onCloseFooterClick,
    onEditFooterClick,
}) => {
    return (
        <div className="action-panel-footer-container">
            <Button handleButtonClick={onCloseFooterClick} status={"secondary"}>
                Close
            </Button>
            <Button handleButtonClick={onEditFooterClick} status={"primary"}>
                Edit
            </Button>
        </div>
    )
}

const PreviewAddNewFooter: FunctionComponent<UnaddedFooterPropsInterface> = ({
    onCancelFooterClick,
    onAddFooterClick,
    disabled,
}) => {
    return (
        <div className="action-panel-footer-container">
            <Button
                handleButtonClick={onCancelFooterClick}
                status={"secondary"}
            >
                Cancel
            </Button>
            <Button
                disabled={disabled}
                handleButtonClick={onAddFooterClick}
                status={"primary"}
            >
                Add
            </Button>
        </div>
    )
}

export { PreviewAddedFooter, PreviewAddNewFooter }
