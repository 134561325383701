/**
 * Replaces an object with a matching key in an array with a new object.
 * @param {T[]} array - The array to modify.
 * @param {T} newObj - The new object to replace the old object with.
 * @param {(obj: T) => string} getKey - A function that returns the key value for a given object.
 * @returns {T[]} - A new array with the updated object.
 */
export const replaceObjectInArray = <T>(
    array: T[],
    newObj: T,
    getKey: (obj: T) => string
) => {
    const key = getKey(newObj)
    return array.map((obj) => (getKey(obj) === key ? newObj : obj))
}

/**
 * Deletes an object with a matching key from an array.
 * @param {T[]} array - The array to modify.
 * @param {(obj: T) => string} getKey - A function that returns the key value for a given object.
 * @param {string} key - The key value of the object to delete.
 * @returns {T[]} - A new array without the deleted object.
 */
export const deleteObjectFromArray = <T>(
    array: T[],
    getKey: (obj: T) => string,
    key: string
) => {
    return array.filter((obj) => getKey(obj) !== key)
}

/**
 * Adds an item to an array.
 * @param {T[]} array - The array to add the item to.
 * @param {T} item - The item to add to the array.
 * @returns {T[]} - A new array with the added item.
 */
export const addDataToArray = <T>(array: T[], item: T): T[] => {
    return [...array, item]
}

/**
 * Finds the index of an object with a matching key in an array.
 * @param {T[]} array - The array to search.
 * @param {(obj: T) => string} getKey - A function that returns the key value for a given object.
 * @param {string} key - The key value to search for.
 * @returns {number|undefined} - The index of the matching object, or undefined if it doesn't exist.
 */
export const findIndexByKeyInArray = <T>(
    array: T[],
    getKey: (obj: T) => string,
    key: string
): number | undefined => {
    const index = array.findIndex((obj) => getKey(obj) === key)
    if (index !== -1) {
        return index
    } else {
        return undefined
    }
}

/**
 * Sorts an array of objects based on a specified key.
 * makes a copy of the array before sorting so the original array is not mutated.
 * @param {T[]} array - The array to sort.
 * @param {(obj: T) => string} getKey - A function that returns the key value for a given object.
 * @param {boolean} caseInsensitive - If true, performs a case sensitive comparison (default: false).
 * @returns {T[]} - The sorted array.
 */

export const sortArrayByKey = <T>(
    arr: T[],
    getKey: (obj: T) => string,
    caseSensitive = false
): T[] => {
    const sortedArray = [...arr]

    const compareFunction = (a: T, b: T) => {
        const valueA = caseSensitive ? getKey(a) : getKey(a).toLowerCase()
        const valueB = caseSensitive ? getKey(b) : getKey(b).toLowerCase()

        if (valueA < valueB) {
            return -1
        }
        if (valueA > valueB) {
            return 1
        }
        return 0
    }

    return sortedArray.sort(compareFunction)
}

/**
 * Sorts an array of strings or numbers.
 * Makes a copy of the array before sorting so the original array is not mutated.
 * @param {(string | number)[]} arr - The array to sort.
 * @param {boolean} caseSensitive - If true, performs a case-sensitive comparison (default: false).
 * @returns {(string | number)[]} - The sorted array.
 */

export const sortArray = (
    arr: (string | number)[],
    caseSensitive = false
): (string | number)[] => {
    const sortedArray = [...arr]

    const compareFunction = (a: string | number, b: string | number) => {
        const valueA =
            typeof a === "string" && !caseSensitive ? a.toLowerCase() : a
        const valueB =
            typeof b === "string" && !caseSensitive ? b.toLowerCase() : b

        if (valueA < valueB) {
            return -1
        }
        if (valueA > valueB) {
            return 1
        }
        return 0
    }

    return sortedArray.sort(compareFunction)
}
