import { useContext, createContext, useState, FunctionComponent } from "react"
import { v4 as uuidv4 } from "uuid"
// types
import {
    AddWellsContextInterface,
    AddWellsInterface,
    AddWellsProviderInterface,
    AddWellsWellDataInterface,
    WellApiNumberFormStateInterface,
} from "../types/addWellsTypes"
import { OrganizationOptionType } from "../types/ViewerTypes"
// utils
import {
    canMoveOnToConfirmation,
    validateWellApiNumberInput,
} from "../util/addWellsUtils/addWellsUtil"
import {
    addDataToArray,
    deleteObjectFromArray,
    replaceObjectInArray,
} from "../util/arrays/array"

// create context
const AddWellsContext = createContext<AddWellsContextInterface | undefined>(
    undefined
)

// Provider
const AddWellsProvider: FunctionComponent<AddWellsProviderInterface> = ({
    children,
}) => {
    /** AddWellState */
    const [addWellsState, setAddWellsState] = useState<AddWellsInterface>({
        displayPanelArray: [],
        addWellsInterfaceState: "addNew",
        addWellsWellData: undefined,
        actionPanel: "empty",
        selectedOrganization: undefined,
        wellApiNumberFormState: {
            noApiNumberCheckBox: false,
            wellApiNumberErrMsg: undefined,
            wellApiNumber: "",
            disableNoApiNumberCheckbox: false,
            disableWellApiNumberInput: false,
        },
    })

    /** Handlers */
    /**
     *  handleAdvanceToConfirmation.
     *  Change add wells modal UI state from 'addNew' to 'confirmation' view.
     *  Upon navigating to confirmation view. Users will lose any values in the edit well form, or unadded wells in the preview panel.
     *  The result of the line above is the action panel will be set to an empty state and addWellsWellData will be cleared.
     *
     */
    const handleAdvanceToConfirmation = () => {
        const canMoveOn = canMoveOnToConfirmation(
            addWellsState.displayPanelArray.length,
            addWellsState.selectedOrganization
        )
        if (canMoveOn) {
            setAddWellsState({
                ...addWellsState,
                addWellsInterfaceState: "confirm",
                addWellsWellData: undefined,
                actionPanel: "empty",
            })
        } else {
            throw new Error(
                "Move to confirmation button should have been disabled."
            )
        }
    }

    /**
     *  handleConfirmationPrevious
     *  Change add wells modal UI state from 'confirmation' to 'addNew' view.
     *  Upon navigating to back from confirmation view to addNew. Users will return to addNew with only the displayPanel array populated with data.
     *  The wellApiNumberForm / actionPanel will be empty.
     */
    const handleConfirmationPrevious = () => {
        setAddWellsState({
            ...addWellsState,
            addWellsInterfaceState: "addNew",
            addWellsWellData: undefined,
            actionPanel: "empty",
            wellApiNumberFormState: {
                noApiNumberCheckBox: false,
                wellApiNumberErrMsg: undefined,
                wellApiNumber: "",
                disableNoApiNumberCheckbox: false,
                disableWellApiNumberInput: false,
            },
        })
    }

    /**
     *  handleSubmitWellApiNumberForm
     *  After Submitting WellApiNumberForm a new form in the actionPanel - preview state will be populated with empty values for wellName, Long. Lat.
     *  The display panel array will remain the same.
     *  well api number form will disable and clear its inputs.
     */
    const handleSubmitWellApiNumberForm = () => {
        const wellApiNumber = addWellsState.wellApiNumberFormState.wellApiNumber
        const wellApiValidationStatus = validateWellApiNumberInput(
            wellApiNumber,
            addWellsState.displayPanelArray
        )

        // well api number is not validated
        if (wellApiValidationStatus !== "validated") {
            setAddWellsState((prevState) => ({
                ...prevState,
                wellApiNumberFormState: {
                    ...prevState.wellApiNumberFormState,
                    wellApiNumberErrMsg: wellApiValidationStatus,
                },
            }))
            return
        }

        // well api number is validated and ready to be added to action panel.
        setAddWellsState((prevState) => ({
            ...prevState,
            addWellsWellData: {
                WellAttributesForm: {
                    apiNumber: wellApiNumber,
                    Latitude: 0,
                    Longitude: 0,
                    WellName: "",
                },
                addWellModalId: uuidv4(),
            },
            actionPanel: "preview",
            addWellsInterfaceState: "addNew",
            wellApiNumberFormState: {
                ...prevState.wellApiNumberFormState,
                wellApiNumberErrMsg: undefined,
                wellApiNumber: "",
                disableNoApiNumberCheckbox: true,
                disableWellApiNumberInput: true,
            },
        }))
    }

    /**
     * handleAddWellToDisplayPanel
     * This action occurs after a user clicks on a well in the display panel
     * Adds a well object to the display panel array and updates the component state accordingly.
     * @param {AddWellsWellDataInterface} addedWellData - The well object to add to the display panel array.
     */
    const handleAddWellToDisplayPanel = (
        addedWellData: AddWellsWellDataInterface
    ) => {
        const copyOfAddedWellData: AddWellsWellDataInterface = {
            addWellModalId: addedWellData.addWellModalId,
            WellAttributesForm: { ...addedWellData.WellAttributesForm },
        }

        // trim beginning and trailing white spaces
        copyOfAddedWellData.WellAttributesForm.WellName =
            copyOfAddedWellData.WellAttributesForm.WellName.trimStart()

        copyOfAddedWellData.WellAttributesForm.WellName =
            copyOfAddedWellData.WellAttributesForm.WellName.trimEnd()

        const { displayPanelArray } = addWellsState
        const updatedWellsArray = addDataToArray(
            displayPanelArray,
            copyOfAddedWellData
        )

        setAddWellsState({
            ...addWellsState,
            displayPanelArray: updatedWellsArray,
            addWellsWellData: undefined,
            actionPanel: "empty",
            wellApiNumberFormState: {
                ...addWellsState.wellApiNumberFormState,
                disableNoApiNumberCheckbox: false,
                disableWellApiNumberInput: false,
                noApiNumberCheckBox: false,
            },
        })
    }

    /**
     * Handles the submission of an updated well object by replacing the original object with the updated object in the display panel array and updating the component state accordingly.
     * This is called from the action panel / edit.
     * After submission the action panel should preview the new edit and the display panel array should be updated.
     * @param {AddWellsWellDataInterface} updatedWellData - The updated well object to replace the original object with in the display panel array.
     */
    const handleSubmitEditWell = (
        updatedWellData: AddWellsWellDataInterface
    ) => {
        const copyUpdatedWellData: AddWellsWellDataInterface = {
            addWellModalId: updatedWellData.addWellModalId,
            WellAttributesForm: { ...updatedWellData.WellAttributesForm },
        }

        // trim beginning and trailing white spaces
        copyUpdatedWellData.WellAttributesForm.WellName =
            copyUpdatedWellData.WellAttributesForm.WellName.trimStart()

        copyUpdatedWellData.WellAttributesForm.WellName =
            copyUpdatedWellData.WellAttributesForm.WellName.trimEnd()

        // Use the replaceObjectInArray() utility function to replace the original well object in the display panel array with the updated well object.
        const updatedArray = replaceObjectInArray(
            addWellsState.displayPanelArray,
            updatedWellData,
            (obj) => obj.addWellModalId
        )

        // Update the component state to include the updated display panel array, set the addWellsWellData property to the updated well data,
        // and set the action panel to "preview".
        setAddWellsState({
            ...addWellsState,
            displayPanelArray: updatedArray,
            addWellsWellData: updatedWellData,
            actionPanel: "preview",
            wellApiNumberFormState: {
                ...addWellsState.wellApiNumberFormState,
                disableNoApiNumberCheckbox: false,
                disableWellApiNumberInput: false,
            },
        })
    }

    /**
     * Handles the deletion of a well object from the display panel array.
     * This action occurs when a user uses the display panel array menu to delete a well.
     * The action should remove the array from the display panel array and leave the action panel in an empty state.
     * @param {AddWellsWellDataInterface} deleteApiData - The well object to delete from the display panel array.
     */
    const handleDeleteWellFromDisplayPanel = (
        deleteApiData: AddWellsWellDataInterface
    ) => {
        // Use the deleteObjectFromArray() utility function to remove the well object from the display panel array.
        const updatedWellsArray = deleteObjectFromArray(
            addWellsState.displayPanelArray,
            (obj) => obj.addWellModalId,
            deleteApiData.addWellModalId
        )

        // Update the component state to include the updated display panel array, set the action panel to "empty", and clear the addWellsWellData property.
        setAddWellsState({
            ...addWellsState,
            displayPanelArray: updatedWellsArray,
            actionPanel: "empty",
            addWellsWellData: undefined,
        })
    }

    /**
     * Handles the cancellation of editting a well in the display panel.
     * The action should leave the display panel in a preview state ( of the well being previously edited.).
     * It should not make changes to the display panel array.
     */
    const handleCancelEdit = () => {
        setAddWellsState({
            ...addWellsState,
            actionPanel: "preview",
            wellApiNumberFormState: {
                ...addWellsState.wellApiNumberFormState,
                disableNoApiNumberCheckbox: false,
                disableWellApiNumberInput: false,
            },
        })
    }

    const getWellApiNumberFormStateBasedOnApiLength =
        (): WellApiNumberFormStateInterface => {
            const apiNumberLength =
                addWellsState.wellApiNumberFormState.wellApiNumber.length

            if (apiNumberLength === 0) {
                return {
                    ...addWellsState.wellApiNumberFormState,
                    disableNoApiNumberCheckbox: false,
                    disableWellApiNumberInput: false,
                    noApiNumberCheckBox: false,
                }
            } else {
                return {
                    ...addWellsState.wellApiNumberFormState,
                    disableNoApiNumberCheckbox: true,
                    disableWellApiNumberInput: false,
                    noApiNumberCheckBox: false,
                }
            }
        }

    /**
     * Handles setting the action panel to "preview" and updating the component state with the selected well data.
     * This action occurs when a user clicks on a well in the display panel array.
     * @param {AddWellsWellDataInterface} selectedWellData - The well data to set as the selected well data in the component state.
     */
    const handleSetActionPanelToPreview = (
        selectedWellData: AddWellsWellDataInterface
    ) => {
        setAddWellsState({
            ...addWellsState,
            addWellsWellData: selectedWellData,
            actionPanel: "preview",
            wellApiNumberFormState: getWellApiNumberFormStateBasedOnApiLength(),
        })
    }

    /**
     * This can be used to close the action panel preview state.
     * Can be used when a user has selected a well from the display panel or when a user has begun the process of adding a new well but decides to cancel the addition.
     * It should leave the addWellsWellData undefined and the action panel in an empty state.
     */
    const handleCloseActionPanelPreview = () => {
        setAddWellsState({
            ...addWellsState,
            actionPanel: "empty",
            addWellsWellData: undefined,
            wellApiNumberFormState: getWellApiNumberFormStateBasedOnApiLength(),
        })
    }
    /**
     * Handles setting the action panel to "edit" and updating the addWellsWellData with the selected wells data.
     * This happens when a user clicks edit from the action panel preview state..
     * @param {AddWellsWellDataInterface} apiData - The well data to edit in the component state.
     */
    const handleSetActionPanelEdit = (apiData: AddWellsWellDataInterface) => {
        setAddWellsState({
            ...addWellsState,
            actionPanel: "edit",
            addWellsWellData: apiData,
            wellApiNumberFormState: {
                ...addWellsState.wellApiNumberFormState,
                disableNoApiNumberCheckbox: true,
                disableWellApiNumberInput: true,
                wellApiNumber: "",
                noApiNumberCheckBox: false,
                wellApiNumberErrMsg: undefined,
            },
        })
    }

    /**
     * getAddWellsWellData
     * used to get addWellsWellData from context.
     * useful because addWellsData can be undefined ( for empty states) and component does not have to check for undefined.
     */

    const getAddWellsWellData = () => {
        if (!addWellsState.addWellsWellData) {
            throw new Error(
                " Tried to get add wells well data and it was undefined."
            )
        } else {
            return addWellsState.addWellsWellData
        }
    }

    /**
     *
     * @param organization - user selected organization
     * sets addWellsState.selectedOrganization to user selected organization
     */
    const handleSetAddWellOrganization = (
        value: OrganizationOptionType | null
    ) => {
        if (!value) {
            throw new Error(
                "Error: Handle organization change. Should not be setting organization to null."
            )
        }

        setAddWellsState((prevState) => ({
            ...prevState,
            selectedOrganization: value,
        }))
    }

    const handleWellApiNumberChange = (formattedWellApiNumber: string) => {
        const inputCleared = formattedWellApiNumber.length === 0 ? true : false

        if (inputCleared) {
            setAddWellsState((prevState) => ({
                ...prevState,
                wellApiNumberFormState: {
                    ...prevState.wellApiNumberFormState,
                    wellApiNumber: formattedWellApiNumber,
                    wellApiNumberErrMsg: undefined,
                    disableNoApiNumberCheckbox: false,
                },
            }))
        } else {
            setAddWellsState((prevState) => ({
                ...prevState,
                wellApiNumberFormState: {
                    ...prevState.wellApiNumberFormState,
                    wellApiNumber: formattedWellApiNumber,
                    disableNoApiNumberCheckbox: true,
                },
            }))
        }
    }

    const handleWellApiNumberInputBlur = () => {
        const inputCleared =
            addWellsState.wellApiNumberFormState.wellApiNumber.length === 0
                ? true
                : false

        if (inputCleared) return

        const wellApiValidationStatus = validateWellApiNumberInput(
            addWellsState.wellApiNumberFormState.wellApiNumber,
            addWellsState.displayPanelArray
        )

        const errMsg =
            wellApiValidationStatus === "validated"
                ? undefined
                : wellApiValidationStatus
        // disable input is handled by handleSubmitWellWithNoApiNumber &  handleWellApiNumberChange
        setAddWellsState((prevState) => ({
            ...prevState,
            wellApiNumberFormState: {
                ...prevState.wellApiNumberFormState,
                wellApiNumberErrMsg: errMsg,
            },
        }))
    }

    const handleSubmitWellWithNoApiNumber = () => {
        setAddWellsState((prevState) => ({
            ...prevState,
            addWellsWellData: {
                addWellModalId: uuidv4(),
                WellAttributesForm: {
                    Latitude: 0,
                    Longitude: 0,
                    WellName: "",
                    apiNumber: false,
                },
            },
            actionPanel: "preview",
            addWellsInterfaceState: "addNew",
            wellApiNumberFormState: {
                ...prevState.wellApiNumberFormState,
                noApiNumberCheckBox: true,
                disableNoApiNumberCheckbox: true,
                disableWellApiNumberInput: true,
                wellApiNumber: "",
                wellApiNumberErrMsg: undefined,
            },
        }))
    }

    // Context Values
    const contextValues = {
        addWellsState,
        getAddWellsWellData,
        handleAddWellToDisplayPanel,
        handleSubmitWellApiNumberForm,
        handleAdvanceToConfirmation,
        handleConfirmationPrevious,
        handleSubmitEditWell,
        handleCancelEdit,
        handleSetActionPanelToPreview,
        handleDeleteWellFromDisplayPanel,
        handleCloseActionPanelPreview,
        handleSetActionPanelEdit,
        handleSetAddWellOrganization,
        handleWellApiNumberChange,
        handleWellApiNumberInputBlur,
        handleSubmitWellWithNoApiNumber,
    }

    return (
        <AddWellsContext.Provider value={contextValues}>
            {children}
        </AddWellsContext.Provider>
    )
}

const useAddWellsContext = () => {
    const context = useContext(AddWellsContext)

    if (context === undefined) {
        throw new Error(
            "useAddWellsContext must be used within AddWellsProvider"
        )
    }

    return context
}

export { AddWellsProvider, useAddWellsContext }
