import classname from "classnames"
import { forwardRef, useImperativeHandle, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./button.scss"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

export type ButtonConditonType = "default" | "alert"

interface ButtonInput {
    children: React.ReactNode | React.ReactNode[] | string | number
    status: "primary" | "secondary"
    condition?: ButtonConditonType
    handleButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    customButtonClassName?: string
    disabled?: boolean
    iconLeft?: IconProp
    iconLeftClass?: string
    id?: string
}

const Button = forwardRef<HTMLButtonElement, ButtonInput>(
    (
        {
            children,
            status,
            condition,
            handleButtonClick,
            disabled = false,
            iconLeft,
            iconLeftClass,
            customButtonClassName,
            id,
            ...props
        },
        outerRef
    ) => {
        Button.displayName = "Button"

        // Build Classes
        let btnClass = ""
        switch (status) {
            case "primary":
                btnClass =
                    condition === "alert"
                        ? "button-primary-alert"
                        : "button-primary-default"
                break
            case "secondary":
                btnClass =
                    condition === "alert"
                        ? "button-secondary-alert"
                        : "button-secondary-default"
                break
            default:
                btnClass = "button"
                break
        }

        const classes = classname("button", btnClass, customButtonClassName)
        const leftIconClass = classname(
            "button-icon-padding-right",
            iconLeftClass
        )
        //Hooks
        const innerRef = useRef<HTMLButtonElement>(null)

        //Event Handlers
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
            handleButtonClick && handleButtonClick(event)
            innerRef.current?.blur()
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        useImperativeHandle(outerRef, () => innerRef.current!, [])

        return (
            <button
                {...props}
                disabled={disabled}
                ref={innerRef}
                onClick={handleClick}
                className={classes}
                id={id}
            >
                {iconLeft && (
                    <FontAwesomeIcon
                        icon={iconLeft}
                        className={leftIconClass}
                    />
                )}
                {children}
            </button>
        )
    }
)

export default Button
