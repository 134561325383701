/**
 *
 * checkForOneNonWhiteSpaceCharacter checks whether a given string contains at least one non-whitespace character.
 * A non-whitespace character is defined as any character that is not a space, tab, or newline character.
 *
 * @param {string} str - The string to check for non-whitespace characters.
 * @returns {boolean} - Returns `true` if the string contains at least one non-whitespace character, `false` otherwise.
 *
 */
const checkForOneNonWhiteSpaceCharacter = (string: string): boolean => {
    return /\S/.test(string)
}

export { checkForOneNonWhiteSpaceCharacter }
