import { useMutation } from "@apollo/client"
import React, { FunctionComponent, useState } from "react"
import { useNavigate } from "react-router-dom"
import { UPDATE_USERS } from "../../../../graphql/mutations/update_users"
import styles from "./form.module.scss"
import { UpdateProfileTimezoneFormI } from "../timezoneUtil/types"
import { InputMaybe, ZoneInfo } from "../../../../generated/graphql"
import { USER_SETTINGS_ABS_ROUTE } from "../../../.."
import ModalFooter from "../../../shared/modalFooter"
import MutationErrorBanner from "../../../shared/graphQlResponse"
import Input from "../../../shared/input"
import { LabelValueOptionType } from "../../../../types/commonTypes"
import { getZoneInfoFromEnumString } from "../../../../graphql/enum/zoneInfo"

const UpdateTimezoneFormComponent: FunctionComponent<{
    userId: string
    timezone: InputMaybe<ZoneInfo> | undefined
}> = ({ userId, timezone }) => {
    const navigate = useNavigate()
    // mutation
    const [updateProfileMutation, { loading: updateProfileMutationLoading }] =
        useMutation(UPDATE_USERS)

    // options
    const options: LabelValueOptionType[] = [
        {
            label: "Central Time",
            value: ZoneInfo.CentralTimeZone,
        },
        {
            label: "Mountain Time",
            value: ZoneInfo.MountainTimeZone,
        },
        {
            label: "Eastern Time",
            value: ZoneInfo.EasternTimeZone,
        },
        {
            label: "Pacific Time",
            value: ZoneInfo.PacificTimeZone,
        },
        {
            label: "Hawaii Time",
            value: ZoneInfo.HawaiiTimeZone,
        },
        {
            label: "Alaska Time",
            value: ZoneInfo.AlaskaTimeZone,
        },
    ]

    // form state
    const [form, setForm] = useState<UpdateProfileTimezoneFormI>({
        zoneInfo:
            timezone != ZoneInfo.Unknown
                ? timezone?.toString()
                : ZoneInfo.CentralTimeZone.toString(),
    })

    const [mutationSuccess, setMutationSuccess] = useState(false)
    const [mutationErrorBanner, setMutationErrorBanner] = useState(false)

    const handleChangeTimezone = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const targetValue = e.target.value
        const updatedForm = {
            ...form,
            zoneInfo: targetValue,
            dirty: true,
        }

        setForm(updatedForm)
    }

    const handleSubmit = async () => {
        try {
            await updateProfileMutation({
                variables: {
                    UserUpdateInput: {
                        userID: userId,
                        zoneInfo: getZoneInfoFromEnumString(form?.zoneInfo),
                    },
                },
            })
            setMutationSuccess(true)
        } catch (err) {
            console.log("update timezone modal mutation error", err)
            setMutationErrorBanner(true)
        }
    }

    if (mutationSuccess) {
        navigate(USER_SETTINGS_ABS_ROUTE)
    }

    return (
        <>
            <div className={styles.container}>
                <div>
                    <Input
                        type="select"
                        label="Timezone"
                        orientation="horizontal"
                        options={options}
                        value={form?.zoneInfo}
                        onChange={handleChangeTimezone}
                        specializedClass="default"
                        customLabelClass={styles.timezoneInputLabel}
                        messageCustomClass={styles.timezoneInputMessage}
                    />
                </div>
            </div>
            {mutationErrorBanner && (
                <>
                    <MutationErrorBanner
                        message={
                            <div>
                                There was a problem updating your timezone
                                preferences. Click{" "}
                                <u
                                    style={{
                                        cursor: "pointer",
                                        marginRight: "4px",
                                    }}
                                    onClick={() =>
                                        navigate(USER_SETTINGS_ABS_ROUTE)
                                    }
                                >
                                    here
                                </u>
                                or close the modal to be redirected.
                            </div>
                        }
                    />
                </>
            )}
            <ModalFooter
                disableAdvance={
                    mutationErrorBanner || updateProfileMutationLoading
                }
                advanceText="Save"
                handleAdvanceClick={handleSubmit}
                disablePrevious={
                    updateProfileMutationLoading || mutationErrorBanner
                }
                previousText="Cancel"
                handlePreviousClick={() => {
                    navigate(USER_SETTINGS_ABS_ROUTE)
                }}
                previousButtonCondition="default"
            />
        </>
    )
}

export default UpdateTimezoneFormComponent
