import { graphql } from "../../generated"

export const USERS_UPDATE_ORG_MEMBERSHIP = graphql(`
    mutation UserUpdateOrganizationMembership(
        $UsersUpdateOrganizationMembershipInput: UsersUpdateOrganizationMembershipInput!
    ) {
        usersUpdateOrganizationMembership(
            input: $UsersUpdateOrganizationMembershipInput
        ) {
            users {
                ...SubjectFields
            }
        }
    }
`)
