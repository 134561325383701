import React, { FunctionComponent, useState } from "react"
import { useAddWellsContext } from "../../../../context/addWellsContext"
//components
import Input from "../../../shared/input"
import ActionPanelEditFooter from "./editFooter"
import "./edit.scss"
import { parseInputToFloat } from "../../../../util/InputValidation/inputValidation"
import {
    getWellAttributesFormErrors,
    isWellAttributesFormValid,
} from "../../../../util/wellAttributes/formValidation"
import {
    WellAttributesErrorFormI,
    WellAttributesFormI,
} from "../../../../util/wellAttributes/wellAttributesTypes"
import { objectHasNonUndefinedFields } from "../../../../util/objects/objects"

interface EditNewWellComponentStateI {
    editedWellAttributes: WellAttributesFormI
    editedWellAttributesErrors: WellAttributesErrorFormI
    disableUpdate: boolean
}

const Edit: FunctionComponent = () => {
    const { getAddWellsWellData, handleSubmitEditWell, handleCancelEdit } =
        useAddWellsContext()

    // initial state of form
    const addWellsWellData = getAddWellsWellData()
    const initialNewWellAttributes = { ...addWellsWellData.WellAttributesForm }

    const [componentState, setComponentState] =
        useState<EditNewWellComponentStateI>({
            editedWellAttributes: initialNewWellAttributes,
            editedWellAttributesErrors: {
                WellName: undefined,
                Latitude: undefined,
                Longitude: undefined,
                apiNumber: undefined,
            },
            disableUpdate: true,
        })

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target
        // create a new object for wellAttributesForm
        let upatedAttribute: string | number
        switch (name as keyof WellAttributesFormI) {
            case "WellName":
                upatedAttribute = e.target.value
                break
            case "Latitude":
            case "Longitude":
                upatedAttribute = parseInputToFloat(e)
                break
            default:
                throw new Error(
                    "Add wells modal - add wells action panel edit - handle Input change error."
                )
        }

        const editedWellAttributes: WellAttributesFormI = {
            ...componentState.editedWellAttributes,
            [name]: upatedAttribute,
        }

        // Errors Form : If there are currently errors...
        // ... Remove them if user is correcting them.
        // Newly created errors should not update error state.
        // They  will disable submit button and will be visible when the input is blurred.

        let errorsForm = {
            ...componentState.editedWellAttributesErrors,
        }

        if (objectHasNonUndefinedFields(errorsForm) === true) {
            errorsForm = getWellAttributesFormErrors(editedWellAttributes)
        }

        const isFormValid = isWellAttributesFormValid(editedWellAttributes)
        const disableFormSubmit = !isFormValid

        setComponentState((prevState) => ({
            ...prevState,
            disableUpdate: disableFormSubmit,
            editedWellAttributes: editedWellAttributes,
            editedWellAttributesErrors: errorsForm,
        }))
    }

    const handleInputBlur = () => {
        const updatedErrorData = getWellAttributesFormErrors({
            ...componentState.editedWellAttributes,
        })

        setComponentState((prevState) => ({
            ...prevState,
            editedWellAttributesErrors: updatedErrorData,
        }))
    }

    const submitEditWellApiWellData = () => {
        handleSubmitEditWell({
            addWellModalId: addWellsWellData.addWellModalId,
            WellAttributesForm: componentState.editedWellAttributes,
        })
    }

    const { editedWellAttributes, disableUpdate, editedWellAttributesErrors } =
        componentState

    return (
        <div className="action-panel-edit-container">
            <div className="action-panel-edit-well-information-container">
                <Input
                    orientation="vertical"
                    onBlur={handleInputBlur}
                    type="text"
                    value={editedWellAttributes.WellName}
                    onChange={handleInputChange}
                    label="Well Name *"
                    disabled={false}
                    name="WellName"
                    message={editedWellAttributesErrors.WellName}
                    specializedClass={
                        editedWellAttributesErrors.WellName
                            ? "default"
                            : "default"
                    }
                />
                <div className="action-panel-edit-flex-row-container">
                    <div className="action-panel-edit-pad-right-19">
                        <Input
                            orientation="vertical"
                            onBlur={handleInputBlur}
                            type="number"
                            value={editedWellAttributes.Latitude}
                            onChange={handleInputChange}
                            label="Latitude *"
                            inputCustomClass="add-well-action-panel-coordinate-input"
                            disabled={false}
                            name="Latitude"
                            message={editedWellAttributesErrors.Latitude}
                            specializedClass={
                                editedWellAttributesErrors.Latitude
                                    ? "danger"
                                    : "default"
                            }
                        />
                    </div>
                    <div>
                        <Input
                            orientation="vertical"
                            onBlur={handleInputBlur}
                            type="number"
                            value={editedWellAttributes.Longitude}
                            onChange={handleInputChange}
                            label="Longitude *"
                            inputCustomClass="add-well-action-panel-coordinate-input"
                            disabled={false}
                            name="Longitude"
                            message={editedWellAttributesErrors.Longitude}
                            specializedClass={
                                editedWellAttributesErrors.Longitude
                                    ? "danger"
                                    : "default"
                            }
                        />
                    </div>
                </div>
            </div>
            <ActionPanelEditFooter
                onCancelFooterClick={handleCancelEdit}
                onUpdateFooterClick={submitEditWellApiWellData}
                disabled={disableUpdate}
            />
        </div>
    )
}

export default Edit
