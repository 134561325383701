import { graphql } from "../../generated"

export const ACTIVATE_USER = graphql(`
    mutation ActivateUsers($UsersActivateInput: UsersActivateInput!) {
        usersActivate(input: $UsersActivateInput) {
            users {
                ...SubjectFields
            }
        }
    }
`)
