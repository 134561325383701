import { graphql } from "../../generated"

export const DURATION_FIELDS_FRAGMENT = graphql(`
    fragment DurationFields on Duration {
        hours
        minutes
        seconds
        totalSeconds
        totalMilliseconds
        totalMicroseconds
        totalNanoseconds
        string
    }
`)
