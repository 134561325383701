import { FunctionComponent } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useNotificationsContext } from "../../../../context/notificationsContext"
import { useQuery } from "@apollo/client"
import {
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../../../shared/graphQlResponse"
import styles from "./moveSubscriptionToUser.module.scss"
import MoveToUserFormContainer from "./formContainer"
import { getFragmentData } from "../../../../../../generated"
import { TRANSFER_SUB_TO_USER_QRY } from "../../../../../../graphql/queries/subscriptionsTransferQuery"
import { SUBSCRIPTION_FRAG_FOR_TRANSFER_SUB_TO_USER } from "../../../../../../graphql/fragments/subscription"
import { SUBJECT_FRAG_FOR_TRANSFER_SUB_TO_USER } from "../../../../../../graphql/fragments/subject"
import ModalHeader from "../../../../../shared/modalHeader"
import { TrexNavigator } from "../../../../../../classes/navigator/navigator"

const MoveSubscriptionToUser: FunctionComponent = () => {
    const { key: locationKey, pathname } = useLocation()
    const navigate = useNavigate()
    const { subscriptionId: subscriptionToTransferId } = useParams()

    if (!subscriptionToTransferId) {
        throw new Error("subscriptionId not resolved from url.")
    }

    const notificationContext = useNotificationsContext()
    const { error, loading, data } = useQuery(TRANSFER_SUB_TO_USER_QRY, {
        variables: {
            subscriptionInput: { ids: [subscriptionToTransferId] },
            usersFirst: 999,
            subjectFilter: {
                active: true,
                organizationIDs: [
                    notificationContext.selectedOrganization.value,
                ],
            },
        },
        fetchPolicy: "network-only",
    })

    const subscriptions = data?.subscriptionsForIDs.subscriptions.map((s) => {
        return getFragmentData(SUBSCRIPTION_FRAG_FOR_TRANSFER_SUB_TO_USER, s)
    })

    let moveToFormContainer = <></>
    if (loading) {
        moveToFormContainer = (
            <SyncLoaderComponent outSideDivStyle={styles.queryLoading} />
        )
    } else if (error) {
        moveToFormContainer = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="There was a problem while fetching subscription information."
            />
        )
    } else {
        const usersOptions = data?.users.nodes
            .map((u) =>
                getFragmentData(SUBJECT_FRAG_FOR_TRANSFER_SUB_TO_USER, u)
            )
            .filter((user) => user.identity)
            .map(({ identity, id }) => ({
                label: identity?.fullName as string, // we can safely assert here because we filter out users without identity above
                value: id,
            }))

        if (!usersOptions || usersOptions.length === 0) {
            moveToFormContainer = (
                <div className={styles.queryEmpty}>
                    You do not have any users to move subscriptions to.
                </div>
            )
        } else if (!subscriptions || subscriptions.length === 0) {
            moveToFormContainer = (
                <div className={styles.queryEmpty}>
                    You do not have any subscriptions to move.
                </div>
            )
        } else {
            moveToFormContainer = (
                <MoveToUserFormContainer
                    subsToTransfer={subscriptions}
                    toUserOptions={usersOptions}
                />
            )
        }
    }

    const fromUserName =
        (subscriptions && subscriptions[0].subscriber.identity?.fullName) || ""

    return (
        <div className={styles.container}>
            <ModalHeader
                title="Move Subscription to User"
                subTitle={`Move from: ${fromUserName}`}
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: pathname,
                        },
                        navigate
                    )
                }
            />
            {moveToFormContainer}
        </div>
    )
}

export default MoveSubscriptionToUser
