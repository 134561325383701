export type PrecisionAdjustmentT =
    | "roundInteger"
    | "floor"
    | { type: "decimal"; places: number }

/**
 * Converts a number to a percentage, maintaining the same number of decimal places as the input
 * @param {number} value - The number to convert to a percentage.
 * @returns {number} The percentage value.
 */
const ratioToPercentage = (value: number): number => {
    if (value === 0) return 0

    // Determine the number of decimal places in the input
    const decimalPlaces = (Math.abs(value).toString().split(".")[1] || "")
        .length

    // Convert the value to a percentage
    const percentage = value * 100

    // Round the percentage to the same number of decimal places as the input
    return Number(percentage.toFixed(decimalPlaces))
}

const percentageToRatio = (value: number): number => {
    if (value === 0) return 0

    // Determine the number of decimal places in the input
    const decimalPlaces = (Math.abs(value).toString().split(".")[1] || "")
        .length

    // Convert the value to a ratio
    const ratio = value / 100

    // Round the ratio to the same number of decimal places as the input
    return Number(ratio.toFixed(decimalPlaces + 2))
}

/**
 * Rounds a number to a specified number of decimal places, removing trailing zeros.
 *
 *
 * @param {number} number - The number to be rounded. Can be a whole number or a decimal.
 * @param {number} decimals - The number of decimal places to round to. Must be a non-negative integer.
 * @returns {number} The rounded number with the specified number of decimal places, without trailing zeros.
 *
 * @example
 * toDecimal(10.00, 2) // returns 10
 * toDecimal(10.005, 2) // returns 10.01
 * toDecimal(3.14159, 3) // returns 3.142
 */
const toDecimal = (number: number, decimals: number): number => {
    if (decimals < 0) throw new Error("Decimals must be a non-negative integer")

    const factor = Math.pow(10, decimals)
    const rounded = Math.round(number * factor) / factor

    return Number(rounded.toFixed(decimals))
}

const adjustNumberPrecision = (
    value: number,
    precisionAdjustment: PrecisionAdjustmentT
): number => {
    switch (precisionAdjustment) {
        case "roundInteger":
            return Math.round(value)
        case "floor":
            return Math.floor(value)
        default:
            if (precisionAdjustment.type === "decimal") {
                return toDecimal(value, precisionAdjustment.places)
            }
            throw new Error("Invalid precision adjustment type")
    }
}

export {
    ratioToPercentage,
    percentageToRatio,
    toDecimal,
    adjustNumberPrecision,
}
