import { graphql } from "../../generated"

export const ALERT_RULE_FIELDS_FRAGMENT = graphql(`
    fragment AlertRuleFields on AlertRule {
        id
        name
        description
        createdBy {
            ...SubjectFields
        }
        metric {
            ...AlertRuleMetricDefinitionFields
        }
        evaluationGroup
        organization {
            name
        }
        window {
            ...DurationFields
        }
        condition {
            ...AlertRuleConditionFields
        }
    }
`)
