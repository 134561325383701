import { graphql } from "../../generated"

export const UPDATE_ORGANIZATIONS = graphql(`
    mutation OrganizationsUpdate(
        $OrganizationsUpdateInput: OrganizationsUpdateInput!
    ) {
        organizationsUpdate(input: $OrganizationsUpdateInput) {
            organizations {
                ...OrganizationWithDescendantsFields
            }
        }
    }
`)
