import { graphql } from "../../generated"

export const ALERT_RULE_CONDITION_FIELDS_FRAGMENT = graphql(`
    fragment AlertRuleConditionFields on AlertRuleCondition {
        operator
        threshold
        threshold2
        hysteresis
        unit
        min
        max
    }
`)
