const isSubmitDisabled = (isTokenValid: boolean, linked: boolean) => {
    // if token is valid and well is not linked -- submit not disabled
    if (isTokenValid && !linked) {
        return false
    } else {
        // token is invalid or well is already linked
        return true
    }
}

export { isSubmitDisabled }
