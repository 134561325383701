const parseNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let returnValue: number | string = parseInt(e.target.value, 10)
    returnValue = returnValue ? returnValue : returnValue === 0 ? 0 : ""
    return returnValue
}

const parseTwoDigitNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let returnValue: number | string

    if (e.target.value.length === 3) {
        returnValue = e.target.value[0] + e.target.value[2]

        return parseInt(returnValue, 10)
    }

    returnValue = parseInt(e.target.value, 10)
    returnValue = returnValue ? returnValue : returnValue === 0 ? 0 : ""
    return returnValue
}

const parseThreeDigitNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let returnValue: number | string

    if (e.target.value.length === 4) {
        returnValue = e.target.value[0] + e.target.value[1] + e.target.value[3]

        return parseInt(returnValue, 10)
    }

    returnValue = parseInt(e.target.value, 10)
    returnValue = returnValue ? returnValue : returnValue === 0 ? 0 : ""
    return returnValue
}

const parseInputToFloat = (e: React.ChangeEvent<HTMLInputElement>) => {
    let returnValue: number | string = parseFloat(e.target.value)
    returnValue = returnValue ? returnValue : returnValue === 0 ? 0 : ""
    return returnValue
}

/**
 * Returns the input value if it's not greater than the specified maximum,
 * otherwise, returns the maximum value.
 *
 * @param input - The input value, which can be a number or a string.
 * @param max - The maximum value to compare against.
 * @returns The input value if it's less than or equal to the maximum; otherwise, the maximum value.
 */
const setInputMax = (input: number | string, max: number) => {
    if (typeof input === "number" && input > max) {
        return max
    }
    return input
}

export {
    parseNumberInput,
    parseTwoDigitNumberInput,
    parseInputToFloat,
    parseThreeDigitNumberInput,
    setInputMax,
}
