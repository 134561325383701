const capMaxSeconds = (highChartsSeries: (number | null)[][]) => {
    // this will mutate incoming array
    for (let i = 0; i < highChartsSeries.length; i++) {
        const seconds = highChartsSeries[i][1]
        if (typeof seconds === "number" && seconds > 86400) {
            highChartsSeries[i][1] = 86400
        }
    }

    return highChartsSeries
}

export { capMaxSeconds }
