import { Row } from "@tanstack/react-table"
import { MyNotificationsTableDataI } from "../tableTypes"
import { getAlertNotificationPriority } from "../../../../../../util/alertRuleMetric/alertRuleMetric"

// sort alerts  notifications in order from highest priority to lowest priority
function sortAlertNotifications(
    rowA: Row<MyNotificationsTableDataI>,
    rowB: Row<MyNotificationsTableDataI>
) {
    const priorityRowA = getAlertNotificationPriority(
        rowA.original.notificationRead,
        Boolean(rowA.original.eventResolvedTime),
        rowA.original.notificationSilenced
    )

    const priorityRowB = getAlertNotificationPriority(
        rowB.original.notificationRead,
        Boolean(rowB.original.eventResolvedTime),
        rowB.original.notificationSilenced
    )

    // Compare priorities and return the result
    if (priorityRowA > priorityRowB) {
        return -1
    } else if (priorityRowA < priorityRowB) {
        return 1
    } else {
        return 0
    }
}
export { sortAlertNotifications }
