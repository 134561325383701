import {
    WellAttributesErrorFormI,
    WellAttributesFormI,
} from "./wellAttributesTypes"
import { objectHasNonUndefinedFields } from "../objects/objects"
import { checkForOneNonWhiteSpaceCharacter } from "../regex"
import {
    WELL_ATTRIBUTES_API_ERROR,
    WELL_ATTRIBUTES_LATITUDE_ERROR,
    WELL_ATTRIBUTES_LONGITUDE_ERROR,
    WELL_ATTRIBUTES_NAME_ERROR,
} from "./errorMessages"

const isLatitudeValid = (latitude: number | string) => {
    if (typeof latitude === "number" && latitude <= 90 && latitude >= -90) {
        return true
    }
    return false
}

const isLongitudeValid = (Longitude: number | string) => {
    if (
        typeof Longitude === "number" &&
        Longitude <= 180 &&
        Longitude >= -180
    ) {
        return true
    }

    return false
}

const isWellNameValid = (wellName: string) => {
    if (checkForOneNonWhiteSpaceCharacter(wellName) === true) {
        return true
    } else {
        return false
    }
}

/**
 * @returns true if valid format and false if invalid format
 */

export const isWellAPINumberFormatValid = (apiNumber: string): boolean => {
    /**
     * The regular expression matches strings that have either:
     * 2 digits, followed by a dash, followed by 3 digits,
     * followed by another dash, followed by 5 digits,
     * Optionally:  followed by 4 more digits,
     * Optionally followed by a dash, followed by 2 digits, and another dash, followed by 2 digits.
     * xx-xxx-xxxxx
     * xx-xxx-xxxxx-xxxx
     * xx-xxx-xxxxx-xx-xx
     */
    const regex = /^\d{2}-\d{3}-\d{5}(-\d{4})?(-\d{2}-\d{2})?$/
    return regex.test(apiNumber)
}

export const isWellAttributesFormValid = (
    wellAttributes: WellAttributesFormI
): boolean => {
    const wellAttributeFormErrors = getWellAttributesFormErrors(wellAttributes)
    const areThereErrors = objectHasNonUndefinedFields(wellAttributeFormErrors)
    const isFormValid = areThereErrors === true ? false : true

    switch (isFormValid) {
        case true:
            return true
        case false:
            return false
    }
}

export const getWellAttributesFormErrors = (
    wellAttributes: WellAttributesFormI
): WellAttributesErrorFormI => {
    const newErrorState: WellAttributesErrorFormI = {
        Latitude: undefined,
        Longitude: undefined,
        WellName: undefined,
        apiNumber: undefined,
    }

    const { Latitude, Longitude, WellName } = wellAttributes

    if (isWellNameValid(WellName) === false) {
        newErrorState.WellName = WELL_ATTRIBUTES_NAME_ERROR
    }

    if (isLatitudeValid(Latitude) === false) {
        newErrorState.Latitude = WELL_ATTRIBUTES_LATITUDE_ERROR
    }

    if (isLongitudeValid(Longitude) === false) {
        newErrorState.Longitude = WELL_ATTRIBUTES_LONGITUDE_ERROR
    }

    if (typeof wellAttributes.apiNumber === "string") {
        if (isWellAPINumberFormatValid(wellAttributes.apiNumber) === false) {
            newErrorState.apiNumber = WELL_ATTRIBUTES_API_ERROR
        }
        // else wellAttributes.apiNumber is false & well has no api number -- there will not be an error state.
    }

    return newErrorState
}
