import { graphql } from "../../generated"

export const CONTROL_SETTING_FIELDS_FRAGMENT = graphql(`
    fragment ControlSettingFields on WellControlSetting {
        ...ControlSettingOnFields
        ...ControlSettingOffFields
        ...ControlSettingConstantIdleFields
        ...ControlSettingTimerFields
        ...ControlSettingUnspecifiedFields
    }
`)

export const CONTROL_SETTING_TIMER_FIELDS_FRAGMENT = graphql(`
    fragment ControlSettingTimerFields on WellControlSettingTimer {
        __typename
        id
        reason
        createdAt {
            unixMilliseconds
        }
        period {
            hours
            minutes
        }
        runRatio
        idleRatio
        runDuration {
            hours
            minutes
        }
        idleDuration {
            hours
            minutes
        }
        createdBy {
            identity {
                ...IdentityFields
            }
        }
    }
`)

export const CONTROL_SETTING_CONSTANT_IDLE_FIELDS_FRAGMENT = graphql(`
    fragment ControlSettingConstantIdleFields on WellControlSettingConstantIdle {
        __typename
        id
        reason
        createdAt {
            unixMilliseconds
        }
        initialRun {
            hours
            minutes
        }
        constantIdle {
            hours
            minutes
        }
        targetEmptyRatio
        createdBy {
            identity {
                ...IdentityFields
            }
        }
    }
`)

export const CONTROL_SETTING_ON_FIELDS_FRAGMENT = graphql(`
    fragment ControlSettingOnFields on WellControlSettingOn {
        __typename
        id
        reason
        createdAt {
            unixMilliseconds
        }
        createdBy {
            identity {
                ...IdentityFields
            }
        }
    }
`)

export const CONTROL_SETTING_OFF_FIELDS_FRAGMENT = graphql(`
    fragment ControlSettingOffFields on WellControlSettingOff {
        __typename
        id
        reason
        createdAt {
            unixMilliseconds
        }
        createdBy {
            identity {
                ...IdentityFields
            }
        }
    }
`)

export const CONTROL_SETTING_UNSPECIFIED_FIELDS_FRAGMENT = graphql(`
    fragment ControlSettingUnspecifiedFields on WellControlSettingUnspecified {
        id
        reason
        __typename
        createdAt {
            unixMilliseconds
        }
        createdBy {
            identity {
                ...IdentityFields
            }
        }
    }
`)
