import ReactSelect, {
    ActionMeta,
    GroupBase,
    MultiValue,
    OptionProps,
    StylesConfig,
    ValueContainerProps,
    components,
} from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"
import React, { FunctionComponent, useMemo } from "react"
import { sortArrayByKey } from "../../../../util/arrays/array"
import { faSquare } from "@fortawesome/pro-regular-svg-icons"

// documentation: https://react-select.com/home
export type MultiSelectOptionT = {
    value: string
    label: string
    isSelected: boolean
    isDisabled: boolean
}

interface MultiSelectI {
    options: MultiSelectOptionT[]
    onChange: (
        newValue: MultiValue<MultiSelectOptionT>,
        actionMeta: ActionMeta<MultiSelectOptionT>
    ) => void // use on change handler to set the isSelected on the selected options to true. See an example inside: /trex/ngweb/src/components/notifications/subscriptions/manageSubscriptions/addSubscriptions/formContainer.tsx
    width?: string // set the width of the component, if not set default is 400px
    menuPortalTarget?: HTMLElement | null | undefined
    isClearable?: boolean
    styles?:
        | StylesConfig<MultiSelectOptionT, true, GroupBase<MultiSelectOptionT>>
        | undefined
}

const MultiSelect: FunctionComponent<MultiSelectI> = ({
    options,
    onChange,
    width = "400px",
    isClearable = true,
    menuPortalTarget,
    styles = {
        container: (provided) => ({
            ...provided,
            width: width,
        }),
        control: (provided) => ({
            ...provided,
            width: "100%",
        }),
        option: (provided) => ({
            ...provided,
            backgroundColor: "#fff",
            color: "#000",
            ":hover": {
                backgroundColor: "#f5f5f5",
            },
        }),
    },
}) => {
    const sortedOptions = useMemo(() => {
        const selectedOptions = options.filter((option) => option.isSelected)
        const unselectedOptions = options.filter((option) => !option.isSelected)

        const sortedSelected = sortArrayByKey(
            selectedOptions,
            (option) => option.label
        )

        const sortedUnselected = sortArrayByKey(
            unselectedOptions,
            (option) => option.label
        )

        return [...sortedSelected, ...sortedUnselected]
    }, [options])

    const selectedOptions = useMemo(() => {
        return options.filter((option) => option.isSelected)
    }, [options])

    return (
        <>
            <ReactSelect
                menuPortalTarget={menuPortalTarget}
                options={sortedOptions}
                isMulti
                hideSelectedOptions={false}
                isSearchable={true}
                isOptionDisabled={(option) => option.isDisabled === true}
                value={selectedOptions}
                isClearable={isClearable}
                isDisabled={false}
                onChange={onChange}
                styles={styles}
                placeholder={"Select"}
                closeMenuOnSelect={false}
                components={{
                    Option,
                    ValueContainer: CustomValueContainer,
                }}
            />
        </>
    )
}

export default MultiSelect

const CustomValueContainer = ({
    children,
    ...props
}: ValueContainerProps<MultiSelectOptionT, true>) => {
    const { getValue } = props
    const selectedCount = getValue().length

    return (
        <components.ValueContainer {...props}>
            <span style={{ marginRight: "8px" }}>
                {selectedCount > 0 ? `${selectedCount} selected` : ""}
            </span>
            {React.Children.map(children, (child) =>
                child &&
                (child as React.ReactElement).type !== components.MultiValue
                    ? child
                    : null
            )}
        </components.ValueContainer>
    )
}

const Option = (props: OptionProps<MultiSelectOptionT>) => {
    const { isSelected, data } = props
    const value = data.label

    const icon = isSelected ? faCheck : faSquare
    const color = isSelected ? "#0DA700" : "#1e88e5"

    return (
        <components.Option {...props}>
            <div
                style={{
                    display: "flex",
                }}
            >
                <FontAwesomeIcon
                    icon={icon}
                    style={{ marginRight: "8px", color: color }}
                />
                <span>{value}</span>
            </div>
        </components.Option>
    )
}
