import { FunctionComponent, useEffect, useState } from "react"
import TableBuilder from "../../../components/table/tableBuilder"
import Paginator from "../../../components/paginator"
import AdminTableHeaders from "../../../components/table/tableHeaders/adminHeaders"
import { Outlet } from "react-router-dom"
import { UserTableConfigData, formatUserData } from "./userTableUtil"
import { CountableConnectionPager } from "../../../classes/pagination/pagination"
import { GET_USERS } from "../../../graphql/queries/users"
import { useQuery } from "@apollo/client"
import styles from "./user.module.scss"
import { SyncLoader } from "react-spinners"
import TableRows from "../../../components/table/tableRows"
import EmptyState from "./emptyState"
import AdminUsersOperation from "../../../components/table/tableOperation/AdminUsersOperation"
import { canRoleFetchInactiveUsers } from "../../../util/rolePermissions/canRole"
import { useViewerContext } from "../../../context/ViewerContext"
import { FetchUserInputValueType } from "./userTableTypes"

const UsersTable: FunctionComponent = () => {
    /** Header Click handlers */
    const usersPerPage = 15

    // viewer
    const viewer = useViewerContext()
    const { role: viewerRole } = viewer.getViewer()

    /** State */
    const [pager, setPager] = useState<CountableConnectionPager>(
        new CountableConnectionPager(usersPerPage)
    )
    const [fetchUserStatus, setFetchUsersStatus] =
        useState<FetchUserInputValueType>("active")

    /**
     * Get Users
     */

    const {
        error,
        data,
        networkStatus,
        loading,
        fetchMore,
        refetch: refetchGetUsers,
    } = useQuery(GET_USERS, {
        variables: {
            ...pager.current(),
            filter: {
                active: fetchUserStatus === "active" ? true : false,
            },
        },
        notifyOnNetworkStatusChange: true,
    })

    let tableRows: JSX.Element

    // reset pager after refetch from user status
    useEffect(() => {
        setPager(new CountableConnectionPager(usersPerPage))
    }, [fetchUserStatus])

    if (error) {
        tableRows = <ErrorState />
    } else if (networkStatus === 1 || loading) {
        tableRows = <LoadingState />
    } else if (!data) {
        throw new Error("No data returned from get users query ")
    } else {
        pager.update(data.users)

        const userData = formatUserData(data)

        if (userData.length === 0) {
            tableRows = <EmptyState />
        }
        tableRows = <TableRows data={userData} config={UserTableConfigData} />
    }

    /** Pagination click handlers */
    const handlePaginationPrevious = () => {
        if (!data) {
            return
        }

        if (pager.cannotPrevious) {
            return
        }

        fetchMore({
            variables: pager.previous(),
        })
    }

    const handlePaginationNext = () => {
        if (!data) {
            return
        }

        if (pager.cannotNext) {
            return
        }

        fetchMore({
            variables: pager.next(),
        })
    }

    // handlers
    const handleFetchUsersChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const value = e.target.value as FetchUserInputValueType
        const fetchActive = value === "active" ? true : false

        // set state
        setFetchUsersStatus(value)
        // refetch users
        // fetch the first 15 users of the selected status
        refetchGetUsers({
            filter: {
                active: fetchActive,
            },
            first: usersPerPage,
            before: null,
            after: null,
            last: null,
        })
    }

    return (
        <>
            <Outlet />
            <TableBuilder
                tableActionsHeader={() => {
                    return (
                        <AdminUsersOperation
                            fetchUsers={fetchUserStatus}
                            inputChange={handleFetchUsersChange}
                            canAccessOperation={canRoleFetchInactiveUsers(
                                viewerRole
                            )}
                        />
                    )
                }}
                tablePagination={() => {
                    return (
                        <Paginator
                            handlePaginationNext={handlePaginationNext}
                            handlePaginationPrevious={handlePaginationPrevious}
                            totalPages={pager.totalPageCount}
                            currentPage={pager.currentPageIndex + 1}
                            disabled={pager.totalPageCount > 1 ? false : true}
                        />
                    )
                }}
                tableHeader={() => {
                    return <AdminTableHeaders config={UserTableConfigData} />
                }}
                tableComponent="Admin"
            >
                {tableRows}
            </TableBuilder>
        </>
    )
}

export default UsersTable

const ErrorState = () => {
    return (
        <div className={styles.tableError}>
            An error has occurred while fetching your users.
        </div>
    )
}

const LoadingState = () => {
    return (
        <tbody>
            <tr className={styles.tableLoading}>
                <td>
                    <SyncLoader color="#1e88e5" size={13} />
                </td>
            </tr>
        </tbody>
    )
}
