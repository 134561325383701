import { useEffect, useState } from "react"
import { SyncLoader } from "react-spinners"
import styles from "./suspensePageFallBack.module.scss"

const SuspensePageFallBack = () => {
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false)

    useEffect(() => {
        const showLoadingTimer = setTimeout(() => {
            return setShowLoadingIndicator(true)
        }, 5000)

        return () => {
            clearTimeout(showLoadingTimer)
        }
    }, [])

    return (
        <>
            {showLoadingIndicator && (
                <div className={styles.container}>
                    <SyncLoader color="#1e88e5" size={13} />
                </div>
            )}
            {!showLoadingIndicator && <></>}
        </>
    )
}

export default SuspensePageFallBack
