import { useLocation, useNavigate } from "react-router-dom"
import { useViewerContext } from "../../../context/ViewerContext"
import { ViewerStateInterface } from "../../../types/ViewerTypes"
import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import { VERIFY_CODE } from "../../../graphql/mutations/verify"
import ModalHeader from "../../shared/modalHeader"
import { USER_SETTINGS_ABS_ROUTE } from "../../.."
import { TrexNavigator } from "../../../classes/navigator/navigator"
import Input from "../../shared/input"
import ModalFooter from "../../shared/modalFooter"
import styles from "./verify.module.scss"
import { AttributeType } from "../../../generated/graphql"
import { toTitleCase } from "../../../util/strings"
import { VIEWER } from "../../../graphql/queries/viewer"
import MutationErrorBanner from "../../shared/graphQlResponse"

const getAttributeValue = (
    attr: string,
    viewer: ViewerStateInterface
): [string, boolean] => {
    const phoneNumber = viewer.getPhoneNumber(true)
    switch (attr) {
        case "email":
            return [viewer.identity.email, viewer.identity.emailVerified]
        case "sms":
            if (!phoneNumber) {
                throw new Error("phone number is empty")
            }

            return [phoneNumber, viewer.identity.telephoneNumberVerified]
        default:
            throw new Error(`Invalid attribute: ${attr}`)
    }
}

export const VerifyAttributeModal = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { getViewer } = useViewerContext()
    const viewer = getViewer()
    const queryParams = new URLSearchParams(location.search)

    const attr = queryParams.get("attr")

    if (!attr) {
        throw new Error("attribute not found in query params")
    }

    const [attrValue, attrVerified] = getAttributeValue(attr, viewer)

    const [{ code }, setForm] = useState({
        code: "",
    })

    const [mutationErrorBanner, setMutationErrorBanner] =
        useState<boolean>(false)

    const [verifyCodeMutation, { loading: mutationLoading }] =
        useMutation(VERIFY_CODE)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = e.target.value
        const updatedForm = {
            code: targetValue,
        }

        setForm(updatedForm)
    }

    const handleSubmit = async () => {
        let attrType: AttributeType
        switch (attr) {
            case "email":
                attrType = AttributeType.Email
                break
            case "sms":
                attrType = AttributeType.Sms
                break
            default:
                throw new Error(`Invalid attribute: ${attr}`)
        }

        try {
            await verifyCodeMutation({
                variables: {
                    VerifyCodeInput: {
                        code: code,
                        type: attrType,
                    },
                },
                refetchQueries: [{ query: VIEWER }],
            })
            navigate(USER_SETTINGS_ABS_ROUTE)
        } catch (e) {
            console.error(e)
            setMutationErrorBanner(true)
        }
    }

    const isSubmitDisabled =
        mutationLoading || attrVerified || mutationErrorBanner

    const isFormDisabled =
        mutationLoading || attrVerified || mutationErrorBanner

    return (
        <div className={styles.container}>
            <ModalHeader
                title={`Verify ${toTitleCase(attr)}`}
                trexNavigator={
                    new TrexNavigator(
                        { navigateTo: USER_SETTINGS_ABS_ROUTE },
                        navigate
                    )
                }
            />
            <div className={styles.bodyContainer}>
                <div className={styles.boldHeading}>
                    Please enter the code sent to: {attrValue}.
                </div>
                <div>
                    <Input
                        type="text"
                        label="Code"
                        orientation="horizontal"
                        value={code}
                        onChange={handleInputChange}
                        disabled={isFormDisabled}
                    />
                </div>
            </div>
            {mutationErrorBanner && (
                <>
                    <MutationErrorBanner
                        message={
                            <div className={styles.errorMessage}>
                                {`We couldn't verify your ${
                                    attr === "email" ? "email" : "phone number"
                                }. `}
                                Please click
                                <u
                                    className={styles.errorLink}
                                    onClick={() =>
                                        navigate(USER_SETTINGS_ABS_ROUTE)
                                    }
                                >
                                    here
                                </u>
                                to close this message and try again with a new
                                verification code.
                            </div>
                        }
                    />
                </>
            )}
            <ModalFooter
                handleAdvanceClick={handleSubmit}
                disableAdvance={isSubmitDisabled}
                advanceText="Submit"
            />
        </div>
    )
}
