import { FunctionComponent } from "react"
import styles from "./emptyState.module.scss"
import { EmptyState } from "../../../../shared/graphQlResponse"
import Button from "../../../../shared/button"
import { useNavigate } from "react-router-dom"
import {
    NOTIFICATIONS_ADMIN_ADD_SUBSCRIPTION_CHILD,
    NOTIFICATIONS_SUBSCRIPTION_ADD_SUBSCRIPTION_CHILD,
} from "../../../../.."
import { ManageSubscriptionViewerActionT } from "../../manageSubscriptions/manageSubscriptionFormUtil/types"

interface SubscriptionsEmptyStateI {
    message: string
    viewerAction: ManageSubscriptionViewerActionT
}

const SubscriptionsEmptyState: FunctionComponent<SubscriptionsEmptyStateI> = ({
    message,
    viewerAction,
}) => {
    const navigate = useNavigate()

    const route =
        viewerAction === "admin"
            ? NOTIFICATIONS_ADMIN_ADD_SUBSCRIPTION_CHILD
            : NOTIFICATIONS_SUBSCRIPTION_ADD_SUBSCRIPTION_CHILD

    return (
        <EmptyState outSideDivStyle={styles.emptyStateContainer}>
            <div className={styles.emptyTitle}>{message}</div>
            <Button
                handleButtonClick={() => {
                    navigate(route)
                }}
                status="primary"
                condition="default"
                customButtonClassName={styles.addSubscriptionButton}
            >
                Add Subscription
            </Button>
        </EmptyState>
    )
}

export default SubscriptionsEmptyState
