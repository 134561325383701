import { FunctionComponent, ReactNode, useState } from "react"
import styles from "./doNotDisturbModal.module.scss"
import ModalHeader from "../../shared/modalHeader"
import ModalFooter from "../../shared/modalFooter"
import { TrexNavigator } from "../../../classes/navigator/navigator"
import { useNavigate } from "react-router-dom"
import { USER_SETTINGS_ABS_ROUTE } from "../../.."
import {
    NewTimeOfDay,
    TimeOfDayToString,
} from "../../../graphql/clock/timeOfDay"
import { useViewerContext } from "../../../context/ViewerContext"
import { useMutation, useQuery } from "@apollo/client"
import { GET_USER_DND_SETTINGS_BY_SUBJECT_ID } from "../../../graphql/queries/userSettings/dndSettings"
import { getFragmentData } from "../../../generated"
import { GET_USER_SETTINGS_DND_FRAGMENT } from "../../../graphql/fragments/userSettingsDnD"
import {
    DND_SETTING_CREATE,
    DND_SETTING_UPDATE,
    DND_SETTINGS_ACTIVATE,
    DND_SETTINGS_DEACTIVATE,
} from "../../../graphql/mutations/userSettings/dndSettings"
import MutationErrorBanner, {
    ErrorMessage,
    SyncLoaderComponent,
} from "../../shared/graphQlResponse"
import { DndFormErrorsI, DndFormI } from "./types"
import UpdateDoNotDisturbFormComponent, {
    getDoNotDisturbFormErrors,
} from "./form"
import { UserSettingsDnDSettingFragment } from "../../../generated/graphql"
import { objectHasNonUndefinedFields } from "../../../util/objects/objects"

const DoNotDisturbModal: FunctionComponent = () => {
    const viewer = useViewerContext().getViewer()
    const navigate = useNavigate()
    const trexNavigator = new TrexNavigator(
        { navigateTo: USER_SETTINGS_ABS_ROUTE },
        navigate
    )

    // query dnd settings
    const { error, loading, data } = useQuery(
        GET_USER_DND_SETTINGS_BY_SUBJECT_ID,
        {
            variables: {
                getDndSettingsBySubjectIDInput: {
                    subjectIDs: [viewer.userId],
                },
            },
        }
    )
    let componet: ReactNode
    if (error) {
        console.error("Failed to fetch do not distrub settings")
        componet = (
            <ErrorMessage
                message="There was a problem fetching your do not distrub settings"
                outSideDivStyle={styles.queryError}
            />
        )
    } else if (loading) {
        componet = <SyncLoaderComponent outSideDivStyle={styles.queryLoading} />
    } else {
        const dndSetting =
            getFragmentData(
                GET_USER_SETTINGS_DND_FRAGMENT,
                data?.dnDSettingBySubjectID.settings[0]
            ) || undefined

        componet = <DoNotDisturbFormContainer dndSetting={dndSetting} />
    }

    return (
        <div className={styles.modalContainer}>
            <ModalHeader
                title="Do Not Disturb Hours"
                trexNavigator={trexNavigator}
            />
            {componet}
        </div>
    )
}

export default DoNotDisturbModal

interface DoNotDisturbFormContainerI {
    dndSetting: UserSettingsDnDSettingFragment | undefined
}

const DoNotDisturbFormContainer: FunctionComponent<
    DoNotDisturbFormContainerI
> = ({ dndSetting }) => {
    const viewer = useViewerContext().getViewer()
    const navigate = useNavigate()

    // state
    let initialDndForm = {} as DndFormI
    if (!dndSetting) {
        // default dnd form
        initialDndForm = {
            active: true,
            from: "22:00:00",
            to: "06:00:00",
            dirty: true,
            new: true,
        }
    } else {
        initialDndForm = {
            dirty: false,
            active: dndSetting.active,
            from: TimeOfDayToString(dndSetting.fromTime),
            to: TimeOfDayToString(dndSetting.toTime),
            new: false,
        }
    }

    const [dndFormState, setDndFormState] = useState<DndFormI>(initialDndForm)
    const [formErrors, setFormErrors] = useState<DndFormErrorsI>({
        from: undefined,
        to: undefined,
    })

    const [mutationErrorBanner, setMutationErrorBanner] = useState(false)

    // mutations
    const [createSetting, { loading: createSettingLoading }] =
        useMutation(DND_SETTING_CREATE)

    const [updateSetting, { loading: updateSettingLoading }] =
        useMutation(DND_SETTING_UPDATE)

    const [activateSetting, { loading: activateDndLoading }] = useMutation(
        DND_SETTINGS_ACTIVATE
    )

    const [deactivateSetting, { loading: deactivateDndLoading }] = useMutation(
        DND_SETTINGS_DEACTIVATE
    )

    // anyMutationLoading is a boolean that will be true if any of the mutations or queries are loading
    const anyMutationLoading =
        createSettingLoading ||
        updateSettingLoading ||
        activateDndLoading ||
        deactivateDndLoading

    // mutation handlers:
    // these are the possible mutations we could make when updating or creating dnd settings
    const handleActivateSetting = () => {
        return activateSetting({
            variables: {
                dndSettingsActivateInput: {
                    subjectIDs: [viewer.userId],
                },
            },
        })
    }

    const handleDeactivateSetting = () => {
        return deactivateSetting({
            variables: {
                dndSettingsDeactivateInput: {
                    subjectIDs: [viewer.userId],
                },
            },
        })
    }

    const handleCreateSetting = () => {
        return createSetting({
            variables: {
                dndSettingCreateInput: {
                    subjectID: viewer.userId,
                    fromTime: NewTimeOfDay(dndFormState.from),
                    toTime: NewTimeOfDay(dndFormState.to),
                },
            },
            refetchQueries: [
                {
                    query: GET_USER_DND_SETTINGS_BY_SUBJECT_ID,
                    variables: {
                        getDndSettingsBySubjectIDInput: {
                            subjectIDs: [viewer.userId],
                        },
                    },
                },
            ],
        })
    }

    const handleUpdateSetting = () => {
        return updateSetting({
            variables: {
                dndSettingUpdateInput: {
                    subjectID: viewer.userId,
                    fromTime: NewTimeOfDay(dndFormState.from),
                    toTime: NewTimeOfDay(dndFormState.to),
                },
            },
        })
    }

    const handleDisableSubmit = () => {
        if (dndSetting?.active != dndFormState.active) {
            return false
        }

        const errs = getDoNotDisturbFormErrors(dndFormState)
        return (
            !dndFormState.dirty ||
            mutationErrorBanner ||
            anyMutationLoading ||
            objectHasNonUndefinedFields(errs)
        )
    }

    // submission handler: this handler determines which mutations to fire based on the incoming setting
    // and the form state. it can fire up to 2 mutations simultaneously (activate/deactive and create/update)
    const handelSubmit = async () => {
        try {
            // if no previous dnd setting, we only need to create the setting
            // the default value for active is true on new settings
            if (!dndSetting) {
                await handleCreateSetting()
                navigate(USER_SETTINGS_ABS_ROUTE)
                return
            }

            if (dndSetting?.active != dndFormState.active) {
                if (dndFormState.active) {
                    await handleActivateSetting()
                } else {
                    await handleDeactivateSetting()
                }
            }

            if (dndFormState.dirty) {
                await handleUpdateSetting()
            }

            navigate(USER_SETTINGS_ABS_ROUTE)
        } catch (error) {
            console.log(`error updating dnd settings: ${error}`)
            setMutationErrorBanner(true)
        }
    }

    return (
        <>
            <UpdateDoNotDisturbFormComponent
                formState={dndFormState}
                setFormState={setDndFormState}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
            />
            {mutationErrorBanner && (
                <>
                    <MutationErrorBanner
                        message={
                            <div>
                                There was a problem updating your DnD Settings.
                                Click{" "}
                                <u
                                    style={{
                                        cursor: "pointer",
                                        marginRight: "4px",
                                    }}
                                    onClick={() =>
                                        navigate(USER_SETTINGS_ABS_ROUTE)
                                    }
                                >
                                    here
                                </u>
                                or close the modal to be redirected.
                            </div>
                        }
                    />
                </>
            )}
            <ModalFooter
                disableAdvance={handleDisableSubmit()}
                advanceText="Save"
                handleAdvanceClick={handelSubmit}
                disablePrevious={false}
                previousText="Cancel"
                handlePreviousClick={() => {
                    navigate(USER_SETTINGS_ABS_ROUTE)
                }}
                previousButtonCondition="default"
            />
        </>
    )
}
