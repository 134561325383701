import { FunctionComponent, useState } from "react"
import AdminTableOperations from "../../../components/table/tableOperation/AdminOperation"
import TableRows from "../../../components/table/tableRows"
import TableBuilder from "../../../components/table/tableBuilder"
import Paginator from "../../../components/paginator"
import AdminTableHeaders from "../../../components/table/tableHeaders/adminHeaders"
import {
    OrganizationTableConfigData,
    formatOrganizationData,
} from "./organizationTableUtil"
import { Outlet, useNavigate } from "react-router-dom"
import { CountableConnectionPager } from "../../../classes/pagination/pagination"
import { useQuery } from "@apollo/client"
import { GET_ORGANIZATIONS } from "../../../graphql/queries/organizations"
import { canRoleAddOrganization } from "../../../util/rolePermissions/canRole"
import { useViewerContext } from "../../../context/ViewerContext"
import styles from "./organization.module.scss"
import { SyncLoader } from "react-spinners"
import EmptyState from "./emptyState"

const OrganizationsTable: FunctionComponent = () => {
    const organizationsPerPage = 15
    const navigate = useNavigate()
    const { getViewer } = useViewerContext()
    const { role: viewerRole } = getViewer()

    /** State */
    const [pager] = useState<CountableConnectionPager>(
        new CountableConnectionPager(organizationsPerPage)
    )

    /**
     * handlers
     */
    const navigateToAddOrg = () => {
        navigate("/admin/organizations/addOrganization")
    }

    /**
     * Get organizations
     */

    const { error, data, networkStatus, loading, fetchMore } = useQuery(
        GET_ORGANIZATIONS,
        {
            variables: pager.current(),
            notifyOnNetworkStatusChange: true,
        }
    )

    let tableRows: JSX.Element

    if (error) {
        tableRows = <ErrorState />
    } else if (networkStatus === 1 || loading) {
        tableRows = <LoadingState />
    } else if (!data) {
        throw new Error("No data returned from get organizations query ")
    } else {
        pager.update(data.organizations)

        const organizationData = formatOrganizationData(data, viewerRole)

        if (organizationData.length === 0) {
            tableRows = <EmptyState />
        }

        tableRows = (
            <TableRows
                data={organizationData}
                config={OrganizationTableConfigData}
            />
        )
    }

    /** Pagination click handlers */
    const handlePaginationPrevious = () => {
        if (!data) {
            return
        }

        if (pager.cannotPrevious) {
            return
        }

        fetchMore({
            variables: pager.previous(),
        })
    }

    const handlePaginationNext = () => {
        if (!data) {
            return
        }

        if (pager.cannotNext) {
            return
        }

        fetchMore({
            variables: pager.next(),
        })
    }

    // conditionally render adminTableOpearations based on if user has permissions to add an organization.
    let adminTableOperations: (() => JSX.Element) | undefined

    if (canRoleAddOrganization(viewerRole)) {
        adminTableOperations = () => {
            return (
                <AdminTableOperations
                    handleButtonClick={navigateToAddOrg}
                    label="Add Organization"
                    showButton={canRoleAddOrganization(viewerRole)}
                />
            )
        }
    } else {
        adminTableOperations = undefined
    }

    return (
        <>
            <Outlet />
            <TableBuilder
                tableActionsHeader={adminTableOperations}
                tablePagination={() => {
                    return (
                        <Paginator
                            handlePaginationNext={handlePaginationNext}
                            handlePaginationPrevious={handlePaginationPrevious}
                            totalPages={pager.totalPageCount}
                            currentPage={pager.currentPageIndex + 1}
                            disabled={pager.totalPageCount > 1 ? false : true}
                        />
                    )
                }}
                tableComponent="Admin"
                tableHeader={() => {
                    return (
                        <AdminTableHeaders
                            config={OrganizationTableConfigData}
                        />
                    )
                }}
            >
                {tableRows}
            </TableBuilder>
        </>
    )
}

export default OrganizationsTable

const ErrorState = () => {
    return (
        <div className={styles.tableError}>
            An error occurred while fetching your organizations.
        </div>
    )
}

const LoadingState = () => {
    return (
        <tbody>
            <tr className={styles.tableLoading}>
                <td>
                    <SyncLoader color="#1e88e5" size={13} />
                </td>
            </tr>
        </tbody>
    )
}
