import { objectHasNonUndefinedFields } from "../../../../util/objects/objects"
import { isValidEmail } from "../../../../util/users"
import { UpdateProfileEmailFormErrorI, UpdateProfileEmailFormI } from "../types"

const getErrorsFromNotificationsEmailForm = (
    form: UpdateProfileEmailFormI
): UpdateProfileEmailFormErrorI => {
    const errors: UpdateProfileEmailFormErrorI = {
        email: undefined,
    }

    const validEmail = isValidEmail(form.email)
    if (!validEmail) {
        errors.email = "Please enter a valid email address."
    }

    return errors
}

const isNotificationsEmailFormDisabled = (
    form: UpdateProfileEmailFormI
): boolean => {
    const errors = getErrorsFromNotificationsEmailForm(form)
    return objectHasNonUndefinedFields(errors)
}

export { getErrorsFromNotificationsEmailForm, isNotificationsEmailFormDisabled }
