import { ZipCelXCell, ZipCelXRow, ZipCelXSheet } from "zipcelx"
import {
    convertSecondsToHours,
    formatUnixMillToMMDDYYY_UTC,
    getVariableDaysAgo_StartOfDayUnixMs,
} from "../../../util/datesAndTimes/datesAndTimes"
import { DateIntervalType, WellDetailsUrlType } from "./wellDetailsChartType"
import HighStock from "highcharts/highstock"
import { capMaxSeconds } from "../../../util/DataFrameHighChartSeriesUtil"

const updateIncomingRunTimeForWellDetailsLineChart = (
    highChartsSeries: (number | null)[][] | undefined
) => {
    if (!highChartsSeries) return
    // Following function will mutate the original array
    /** cap max seconds to 86400 (1 day) */
    const updatedHighChartSeries_capMaxSeconds = capMaxSeconds(highChartsSeries)

    return updatedHighChartSeries_capMaxSeconds
}

/**
 *
 *  Function used to convert incoming highChart series to a format usable by zipcelx
 *  https://github.com/egeriis/zipcelx/wiki/How-to-use
 *
 */
const convertToZipcelxFormat = (array: (number | null)[][]): ZipCelXSheet => {
    const zipcelxSheet: ZipCelXSheet = {
        data: [
            [
                { type: "string", value: "Date" },
                { type: "string", value: "Run Time (Hr)" },
            ],
        ],
    }
    for (const [timestamp, seconds] of array) {
        // convert unixSeconds to 'mm-dd-yyyy'format
        const convertedTimeStamp: ZipCelXCell = timestamp
            ? { value: formatUnixMillToMMDDYYY_UTC(timestamp), type: "string" }
            : { value: "", type: "string" }
        // convert seconds to hours as a decimal, with 2 decimal places. Return null as empty string
        let hours: ZipCelXCell
        if (seconds === 0 || seconds) {
            hours = { value: convertSecondsToHours(seconds), type: "number" }
        } else {
            hours = { value: "", type: "string" }
        }

        const row: ZipCelXRow = [convertedTimeStamp, hours]
        zipcelxSheet.data.push(row)
    }

    return zipcelxSheet
}

function convertHighChartSeriesToCSV(arrayOfArrays: (number | null)[][]) {
    const csvArray = arrayOfArrays.map(([timestamp, seconds]) => {
        const utcDate = timestamp ? formatUnixMillToMMDDYYY_UTC(timestamp) : ""
        let hours
        if (seconds === 0 || seconds) {
            hours = convertSecondsToHours(seconds)
        } else {
            hours = ""
        }

        return `${utcDate},${hours}`
    })
    // Add header row
    csvArray.unshift("Date ,Run Time (Hr)")
    return csvArray.join("\n")
}

const createWellDetailsSearchParams = (
    from: number | undefined,
    to: number | undefined,
    dateInterval: DateIntervalType | null
) => {
    const obj: WellDetailsUrlType = {}
    if (from) {
        obj.from = from.toString()
    }
    if (to) {
        obj.to = to.toString()
    }
    if (dateInterval) {
        obj.dateInterval = dateInterval
    }
    return obj
}

const isWellDetailsRangeValid = (
    from: string | undefined,
    to: string | undefined
) => {
    const minTimestamp = 1640995200000
    const maxTimestamp = Date.now()

    if (!to || !from) return false
    if (to <= from) return false

    const toParsed = parseInt(to)
    const fromParsed = parseInt(from)

    if (toParsed <= minTimestamp || toParsed >= maxTimestamp) {
        return false
    }
    if (fromParsed <= minTimestamp || fromParsed >= maxTimestamp) {
        return false
    }

    return { to: toParsed, from: fromParsed }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDefaultMinMax = (highChartsSeries: any[][] | undefined) => {
    const unixMilliSecondsNowUTC = Date.now()
    if (!highChartsSeries) {
        return {
            to: getVariableDaysAgo_StartOfDayUnixMs(31),
            from: unixMilliSecondsNowUTC,
        }
    } else {
        return {
            to: highChartsSeries[0][0] as number,
            from: unixMilliSecondsNowUTC,
        }
    }
}

const getAggregationUnits = (value: DateIntervalType) => {
    let chartAggregationUnits: [string, number[] | null][]
    let selected: DateIntervalType

    // get aggregation units
    switch (value) {
        case `day`: {
            chartAggregationUnits = [["day", [1]]]
            selected = "day"
            break
        }
        case "week": {
            chartAggregationUnits = [["week", [1]]]
            selected = "week"
            break
        }
        case "month": {
            chartAggregationUnits = [["month", [1]]]
            selected = "month"
            break
        }
        default:
            selected = "day"
            chartAggregationUnits = [["day", [1]]]
    }

    return {
        selected,
        chartAggregationUnits,
    }
}

const valuesInsideChartView = (points: HighStock.Point[]) => {
    for (let i = 0; i < points.length; i++) {
        const plotX = points[i].plotX
        const y = points[i].y

        if (
            typeof plotX === "number" &&
            plotX >= 0 &&
            typeof y === "number" &&
            y >= 0
        ) {
            return true
        }
    }
    return false
}

/**
 *
 * To be used in HighStock.Chart.prototype.getCSV override
 *
 * @param points Incoming series data from highcharts chart reference
 * @returns number[][] of x
 */
const getHighChartsDataInView = (points: HighStock.Point[]) => {
    const returnData = []

    for (let i = 0; i < points.length; i++) {
        const plotX = points[i].plotX
        const x = points[i].x
        let y: number | undefined | null = points[i].y

        if (typeof y !== "number") {
            y = null
        }

        if (typeof plotX === "number" && plotX >= 0) {
            returnData.push([x, y])
        }
    }
    return returnData
}

export {
    convertToZipcelxFormat,
    convertHighChartSeriesToCSV,
    createWellDetailsSearchParams,
    isWellDetailsRangeValid,
    getDefaultMinMax,
    getAggregationUnits,
    valuesInsideChartView,
    getHighChartsDataInView,
    updateIncomingRunTimeForWellDetailsLineChart,
}
