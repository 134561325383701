import React, { useEffect } from "react"
import { useMutation } from "@apollo/client"
import { FRESHDESK_JWT_CREATE } from "../../graphql/mutations/freshdesk_jwt"

const FRESHDESK_TOKEN_STORAGE_KEY = "freshdeskToken"
const FRESHDESK_TOKEN_EXPIRES_AT_STORAGE_KEY = "freshdeskTokenExpiresAt"
const TOKEN_REFRESH_THRESHOLD = 60000 // 60 seconds before expiration

const FreshdeskWidget: React.FC = () => {
    const [fetchJwtToken] = useMutation(FRESHDESK_JWT_CREATE)

    const refreshToken = async () => {
        try {
            const { data } = await fetchJwtToken()
            const token = data?.freshDeskJWTCreate.jwt.encoded
            const expiresAt =
                data?.freshDeskJWTCreate.jwt.payload.expiration
                    ?.unixMilliseconds

            if (token && expiresAt) {
                localStorage.setItem(FRESHDESK_TOKEN_STORAGE_KEY, token)
                localStorage.setItem(
                    FRESHDESK_TOKEN_EXPIRES_AT_STORAGE_KEY,
                    String(expiresAt)
                )

                // Authenticate widget with new token
                window.FreshworksWidget("authenticate", { token })
            }
        } catch (error) {
            console.error(`Failed to fetch JWT token: ${error}`)
        }
    }

    const validateOrRefreshToken = async () => {
        const token = localStorage.getItem(FRESHDESK_TOKEN_STORAGE_KEY)
        const expiresAt = localStorage.getItem(
            FRESHDESK_TOKEN_EXPIRES_AT_STORAGE_KEY
        )

        if (
            !token ||
            !expiresAt ||
            Date.now() >= Number(expiresAt) - TOKEN_REFRESH_THRESHOLD
        ) {
            await refreshToken()
        } else {
            // Authenticate widget with existing token
            window.FreshworksWidget("authenticate", { token: token })
        }
    }

    useEffect(() => {
        // call validateOrRefreshToken on initial load
        validateOrRefreshToken()
        // set interval to call validateOrRefreshToken every TOKEN_REFRESH_THRESHOLD milliseconds
        const intervalId = setInterval(
            validateOrRefreshToken,
            TOKEN_REFRESH_THRESHOLD
        )

        return () => clearInterval(intervalId)
    }, [])

    return null
}

export default FreshdeskWidget
