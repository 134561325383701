import { Header, Table, flexRender } from "@tanstack/react-table"
import { FunctionComponent } from "react"
import styles from "./header.module.scss"
import { MySubscriptionsTableDataI } from "../tableTypes"
import HeaderSortIcon from "../../../../../shared/tanStack/headerSortIcon"

interface MySubscriptionsHeaderI {
    table: Table<MySubscriptionsTableDataI>
}

const MySubscriptionsTableHeader: FunctionComponent<MySubscriptionsHeaderI> = ({
    table,
}) => {
    return (
        <thead className={styles.tHeadContainer}>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr className={styles.tableHeaderRow} key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                        return (
                            <th
                                className={styles[`${header.id}_Header`]}
                                key={header.id}
                            >
                                <span className={styles.headerText}>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </span>
                                {header.column.getCanSort() && (
                                    <HeaderSortIcon
                                        sortedStyling={styles.sorted}
                                        header={
                                            header as Header<unknown, unknown>
                                        }
                                    />
                                )}
                            </th>
                        )
                    })}
                </tr>
            ))}
        </thead>
    )
}

export default MySubscriptionsTableHeader
