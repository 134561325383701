import React, { FunctionComponent, useState } from "react"
import styles from "./updateProfileModal.module.scss"
import ModalHeader, {
    ModalHeaderComponentInterface,
} from "../../shared/modalHeader"
import { useNavigate } from "react-router-dom"
import ModalFooter, {
    ModalFooterComponentInterface,
} from "../../shared/modalFooter"
import { useMutation } from "@apollo/client"
import { UPDATE_USERS } from "../../../graphql/mutations/update_users"
import Alert from "../../shared/alert"
import {
    UpdateProfileModalStateInterface,
    UpdateProfileStateInterface,
} from "../../../types/updateProfileTypes"
import Input from "../../shared/input"
import { TrexNavigator } from "../../../classes/navigator/navigator"
import { useViewerContext } from "../../../context/ViewerContext"
import { USER_SETTINGS_ABS_ROUTE } from "../../.."

const UpdateProfileModal: FunctionComponent = () => {
    /** Hooks */
    const navigate = useNavigate()
    const { getViewer } = useViewerContext()
    const { identity, userId } = getViewer()

    const initialUpdateUserProfileState = {
        firstName: identity.firstName,
        lastName: identity.lastName,
        userId: userId,
    }

    /** State */
    const [updateProfileModalState, setUpdateProfileModalState] =
        useState<UpdateProfileModalStateInterface>({
            disableSubmitUpdate: true,
            updateProfile: initialUpdateUserProfileState,
        })
    const [updateProfileErrorBanner, setUpdateProfileErrorBanner] =
        useState(false)

    /** Mutations */
    const [updateProfileMutation, { loading: updateProfileMutationLoading }] =
        useMutation(UPDATE_USERS)

    /** Handlers */
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        const updatedProfile: UpdateProfileStateInterface = {
            ...updateProfileModalState.updateProfile,
            [name]: value,
        }

        const disableSubmit = disableSubmitUpdateProfile(
            initialUpdateUserProfileState,
            updatedProfile
        )

        setUpdateProfileModalState(() => ({
            updateProfile: updatedProfile,
            disableSubmitUpdate: disableSubmit,
        }))
    }

    const disableSubmitUpdateProfile = (
        initialUpdateUserProfileState: UpdateProfileStateInterface,
        updateProfileModalState: UpdateProfileStateInterface
    ) => {
        const { firstName: initialFirstName, lastName: initialLastName } =
            initialUpdateUserProfileState
        const { firstName, lastName } = updateProfileModalState
        if (firstName === initialFirstName && lastName === initialLastName) {
            return true
        }
        if (firstName.length === 0 || lastName.length === 0) {
            return true
        }
        return false
    }

    const handleSubmitUpdateUser = async () => {
        const { updateProfile } = updateProfileModalState

        try {
            await updateProfileMutation({
                variables: {
                    UserUpdateInput: {
                        userID: updateProfile.userId,
                        firstName: updateProfile.firstName,
                        lastName: updateProfile.lastName,
                    },
                },
            })
            navigate(USER_SETTINGS_ABS_ROUTE)
        } catch (error) {
            console.error("Update profile modal mutation error", error)
            setUpdateProfileErrorBanner(true)
        }
    }

    /** Header and footer props */
    const modalHeaderProps: ModalHeaderComponentInterface = {
        title: "Edit User Profile",
        trexNavigator: new TrexNavigator(
            { navigateTo: USER_SETTINGS_ABS_ROUTE },
            navigate
        ),
    }

    const modalFooterProps: ModalFooterComponentInterface = {
        advanceText: "Submit",
        disableAdvance:
            updateProfileModalState.disableSubmitUpdate ||
            updateProfileMutationLoading ||
            updateProfileErrorBanner,
        handleAdvanceClick: handleSubmitUpdateUser,
    }

    return (
        <div className={styles.container}>
            <ModalHeader {...modalHeaderProps} />
            <UpdateProfileBody
                handleInputChange={handleInputChange}
                modalState={updateProfileModalState}
            />
            {updateProfileErrorBanner && (
                <Alert
                    isCloseable={false}
                    message={
                        <div>
                            There was a problem updating your profile. Click{" "}
                            <u
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    navigate(USER_SETTINGS_ABS_ROUTE)
                                }
                            >
                                here
                            </u>{" "}
                            or close the modal to be redirected to the settings
                            page.
                        </div>
                    }
                    alertType="danger"
                />
            )}
            <ModalFooter {...modalFooterProps} />
        </div>
    )
}

export default UpdateProfileModal

interface UpdateProfileBodyPropsInterface {
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    modalState: UpdateProfileModalStateInterface
}

const UpdateProfileBody: FunctionComponent<UpdateProfileBodyPropsInterface> = ({
    handleInputChange,
    modalState,
}) => {
    const { firstName, lastName } = modalState.updateProfile

    return (
        <>
            <div className={styles.profileBodyContainer}>
                <div className={styles.marginRight}>
                    <Input
                        orientation="vertical"
                        type="text"
                        value={firstName}
                        onChange={handleInputChange}
                        label="First Name *"
                        disabled={false}
                        name="firstName"
                    />
                </div>
                <div className={styles.marginLeft}>
                    <Input
                        orientation="vertical"
                        type="text"
                        value={lastName}
                        onChange={handleInputChange}
                        label="Last Name *"
                        disabled={false}
                        name="lastName"
                    />
                </div>
            </div>
        </>
    )
}
