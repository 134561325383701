import { TimeOfDay } from "../../generated/graphql"
import { isValidTimeString } from "../../util/datesAndTimes/timeString/timeString"

export const NewTimeOfDay = (input: string): TimeOfDay => {
    if (!isValidTimeString(input)) {
        throw new Error("Invalid TimeOfDay")
    }

    const time = input.split(":")
    return {
        hour: parseInt(time[0]),
        minute: parseInt(time[1]),
        second: parseInt(time[2]),
    }
}

// function that converts a TimeOfDay object to a string
export const TimeOfDayToString = (
    time: TimeOfDay,
    includeSeconds = false
): string => {
    const h = time.hour < 10 ? `0${time.hour}` : time.hour
    const m = time.minute < 10 ? `0${time.minute}` : time.minute
    const s = time.second < 10 ? `0${time.second}` : time.second
    return `${h}:${m}${includeSeconds ? `:${s}` : ""}`
}

// this will eventually be replaced by the graphql type handling display formatting for us
export const TimeOfDayToDisplayString = (
    time: TimeOfDay,
    includeSeconds: boolean
): string => {
    let h = time.hour % 12
    const m = time.minute < 10 ? `0${time.minute}` : time.minute
    const s = time.second < 10 ? `0${time.second}` : time.second

    if (h === 0) {
        h = 12
    }

    return `${h}:${m}${includeSeconds ? `:${s}` : ""} ${
        time.hour < 12 ? "AM" : "PM"
    }`
}
