import { graphql } from "../../generated"

export const WELL_CONFIGURATION_REVISION_PARAMETERS_FIELDS_FRAGMENT = graphql(`
    fragment WellConfigurationRevisionParameters on WellConfigurationRevision {
        id
        createdAt {
            ...DateTimeFields
        }
        stateChangeParameters {
            prior {
                ...NormalDistributionParametersFields
            }
        }
    }
`)

export const NORMAL_DISTRIBUTION_PARAMETERS_FIELDS_FRAGMENT = graphql(`
    fragment NormalDistributionParametersFields on NormalDistributionParameters {
        dimension
        mean
        covariance
    }
`)
