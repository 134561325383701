import { FunctionComponent } from "react"
import { DndFormErrorsI, DndFormI } from "../types"
import { Toggle } from "../../../shared/selectionControls"
import Input from "../../../shared/input"
import styles from "./form.module.scss"
import { isValidTimeString } from "../../../../util/datesAndTimes/timeString/timeString"
import { objectHasNonUndefinedFields } from "../../../../util/objects/objects"

const UpdateDoNotDisturbFormComponent: FunctionComponent<{
    formState: DndFormI
    setFormState: (formState: DndFormI) => void
    formErrors: DndFormErrorsI
    setFormErrors: (formErrors: DndFormErrorsI) => void
}> = ({ formState, setFormState, formErrors, setFormErrors }) => {
    // form handlers
    const handleActiveCheckbox = () => {
        setFormState({
            ...formState,
            active: !formState.active,
        })
    }

    const handleTimeChange =
        (field: "from" | "to") => (e: React.ChangeEvent<HTMLInputElement>) => {
            const updatedForm = {
                ...formState,
                dirty: true,
                [field]: e.target.value,
            }

            let updatedErrors = formErrors
            if (objectHasNonUndefinedFields(formErrors)) {
                updatedErrors = getDoNotDisturbFormErrors(updatedForm)
            }

            setFormState(updatedForm)
            setFormErrors(updatedErrors)
        }

    // blur handler
    const handleTimeInputBlur = () => {
        const updatedErrors = getDoNotDisturbFormErrors(formState)
        setFormErrors({
            ...updatedErrors,
        })
    }

    return (
        <div className={styles.formContainer}>
            <div className={styles.toggleContainer}>
                <Toggle
                    checked={formState.active}
                    handleToggleClick={handleActiveCheckbox}
                    toggleLabel="Use Do Not Disturb Schedule"
                    disabled={formState.new}
                />
            </div>
            <div className={styles.marginTop4}>
                <Input
                    type="time"
                    label="Start Time:"
                    customLabelClass={styles.datePickerLabel}
                    orientation="horizontal"
                    value={formState.from}
                    onChange={handleTimeChange("from")}
                    onBlur={handleTimeInputBlur}
                    message={formErrors.from ? formErrors.from : undefined}
                    specializedClass={formErrors.from ? "danger" : "default"}
                    disabled={!formState.active}
                />
            </div>
            <div className={styles.marginTop8}>
                <Input
                    type="time"
                    label="End Time:"
                    customLabelClass={styles.datePickerLabel}
                    orientation="horizontal"
                    value={formState.to}
                    onChange={handleTimeChange("to")}
                    onBlur={handleTimeInputBlur}
                    message={formErrors.to ? formErrors.to : undefined}
                    specializedClass={formErrors.to ? "danger" : "default"}
                    disabled={!formState.active}
                />
            </div>
        </div>
    )
}

export default UpdateDoNotDisturbFormComponent

export const getDoNotDisturbFormErrors = (form: DndFormI): DndFormErrorsI => {
    const errors: DndFormErrorsI = {
        from: undefined,
        to: undefined,
    }

    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time#handling_browser_support
    // older versions of Safari and a few other, less common, browsers don't support time inputs natively
    // we can assure we are getting the correct format by checking the input value against a regex
    const startValidFormat = isValidTimeString(form.from)
    if (!startValidFormat) {
        errors.from = "Format must be hh:mm"
    }

    const endValidFormat = isValidTimeString(form.to)
    if (!endValidFormat) {
        errors.to = "Format must be hh:mm"
    }

    // input times cannot be midnight, this is invalid per ngapi requirements.
    if (form.from === "00:00") {
        errors.from = "Start time cannot be midnight"
    }

    if (form.to === "00:00") {
        errors.to = "End time cannot be midnight"
    }

    // input cannot be 24 hours and 0 hours, both of these inputs would look similar for either situation
    // we will hint at disabling settings as that might be what they're trying to accomplish
    if (form.from === form.to) {
        errors.from = "Start and end time cannot be the same"
    }

    return errors
}
