import {
    MY_NOTIFICATIONS_ABS_ROUTE,
    MY_NOTIFICATIONS_CHILD_ROUTE,
    NOTIFICATIONS_ABS_ROUTE,
    NOTIFICATIONS_RULES_ABS_ROUTE,
    NOTIFICATIONS_RULES_CHILD_ROUTE,
    NOTIFICATIONS_SUBSCRIPTION_ABS_ROUTE,
    NOTIFICATIONS_SUBSCRIPTION_BY_RULE_ABS_ROUTE,
    NOTIFICATIONS_SUBSCRIPTION_BY_RULE_CHILD,
    NOTIFICATIONS_SUBSCRIPTION_BY_USER_ABS_ROUTE,
    NOTIFICATIONS_SUBSCRIPTION_BY_USER_CHILD,
    NOTIFICATIONS_SUBSCRIPTION_CHILD_ROUTE,
} from "../.."
import { NavigateOptions, To } from "react-router-dom"

// Notes
// - use this function when a simpler solution does not work.
// - this function may not be exhaustive and may need to be updated as new routes are added.
// - while updating this function consider that the order of the if statements are important.
// - this function should view that pathName where the mutation is performed, and return the appropriate path to navigate to after the mutation is completed, based on the slugs of the pathaname.
export const navigateAfterMutation = (
    pathname: string
): { to: To; options?: NavigateOptions } => {
    // subscriptions by rule
    if (pathname.includes(NOTIFICATIONS_SUBSCRIPTION_BY_RULE_CHILD)) {
        return { to: NOTIFICATIONS_SUBSCRIPTION_BY_RULE_ABS_ROUTE }
    }

    // subscription by user
    if (pathname.includes(NOTIFICATIONS_SUBSCRIPTION_BY_USER_CHILD)) {
        return { to: NOTIFICATIONS_SUBSCRIPTION_BY_USER_ABS_ROUTE }
    }

    // my subscriptions
    const mySubRoute = `${NOTIFICATIONS_ABS_ROUTE}/${NOTIFICATIONS_SUBSCRIPTION_CHILD_ROUTE}`
    if (pathname.includes(mySubRoute)) {
        return { to: NOTIFICATIONS_SUBSCRIPTION_ABS_ROUTE }
    }

    // my notifications
    if (pathname.includes(MY_NOTIFICATIONS_CHILD_ROUTE)) {
        return { to: MY_NOTIFICATIONS_ABS_ROUTE }
    }

    // alert rules
    if (pathname.includes(`${NOTIFICATIONS_RULES_CHILD_ROUTE}`)) {
        return { to: NOTIFICATIONS_RULES_ABS_ROUTE }
    }

    // default
    return {
        to: NOTIFICATIONS_ABS_ROUTE,
    }
}
