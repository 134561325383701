import {
    RulePreviewEvaluateInput,
    RuleUpdateInput,
    RulesAddInput,
    Unit,
} from "../../../../../generated/graphql"
import { convertDurationUnit } from "../../../../../util/duration/duration"
import { percentageToRatio } from "../../../../../util/numbers/numbers"
import { AlertRuleFormConditionI, AlertRuleFormI } from "./alertRuleFormTypes"

const getUpdateAlertRuleMutationVars = (
    form: AlertRuleFormI,
    alertRuleId: string
): RuleUpdateInput => {
    const updatedThresholds = convertThresholds(form.condition)

    const variables: RuleUpdateInput = {
        id: alertRuleId,
        condition: {
            hysteresis: form.condition.hysteresis,
            operator: form.condition.operator,
            threshold: updatedThresholds.threshold,
            threshold2: updatedThresholds.threshold2,
        },
        description: form.description,
        name: form.ruleName,
        window: { seconds: form.windowTotalSeconds },
    }
    return variables
}

const getAddAlertRuleMutationVars = (
    form: AlertRuleFormI,
    organizationID: string
): RulesAddInput => {
    const updatedThresholds = convertThresholds(form.condition)

    const variables: RulesAddInput = {
        ruleInput: [
            {
                organizationID: organizationID,
                condition: {
                    hysteresis: form.condition.hysteresis,
                    operator: form.condition.operator,
                    threshold: updatedThresholds.threshold,
                    threshold2: updatedThresholds.threshold2,
                },
                description: form.description,
                metricID: form.metric,
                name: form.ruleName,
                evaluationGroupID: form.evaluationGroup,
                window: { seconds: form.windowTotalSeconds },
            },
        ],
    }
    return variables
}

const getRulePreviewEvaluateQueryVars = (
    form: AlertRuleFormI,
    organizationID: string
): RulePreviewEvaluateInput => {
    const updatedThresholds = convertThresholds(form.condition)

    const input: RulePreviewEvaluateInput = {
        // do not set name or description or each time the form changes it will requery
        name: "",
        description: "",
        organizationID: organizationID,
        evaluationGroupID: form.evaluationGroup,
        // when window, metricID, or condition is changed, requery
        window: {
            seconds: form.windowTotalSeconds,
        },
        metricID: form.metric,
        condition: {
            operator: form.condition.operator,
            threshold: updatedThresholds.threshold,
            threshold2: updatedThresholds.threshold2,
            hysteresis: form.condition.hysteresis,
        },
    }

    return input
}

// If the condition unit is duration, convert the threshold to seconds
const convertThresholds = (condition: AlertRuleFormConditionI) => {
    let threshold = condition.threshold
    let threshold2 = condition.threshold2

    if (condition.unit === Unit.Duration && condition.thresholdTimeUnit) {
        const { value: thresholdSeconds } = convertDurationUnit(
            { unit: condition.thresholdTimeUnit, value: condition.threshold },
            "s"
        )
        const { value: threshold2Seconds } = convertDurationUnit(
            { unit: condition.thresholdTimeUnit, value: condition.threshold2 },
            "s"
        )

        threshold = thresholdSeconds
        threshold2 = threshold2Seconds
    }

    // If the condition unit is percentage, convert the threshold to a ratio. The api expects a ratio between 0 and 1
    if (condition.unit === Unit.Percentage) {
        threshold = percentageToRatio(condition.threshold)
        if (condition.threshold2) {
            threshold2 = percentageToRatio(condition.threshold2)
        }
    }

    return { threshold, threshold2 }
}

export {
    getAddAlertRuleMutationVars,
    getUpdateAlertRuleMutationVars,
    getRulePreviewEvaluateQueryVars,
}
