import { FunctionComponent, ReactNode } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalHeader from "../../../../shared/modalHeader"
import { TrexNavigator } from "../../../../../classes/navigator/navigator"
import { useNotificationsContext } from "../../../context/notificationsContext"
import { useQuery } from "@apollo/client"
import {
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../../shared/graphQlResponse"
import styles from "./updateSubscriptions.module.scss"
import { SUBSCRIPTION_UPDATE_QRY } from "../../../../../graphql/queries/subscriptionsUpdateQuery"
import { getFragmentData } from "../../../../../generated"
import { SUBSCRIPTION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/subscription"
import UpdateSubscriptionForm from "./formContainer"
import { ManageSubscriptionViewerActionT } from "../manageSubscriptionFormUtil/types"
import { canRoleAdminSubscriptions } from "../../../../../util/rolePermissions/canRole"
import { useViewerContext } from "../../../../../context/ViewerContext"
import { UnauthorizedModalBody } from "../../../../UnauthorizedModal"

interface UpdateSubscriptionI {
    viewerAction: ManageSubscriptionViewerActionT
}

const UpdateSubscription: FunctionComponent<UpdateSubscriptionI> = ({
    viewerAction,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const notificationsContext = useNotificationsContext()
    const { role: viewerRole } = useViewerContext().getViewer()

    const { subscriptionId } = useParams()
    if (!subscriptionId) {
        throw new Error("subscriptionId not resolved from url.")
    }

    const { error, loading, data } = useQuery(SUBSCRIPTION_UPDATE_QRY, {
        variables: {
            orgId: notificationsContext.selectedOrganization.value,
            AlertSubscriptionsForIDsInput: {
                ids: [subscriptionId],
            },
        },
        fetchPolicy: "network-only",
    })

    // check the viewers attempted action and their permissions.
    if (viewerAction === "admin" && !canRoleAdminSubscriptions(viewerRole)) {
        return <UnauthorizedModalBody />
    }

    let updateSubscriptionFormComponent: ReactNode
    if (loading) {
        updateSubscriptionFormComponent = (
            <SyncLoaderComponent outSideDivStyle={styles.queryLoading} />
        )
    } else if (error) {
        updateSubscriptionFormComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="We had a problem while fetching your subscription."
            />
        )
    } else {
        // get available well options
        const availableWellOptions = data?.wells?.nodes?.map((w) => {
            return { label: w.name, value: w.id }
        })

        // we can use the first subscription since we  are only querying one subscription by its id
        const s = data?.subscriptionsForIDs.subscriptions[0]
        const subscription = getFragmentData(SUBSCRIPTION_FIELDS_FRAGMENT, s)

        if (!subscription) {
            updateSubscriptionFormComponent = (
                <div className={styles.empty}>
                    This subscription does not exist.
                </div>
            )
        } else if (!availableWellOptions || availableWellOptions.length === 0) {
            updateSubscriptionFormComponent = (
                <div className={styles.empty}>
                    You do not have any wells to update subscriptions for.
                </div>
            )
        } else {
            updateSubscriptionFormComponent = (
                <UpdateSubscriptionForm
                    viewerAction={viewerAction}
                    subscription={subscription}
                    wellOptions={availableWellOptions}
                />
            )
        }
    }

    // The modal footer is in the UpdateSubscriptionForm component
    // The submit function needs access to the form state to submit the add mutation
    return (
        <div className={styles.container}>
            <ModalHeader
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: location.key,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
                title="Update Subscription"
            />
            {updateSubscriptionFormComponent}
        </div>
    )
}

export default UpdateSubscription
