import {
    AlertSubscriptionAddInput,
    SubscriptionFragForTransferSubToUserFragment,
} from "../../../../../../generated/graphql"

export const subscriptionsToAlertSubAddInput = (
    subscriptions: SubscriptionFragForTransferSubToUserFragment[],
    transferToUserId: string
): AlertSubscriptionAddInput[] => {
    // set silenceUntil,disturb,email,phone, to default values.
    return subscriptions.map((s) => {
        return {
            disturb: false,
            email: false,
            phone: false,
            silenceUntil: {
                day: 1,
                hour: 1,
                minute: 1,
                month: 1,
                year: 1971,
            },
            // from the subscription
            ruleID: s.alertRule.id,
            subscriberID: transferToUserId,
            includeNewWells: s.includeNewWells,
            subscribedWells: s.subscribedWells.map((sw) => sw.id),
        }
    })
}
