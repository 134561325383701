import { graphql } from "../../generated"

export const SUBSCRIPTION_UPDATE_QRY = graphql(`
    query UpdateSubscriptionsQry(
        $orgId: ID!
        $AlertSubscriptionsForIDsInput: AlertSubscriptionsForIDsInput!
    ) {
        wells(
            first: 1000
            filter: {
                organizationID: $orgId
                includeChildOrgs: true
                linked: true
            }
        ) {
            nodes {
                id
                name
            }
        }
        subscriptionsForIDs(input: $AlertSubscriptionsForIDsInput) {
            subscriptions {
                ...SubscriptionFields
            }
        }
    }
`)
