import {
    faBarsSort,
    faChevronDown,
    faChevronUp,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Header } from "@tanstack/react-table"
import { FunctionComponent } from "react"

interface HeaderSortIconI {
    header: Header<unknown, unknown>
    sortedStyling: string
}

const HeaderSortIcon: FunctionComponent<HeaderSortIconI> = ({
    header,
    sortedStyling,
}) => {
    const sortedDirection = header.column.getIsSorted()

    // render icon based on sort direction
    let icon: React.ReactNode
    if (sortedDirection === "asc") {
        icon = <FontAwesomeIcon className={sortedStyling} icon={faChevronUp} />
    }
    if (sortedDirection === "desc") {
        icon = (
            <FontAwesomeIcon className={sortedStyling} icon={faChevronDown} />
        )
    }
    if (sortedDirection === false) {
        icon = <FontAwesomeIcon className="" icon={faBarsSort} />
    }

    const toggleSort = header.column.getToggleSortingHandler()
    return (
        <div id={header.column.id} onClick={toggleSort}>
            {icon}
        </div>
    )
}

export default HeaderSortIcon
