import { FunctionComponent } from "react"
import { useViewerContext } from "../../../context/ViewerContext"
import UnauthorizedModal from "../../UnauthorizedModal"
import { ViewComponentPermissions } from "../../../classes/Permissions/viewComponentPermission"
import { useLocation } from "react-router-dom"

interface RoleBasedProtectedRouteProps {
    children?: React.ReactNode
}

const RoleBasedProtectedRoute: FunctionComponent<
    RoleBasedProtectedRouteProps
> = ({ children }) => {
    const { getViewer } = useViewerContext()
    const { role } = getViewer()
    const { pathname } = useLocation()
    const trexPermissions = new ViewComponentPermissions(role, pathname)

    if (!trexPermissions.canReadComponent()) {
        return <UnauthorizedModal />
    }

    return <>{children}</>
}

export default RoleBasedProtectedRoute
