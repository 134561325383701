import { FunctionComponent } from "react"

import { Table } from "@tanstack/react-table"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/pro-solid-svg-icons"
import styles from "./tanStackPaginator.module.scss"
import Input from "../../input"
import Button from "../../button"

interface WellIndexPaginationI {
    tableInstance: Table<unknown>
    pageSizeOptions: number[]
}

const TanStackPaginator: FunctionComponent<WellIndexPaginationI> = ({
    tableInstance,
    pageSizeOptions,
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.pageSizeSelectorContainer}>
                <Input
                    type="select"
                    onChange={(e) => {
                        // set page size
                        tableInstance.setPageSize(Number(e.target.value))
                    }}
                    options={pageSizeOptions}
                    value={tableInstance.getState().pagination.pageSize}
                    orientation="horizontal"
                    disabled={false}
                    label="Page size"
                    specializedClass="noMessage"
                    inputCustomClass={styles.pageSelector}
                    customLabelClass={styles.pageSelectorLabel}
                />
            </div>
            <div className={styles.arrowContainer}>
                <Button
                    disabled={!tableInstance.getCanPreviousPage()}
                    handleButtonClick={() => {
                        tableInstance.firstPage()
                    }}
                    status="secondary"
                    customButtonClassName={styles.arrowButton}
                >
                    <FontAwesomeIcon icon={faChevronDoubleLeft} />
                </Button>
                <Button
                    disabled={!tableInstance.getCanPreviousPage()}
                    handleButtonClick={() => {
                        tableInstance.previousPage()
                    }}
                    status="secondary"
                    customButtonClassName={styles.arrowButton}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <div className={styles.textContainer}>
                    {tableInstance.getState().pagination.pageIndex + 1} of{" "}
                    {tableInstance.getPageCount().toLocaleString()}
                </div>
                <Button
                    disabled={!tableInstance.getCanNextPage()}
                    handleButtonClick={() => {
                        tableInstance.nextPage()
                    }}
                    status="secondary"
                    customButtonClassName={styles.arrowButton}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
                <Button
                    disabled={!tableInstance.getCanNextPage()}
                    handleButtonClick={() => {
                        tableInstance.lastPage()
                    }}
                    status="secondary"
                    customButtonClassName={styles.arrowButton}
                >
                    <FontAwesomeIcon icon={faChevronDoubleRight} />
                </Button>
            </div>
        </div>
    )
}

export default TanStackPaginator
