import { FunctionComponent, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ModalFooter from "../../../../shared/modalFooter"
import { useMutation } from "@apollo/client"
import {
    ManageSubscriptionFormI,
    ManageSubscriptionViewerActionT,
    UpdateSubscriptionFormI,
} from "../manageSubscriptionFormUtil/types"
import MutationErrorBanner from "../../../../shared/graphQlResponse"
import ManageSubscriptionForm, {
    ManageSubscriptionFormType,
    ManageSub_UpdateAdmin_CompI,
    ManageSub_Update_CompI,
} from "../form"
import { getSubscriptionUpdateMutationVariables } from "../manageSubscriptionFormUtil/api"
import { MultiValue } from "react-select"
import { LabelValueOptionType } from "../../../../../types/commonTypes"
import { SubscriptionFieldsFragment } from "../../../../../generated/graphql"
import { SUBSCRIPTION_UPDATE } from "../../../../../graphql/mutations/subscriptions_update"
import { getUpdateSubscriptionFormFromSubscriptionFrag } from "../../subscriptions_GraphQL/subscriptionsFragment"
import { MultiSelectOptionT } from "../../../../shared/input/react-select/multiSelect"
import { parseNumberInput } from "../../../../../util/InputValidation/inputValidation"
import { DurationUnitT } from "../../../../../util/duration/types"
import ManageSubscriptionsActions from "../../manageSubscriptions/actions"
import { navigateAfterMutation } from "../../../navigate"

interface UpdateSubscriptionFormComponentI {
    subscription: SubscriptionFieldsFragment
    wellOptions: LabelValueOptionType[]
    viewerAction: ManageSubscriptionViewerActionT
}

const UpdateSubscriptionForm: FunctionComponent<
    UpdateSubscriptionFormComponentI
> = ({ subscription, wellOptions, viewerAction }) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [updateSubscriptionMutation, { loading: mutationLoading }] =
        useMutation(SUBSCRIPTION_UPDATE)

    const formTypeIs: ManageSubscriptionFormType =
        viewerAction === "admin" ? "UpdateAdmin" : "UpdateSelf"

    // form state
    const initialForm = getUpdateSubscriptionFormFromSubscriptionFrag(
        subscription,
        wellOptions
    )

    const [subscriptionForm, setSubscriptionForm] =
        useState<UpdateSubscriptionFormI>(initialForm)

    const [mutationErrorBanner, setMutationErrorBanner] =
        useState<boolean>(false)

    // handlers:
    const handleSelectedWellsChange = (
        newValue: MultiValue<MultiSelectOptionT>
    ) => {
        const updatedWellsOptions = subscriptionForm.wellOptions.map((well) => {
            // look for value in new value - if it exists.set isSelected to true, else set false
            const isWellInNewValue = newValue.findIndex(
                (option) => option.value === well.value
            )

            if (isWellInNewValue > -1) {
                return { ...well, isSelected: true }
            }

            // well is not in new value
            return { ...well, isSelected: false }
        })

        setSubscriptionForm({
            ...subscriptionForm,
            wellOptions: updatedWellsOptions,
        })
    }

    const handleUpdateSubscriptionPreferences = (
        inputId: keyof ManageSubscriptionFormI,
        value: boolean
    ) => {
        setSubscriptionForm({
            ...subscriptionForm,
            [inputId]: value,
        })
    }

    const handleSelectAllWells = (updatedSelect: boolean) => {
        const updatedWellOptions = subscriptionForm.wellOptions.map((well) => {
            return { ...well, isSelected: updatedSelect }
        })

        setSubscriptionForm({
            ...subscriptionForm,
            wellOptions: updatedWellOptions,
        })
    }

    const handleSilenceValueChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = parseNumberInput(e)
        setSubscriptionForm({
            ...subscriptionForm,
            silenceForm: {
                ...subscriptionForm.silenceForm,
                timeValue: value,
            },
        })
    }

    const handleSilenceUnitChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const targetUnit = e.target.value as DurationUnitT
        setSubscriptionForm({
            ...subscriptionForm,
            silenceForm: {
                ...subscriptionForm.silenceForm,
                timeUnit: targetUnit,
            },
        })
    }

    // submit handlers
    const handleSubmitUpdateSubscription = () => {
        const mutationVariables =
            getSubscriptionUpdateMutationVariables(subscriptionForm)

        updateSubscriptionMutation({
            variables: {
                AlertSubscriptionUpdateInput: mutationVariables,
            },
        })
            .then(() => {
                navigate(navTo.to, navTo.options)
            })
            .catch((error) => {
                console.error("Update subscription mutation error:", error)
                setMutationErrorBanner(true)
            })
    }

    // constants
    const navTo = navigateAfterMutation(pathname)

    // build props
    const props = {
        ruleOptions: [subscriptionForm.rule],
        wellOptions,
        handleSelectedWellsChange,
        handleUpdateSubscriptionPreferences,
        handleSelectAllWells,
        handleSilenceUnitChange,
        handleSilenceValueChange,
        form: subscriptionForm,
    }

    let manageSubscriptionFormProps:
        | ManageSub_UpdateAdmin_CompI
        | ManageSub_Update_CompI

    switch (formTypeIs) {
        case "UpdateSelf":
            manageSubscriptionFormProps = {
                ...props,
                formType: formTypeIs,
            }
            break
        case "UpdateAdmin":
            manageSubscriptionFormProps = {
                ...props,
                formType: formTypeIs,
                subscriberOptions: [
                    {
                        label: subscriptionForm.subscriber.label,
                        value: subscriptionForm.subscriber.id,
                    },
                ],
            }
            break
    }

    const selectedWells = subscriptionForm.wellOptions.filter((well) => {
        return well.isSelected
    })

    return (
        <>
            <ManageSubscriptionForm {...manageSubscriptionFormProps} />
            {!mutationErrorBanner && (
                <ManageSubscriptionsActions
                    formType={manageSubscriptionFormProps.formType}
                    alertRuleId={subscriptionForm.rule.value}
                    selectedSubscribedWells={selectedWells}
                />
            )}
            {mutationErrorBanner && (
                <>
                    <MutationErrorBanner
                        message={
                            <div>
                                There was problem updating your subscription.
                                Click{" "}
                                <u
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        navigate(navTo.to, navTo.options)
                                    }
                                >
                                    here
                                </u>{" "}
                                or close the modal to be redirected.
                            </div>
                        }
                    />
                </>
            )}
            <ModalFooter
                advanceText="Submit"
                disableAdvance={mutationLoading || mutationErrorBanner}
                handleAdvanceClick={handleSubmitUpdateSubscription}
                disablePrevious={mutationLoading}
                handlePreviousClick={() => navigate(-1)}
                previousButtonCondition="default"
                previousText="Cancel"
            />
        </>
    )
}

export default UpdateSubscriptionForm
