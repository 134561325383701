import { graphql } from "../../generated"

export const DEACTIVATE_ORGANIZATIONS = graphql(`
    mutation OrganizationsDeactivate(
        $OrganizationsDeactivateInput: OrganizationsDeactivateInput!
    ) {
        organizationsDeactivate(input: $OrganizationsDeactivateInput) {
            organizations {
                ...OrganizationWithDescendantsFields
            }
        }
    }
`)
