import React, { FunctionComponent } from "react"
import Input from "../shared/input"
import styles from "./updateUserBody.module.scss"
import { Toggle } from "../shared/selectionControls"
import {
    UpdateUserFormStateI,
    UpdateUserModalStateInterface,
} from "./updateUserModalTypes"
import { getInitials } from "../../util/users"
import { getUpdateUserRoleOptionsByRole } from "../../util/rolePermissions/getByRole"
import { useViewerContext } from "../../context/ViewerContext"

interface UpdateUserBodyComponentPropsInterface {
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void
    handleChangeUserActivationStatus: () => void
    modalState: UpdateUserModalStateInterface
    initialFormState: UpdateUserFormStateI
    organizationName: string
    email: string
}

const UpdateUserBody: FunctionComponent<
    UpdateUserBodyComponentPropsInterface
> = ({
    handleInputChange,
    handleChangeUserActivationStatus,
    modalState,
    initialFormState,
    organizationName,
    email,
}) => {
    const { getViewer } = useViewerContext()
    const { role: viewerRole } = getViewer()
    const {
        updateUser: { firstName, lastName, userRole, activeUser },
    } = modalState

    // constants
    const initials = getInitials(
        initialFormState.firstName,
        initialFormState.lastName
    )

    const isUserActive = initialFormState.activeUser

    return (
        <>
            <div className={styles.bodyContainerHeader}>
                <div className={styles.userIcon}>{initials}</div>
                <div className={styles.headerName}>
                    <span>{`${initialFormState.firstName} ${initialFormState.lastName}`}</span>
                    <div className={styles.headerEmailDiv}>{email}</div>
                </div>
            </div>
            <div className={styles.bodyContainer}>
                <div className={styles.rowContainer}>
                    <div className={styles.row_leftContainer}>
                        <Input
                            orientation="vertical"
                            type="text"
                            value={firstName}
                            onChange={handleInputChange}
                            label="First Name *"
                            disabled={!isUserActive}
                            name="firstName"
                        />
                    </div>
                    <div className={styles.row_rightContainer}>
                        <Input
                            orientation="vertical"
                            type="text"
                            value={lastName}
                            onChange={handleInputChange}
                            label="Last Name *"
                            disabled={!isUserActive}
                            name="lastName"
                        />
                    </div>
                </div>

                <div className={styles.rowContainer}>
                    <div className={styles.organizationDiv}>
                        <div className={styles.organizationDivHeading}>
                            Organization
                        </div>
                        <div className={styles.organizationDivText}>
                            {organizationName}
                        </div>
                    </div>
                    <div className={styles.row_rightContainer}>
                        <Input
                            onChange={handleInputChange}
                            options={getUpdateUserRoleOptionsByRole(viewerRole)}
                            orientation="vertical"
                            type="select"
                            value={userRole}
                            label="Role"
                            specializedClass="default"
                            name="userRole"
                            disabled={!isUserActive}
                        />
                    </div>
                </div>
                <div className={styles.userActivationToggleContainer}>
                    <Toggle
                        checked={activeUser}
                        handleToggleClick={handleChangeUserActivationStatus}
                        toggleLabel=""
                    />
                    <div className={styles.userActivationText}>
                        <b>User : </b>
                        <span>{`${
                            activeUser ? "Activate" : "Inactive"
                        } `}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateUserBody
