// PageInfo represents the data returned by a server to allow a client to track their location while navigating a connection
export interface PageInfo {
    hasNextPage: boolean
    hasPreviousPage: boolean
    endCursor?: string | null
    startCursor?: string | null
}

// PageRequest represents the set of variables transmitted to a server while navigating a connection
export interface PageRequest {
    first?: number | null
    last?: number | null
    before?: string | null
    after?: string | null
}

// CountableCollection represents an extension to a standard relay connection
// By including the `totalCount` field, the client can provide additional information
// like current page index and total count of pages
export interface CountableCollection {
    totalCount: number
    pageInfo: PageInfo
}

export type PagingDirection = "forward" | "backward" | "undefined"

/**
 * RelayPager implements the relay pager specification defined here:
 * > https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 * It simply tracks the state for relay compliant paging of connections and provides convienent methods
 */
export class RelayPager {
    private pageInfo: PageInfo
    private state: PageRequest

    constructor(
        readonly pageSize: number,
        private initialDirection: PagingDirection = "forward",
        private initialCursor: string | null = null
    ) {
        this.pageInfo = {
            hasNextPage: false,
            hasPreviousPage: false,
            endCursor: null,
            startCursor: null,
        }

        this.state = {
            first: null,
            after: null,
            before: null,
            last: null,
        }

        // Initialize State
        switch (initialDirection) {
            case "forward":
                this.next()
                break
            case "backward":
                this.previous()
                break
            default:
                throw new Error(
                    `received invalid direction: ${this.initialDirection}`
                )
        }
    }

    /**
     * Accepts the PageInfo data returned by the latest request for a connection
     * It is intended to be called with the connection object data returned from an observed query
     */
    public update(info: PageInfo) {
        this.pageInfo = info
    }

    /**
     * Provides the PageRequest data that should be included in the current query for a connection
     * It is intended to be used to be passed as the variables for a `useQuery()` call
     */
    public current(): PageRequest {
        return {
            first: this.state.first,
            after: this.state.after,
            before: this.state.before,
            last: this.state.last,
        }
    }

    /**
     * Provides the PageRequest data that will cause the pager to go forward one page in the connection
     * It is intended to be used to define the variables when calling `fetchMore()` on a connection
     */
    public next(): PageRequest {
        this.state = {
            first: this.pageSize,
            after: this.pageInfo.endCursor,
            before: null,
            last: null,
        }
        return this.current()
    }

    /**
     * Provides the PageRequest data that will cause the pager to go backward one page in the connection
     * It is intended to be used to define the variables when calling `fetchMore()` on a connection
     */
    public previous(): PageRequest {
        this.state = {
            first: null,
            after: null,
            before: this.pageInfo.startCursor,
            last: this.pageSize,
        }

        return this.current()
    }

    /**
     * Provides a copy of the current PageInfo state
     * Useful for inspecting the state of the pager and for collaborates to derive additional state
     * It is a copy to prevent any clients from accidentally destroying the pager's internal state
     */
    public get info(): PageInfo {
        return {
            hasNextPage: this.pageInfo.hasNextPage,
            hasPreviousPage: this.pageInfo.hasPreviousPage,
            startCursor: this.pageInfo.startCursor,
            endCursor: this.pageInfo.endCursor,
        }
    }
}

/**
 * Composes with a RelayPager to add additional information about the page location and total count of pages available on the
 * connection
 *
 * Caveats:
 *   1. Page index information is *only* available if initialized from the first page. The `totalCount` field alone is
 *      insufficient for arbitrary initialization. I.E. pageIndex **can only ever be initialized to 0**
 *   2. Can not integrate with URL state for tracking. The pageIndex state is UI state only! My preference would be to move
 *      towards a "true" paging solution that allows arbitrary page navigation in the API for collections that are small enough
 *      for limit/offset paging rather than odd workarounds like syncing this pageIndex state to the URL
 *
 * @example
 * // initialize a pager inside a functional component
 * const [pager] = useState<CountableConnectionPager>(new CountableConnectionPager(10))
 */
export class CountableConnectionPager {
    private pager: RelayPager

    private totalCount = -1
    private pageIndex = 0
    private direction: PagingDirection

    constructor(
        pageSize: number,
        initialDirection: PagingDirection = "forward",
        initialCursor: string | null = null
    ) {
        this.direction = initialDirection
        this.pager = new RelayPager(pageSize, initialDirection, initialCursor)
    }

    public current(): PageRequest {
        return this.pager.current()
    }

    public next(): PageRequest {
        if (this.cannotNext) {
            throw new Error("page request undefined")
        }
        this.direction = "forward"
        this.pageIndex += 1
        return this.pager.next()
    }

    public previous(): PageRequest {
        if (this.cannotPrevious) {
            throw new Error("page request undefined")
        }
        this.direction = "backward"
        this.pageIndex -= 1
        return this.pager.previous()
    }

    public update(collection: CountableCollection): void {
        this.totalCount = collection.totalCount
        this.pager.update(collection.pageInfo)
    }

    /**
     * Informs the caller if a next page is avilable
     */
    public get canNext(): boolean {
        if (this.pagingDirection === "forward") {
            return this.pager.info.hasNextPage
        }

        return true
    }

    /**
     * Informs the caller if a previous page is avilable
     */
    public get canPrevious(): boolean {
        if (this.pagingDirection === "backward") {
            return this.pager.info.hasPreviousPage
        }

        // Unfortunate special case ...
        // Cause: `hasPreviousPage` only has a meaningful true/false value when paging backwards
        // Explaination: If we're paging forward, we can not simply return true as we may be at
        // the first page and this state clearly can not page to the previous page
        if (this.currentPageIndex === 0) {
            return false
        }

        return true
    }

    /**
     * The opposite of canNext
     * Very useful for setting `disabled` attributes
     */
    public get cannotNext(): boolean {
        return !this.canNext
    }

    /**
     * The opposite of canPrevious
     * Very useful for setting `disabled` attributes
     */
    public get cannotPrevious(): boolean {
        return !this.canPrevious
    }

    public get pagingDirection(): PagingDirection {
        return this.direction
    }

    /**
     * Provides the current zero-indexed page location within the connection
     */
    public get currentPageIndex(): number {
        return this.pageIndex
    }

    /**
     * Provides the total page count based on the page size and total collection size
     */
    public get totalPageCount(): number {
        const ps = this.pager.pageSize

        const totalPages = Math.floor(this.totalCount / ps)
        const addOne = this.totalCount % ps ? 1 : 0
        return totalPages + addOne
    }
}
