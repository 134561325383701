import { DiscreteControlWiringMode } from "../../generated/graphql"

const wiringModeUiDisplayObject = {
    [DiscreteControlWiringMode.NormallyClosed]: "Normally Closed",
    [DiscreteControlWiringMode.NormallyOpen]: "Normally Open",
    [DiscreteControlWiringMode.Unknown]: "Unknown",
} as const

// functions
const getWiringModeInitials = (wiringMode: DiscreteControlWiringMode) => {
    switch (wiringMode) {
        case DiscreteControlWiringMode.NormallyClosed:
            return "(N.C.)"
        case DiscreteControlWiringMode.NormallyOpen:
            return "(N.O.)"
        case DiscreteControlWiringMode.Unknown:
            return ""
    }
}

const isEdgeComputerTokenValid = (value: string): boolean => {
    const regex = /^[a-zA-Z0-9]{6}$/
    const isValid = regex.test(value)

    if (isValid) {
        return true
    } else {
        return false
    }
}

export {
    wiringModeUiDisplayObject,
    getWiringModeInitials,
    isEdgeComputerTokenValid,
}
