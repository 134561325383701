import { graphql } from "../../generated"

export const UPDATE_USERS = graphql(`
    mutation UpdateUsers($UserUpdateInput: UserUpdateInput!) {
        userUpdate(input: $UserUpdateInput) {
            user {
                ...SubjectFields
            }
        }
    }
`)

export const UPDATE_USERS_ADMIN = graphql(`
    mutation UpdateUsersAdmin($UsersUpdateAdmin: UsersUpdateAdminInput!) {
        usersUpdateAdmin(input: $UsersUpdateAdmin) {
            users {
                ...SubjectFields
            }
        }
    }
`)
