import {
    FunctionComponent,
    useDeferredValue,
    useEffect,
    useMemo,
    useState,
} from "react"
import { WellIndexTableDataI } from "./tableTypes"
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    getFacetedUniqueValues,
    ColumnFiltersState,
    SortingState,
    Table,
} from "@tanstack/react-table"
import wellIndexColumns from "./column"
import { Outlet, useLocation, useSearchParams } from "react-router-dom"
import styles from "./wellIndexTable.module.scss"
import WellIndexHeader from "./header"
import WellIndexActionHeader from "./actionHeader"
import { canRoleAddWell } from "../../util/rolePermissions/canRole"
import { useViewerContext } from "../../context/ViewerContext"
import classNames from "classnames"
import {
    getWellIndexStateFromUrlQueryParams,
    wellIndexPageSizeSelectorArray,
    wellIndexTableUrlMapping,
} from "./tableUtils"
import TanStackPaginator from "../shared/tanStack/paginator"
import { tableStateToUrlSearchParams } from "../table/tableUtil"

interface WellIndexTableI {
    data: WellIndexTableDataI[]
}

const WellIndexTable: FunctionComponent<WellIndexTableI> = ({ data }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const initUrlState = useMemo(() => {
        return getWellIndexStateFromUrlQueryParams(
            searchParams,
            wellIndexTableUrlMapping
        )
    }, [data])

    const viewer = useViewerContext()
    const { role } = viewer.getViewer()
    const [globalFilter, setGlobalFilter] = useState(initUrlState.search)
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
        initUrlState.filter
    )
    const [sortingState, setSortingState] = useState<SortingState>(
        initUrlState.sorting
    )
    const [paginationState, setPaginationState] = useState({
        pageIndex: initUrlState.pageIndex,
        pageSize: initUrlState.size,
    })

    const deferredGlobalFilter = useDeferredValue(globalFilter)
    const deferredColumnFilters = useDeferredValue(columnFilters)
    const deferredSortingState = useDeferredValue(sortingState)
    const isStateDeferred =
        deferredGlobalFilter !== globalFilter ||
        deferredColumnFilters !== columnFilters ||
        deferredSortingState !== sortingState

    const table = useReactTable({
        data: data,
        // autoResetPageIndex is because false : if true, the page index will be reset to 0 whenever the data changes (from a mutation).
        // That creates a bad user experience, in this circumstance if the user changed a run mode on page 5 they would be directed back to page 1.
        // The down side of autoResetPageIndex: false is that we need to manually reset the page index when updating filters, globals and sorting
        autoResetPageIndex: false,
        columns: wellIndexColumns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        onColumnFiltersChange: (updater) => {
            table.setPageIndex(0)
            return setColumnFilters(updater)
        },
        onSortingChange: (updater) => {
            table.setPageIndex(0)
            return setSortingState(updater)
        },
        onGlobalFilterChange: (updater) => {
            table.setPageIndex(0)
            return setGlobalFilter(updater)
        },
        onPaginationChange: (updater) => {
            return setPaginationState(updater)
        },
        initialState: {
            columnVisibility: {
                organization: false,
            },
        },
        state: {
            globalFilter: deferredGlobalFilter,
            columnFilters: deferredColumnFilters,
            sorting: sortingState,
            pagination: paginationState,
        },
    })

    // Effect hook to keep app state in sync with URL
    // if the dependencies change based on updated table state - this effect will fire and update the URL
    useEffect(() => {
        // do not set parameters if the current url is a modal and not the dashboard
        // This causes issues when navigating directly to a modal
        if (!location.pathname.endsWith("/dashboard")) {
            return
        }

        const newParams = tableStateToUrlSearchParams(
            paginationState,
            sortingState,
            columnFilters,
            globalFilter
        )

        // replace on history stack so each parameter change does not cause a new entry
        setSearchParams(newParams, { replace: true })
    }, [columnFilters, sortingState, globalFilter, paginationState])

    return (
        <>
            <Outlet />
            <div className={styles.container}>
                <WellIndexActionHeader
                    canAddWell={canRoleAddWell(role)}
                    globalFilter={globalFilter}
                    organizationColumn={table.getColumn("organization")}
                    table={table}
                />
                <div className={styles.overflowContainer}>
                    <table className={styles.tableClass}>
                        <WellIndexHeader table={table} />
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr
                                    className={classNames(
                                        styles.tableRow,
                                        row.original.customTableRowClass &&
                                            styles[
                                                row.original.customTableRowClass
                                            ],
                                        isStateDeferred && styles.deferredRow
                                    )}
                                    key={row.id}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            className={classNames(
                                                styles.tableCell,
                                                styles[`${cell.column.id}_Cell`]
                                            )}
                                            key={cell.id}
                                        >
                                            <span className={styles.cellSpan}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </span>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <TanStackPaginator
                    pageSizeOptions={wellIndexPageSizeSelectorArray}
                    tableInstance={table as Table<unknown>}
                />
            </div>
        </>
    )
}
export default WellIndexTable
