import { FunctionComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleArrowUp } from "@fortawesome/pro-solid-svg-icons"
import "./emptyState.scss"

const EmptyState: FunctionComponent = () => {
    return (
        <div className="display-panel-empty-container">
            <FontAwesomeIcon
                icon={faCircleArrowUp}
                className="display-panel-empty-fai"
            />
            <div className="display-panel-empty-heading-text">
                Enter Well API Number
            </div>
            <div className="display-panel-empty-subtext">
                Enter your Well API number in the field <br /> above and click +
            </div>
        </div>
    )
}

export default EmptyState
