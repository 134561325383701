import { FunctionComponent } from "react"
import {
    convertSecondsToHoursAndMinutes,
    convertUnixMillisecondsToLocalDate,
    convertUnixMillisecondsToLocalTime,
} from "../../../util/datesAndTimes/datesAndTimes"
import { WellRunModeType } from "../../../types/controlSettingTypes"
import { Link } from "react-router-dom"
import { getFragmentData } from "../../../generated"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faBoltLightning,
    faCircleInfo,
    faRouter,
    faSensorOn,
    faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons"
import { WellAlertConcernUiDisplayType } from "../../../types/wellQueryResponseTypes"
import { WellAlertFieldsFragment } from "../../../generated/graphql"
import { DATE_TIME_FIELDS_FRAGMENT } from "../../../graphql/fragments/dateTime"
import styles from "./cell.module.scss"
import ToolTip_Popover from "../../shared/toolTip/toolTip_popover"
import classNames from "classnames"

const SpmCell: FunctionComponent<{ spm: number | undefined }> = ({ spm }) => {
    if (typeof spm !== "number") {
        return <></>
    } else {
        return <>{spm.toFixed(1)}</>
    }
}

const RunModeCell: FunctionComponent<{
    linkTo: string
    runMode: WellRunModeType | undefined
}> = ({ linkTo, runMode }) => {
    if (!runMode) {
        return <></>
    } else {
        return (
            <Link
                style={{ color: "#1e88e5", textDecoration: "none" }}
                to={linkTo}
            >
                {runMode}
            </Link>
        )
    }
}

const RunTimeCell: FunctionComponent<{
    runTimeSeconds: number | undefined
}> = ({ runTimeSeconds }) => {
    if (typeof runTimeSeconds !== "number") {
        return <></>
    } else {
        const { hours, minutes } =
            convertSecondsToHoursAndMinutes(runTimeSeconds)
        return (
            <>
                {hours}h {minutes}m
            </>
        )
    }
}

const commLossIcon = (
    <FontAwesomeIcon
        icon={faRouter}
        className={classNames(styles.fai, styles.red)}
    />
)
const sensorLossIcon = (
    <FontAwesomeIcon
        icon={faSensorOn}
        className={classNames(styles.fai, styles.red)}
    />
)
const powerLossIcon = (
    <FontAwesomeIcon
        icon={faBoltLightning}
        className={classNames(styles.fai, styles.red)}
    />
)
const ctrlLossIcon = (
    <FontAwesomeIcon
        icon={faTriangleExclamation}
        className={classNames(styles.fai, styles.red)}
    />
)
const infoAlertIcon = (
    <FontAwesomeIcon
        icon={faCircleInfo}
        className={classNames(styles.fai, styles.gray)}
    />
)

const WellAlertCell: FunctionComponent<{
    wellAlert: WellAlertFieldsFragment | null | undefined
}> = ({ wellAlert }) => {
    let fontAwesomeIcon: React.ReactNode
    let wellAlertConcern: WellAlertConcernUiDisplayType

    if (!wellAlert) {
        return <></>
    } else {
        switch (wellAlert.__typename) {
            case "CommunicationsAlert":
                fontAwesomeIcon = commLossIcon
                wellAlertConcern = "Lost Communication"
                break
            case "SensorAlert":
                fontAwesomeIcon = sensorLossIcon
                wellAlertConcern = "Lost Sensor Communication"
                break
            case "ControlAlert":
                fontAwesomeIcon = ctrlLossIcon
                wellAlertConcern = "Loss of Control"
                break
            case "PowerLossAlert":
                fontAwesomeIcon = powerLossIcon
                wellAlertConcern = "Loss of Power"
                break
            case "InfoAlert":
                fontAwesomeIcon = infoAlertIcon
                wellAlertConcern = undefined
                break
            default:
                return <></>
        }
    }
    const alertOccuredAt = getFragmentData(
        DATE_TIME_FIELDS_FRAGMENT,
        wellAlert.occurredAt
    )

    return (
        <ToolTip_Popover
            content={
                <>
                    <div>{wellAlertConcern && wellAlertConcern}</div>
                    <div>
                        {`${convertUnixMillisecondsToLocalDate(
                            alertOccuredAt.unixMilliseconds,
                            {
                                month: "2-digit",
                                day: "2-digit",
                                year: "2-digit",
                            }
                        )} ${convertUnixMillisecondsToLocalTime(
                            alertOccuredAt.unixMilliseconds,
                            {
                                hour: "numeric",
                                minute: "2-digit",
                                hour12: true,
                                timeZoneName: "short",
                            }
                        )}`}
                    </div>
                    <div>{wellAlert.tooltip}</div>
                </>
            }
            triggerChildren={<div>{fontAwesomeIcon}</div>}
            popover={{
                removePopoverButtonStyling: true,
                side: "right",
            }}
            tooltip={{ side: "right" }}
        />
    )
}

const WellNameCell: FunctionComponent<{
    wellName: string
    wellLinkedStatus: boolean
    wellId: string
}> = ({ wellName, wellLinkedStatus, wellId }) => {
    if (!wellLinkedStatus) {
        return <>{wellName}</>
    }

    return (
        <Link
            style={{ color: "#1e88e5", textDecoration: "none" }}
            to={`/wellDetails/${wellId}`}
        >
            {wellName}
        </Link>
    )
}

export { SpmCell, RunModeCell, RunTimeCell, WellAlertCell, WellNameCell }
