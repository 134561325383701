import { graphql } from "../../generated"

export const DATA_POINT_FIELDS_FRAGMENT = graphql(`
    fragment DataPointFields on Datapoint {
        occuredAt {
            ...DateTimeFields
        }
        value
        column
        meta {
            key
            value
        }
        highchartSeries
    }
`)
