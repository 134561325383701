import {
    NewUserErrorStateInterface,
    NewUserStateInterface,
} from "./inviteUserModalTypes"
import { isValidEmail } from "../../util/users"
import { inviteUserErrorMessages } from "../../util/errorMessages"
import { checkForOneNonWhiteSpaceCharacter } from "../../util/regex"
import { SubjectRole } from "../../generated/graphql"
import { LabelValueOptionType } from "../../types/commonTypes"
import { OrganizationOptionType } from "../../types/ViewerTypes"
import { RolesUiDisplayObj } from "../../util/subjectRole"

const disableSubmitInviteUser = (newUserState: NewUserStateInterface) => {
    const { email, firstName, lastName, organization, inviteeUserRole } =
        newUserState

    if (!checkForOneNonWhiteSpaceCharacter(firstName)) {
        return true
    }

    if (!checkForOneNonWhiteSpaceCharacter(lastName)) {
        return true
    }

    if (organization === undefined) {
        return true
    }

    if (inviteeUserRole === undefined) {
        return true
    }
    if (!isValidEmail(email)) {
        return true
    }

    return false
}

const validateInviteUserInputData = (
    newUserState: NewUserStateInterface
): NewUserErrorStateInterface => {
    const newErrorState: NewUserErrorStateInterface = {
        email: undefined,
        firstName: undefined,
        lastName: undefined,
        organization: undefined,
        inviteeUserRole: undefined,
    }

    const { email, organization, firstName, lastName } = newUserState

    if (email.length > 0 && !isValidEmail(email)) {
        newErrorState.email = inviteUserErrorMessages.newUserErrorState.email
    }

    if (organization === undefined) {
        newErrorState.organization =
            inviteUserErrorMessages.newUserErrorState.organization
    }

    if (!checkForOneNonWhiteSpaceCharacter(firstName)) {
        newErrorState.firstName =
            inviteUserErrorMessages.newUserErrorState.firstName
    }

    if (!checkForOneNonWhiteSpaceCharacter(lastName)) {
        newErrorState.lastName =
            inviteUserErrorMessages.newUserErrorState.lastName
    }

    return newErrorState
}

const getRoleInvitationOptions = (
    role: SubjectRole
): LabelValueOptionType[] => {
    switch (role) {
        case SubjectRole.PpoAdmin:
        case SubjectRole.OrgAdmin:
            return [
                {
                    label: RolesUiDisplayObj[SubjectRole.OrgAdmin],
                    value: SubjectRole.OrgAdmin,
                },
                {
                    label: RolesUiDisplayObj[SubjectRole.OrgMember],
                    value: SubjectRole.OrgMember,
                },
            ]
        case SubjectRole.OrgMember:
        case SubjectRole.System:
        case SubjectRole.Unknown:
            throw new Error(
                "getRoleInvitationOptions - Unknown, System && OrgMember roles should not have access to invite user modal"
            )
    }
}

// Per business logic && request from the team:
// if there is only one organization the dropdown should be populated with that organization
// otherwise the dropdown should be empty to prevent the accidental assignment of the user to the wrong organization. (We want the user to manually select the organization)
const getInviteUserFormInitialOrgState = (
    organizationAndDescendants: OrganizationOptionType[]
) => {
    if (organizationAndDescendants.length === 1) {
        return organizationAndDescendants[0]
    }
    return undefined
}

export {
    getRoleInvitationOptions,
    validateInviteUserInputData,
    disableSubmitInviteUser,
    getInviteUserFormInitialOrgState,
}
