import { DiscreteControlWiringMode } from "../../../generated/graphql"
import { UpdateWiringModeModalLocationState } from "./updateWellWiringModeModalTypes"

const getModalLocationState = (
    state: UpdateWiringModeModalLocationState | undefined
) => {
    if (state) {
        return {
            wellName: state.wellIdentificationFragment.name,
            wiringMode: state.wiringMode,
        }
    } else {
        return {
            wellName: "",
            wiringMode: DiscreteControlWiringMode.Unknown,
        }
    }
}

export { getModalLocationState }
