import { FunctionComponent, ReactNode, useMemo } from "react"
import ModalHeader from "../../../../shared/modalHeader"
import { TrexNavigator } from "../../../../../classes/navigator/navigator"
import styles from "./updateAlertRule.module.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import {
    SyncLoaderComponent,
    ErrorMessage,
    EmptyState,
} from "../../../../shared/graphQlResponse"
import { getFragmentData } from "../../../../../generated"
import { ALERT_RULE_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRule"
import UpdateAlertRuleFormContainer from "./formContainer"
import { GET_ALERT_RULES_BY_ID } from "../../../../../graphql/queries/rulesById"
import { useViewerContext } from "../../../../../context/ViewerContext"
import { SubjectRole } from "../../../../../generated/graphql"

const UpdateAlertRule: FunctionComponent = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { role: viewerRole } = useViewerContext().getViewer()
    const urlSlugs = useParams()
    const ruleId = urlSlugs.ruleId as string

    // query alert rule by id
    const { error, loading, data } = useQuery(GET_ALERT_RULES_BY_ID, {
        variables: {
            input: { ids: [ruleId] },
        },
        fetchPolicy: "network-only", // we want to always fetch the latest data before updating an alert rule
    })

    const ruleToBeUpdated = useMemo(() => {
        // this modal only queries / updates one rule one rule so can safely return the 0 index.
        if (data?.rulesByIDs.rules && data?.rulesByIDs.rules.length > 0) {
            return getFragmentData(
                ALERT_RULE_FIELDS_FRAGMENT,
                data?.rulesByIDs.rules[0]
            )
        }
        return undefined
    }, [data])

    // rendering
    let updateAlertRuleFormComponent: ReactNode
    if (loading) {
        updateAlertRuleFormComponent = (
            <SyncLoaderComponent outSideDivStyle={styles.queryLoading} />
        )
    } else if (error) {
        updateAlertRuleFormComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="We had a problem while fetching your alert rule information."
            />
        )
    } else if (!ruleToBeUpdated) {
        updateAlertRuleFormComponent = (
            <EmptyState outSideDivStyle={styles.queryEmpty}>
                <> No alert rule with this id was found.</>
            </EmptyState>
        )
    } else {
        updateAlertRuleFormComponent = (
            <>
                <UpdateAlertRuleFormContainer
                    ruleToBeUpdated={ruleToBeUpdated}
                />
            </>
        )
    }

    // constants
    const modalTitle = [SubjectRole.PpoAdmin, SubjectRole.OrgAdmin].includes(
        viewerRole
    )
        ? "Update Alert Rule"
        : "View Alert Rule"

    return (
        // The modal footer is in the UpdatedAlertRuleFormContainer component
        // The submit function needs access to the form state to submit the update mutation
        <div className={styles.container}>
            <ModalHeader
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: location.key,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
                title={modalTitle}
            />
            {updateAlertRuleFormComponent}
        </div>
    )
}

export default UpdateAlertRule
