import { ReactNode } from "react"
import { Link } from "react-router-dom"
import {
    GetOrganizationsQuery,
    OrganizationWithDescendantsFieldsFragment,
    SubjectRole,
} from "../../../generated/graphql"
import { OrganizationDataInterface } from "./organizationTableTypes"
import { TableColumnInterface } from "../../../types/tableTypes"
import { getFragmentData } from "../../../generated"
import { ORGANIZATION_WITH_DESCENDANTS_FIELDS_FRAGMENT } from "../../../graphql/fragments/organizations"
import { canRoleUpdateOrganization } from "../../../util/rolePermissions/canRole"
import styles from "./organization.module.scss"

export const formatOrganizationData = (
    rawData: GetOrganizationsQuery,
    viewerRole: SubjectRole
) => {
    const data: OrganizationDataInterface[] = []

    const { edges } = rawData.organizations

    if (edges) {
        for (let i = 0; i < edges.length; i++) {
            const node = edges[i].node
            if (!node) continue

            const organizationFragmentFields = getFragmentData(
                ORGANIZATION_WITH_DESCENDANTS_FIELDS_FRAGMENT,
                node
            )

            const organization: OrganizationDataInterface = {
                id: organizationFragmentFields.id,
                Well: "",
                Organization: getOrganizationNameLink(
                    organizationFragmentFields,
                    viewerRole
                ),
                Phone: "",
                TechnicalContact: "",
                User: "",
                activationStatus: organizationFragmentFields.active,
                parentId: organizationFragmentFields.parentID || undefined,
                viewerRole: SubjectRole.PpoAdmin,
            }
            data.push(organization)
        }
    }
    return data
}

const getOrganizationNameLink = (
    obj: OrganizationWithDescendantsFieldsFragment,
    viewerRole: SubjectRole
): ReactNode => {
    if (canRoleUpdateOrganization(viewerRole)) {
        // if role can update organization, make organization name a link
        return (
            <Link
                className={styles.tableLink}
                to={`/admin/organizations/${obj.id}/update`}
                state={{
                    name: obj.name,
                    activationStatus: obj.active,
                    parentId: obj.parentID,
                }}
            >
                {obj.name}
            </Link>
        )
    } else {
        // if role cannot update organization, make organization name plain text
        return <div className={styles.tableLink}>{obj.name}</div>
    }
}

export const OrganizationTableConfigData: TableColumnInterface<OrganizationDataInterface>[] =
    [
        {
            label: "Organization",
            id: "organization",
            render: (obj) => obj.Organization,
            headerCustomClass: "headerOrganization",
            cellCustomClass: "cellOrganization",
        },
        {
            label: "Org. Status",
            id: "organizationStatus",
            render: (obj) => (
                <div> {obj.activationStatus ? "Active" : "Inactive"}</div>
            ),
            headerCustomClass: "headerOrgStatus",
            cellCustomClass: "cellOrgStatus",
        },
    ]
