import { StringStringObjectType } from "../../types/commonTypes"

// TO DO - Replace this function
const getURLParameters = (searchParams: URLSearchParams) => {
    const paramsObject: StringStringObjectType = {}

    for (const [key, value] of searchParams.entries()) {
        paramsObject[key] = value
    }
    return paramsObject
}

/**
 * Checks if a specific query parameter exists in a URL's search parameters.
 *
 * @param {string} findParam - The name of the query parameter to find (case-insensitive).
 * @param {URLSearchParams} searchParams - The URL search parameters to search within.
 *
 * @returns {string | false} The value of the found query parameter if it exists, or false if not found.
 */
const doesSearchParamExist = (
    findParam: string,
    searchParams: URLSearchParams
) => {
    for (const [key, value] of searchParams) {
        if (key.toLowerCase() === findParam.toLowerCase()) {
            return value
        }
        return false
    }
}

/**
 * parse URLSearchParams to object.
 * @param searchParams - The URLSearchParams object containing the query parameters to parse.
 * @param normalizeKeys - if true .toLowerCase will be called on all URLSearchParams keys
 * @returns An object representing the parsed query parameters with keys as query parameter names and values as corresponding values.
 */
const urlSearchParamsToObject = function (
    searchParams: URLSearchParams,
    normalizeKeys: boolean
): Record<string, string> {
    const obj = {} as Record<string, string>

    searchParams.forEach((value, key) => {
        const k = normalizeKeys ? key.toLowerCase() : key
        obj[k] = value
    })

    return obj
}

/**
 * READ BEFORE USING
 *
 * Updates the query parameters in a given URLSearchParams object with the provided parameters (updatedParam) to update.
 * Important caveats to understand: if updatedParam and currentSearchParams share a key - updatedParam will take precedence. This is used to replace params values.
 * If a updatedParam key value is set to null - that key-value will be deleted from currentSearchParams and consequently the return object. This is used to delete params
 * See unit test for examples.
 *
 * @param updatedParam - An object containing the updated query parameters to be applied.
 * @param currentSearchParams - The URLSearchParams object containing the current query parameters.
 * @returns A new URLSearchParams object representing the updated query parameters.
 */
const updateUrlSearchParams = (
    updatedParam: Record<string, string | null>,
    currentSearchParams: URLSearchParams
): Record<string, string> => {
    for (const [key, value] of Object.entries(updatedParam)) {
        // If the value is null, delete the parameter from the URLSearchParams object
        if (value === null) {
            currentSearchParams.delete(key)
        } else {
            // Otherwise, set or update the parameter in the URLSearchParams object
            currentSearchParams.set(key, value)
        }
    }

    // Convert URLSearchParams to object
    const searchParamsObject: Record<string, string> = {}
    currentSearchParams.forEach((value, key) => {
        searchParamsObject[key] = value
    })

    return searchParamsObject
}

export {
    getURLParameters,
    doesSearchParamExist,
    urlSearchParamsToObject,
    updateUrlSearchParams,
}
