import { graphql } from "../../generated"

export const WELL_ALERT_FIELDS_FRAGMENT = graphql(`
    fragment WellAlertFields on WellAlert {
        tooltip
        occurredAt {
            ...DateTimeFields
        }
        ...SensorAlertFields
    }
`)

export const SENSOR_ALERT_FIELDS_FRAGMENT = graphql(`
    fragment SensorAlertFields on SensorAlert {
        SensorAlertLocation: location
    }
`)

/**
 * Dev Notes - Aug 14,2023
 *
 * There are 5 polymorphic implementations of the WellAlert Object
 *
 * All implementations share tooltip and occured at. These are the fields necessary for the current state of the app.
 * Because of this, I have choosen not to add fragments for each implementation (subject to change).
 * __typename(automatically added by apollo client), tooltip, occuredAt are used in the app
 *
 *
 */
