import { FunctionComponent } from "react"
import { useAddWellsContext } from "../../../../context/addWellsContext"
//componentns
import PreviewAdded from "./previewAdded"
import PreviewAddNew from "./previewAddNew"
import { findIndexByKeyInArray } from "../../../../util/arrays/array"

// Preview index - default export
const Preview: FunctionComponent = () => {
    // context
    const {
        addWellsState: { displayPanelArray },
        getAddWellsWellData,
    } = useAddWellsContext()
    // context hook to get addWellsWellData
    const addWellsWellData = getAddWellsWellData()

    // Does well api number exists in displayPanelArray
    const wellIndexInDisplayPanel = findIndexByKeyInArray(
        displayPanelArray,
        (obj) => obj.addWellModalId,
        addWellsWellData.addWellModalId
    )

    if (wellIndexInDisplayPanel === undefined) {
        // this scenario happens when a user enters a new well api number into wellApiNumberForm
        return <PreviewAddNew />
    } else {
        // this scenario happens when a clicks on a well from the display panel.
        return <PreviewAdded />
    }
}

export default Preview
