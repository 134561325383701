import { FunctionComponent } from "react"
import classnames from "classnames"
import "./navigationControls.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

interface NavigationControlsInterface {
    children: React.ReactNode
    usage: "nav-bar" | "sub-nav"
    condition: "default" | "selected"
    disabled: boolean
    handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    iconLeft?: IconProp
    iconRight?: IconProp
    id?: string
    navControlsCustomClass?: string
}

const NavigationControls: FunctionComponent<NavigationControlsInterface> = ({
    disabled,
    usage,
    condition,
    iconLeft,
    iconRight,
    id,
    handleClick,
    navControlsCustomClass,
    children,
}) => {
    const navigationControlClass = classnames(
        "nav-controls-base-class",
        condition === "default"
            ? `${usage}-controls-default`
            : `${usage}-controls-selected`,
        navControlsCustomClass && navControlsCustomClass
    )

    return (
        <>
            <button
                id={id}
                onClick={handleClick}
                className={navigationControlClass}
                disabled={disabled}
            >
                {iconLeft && (
                    <FontAwesomeIcon
                        icon={iconLeft}
                        className={"nav-controls-with-icon-left"}
                    />
                )}
                {children}
                {iconRight && (
                    <FontAwesomeIcon
                        icon={iconRight}
                        className={"nav-controls-with-icon-right"}
                    />
                )}
            </button>
        </>
    )
}

export default NavigationControls
