import { useWellControlsContext } from "../../../context/wellControlsContext"
import NavigationControls from "../../shared/navigationControls"
import styles from "./wellControlsNavigation.module.scss"

const WellControlsNavigation = () => {
    const { wellControlState, handleWellControlSettingChange } =
        useWellControlsContext()

    const { wellControlModalView } = wellControlState.UiState

    return (
        <div className={styles.container}>
            <div className={styles.navControl}>
                <NavigationControls
                    disabled={false}
                    usage={"sub-nav"}
                    condition={
                        wellControlModalView === "Control"
                            ? "selected"
                            : "default"
                    }
                    handleClick={handleWellControlSettingChange}
                    navControlsCustomClass={"wellControlsHistoryNavButton"}
                    id="Control"
                >
                    Controls
                </NavigationControls>
            </div>
            <NavigationControls
                disabled={false}
                usage={"sub-nav"}
                condition={
                    wellControlModalView === "History" ? "selected" : "default"
                }
                handleClick={handleWellControlSettingChange}
                id="History"
            >
                History
            </NavigationControls>
        </div>
    )
}

export default WellControlsNavigation
