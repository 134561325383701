import { IwpSubNavNavControlsI } from "./subNavigationTypes"

const getInitialSelectedNavigationalControl = (
    urlPath: string
): IwpSubNavNavControlsI => {
    const initialPathSegment = urlPath.split("/")[1]

    return {
        wellDetails: initialPathSegment === "wellDetails" ? true : false,
        wellAttributes: initialPathSegment === "wellAttributes" ? true : false,
    }
}

export { getInitialSelectedNavigationalControl }
