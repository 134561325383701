import { graphql } from "../../generated"

export const ADD_ORGANIZATIONS = graphql(`
    mutation OrganizationsAdd($OrganizationsAddInput: OrganizationsAddInput!) {
        organizationsAdd(input: $OrganizationsAddInput) {
            organizations {
                ...OrganizationWithDescendantsFields
            }
        }
    }
`)
