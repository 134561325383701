import { graphql } from "../../generated"

export const ORGANIZATION_WITH_DESCENDANTS_FIELDS_FRAGMENT = graphql(`
    fragment OrganizationWithDescendantsFields on Organization {
        id
        name
        parentID
        active
        descendants {
            id
            name
            parentID
            active
        }
    }
`)
