import { createContext } from "react"
import { NGWebConfigHelper } from "../configuration/configuration"

export interface ConfigWireType {
    environmentStage: string
    graphQLUrl: string
    graphQLWSUrl: string
    awsRegion: string
    awsCognitoUserPoolId: string
    awsCognitoClientId: string
    awsCognitoRedirectURI: string
    awsCognitoIDPURI: string
}

export class Config {
    readonly environmentStage: string
    readonly graphQLUrl: string
    readonly graphQLWSUrl: string
    readonly awsRegion: string
    readonly awsCognitoUserPoolId: string
    readonly awsCognitoClientId: string
    readonly awsCognitoRedirectURI: string
    readonly awsCognitoIDPURI: string

    constructor(c: ConfigWireType) {
        this.environmentStage = c.environmentStage
        this.awsRegion = c.awsRegion
        this.awsCognitoClientId = c.awsCognitoClientId
        this.awsCognitoUserPoolId = c.awsCognitoUserPoolId
        this.graphQLUrl = c.graphQLUrl
        this.graphQLWSUrl = c.graphQLWSUrl
        this.awsCognitoRedirectURI = c.awsCognitoRedirectURI
        this.awsCognitoIDPURI = c.awsCognitoIDPURI
    }
}

export const defaultConfig = new Config({
    environmentStage: "development",
    awsRegion: "us-east-1",
    awsCognitoUserPoolId: "us-east-1_Bct82nkVe",
    awsCognitoClientId: "4v5i0sa2fkan828q641je8qm86",
    graphQLUrl: "http://localhost:9090",
    graphQLWSUrl: "ws://localhost:5002/graphql",
    awsCognitoRedirectURI: "",
    awsCognitoIDPURI: "",
})

export const ConfigContext = createContext<NGWebConfigHelper>(
    {} as NGWebConfigHelper
)
