import {
    AlertSubscriptionAddInput,
    AlertSubscriptionUpdateInput,
    DateTimeInput,
} from "../../../../../generated/graphql"
import { unixMsToUtcDateTimeInput } from "../../../../../util/datesAndTimes/datesAndTimes"
import { getDateNowPlusDuration } from "../../../../../util/duration/duration"
import { ManageSubscriptionFormI, UpdateSubscriptionFormI } from "./types"

const getSubscriptionAddMutationVariables = (
    form: ManageSubscriptionFormI
): AlertSubscriptionAddInput[] => {
    const subscribedWellIds = form.wellOptions
        .filter((well) => well.isSelected)
        .map((well) => well.value)

    const subscription: AlertSubscriptionAddInput = {
        disturb: form.byPassDnd,
        email: form.enableEmail,
        includeNewWells: false,
        phone: form.enableText,
        subscribedWells: subscribedWellIds,
        ruleID: form.rule.value,
        subscriberID: form.subscriber.id,
        // silence is not accessible from front end form but required via api. Set it to a date long in the past
        silenceUntil: {
            day: 1,
            hour: 1,
            minute: 1,
            month: 1,
            year: 1971,
        },
    }
    return [subscription]
}

const getSubscriptionUpdateMutationVariables = (
    form: UpdateSubscriptionFormI
): AlertSubscriptionUpdateInput => {
    // set silence until its initial value
    let silenceUntil: DateTimeInput = unixMsToUtcDateTimeInput(
        form.initialSilenceUnixMs
    )

    // if user has enetered a new silence duration - update silence until
    if (typeof form.silenceForm.timeValue === "number") {
        const updateSilenceDate = getDateNowPlusDuration({
            unit: form.silenceForm.timeUnit,
            value: form.silenceForm.timeValue,
        })

        const updatedSilenceUntilUnixMs = updateSilenceDate.getTime()
        silenceUntil = unixMsToUtcDateTimeInput(updatedSilenceUntilUnixMs)
    }

    const subscribedWellIds = form.wellOptions
        .filter((well) => well.isSelected)
        .map((well) => well.value)

    return {
        disturb: form.byPassDnd,
        email: form.enableEmail,
        id: form.subscriptionId,
        includeNewWells: false,
        phone: form.enableText,
        silenceUntil: silenceUntil,
        subscribedWells: subscribedWellIds,
    }
}

export {
    getSubscriptionAddMutationVariables,
    getSubscriptionUpdateMutationVariables,
}
