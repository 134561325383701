import { capMaxSeconds } from "../../../../util/DataFrameHighChartSeriesUtil"

const updateIncomingRunTimeForWellIndexBarChart = (
    highChartsSeries: (number | null)[][]
) => {
    if (!highChartsSeries) return

    // Following function will mutate the original array
    /** cap max seconds to 86400 (1 day) */
    const updatedHighChartSeries_capMaxSeconds = capMaxSeconds(highChartsSeries)

    return updatedHighChartSeries_capMaxSeconds
}

export { updateIncomingRunTimeForWellIndexBarChart }
