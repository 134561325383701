import { graphql } from "../../generated"

export const SUBSCRIPTION_FIELDS_FRAGMENT = graphql(`
    fragment SubscriptionFields on AlertSubscription {
        id
        email
        phone
        includeNewWells
        disturb
        silenceUntil {
            unixMilliseconds
        }
        subscribedWells {
            ...WellIdentificationFields
        }
        subscriber {
            ...SubjectFields
        }
        createdBy {
            ...SubjectFields
        }
        alertRule {
            ...AlertRuleFields
        }
    }
`)

export const SUBSCRIPTION_FRAG_FOR_TRANSFER_SUB_TO_USER = graphql(`
    fragment SubscriptionFragForTransferSubToUser on AlertSubscription {
        id
        alertRule {
            id
        }
        subscriber {
            id
            identity {
                fullName
            }
        }
        includeNewWells
        subscribedWells {
            id
            name
        }
    }
`)

export const SUBSCRIPTION_FIELDS_FOR_ADMIN_SUBSCRIPTION_TABLES = graphql(`
    fragment SubscriptionFieldsForAdminSubscriptionTables on AlertSubscription {
        id
        alertRule {
            id
            name
            metric {
                displayName
            }
        }
        phone
        email
        subscriber {
            id
            identity {
                fullName
                emailVerified
                email
                emailConsent
                telephoneNumber {
                    e164
                }
                telephoneNumberVerified
                smsConsent
            }
        }
        subscribedWells {
            name
            id
        }
        silenceUntil {
            unixMilliseconds
        }
    }
`)
