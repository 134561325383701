import { graphql } from "../../generated"

export const WELL_UPDATE_WIRING_MODE = graphql(`
    mutation WellUpdateWiringMode(
        $WellUpdateWiringModeInput: WellUpdateWiringModeInput!
    ) {
        wellUpdateWiringMode(input: $WellUpdateWiringModeInput) {
            well {
                ...WellIdentificationFields
                installation {
                    ...InstallationFields
                }
            }
        }
    }
`)
