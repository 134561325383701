import React, { FunctionComponent } from "react"
import styles from "./tableBuilder.module.scss"
import classNames from "classnames"

interface TableBuilderPropsInterface {
    children: React.ReactNode
    tableActionsHeader?: () => JSX.Element // Component that houses the component commonly used above components like add well, add user. etc
    tablePagination?: () => JSX.Element
    tableHeader?: () => JSX.Element
    tableComponent: "WellIndex" | "Admin"
}

const TableBuilder: FunctionComponent<TableBuilderPropsInterface> = ({
    children,
    tableActionsHeader: tableActionsHeader,
    tablePagination,
    tableHeader,
    tableComponent,
}) => {
    /** Build classes */
    const OuterTableWrapperClass = classNames(
        styles.OuterTableWrapper,
        styles[`${tableComponent}OuterTableWrapper`]
    )

    return (
        <div className={styles.pageContainer}>
            <div className={OuterTableWrapperClass}>
                {tableActionsHeader && tableActionsHeader()}
                <div
                    id="tableBuilderOverFlowWrapper"
                    className={styles.overflowWrapper}
                >
                    <table className={styles.tableWrapper}>
                        <thead>{tableHeader && tableHeader()}</thead>
                        {children}
                    </table>
                </div>
                {tablePagination && tablePagination()}
            </div>
        </div>
    )
}

export default TableBuilder
