import React, { FunctionComponent, useEffect, useState } from "react"
import styles from "./updateUserModal.module.scss"
import ModalHeader, {
    ModalHeaderComponentInterface,
} from "../shared/modalHeader"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalFooter, {
    ModalFooterComponentInterface,
} from "../shared/modalFooter"
import {
    UpdateUserFormStateI,
    UpdateUserLocationStateI,
    UpdateUserModalStateInterface,
} from "./updateUserModalTypes"
import {
    disableSubmitUpdateUser,
    getFormMutationInputs,
} from "./updateUserModalUtil"
import { useMutation } from "@apollo/client"
import { UPDATE_USERS_ADMIN } from "../../graphql/mutations/update_users"
import Alert from "../shared/alert"
import { ACTIVATE_USER } from "../../graphql/mutations/activate_users"
import { DEACTIVATE_USER } from "../../graphql/mutations/deactivate_users"
import UpdateUserBody from "./UpdateUserBody"
import { TrexNavigator } from "../../classes/navigator/navigator"
import { SubjectRole } from "../../generated/graphql"
import { USERS_UPDATE_ORG_MEMBERSHIP } from "../../graphql/mutations/user_organization_membership_update"
import { GET_USERS } from "../../graphql/queries/users"

const UpdateUserModal: FunctionComponent = () => {
    /** Hooks */
    const navigate = useNavigate()
    const location = useLocation()
    const { userId } = useParams()
    if (!userId) {
        throw new Error("")
    }
    const locationState = location.state as UpdateUserLocationStateI
    let initialFormState = {} as UpdateUserFormStateI

    // constants
    const organizationName = locationState ? locationState.organizationName : ""
    const email = locationState ? locationState.email : ""

    if (locationState === null) {
        initialFormState = {
            firstName: "",
            lastName: "",
            userRole: SubjectRole.Unknown,
            activeUser: false,
        }
    } else {
        initialFormState = {
            firstName: locationState.firstName || "",
            lastName: locationState.lastName || "",
            userRole: locationState.userRole || "",
            activeUser: locationState.activeUser,
        }
    }

    /** State */
    const [updateUserModalState, setUpdateUserModalState] =
        useState<UpdateUserModalStateInterface>({
            disableSubmitUpdate: true,
            updateUser: initialFormState,
        })
    const [updateUserErrorBanner, setUpdateUserErrorBanner] = useState(false)

    /** Use Effect */
    useEffect(() => {
        if (locationState === null) {
            navigate("/admin/users")
        }
    }, [locationState])

    /** Mutations */
    const [updateUserMutation, { loading: updateUserMutationLoading }] =
        useMutation(UPDATE_USERS_ADMIN)

    const [
        usersUpdateOrganizationMemberships,
        { loading: userUpdateOrganizationMembershipsLoading },
    ] = useMutation(USERS_UPDATE_ORG_MEMBERSHIP)

    const [activateUser, { loading: activateUserMutationLoading }] =
        useMutation(ACTIVATE_USER, { refetchQueries: [GET_USERS] })

    const [deactivateUser, { loading: deactivateUserMutationLoading }] =
        useMutation(DEACTIVATE_USER, { refetchQueries: [GET_USERS] })

    /** Input Handlers */
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target

        const updatedUser: UpdateUserFormStateI = {
            ...updateUserModalState.updateUser,
            [name]: value,
        }

        const disableSubmit = disableSubmitUpdateUser(
            updatedUser,
            initialFormState
        )

        setUpdateUserModalState(() => ({
            updateUser: updatedUser,
            disableSubmitUpdate: disableSubmit,
        }))
    }

    const handleChangeUserActivationStatus = () => {
        let updatedActiveUserStatus: boolean

        if (updateUserModalState.updateUser.activeUser) {
            updatedActiveUserStatus = false
        } else {
            updatedActiveUserStatus = true
        }

        const updatedUser: UpdateUserFormStateI = {
            ...updateUserModalState.updateUser,
            activeUser: updatedActiveUserStatus,
        }

        const disableSubmit = disableSubmitUpdateUser(
            updatedUser,
            initialFormState
        )

        setUpdateUserModalState(() => ({
            updateUser: updatedUser,
            disableSubmitUpdate: disableSubmit,
        }))
    }

    // Submit Handler
    const handleSubmitUpdateUser = async () => {
        const { updateUser } = updateUserModalState

        const mutationInputs = getFormMutationInputs(
            initialFormState,
            updateUser,
            userId
        )

        try {
            const mutationPromises = []
            if (mutationInputs.usersActivateInput) {
                const activatePromise = activateUser({
                    variables: {
                        UsersActivateInput: mutationInputs.usersActivateInput,
                    },
                })
                mutationPromises.push(activatePromise)
            }

            if (mutationInputs.usersDeactivateInput) {
                const deactivatePromise = deactivateUser({
                    variables: {
                        UsersDeactivateInput:
                            mutationInputs.usersDeactivateInput,
                    },
                })
                mutationPromises.push(deactivatePromise)
            }

            if (mutationInputs.usersUpdateAdminInput) {
                const updateUserPromise = updateUserMutation({
                    variables: {
                        UsersUpdateAdmin: mutationInputs.usersUpdateAdminInput,
                    },
                })
                mutationPromises.push(updateUserPromise)
            }

            if (mutationInputs.usersUpdateOrgMemInput) {
                const updateOrgMemPromise = usersUpdateOrganizationMemberships({
                    variables: {
                        UsersUpdateOrganizationMembershipInput:
                            mutationInputs.usersUpdateOrgMemInput,
                    },
                })
                mutationPromises.push(updateOrgMemPromise)
            }

            await Promise.all(mutationPromises)
            navigate(-1)
        } catch (error) {
            console.error("Update user modal mutation error", error)
            setUpdateUserErrorBanner(true)
        }
    }

    /** Header and footer props */
    const modalHeaderProps: ModalHeaderComponentInterface = {
        title: "Update User",
        trexNavigator: new TrexNavigator(
            {
                navigateTo: -1,
                locationKey: location.key,
                pathName: location.pathname,
            },
            navigate
        ),
    }

    const modalFooterProps: ModalFooterComponentInterface = {
        advanceText: "Save",
        disableAdvance:
            updateUserModalState.disableSubmitUpdate ||
            updateUserMutationLoading ||
            activateUserMutationLoading ||
            deactivateUserMutationLoading ||
            userUpdateOrganizationMembershipsLoading ||
            updateUserErrorBanner,
        handleAdvanceClick: handleSubmitUpdateUser,
    }

    return (
        <div className={styles.container}>
            <ModalHeader {...modalHeaderProps} />
            <UpdateUserBody
                handleInputChange={handleInputChange}
                handleChangeUserActivationStatus={
                    handleChangeUserActivationStatus
                }
                modalState={updateUserModalState}
                initialFormState={initialFormState}
                organizationName={organizationName}
                email={email}
            />
            {updateUserErrorBanner && (
                <Alert
                    isCloseable={false}
                    message={
                        <div>
                            There was a problem updating the user. Click{" "}
                            <u
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/admin/users")}
                            >
                                here
                            </u>{" "}
                            or close the modal to be redirected to the admin
                            page.
                        </div>
                    }
                    alertType="danger"
                />
            )}
            <ModalFooter {...modalFooterProps} />
        </div>
    )
}

export default UpdateUserModal
