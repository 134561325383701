import { WellUpdateAttributesMutationVariables } from "../../../generated/graphql"
import { getApiNumberFromWellAttributes } from "../../../util/wellAttributes"
import {
    getApiNumberSubmission,
    getLocationSubmission,
} from "../../../util/wellAttributes/formSubmission"
import { WellAttributesFormI } from "../../../util/wellAttributes/wellAttributesTypes"

const getInitialWellAttributesFormFromLocationState = (
    locationState: WellAttributesFormI | undefined
): WellAttributesFormI => {
    if (locationState) {
        return {
            apiNumber: locationState.apiNumber,
            Latitude: locationState.Latitude,
            Longitude: locationState.Longitude,
            WellName: locationState.WellName,
        }
    } else {
        return {
            apiNumber: "",
            Latitude: 0,
            Longitude: 0,
            WellName: "",
        }
    }
}

const formateWellUpdateAttributesVariables = (
    wellId: string,
    updatedWellAttributers: WellAttributesFormI
): WellUpdateAttributesMutationVariables => {
    const variables = {
        WellUpdateAttributesInput: {
            id: wellId,
            apiNumber: getApiNumberSubmission(updatedWellAttributers.apiNumber),
            location: getLocationSubmission(
                updatedWellAttributers.Latitude,
                updatedWellAttributers.Longitude
            ),
            name: updatedWellAttributers.WellName,
        },
    } as WellUpdateAttributesMutationVariables
    return variables
}

const getNoApiNumberCheckBoxStatus = (
    wellAttributesForm: WellAttributesFormI
) => {
    const { apiUnknown } = getApiNumberFromWellAttributes(wellAttributesForm)

    if (apiUnknown) {
        return true
    } else {
        return false
    }
}

export {
    getInitialWellAttributesFormFromLocationState,
    formateWellUpdateAttributesVariables,
    getNoApiNumberCheckBoxStatus,
}
