import { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./tableRows.module.scss"
import {
    UserDataInterface,
    UserTableRowsInterface,
} from "../../../pages/admin/users/userTableTypes"
import {
    OrganizationDataInterface,
    OrganizationTableRowsInterface,
} from "../../../pages/admin/organizations/organizationTableTypes"

const TableRows: FunctionComponent<
    UserTableRowsInterface | OrganizationTableRowsInterface
> = ({ data, config }) => {
    const renderedRows = data.map((rowData) => {
        const renderedCells = config.map((column) => {
            const cellCustomClass = column.cellCustomClass
            const cellClass = classNames(
                styles.tableCellClass,
                cellCustomClass && styles[cellCustomClass]
            )
            return (
                <td className={cellClass} key={column.id} id={column.id}>
                    <span className={styles.tdCellSpan}>
                        {column.render(
                            rowData as UserDataInterface &
                                OrganizationDataInterface
                        )}
                    </span>
                </td>
            )
        })

        const customRowClass = rowData.customTableRowClass
        const rowClass = classNames(
            styles.tableRowClass,
            customRowClass && styles[customRowClass]
        )

        return (
            <tr className={rowClass} key={rowData.id}>
                {renderedCells}
            </tr>
        )
    })

    return <tbody>{renderedRows}</tbody>
}

export default TableRows
