import { graphql } from "../../generated"

export const NOTIFICATION_MARK_AS_READ = graphql(`
    mutation NotificationMarkAsRead($input: AlertNotificationMarkAsReadInput!) {
        notificationMarkAsRead(input: $input) {
            id
        }
    }
`)

export const NOTIFICATION_MARK_ALL_AS_READ = graphql(`
    mutation NotificationMarkAllRead {
        notificationsMarkAllRead {
            ids
        }
    }
`)
