import { FunctionComponent } from "react"
import styles from "./updateWellAttributesModal.module.scss"
import Input from "../../shared/input"
import Alert from "../../shared/alert"
import { Checkbox } from "../../shared/selectionControls"
import { UpdateWellAttributesComponentI } from "./updateWellAttributesModalTypes"
import { getApiNumberFromWellAttributes } from "../../../util/wellAttributes"

interface UpdateWellAttributesFormI extends UpdateWellAttributesComponentI {
    handleFormChange: (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => void
    handleInputBlur: () => void
    updateWellAttributesFormErrorBanner: boolean
}

const UpdateWellAttributesForm: FunctionComponent<
    UpdateWellAttributesFormI
> = ({
    wellAttributesForm,
    wellAttributesFormErrors,
    handleInputBlur,
    handleFormChange,
    updateWellAttributesFormErrorBanner,
}) => {
    const { apiUnknown, apiNumberFormatted } =
        getApiNumberFromWellAttributes(wellAttributesForm)

    return (
        <div className={styles.bodyContainer}>
            <Input
                orientation="vertical"
                onBlur={handleInputBlur}
                type="text"
                value={wellAttributesForm.WellName}
                onChange={handleFormChange}
                label="Well Name *"
                disabled={false}
                name="WellName"
                message={wellAttributesFormErrors.WellName}
                specializedClass={
                    wellAttributesFormErrors.WellName ? "danger" : "default"
                }
            />
            <Input
                orientation="vertical"
                onBlur={handleInputBlur}
                type="text"
                value={apiNumberFormatted}
                onChange={handleFormChange}
                label="API Number *"
                disabled={apiUnknown}
                name="apiNumber"
                message={wellAttributesFormErrors.apiNumber}
                specializedClass={
                    wellAttributesFormErrors.apiNumber ? "danger" : "default"
                }
            />
            <div className={styles.noApiRow}>
                <Checkbox
                    checkboxLabel={"Well Has No API #"}
                    checked={apiUnknown ? true : false}
                    handleCheckBoxClick={handleFormChange}
                />
            </div>

            <div className={styles.latLongRow}>
                <div className={styles.latitudeInput}>
                    <Input
                        orientation="vertical"
                        onBlur={handleInputBlur}
                        type="number"
                        value={wellAttributesForm.Latitude}
                        onChange={handleFormChange}
                        label="Latitude *"
                        inputCustomClass="add-well-action-panel-coordinate-input"
                        disabled={false}
                        name="Latitude"
                        message={wellAttributesFormErrors.Latitude}
                        specializedClass={
                            wellAttributesFormErrors.Latitude
                                ? "danger"
                                : "default"
                        }
                    />
                </div>
                <div>
                    <Input
                        orientation="vertical"
                        onBlur={handleInputBlur}
                        type="number"
                        value={wellAttributesForm.Longitude}
                        onChange={handleFormChange}
                        label="Longitude *"
                        inputCustomClass="add-well-action-panel-coordinate-input"
                        disabled={false}
                        name="Longitude"
                        message={wellAttributesFormErrors.Longitude}
                        specializedClass={
                            wellAttributesFormErrors.Longitude
                                ? "danger"
                                : "default"
                        }
                    />
                </div>
            </div>
            {updateWellAttributesFormErrorBanner && (
                <Alert
                    isCloseable={false}
                    message={
                        <div>
                            There was a problem updating your well&apos;s
                            attribute. Close the modal to be redirected back to
                            the attributes page.
                        </div>
                    }
                    alertType="danger"
                />
            )}
        </div>
    )
}

export default UpdateWellAttributesForm
