import { FunctionComponent, ReactNode, useState } from "react"
import { NavigateOptions, To, useLocation, useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { SUBSCRIPTION_ADD } from "../../../../../../graphql/mutations/subscription_add"
import styles from "./moveSubscriptionToUser.module.scss"
import { subscriptionsToAlertSubAddInput } from "../transferSubscriptionFormUtil/api"
import { GET_SUBSCRIPTIONS_FOR_ORG } from "../../../../../../graphql/queries/subscriptionsForOrg"
import { useNotificationsContext } from "../../../../context/notificationsContext"
import { navigateAfterMutation } from "../../../../navigate"
import {
    TransferSubscriptionFormType,
    TransferSubscriptionFormContainerComponentI,
} from "../transferSubscriptionFormUtil/types"
import { SUBSCRIPTIONS_DELETE } from "../../../../../../graphql/mutations/subscriptions_delete"
import TransferSubscriptionForm from "../form/form"
import { GET_SUBSCRIPTIONS_FOR_USER } from "../../../../../../graphql/queries/subscriptionsForUser"

const MoveToUserFormContainer: FunctionComponent<
    TransferSubscriptionFormContainerComponentI
> = ({ toUserOptions, subsToTransfer }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const notificationsContext = useNotificationsContext()

    // state
    const [moveToUser, setMoveToUser] =
        useState<TransferSubscriptionFormType>(undefined)
    const [mutationErrorBanner, setMutationErrorBanner] = useState<{
        showErrorBanner: boolean
        errMessage: ReactNode
    }>({
        showErrorBanner: false,
        errMessage: "",
    })

    // mutations
    const [subscriptionAdd, { loading: addSubscriptionLoading }] =
        useMutation(SUBSCRIPTION_ADD)

    const [subscriptionDelete, { loading: deleteSubscriptionLoading }] =
        useMutation(SUBSCRIPTIONS_DELETE)

    // handlers
    // to move a subscription - first add it to the new user, then remove it from the old user
    const handleSubmitMove = () => {
        if (!moveToUser) {
            // submit is disabled if no user is selected
            return
        }

        const addSubscriptionsInput = subscriptionsToAlertSubAddInput(
            subsToTransfer,
            moveToUser.value
        )

        subscriptionAdd({
            variables: {
                AlertSubscriptionsAddInput: { inputs: addSubscriptionsInput },
            },
        })
            .then(() => {
                const deleteSubscriptionIds = subsToTransfer.map(
                    (sub) => sub.id
                )

                subscriptionDelete({
                    variables: {
                        AlertSubscriptionsDeleteInput: {
                            ids: deleteSubscriptionIds,
                        },
                    },
                    refetchQueries: [
                        GET_SUBSCRIPTIONS_FOR_USER,
                        {
                            query: GET_SUBSCRIPTIONS_FOR_ORG,
                            variables: {
                                input: {
                                    orgIDs: [
                                        notificationsContext
                                            .selectedOrganization.value,
                                    ],
                                },
                            },
                        },
                    ],
                })
                    .then(() => {
                        navigate(navTo.to, navTo.options)
                    })
                    .catch((e) => {
                        console.error(e)
                        const s =
                            subsToTransfer.length > 1
                                ? "subscriptions"
                                : "subscription"
                        const errMsg = `There was a problem moving the ${s}. Subscriptions were added to ${moveToUser.label} but not deleted from ${fromUserName}.`
                        setMutationErrorBanner({
                            showErrorBanner: true,
                            errMessage: getMutationErrorMessage(
                                errMsg,
                                navTo,
                                navigate
                            ),
                        })
                    })
            })
            .catch((e) => {
                console.error(e)

                const s =
                    subsToTransfer.length > 1 ? "subscriptions" : "subscription"
                const errMsg = `There was a problem moving the ${s}. Subscriptions were not added to ${moveToUser.label} or deleted from ${fromUserName}.`
                setMutationErrorBanner({
                    showErrorBanner: true,
                    errMessage: getMutationErrorMessage(
                        errMsg,
                        navTo,
                        navigate
                    ),
                })
            })
    }

    // constants
    const navTo = navigateAfterMutation(pathname)
    const subscriptionsCount = subsToTransfer.length
    // Remove user from transferToOptions if the user is a subscriber on the subscription(s)
    const filteredToUserOptions = toUserOptions.filter((toUser) => {
        return (
            subsToTransfer.findIndex(
                (s) => s.subscriber.id === toUser.value
            ) === -1
        )
    })
    const s = subsToTransfer.length > 1 ? "subscriptions" : "subscription"
    const formDescription = (
        <>
            {subscriptionsCount} {s} will be{" "}
            <span className={styles.bodyBoldText}>moved to</span> the below
            user:
        </>
    )
    const fromUserName =
        (subsToTransfer && subsToTransfer[0].subscriber.identity?.fullName) ||
        ""

    return (
        <TransferSubscriptionForm
            toUserOptions={filteredToUserOptions}
            selectedUser={moveToUser}
            mutationLoading={
                addSubscriptionLoading || deleteSubscriptionLoading
            }
            mutationErrorBanner={mutationErrorBanner}
            formDescription={formDescription}
            handleSubmitTransfer={handleSubmitMove}
            handleSetSelectedUser={(e) => {
                if (!e) {
                    setMoveToUser(undefined)
                }
                if (e) {
                    setMoveToUser({ label: e?.label, value: e?.value })
                }
            }}
        />
    )
}

export default MoveToUserFormContainer

const getMutationErrorMessage = (
    errorMessage: string,
    navTo: {
        to: To
        options?: NavigateOptions
    },
    navigate: (to: To, options?: NavigateOptions) => void
): ReactNode => {
    return (
        <div className={styles.errorMessageContainer}>
            <div className={styles.errorMessage}>{errorMessage}</div>
            <div>
                Click{" "}
                <u
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(navTo.to, navTo.options)}
                >
                    here
                </u>{" "}
                or close the modal to be redirected.
            </div>
        </div>
    )
}
