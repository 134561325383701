import {
    UsersActivateInput,
    UsersDeactivateInput,
    UsersUpdateOrganizationMembershipInput,
    UsersUpdateAdminInput,
} from "../../generated/graphql"
import _ from "lodash"
import {
    UpdateUserModalMutationInputs,
    UpdateUserFormStateI,
} from "./updateUserModalTypes"

const disableSubmitUpdateUser = (
    updatedUser: UpdateUserFormStateI,
    initialState: UpdateUserFormStateI
) => {
    /** Disable if first or last name is empty */
    const { firstName, lastName } = updatedUser
    if (firstName.length === 0 || lastName.length === 0) return true
    /** Disable if the current state is equal to the initial state */
    const isInitialState = _.isEqual(updatedUser, initialState)

    if (isInitialState) {
        return true
    }
    return false
}

const getFormMutationInputs = (
    initialState: UpdateUserFormStateI,
    updatedState: UpdateUserFormStateI,
    userId: string
): UpdateUserModalMutationInputs => {
    const usersUpdateAdminInput = getUsersUpdateAdminInput(
        initialState,
        updatedState,
        userId
    )
    const usersUpdateOrgMemInput = getUsersUpdateOrgMemInput(
        initialState,
        updatedState,
        userId
    )
    const activationInputs = getUsersActivationInputs(
        initialState,
        updatedState,
        userId
    )

    return {
        usersUpdateAdminInput: usersUpdateAdminInput,
        usersUpdateOrgMemInput: usersUpdateOrgMemInput,
        usersActivateInput: activationInputs.activateInput,
        usersDeactivateInput: activationInputs.deactivateInput,
    }
}

const getUsersActivationInputs = (
    initialState: UpdateUserFormStateI,
    updatedState: UpdateUserFormStateI,
    userId: string
): {
    activateInput: UsersActivateInput | undefined
    deactivateInput: UsersDeactivateInput | undefined
} => {
    const intialStatus = initialState.activeUser
    const updatedStatus = updatedState.activeUser

    if (intialStatus === updatedStatus) {
        return {
            activateInput: undefined,
            deactivateInput: undefined,
        }
    }

    if (updatedStatus) {
        const activateInput: UsersActivateInput = {
            userIDs: [userId],
        }
        return {
            activateInput: activateInput,
            deactivateInput: undefined,
        }
    } else {
        const deactivateInput: UsersDeactivateInput = {
            userIDs: [userId],
        }
        return {
            activateInput: undefined,
            deactivateInput: deactivateInput,
        }
    }
}

const getUsersUpdateOrgMemInput = (
    initialState: UpdateUserFormStateI,
    updatedState: UpdateUserFormStateI,
    userId: string
): UsersUpdateOrganizationMembershipInput | undefined => {
    const roleUpdated = initialState.userRole !== updatedState.userRole

    if (roleUpdated) {
        const userOrgMembershipVariablesInput: UsersUpdateOrganizationMembershipInput =
            {
                users: [
                    {
                        userID: userId,
                        role: updatedState.userRole,
                    },
                ],
            }

        return userOrgMembershipVariablesInput
    }

    return undefined
}

const getUsersUpdateAdminInput = (
    initialState: UpdateUserFormStateI,
    updatedState: UpdateUserFormStateI,
    userId: string
): UsersUpdateAdminInput | undefined => {
    const firstNameUpdated = initialState.firstName !== updatedState.firstName
    const lastNameUpdated = initialState.lastName !== updatedState.lastName

    if (firstNameUpdated || lastNameUpdated) {
        const uuInput: UsersUpdateAdminInput = {
            users: [
                {
                    userID: userId,
                },
            ],
        }

        if (firstNameUpdated) {
            uuInput.users[0].firstName = updatedState.firstName
        }

        if (lastNameUpdated) {
            uuInput.users[0].lastName = updatedState.lastName
        }

        const userVariablesInput: UsersUpdateAdminInput = uuInput

        return userVariablesInput
    }

    return undefined
}

export { disableSubmitUpdateUser, getFormMutationInputs }
