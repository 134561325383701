import { ApiNumberInput, GeoLocationInput } from "../../generated/graphql"

const getApiNumberSubmission = (apiNumber: string | false): ApiNumberInput => {
    if (typeof apiNumber === "string") {
        return {
            by: { format: apiNumber },
        }
    } else {
        return {
            by: { unknown: true },
        }
    }
}

const getLocationSubmission = (
    latitude: number,
    longitude: number
): GeoLocationInput => {
    return {
        by: {
            coordinates: {
                latitude: latitude,
                longitude: longitude,
            },
        },
    }
}

export { getApiNumberSubmission, getLocationSubmission }
