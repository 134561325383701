import { FunctionComponent } from "react"
import Input from "../shared/input"
import styles from "./updateOrganizationBody.module.scss"
import { Toggle } from "../shared/selectionControls"
import Select, { GroupBase, StylesConfig } from "react-select"
import { OrganizationOptionType } from "../../types/ViewerTypes"
import { UpdateOrganizationModalStateInterface } from "../../types/adminOrganizationTypes/updateOrganizationModalTypes"

interface UpdateOrganizatioBodyComponentInterface {
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void
    modalState: UpdateOrganizationModalStateInterface
    handleChangeOrganizationActivationStatus: () => void
    organizations: OrganizationOptionType[]
    handleParentOrgChange: (value: OrganizationOptionType | null) => void
}

const UpdateOrganizationBody: FunctionComponent<
    UpdateOrganizatioBodyComponentInterface
> = ({
    handleInputChange,
    handleChangeOrganizationActivationStatus,
    modalState,
    organizations,
    handleParentOrgChange,
}) => {
    const {
        organizationState: {
            parentOrganizationId,
            organizationName,
            organizationActivationStatus,
        },
    } = modalState

    return (
        <>
            <div className={styles.bodyContainer}>
                <Input
                    orientation="vertical"
                    type="text"
                    value={organizationName}
                    label="Organization Name *"
                    disabled={false}
                    name="organizationName"
                    onChange={handleInputChange}
                />
                {parentOrganizationId && (
                    <div>
                        <div className={styles.parentOrganizationIdLabel}>
                            Parent Organization ID
                        </div>
                        <Select
                            options={organizations}
                            isSearchable={true}
                            onChange={handleParentOrgChange}
                            value={parentOrganizationId}
                            styles={reactSelectStyles}
                            placeholder={"- Select -"}
                        />
                    </div>
                )}
                <div className={styles.activationContainer}>
                    <Toggle
                        checked={organizationActivationStatus}
                        handleToggleClick={
                            handleChangeOrganizationActivationStatus
                        }
                        toggleLabel=""
                    />
                    <span>
                        <span className={styles.labelFont}>Organization :</span>
                        {` ${
                            organizationActivationStatus
                                ? "Activate"
                                : "Inactive"
                        } `}
                    </span>
                </div>
            </div>
        </>
    )
}

export default UpdateOrganizationBody

const reactSelectStyles: StylesConfig<
    OrganizationOptionType,
    false,
    GroupBase<OrganizationOptionType>
> = {
    control: (provided, state) => ({
        ...provided,
        boxSizing: "border-box",
        outline: "none",
        padding: ".0 .25rem",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: state.isFocused ? "#64b5f6" : "#cfd8dc",
        borderRadius: "4px",
        backgroundColor: "white",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#37474f",
        height: "32px",
        boxShadow: state.isFocused
            ? "0px 0px 0px 3.2px rgba(0, 123, 255, 0.25)"
            : "none",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? "#64b5f6"
            : state.isFocused
            ? "#f5f5f5"
            : "transparent",

        color: state.isSelected ? "white" : "inherit",
    }),
}
