import { graphql } from "../../generated"

export const DATA_FRAME_FIELDS_FRAGMENT = graphql(`
    fragment DataFrameFields on Dataframe {
        data
        index
        columns
        highchartSeries
    }
`)
