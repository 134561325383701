import { NotficationFragMyNotificationsFragment } from "../../../../../generated/graphql"
import { TableStateType } from "../../../../table/tableTypes"
import { getTableStateFromUrlParams } from "../../../../table/tableUtil"
import {
    METRIC_DISPLAY_NAME_COLUMN_ID,
    WELL_NAME_COLUMN_ID,
} from "./column/column"
import {
    MyNotificationsTableDataI,
    MyNotificationsUrlToStateMappings,
} from "./tableTypes"

const getMyNotificationsTableFromNotificationFrag = (
    notifications: NotficationFragMyNotificationsFragment[]
): MyNotificationsTableDataI[] => {
    return notifications.map((n) => {
        const event = n.event

        return {
            id: n.id,
            notificationRead: n.read,
            ruleName: event.alertRule.name,
            metricDisplayName: event.alertRule.metric.displayName,
            alertRuleMetric: event.alertRule.metric.metric,
            wellName: event.well.name,
            wellId: event.well.id,
            eventTriggerTime: event.triggeredTime.unixMilliseconds,
            eventResolvedTime: event?.resolvedTime?.unixMilliseconds,
            subscriptionId: n.subscriptionID,
            alertTriggerValue: event.triggeredValue,
            alertRuleConditionUnit: event.alertRule.condition.unit,
            alertMetricThreshold: n.threshold,
            alertMetricThreshold2: event.alertRule.condition.threshold2, //this is a temporary way to get threshold2 , we need to get threshold 2 off the notifications table once it is added to the backend
            alertRuleOperator: event.alertRule.condition.operator,
            notificationSilenced: n.silenced,
            alertRuleId: event.alertRule.id,
        }
    })
}

const myNotificationsPageSizeSelectorArray = [15, 25, 50]

const myNotificationsTableUrlMapping: MyNotificationsUrlToStateMappings = {
    w: (value: string, state: TableStateType) => {
        state.filter.push({ id: WELL_NAME_COLUMN_ID, value })
    },
    [WELL_NAME_COLUMN_ID]: (value: string, state: TableStateType) => {
        state.filter.push({ id: WELL_NAME_COLUMN_ID, value })
    },
    [METRIC_DISPLAY_NAME_COLUMN_ID]: (value: string, state: TableStateType) => {
        state.filter.push({ id: METRIC_DISPLAY_NAME_COLUMN_ID, value })
    },
}

const getMyNotificationsStateFromUrlParams = (
    searchParams: URLSearchParams,
    customMapping: MyNotificationsUrlToStateMappings
): TableStateType => {
    const defaultState: TableStateType = {
        pageIndex: 0,
        size: myNotificationsPageSizeSelectorArray[0],
        sorting: [],
        search: "",
        filter: [],
    }

    return getTableStateFromUrlParams(
        searchParams,
        defaultState,
        myNotificationsPageSizeSelectorArray,
        customMapping
    )
}

export {
    getMyNotificationsTableFromNotificationFrag,
    getMyNotificationsStateFromUrlParams,
    myNotificationsPageSizeSelectorArray,
    myNotificationsTableUrlMapping,
}
