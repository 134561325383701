import { FunctionComponent } from "react"
import Select, { SingleValue } from "react-select"
import styles from "./form.module.scss"
import { AlertRuleMetricDefinitionFieldsFragment } from "../../../../../generated/graphql"
import Tooltip from "../../../../shared/toolTip/tooltip"
import {
    getMetricOptionsFromMetrics,
    LabelMetricEnumOptionType,
} from "../alerts_GraphQL/alertRuleMetricFragment"
import { baseReactSelectStyles } from "../../../../../styles/reactSelect"
import { LabelValueOptionType } from "../../../../../types/commonTypes"

interface ManageAlertRuleMetricSelectProps {
    disabled: boolean
    alertRuleMetric: AlertRuleMetricDefinitionFieldsFragment | undefined
    metricOptions: LabelMetricEnumOptionType[]
    handleMetricChange:
        | ((e: SingleValue<LabelValueOptionType>) => void)
        | undefined
}

const ManageAlertRuleMetricSelect: FunctionComponent<
    ManageAlertRuleMetricSelectProps
> = ({ disabled, alertRuleMetric, metricOptions, handleMetricChange }) => {
    let component: JSX.Element
    const select: JSX.Element = (
        <Select
            options={metricOptions}
            isSearchable={true}
            styles={baseReactSelectStyles}
            placeholder={"- Select -"}
            value={
                alertRuleMetric
                    ? getMetricOptionsFromMetrics([alertRuleMetric])
                    : undefined
            }
            onChange={handleMetricChange}
            isDisabled={disabled}
            isClearable={true}
        />
    )
    if (disabled && alertRuleMetric) {
        component = (
            <Tooltip
                tooltipContent={
                    <>{alertRuleMetric && alertRuleMetric.displayName}</>
                }
                triggerChildren={<div style={{ width: "100%" }}>{select}</div>}
                side="bottom"
                renderDelay={0}
            />
        )
    } else {
        component = select
    }

    return (
        <>
            <div className={styles.metricInput}>
                {component}
                <div
                    className={
                        disabled
                            ? styles.metricDescriptionDisabled
                            : styles.metricDescription
                    }
                >
                    {alertRuleMetric?.description}
                </div>
            </div>
        </>
    )
}

export default ManageAlertRuleMetricSelect
