import { FunctionComponent, ReactNode } from "react"
import { SyncLoader } from "react-spinners"
import Alert from "../alert"

interface SyncLoaderComponentI {
    outSideDivStyle?: string
    speedMultiplier?: number
    size?: number
    color?: string
}

const SyncLoaderComponent: FunctionComponent<SyncLoaderComponentI> = ({
    outSideDivStyle,
    speedMultiplier = 0.75,
    size = 13,
    color = "#1e88e5",
}) => {
    return (
        <div className={outSideDivStyle}>
            <SyncLoader
                speedMultiplier={speedMultiplier}
                color={color}
                size={size}
            />
        </div>
    )
}

interface ErrorMessageI {
    message?: string
    outSideDivStyle?: string
}

const ErrorMessage: FunctionComponent<ErrorMessageI> = ({
    message,
    outSideDivStyle,
}) => {
    return <div className={outSideDivStyle}>{message}</div>
}

interface EmptyStateI {
    outSideDivStyle?: string
    children: ReactNode
}

const EmptyState: FunctionComponent<EmptyStateI> = ({
    outSideDivStyle,
    children,
}) => {
    return <div className={outSideDivStyle}>{children}</div>
}

interface MutationErrorBannerProps {
    message: ReactNode
}

const MutationErrorBanner: FunctionComponent<MutationErrorBannerProps> = ({
    message,
}) => {
    return <Alert alertType="danger" isCloseable={false} message={message} />
}

export default MutationErrorBanner

export { SyncLoaderComponent, ErrorMessage, EmptyState, MutationErrorBanner }
