import { getFragmentData } from "../../../../../generated"
import {
    AlertSubscriptionUpdateInput,
    SubscriptionFieldsFragment,
} from "../../../../../generated/graphql"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/wellIdentification"
import { unixMsToUtcDateTimeInput } from "../../../../../util/datesAndTimes/datesAndTimes"
import { getDateNowPlusDuration } from "../../../../../util/duration/duration"
import { ManageSilenceFormI } from "./types"

const getManageSilenceMutationValues = (
    manageSilenceForm: ManageSilenceFormI,
    subscription: SubscriptionFieldsFragment
): AlertSubscriptionUpdateInput => {
    // we will disable the button if the time value is not set, and getManageSilenceMutationValues will not be called.
    if (typeof manageSilenceForm.timeValue === "string") {
        throw new Error(
            "getManageSilenceMutationValues called with time value being set."
        )
    }

    const updateSilenceDate = getDateNowPlusDuration({
        unit: manageSilenceForm.timeUnit,
        value: manageSilenceForm.timeValue,
    })

    // get dateTimeInput in utc
    const updatedSilenceUntil = unixMsToUtcDateTimeInput(
        updateSilenceDate.getTime()
    )

    return {
        id: subscription.id,
        silenceUntil: updatedSilenceUntil,
        disturb: subscription.disturb,
        email: subscription.email,
        phone: subscription.phone,
        includeNewWells: subscription.includeNewWells,
        subscribedWells: subscription.subscribedWells.map((well) => {
            return getFragmentData(WELL_IDENTIFICATION_FIELDS_FRAGMENT, well).id
        }),
    }
}

export { getManageSilenceMutationValues }
