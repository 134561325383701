import { TableStateType } from "../../../../table/tableTypes"
import { getTableStateFromUrlParams } from "../../../../table/tableUtil"
import { METRIC_COLUMN_ID, SUBSCRIBED_WELLS_COLUMN_ID } from "./column"
import { MySubscriptionsUrlToStateMappings } from "./tableTypes"

const mySubscriptionsPageSizeSelectorArray = [15, 25, 50]

const mySubscriptionsTableUrlMapping: MySubscriptionsUrlToStateMappings = {
    [METRIC_COLUMN_ID]: (value: string, state: TableStateType) => {
        state.filter.push({ id: METRIC_COLUMN_ID, value })
    },
    [SUBSCRIBED_WELLS_COLUMN_ID]: (value: string, state: TableStateType) => {
        state.filter.push({ id: SUBSCRIBED_WELLS_COLUMN_ID, value })
    },
}

const getMySubscriptionsStateFromUrlParams = (
    searchParams: URLSearchParams,
    customMapping: MySubscriptionsUrlToStateMappings
): TableStateType => {
    const defaultState: TableStateType = {
        pageIndex: 0,
        size: mySubscriptionsPageSizeSelectorArray[0],
        sorting: [],
        search: "",
        filter: [],
    }

    return getTableStateFromUrlParams(
        searchParams,
        defaultState,
        mySubscriptionsPageSizeSelectorArray,
        customMapping
    )
}

export {
    getMySubscriptionsStateFromUrlParams,
    mySubscriptionsPageSizeSelectorArray,
    mySubscriptionsTableUrlMapping,
}
