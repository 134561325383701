import { graphql } from "../../generated"

export const GET_ALERT_RULES_BY_ID = graphql(`
    query RulesById($input: RulesByIDsInput!) {
        rulesByIDs(input: $input) {
            rules {
                ...AlertRuleFields
            }
        }
    }
`)
