import { debounce } from "lodash"
import {
    FunctionComponent,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react"

type BreakPointContextType = "sm" | "md" | "lg"
const BreakPointContext = createContext<BreakPointContextType | undefined>(
    undefined
)

interface BreakpointContextProviderI {
    children: React.ReactNode
}

const smallBreakpoint = 768
const mediumBreakpoint = 992

const getBreakPoint = (width: number): BreakPointContextType => {
    if (width <= smallBreakpoint) {
        return "sm"
    } else if (width <= mediumBreakpoint) {
        return "md"
    } else {
        return "lg"
    }
}

// Provider
const BreakPointContextProvider: FunctionComponent<
    BreakpointContextProviderI
> = ({ children }) => {
    const initialBreakpoint = getBreakPoint(window.innerWidth)
    const [breakPoint, setBreakPoint] = useState(initialBreakpoint)

    useEffect(() => {
        const handleResize = () => {
            setBreakPoint(() => getBreakPoint(window.innerWidth))
        }
        const debouncedHandleResize = debounce(handleResize, 200)
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    }, [])

    return (
        <BreakPointContext.Provider value={breakPoint}>
            {children}
        </BreakPointContext.Provider>
    )
}

const useBreakPointContext = () => {
    const context = useContext(BreakPointContext)
    if (context === undefined) {
        throw new Error(
            "useBreakPointContext must be used within a BreakPointContextProvider"
        )
    }
    return context
}

export { BreakPointContextProvider, useBreakPointContext }
