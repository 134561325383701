import { FunctionComponent } from "react"
import Input from "../../shared/input"
import "./wellControlsTimer.scss"
import { useWellControlsContext } from "../../../context/wellControlsContext"
import { updateTimerState } from "../../../util/wellControlUtil/wellControlsUtils"
import { objectHasNonUndefinedFields } from "../../../util/objects/objects"

interface SummaryCycleTimelineInterface {
    timerCycleDisplay: {
        timerCycleHours: number | string
        timerCycleMinutes: number | string
        runPercentage: number | string
    }
    runForTimeDisplay: {
        hours: number | string
        minutes: number | string
    }
    idleForTimeDisplay: {
        hours: number | string
        minutes: number | string
    }
}

const WellControlsTimer: FunctionComponent = () => {
    // context
    const {
        wellControlState: { timerState, timerErrorsState },
        handleWellControlTimerChange,
        handleWellControlTimerBlur,
    } = useWellControlsContext()

    const summaryCycleTimelineErrorObject: SummaryCycleTimelineInterface = {
        timerCycleDisplay: {
            timerCycleHours: "—",
            timerCycleMinutes: "—",
            runPercentage: "—",
        },
        idleForTimeDisplay: {
            hours: "—",
            minutes: "—",
        },
        runForTimeDisplay: {
            hours: "—",
            minutes: "—",
        },
    }
    // Get runForTime and IdleTime from timerState
    const { runForTime, IdleForTime } = updateTimerState(timerState)
    // get values to be displayed in timeline summary
    let displaySummaryCycleTimeline = {} as SummaryCycleTimelineInterface

    const hasErrors = objectHasNonUndefinedFields(timerErrorsState)
    // if timerErrorsState has errors, use summaryCycleTimelineErrorObject for values to be displayed.
    if (hasErrors) {
        displaySummaryCycleTimeline = summaryCycleTimelineErrorObject
    }
    // else use timer state and calculated values (idle for time, run for time)
    else {
        displaySummaryCycleTimeline = {
            timerCycleDisplay: { ...timerState },
            idleForTimeDisplay: { ...IdleForTime },
            runForTimeDisplay: { ...runForTime },
        }
    }

    const {
        idleForTimeDisplay,
        runForTimeDisplay,
        timerCycleDisplay: timerCyleDisplay,
    } = displaySummaryCycleTimeline

    // handlers
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleWellControlTimerChange(e, timerState, timerErrorsState)
    }

    return (
        <div className="timer-cycle-container">
            <div className="timer-cycle-instructions-row">
                <div className="timer-cycle-instructions-step1">
                    Step 1: Timer Cycle
                </div>
                <div>Step 2: Runtime</div>
            </div>
            <div className="timer-cycle-input-row">
                <div className="timer-cycle-input-row-inputs">
                    <Input
                        orientation="horizontal"
                        type="number"
                        value={timerState.timerCycleHours}
                        onChange={handleInputChange}
                        onBlur={handleWellControlTimerBlur}
                        id="timerCycleHours"
                        min={0}
                        inputLabelRight="hr"
                        inputCustomClass="well-control-timer-cycle-input-hr"
                        disabled={false}
                        message={timerErrorsState.timerCycleHours}
                    />
                </div>
                <div className="timer-cycle-input-row-inputs">
                    <Input
                        orientation="horizontal"
                        type="number"
                        value={timerState.timerCycleMinutes}
                        onChange={handleInputChange}
                        onBlur={handleWellControlTimerBlur}
                        id="timerCycleMinutes"
                        min={0}
                        max={59}
                        inputLabelRight="min"
                        inputCustomClass="well-control-timer-cycle-input-min"
                        disabled={false}
                        message={timerErrorsState.timerCycleMinutes}
                    />
                </div>
                <div className="timer-cycle-input-row-inputs">
                    <Input
                        orientation="horizontal"
                        type="range"
                        value={timerState.runPercentage}
                        min={1}
                        max={99}
                        onChange={handleInputChange}
                        onBlur={handleWellControlTimerBlur}
                        id="runPercentage"
                        inputCustomClass="well-control-timer-cycle-range-percent"
                        disabled={false}
                    />
                </div>
                <Input
                    orientation="horizontal"
                    type="number"
                    value={timerState.runPercentage}
                    min={1}
                    max={99}
                    onChange={handleInputChange}
                    onBlur={handleWellControlTimerBlur}
                    id="runPercentage"
                    inputLabelRight="%"
                    inputCustomClass="well-control-timer-cycle-input-percent"
                    disabled={false}
                    message={timerErrorsState.runPercentage}
                />
            </div>
            <div className="timer-cycle-summary-heading">
                Summary: Cycle Timeline
            </div>

            <div className="timer-cycle-summary-wrapper">
                {displaySummaryCycleTimeline && (
                    <>
                        <div className="timer-cycle-summary-calculation-container timer-cycle-summary-border-right">
                            <div className="timer-cycle-summary-calculation-text ">
                                Every
                            </div>
                            <div className="timer-cycle-summary-calculation-value">
                                <div className="timerCycleSummaryValues">
                                    {timerCyleDisplay.timerCycleHours || 0}
                                </div>
                                <div className="timerCycleSummaryValues">
                                    hr
                                </div>
                                <div className="timerCycleSummaryValues">
                                    {timerCyleDisplay.timerCycleMinutes || 0}
                                </div>
                                <div className="timerCycleSummaryValues">
                                    min
                                </div>
                            </div>
                        </div>
                        <div className="timer-cycle-summary-calculation-container">
                            <div className="timer-cycle-summary-calculation-text">
                                Run For
                            </div>
                            <div className="timer-cycle-summary-calculation-value">
                                <div className="timerCycleSummaryValues">
                                    {runForTimeDisplay.hours}
                                </div>
                                <div className="timerCycleSummaryValues">
                                    hr
                                </div>
                                <div className="timerCycleSummaryValues">
                                    {runForTimeDisplay.minutes}
                                </div>
                                <div className="timerCycleSummaryValues">
                                    min
                                </div>
                            </div>
                        </div>
                        <div className="timer-cycle-summary-calculation-container">
                            <div className="timer-cycle-summary-calculation-text">
                                Idle For
                            </div>
                            <div className="timer-cycle-summary-calculation-value">
                                <div className="timerCycleSummaryValues">
                                    {idleForTimeDisplay.hours}
                                </div>
                                <div className="timerCycleSummaryValues">
                                    hr
                                </div>
                                <div className="timerCycleSummaryValues">
                                    {idleForTimeDisplay.minutes}
                                </div>
                                <div className="timerCycleSummaryValues">
                                    min
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default WellControlsTimer
