import { FunctionComponent, useRef } from "react"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import styles from "./actionsMenu.module.scss"
import { useNavigate } from "react-router-dom"
import Button from "../../../button"
import RadixDropDownMenu from "../../../dropDown/radixDropDownMenu"
import { LabelRouteObjType } from "../../../../../types/commonTypes"

interface ActionMenuI {
    menuOptions: LabelRouteObjType[]
}

const ActionMenu: FunctionComponent<ActionMenuI> = ({ menuOptions }) => {
    const ref = useRef<HTMLButtonElement>(null)
    const navigate = useNavigate()

    const dropDownOptions = menuOptions.map(({ label, route }) => {
        return (
            <DropdownMenu.Item
                key={label}
                onClick={() => {
                    navigate(route)
                }}
                className={styles.DropdownMenuItem}
            >
                {label}
            </DropdownMenu.Item>
        )
    })

    if (dropDownOptions.length === 0) {
        return <></>
    }

    return (
        <RadixDropDownMenu
            triggerChildren={
                <Button
                    status="secondary"
                    customButtonClassName="button-table-row-outlined"
                    condition="default"
                    disabled={false}
                    ref={ref}
                >
                    Actions
                </Button>
            }
        >
            {dropDownOptions}
        </RadixDropDownMenu>
    )
}

export { ActionMenu }
