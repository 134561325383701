import { SubjectRole } from "../generated/graphql"

const RolesUiDisplayObj = {
    [SubjectRole.OrgAdmin]: "Org Admin",
    [SubjectRole.OrgMember]: "Org Member",
    [SubjectRole.PpoAdmin]: "PPO Admin",
    [SubjectRole.Unknown]: "Unknown",
    [SubjectRole.System]: "System",
} as const

export { RolesUiDisplayObj }
