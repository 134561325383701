import { FunctionComponent, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
// components
import Input from "../../shared/input"
import Alert from "../../shared/alert"
import ModalHeader from "../../shared/modalHeader"
import ModalFooter from "../../shared/modalFooter"
// scss
import styles from "./wellControlsLink.module.scss"
// types & queries
import { useMutation, useQuery } from "@apollo/client"
import { getFragmentData } from "../../../generated"
import { LINK_WELL } from "../../../graphql/mutations/link_well"
import {
    DiscreteControlWiringMode,
    LinkWellInput,
} from "../../../generated/graphql"
import { ComponentStateI } from "./wellControlsLinkTypes"
//utils
import { isSubmitDisabled } from "./wellControlsLinkUtil"
import { GET_SINGLE_WELL } from "../../../graphql/queries/well"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../../graphql/fragments/wellIdentification"
import { TrexNavigator } from "../../../classes/navigator/navigator"
import { isEdgeComputerTokenValid } from "../../../util/installation"
import { INDIVIDUAL_WELL_PAGE_QUERY } from "../../../graphql/queries/individualWellPage"
import {
    iwpQueryParam_equipStatsFrom,
    iwpQueryParam_equipStatsTo,
    iwpQueryParam_historyCount,
} from "../../../pages/individualWellPage"

const WellControlsLink: FunctionComponent = () => {
    const navigate = useNavigate()
    const { key: locationKey } = useLocation()
    const { wellId: wellIdFromUrl } = useParams()

    if (!wellIdFromUrl) {
        throw new Error("wellId not resolved from url.")
    }

    const [linkWell, { loading: mutationLoading }] = useMutation(LINK_WELL, {
        refetchQueries: [
            {
                query: INDIVIDUAL_WELL_PAGE_QUERY,
                variables: {
                    wellInput: { id: wellIdFromUrl },
                    equipStats_from: iwpQueryParam_equipStatsFrom,
                    equipStats_to: iwpQueryParam_equipStatsTo,
                    historyCount: iwpQueryParam_historyCount,
                    wellControlInput: { wellID: wellIdFromUrl },
                },
            },
        ],
    })

    const initialComponentState: ComponentStateI = {
        token: "",
        wiringMode: DiscreteControlWiringMode.NormallyOpen,
        submitDisabled: true,
        errorBanner: false,
        tokenValidation: undefined,
    }

    // state
    const [componentState, setComponentState] = useState<ComponentStateI>(
        initialComponentState
    )

    const { error, loading, data } = useQuery(GET_SINGLE_WELL, {
        variables: {
            wellInput: { id: wellIdFromUrl },
        },
    })

    if (loading) {
        return <></>
    }

    if (error) {
        throw new Error(`GET_SINGLE_WELL error: ${error}`)
    }

    if (!data) return <></>
    const well = getFragmentData(WELL_IDENTIFICATION_FIELDS_FRAGMENT, data.well)
    if (!well) return <></>

    const {
        name: wellName,
        apiNumber: { format: wellApiNumber },
        linked,
    } = well

    const handleLinkInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = e.target.value as DiscreteControlWiringMode
        setComponentState({
            ...componentState,
            wiringMode: selectedOption,
        })
    }

    const handleTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isTokenValid = isEdgeComputerTokenValid(e.target.value)

        const disableSubmit = isSubmitDisabled(isTokenValid, linked)
        const tokenValidation = isTokenValid
            ? undefined
            : "Use a valid field device token"

        setComponentState({
            ...componentState,
            token: e.target.value.toUpperCase(),
            tokenValidation: tokenValidation,
            submitDisabled: disableSubmit,
        })
    }

    const handleSubmitLinkToken = async () => {
        const { token } = componentState

        const LinkWellInputVariables: LinkWellInput = {
            edgeComputerBy: {
                linkCode: token,
            },
            wellBy: {
                id: wellIdFromUrl,
            },
            wiringMode: componentState.wiringMode,
        }

        await linkWell({
            variables: {
                LinkWellInput: LinkWellInputVariables,
            },
        })
            .then(() => {
                // Upon successful mutation, navigate back -1
                navigate(-1)
            })
            .catch((error) => {
                console.error("Linking mutation error:", error)
                setComponentState({ ...componentState, errorBanner: true })
            })
    }

    return (
        <div className={styles.container}>
            <ModalHeader
                title={"Link Device to Well"}
                subTitle={wellName}
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
            />
            <div className={styles.bodyHeading}>
                Please enter in the field device token to link for:
            </div>
            <div className={styles.wellName}>{wellName}</div>
            <div className={styles.wellApi}>{wellApiNumber}</div>
            <div className={styles.inputContainer}>
                <div className={styles.marginRight}>
                    <Input
                        type="text"
                        id="token"
                        orientation="vertical"
                        label="Token"
                        value={componentState.token}
                        onChange={handleTokenChange}
                        inputCustomClass="well-control-link-text"
                        disabled={componentState.errorBanner}
                        message={componentState.tokenValidation}
                        specializedClass={
                            componentState.tokenValidation
                                ? "danger"
                                : "default"
                        }
                    />
                </div>
                <div>
                    <Input
                        type="select"
                        label="Relay Configuration"
                        value={componentState.wiringMode}
                        options={[
                            {
                                value: DiscreteControlWiringMode.NormallyOpen,
                                label: "Normally Open",
                            },
                            {
                                value: DiscreteControlWiringMode.NormallyClosed,
                                label: "Normally Closed",
                            },
                        ]}
                        onChange={handleLinkInputChange}
                        orientation="vertical"
                        inputCustomClass="well-control-link-text"
                        disabled={componentState.errorBanner}
                    />
                </div>
            </div>
            {componentState.errorBanner && (
                <Alert
                    message={
                        <div>
                            There was a problem linking your well. Click{" "}
                            <u
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate(-1)}
                            >
                                here
                            </u>{" "}
                            or close the modal to be redirected to the
                            dashboard.
                        </div>
                    }
                    alertType={"danger"}
                    isCloseable={false}
                />
            )}
            <ModalFooter
                advanceText="Submit"
                handleAdvanceClick={handleSubmitLinkToken}
                disableAdvance={
                    componentState.submitDisabled ||
                    componentState.errorBanner ||
                    mutationLoading
                }
            />
        </div>
    )
}

export default WellControlsLink
