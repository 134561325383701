import { OrganizationsUpdateInput } from "../generated/graphql"
import {
    UpdateOrganizationModalStateInterface,
    UpdateOrganizationStateInterface,
} from "../types/adminOrganizationTypes/updateOrganizationModalTypes"
import _ from "lodash"

const getActivationUpdateOrganizationStatus = (
    initialActivationStatus: boolean,
    updatedActivationStatus: boolean
) => {
    if (initialActivationStatus === updatedActivationStatus) {
        return false
    }
    if (updatedActivationStatus) {
        return "activateOrganization"
    } else {
        return "deactivateOrganization"
    }
}

const disableSubmitUpdateOrganization = (
    initialOrganizationState: UpdateOrganizationStateInterface,
    updatedOrganizationState: UpdateOrganizationStateInterface
) => {
    const { organizationName } = updatedOrganizationState
    const isInitialState = _.isEqual(
        initialOrganizationState,
        updatedOrganizationState
    )
    if (isInitialState) {
        return true
    }
    if (organizationName.length === 0) return true
    return false
}

const initialUpdateOrganizationModalState: UpdateOrganizationModalStateInterface =
    {
        disableSubmit: true,
        organizationState: {
            organizationName: "",
            parentOrganizationId: undefined,
            organizationActivationStatus: false,
        },
    }

const getUpdatedOrganizationValues = (
    initialState: UpdateOrganizationStateInterface,
    currentState: UpdateOrganizationStateInterface
): string[] | false => {
    const changedFields: string[] = []

    const keys: Array<keyof UpdateOrganizationStateInterface> = Object.keys(
        currentState
    ) as Array<keyof UpdateOrganizationStateInterface>
    for (const key of keys) {
        if (
            key !== "organizationActivationStatus" &&
            currentState[key] !== initialState[key]
        ) {
            changedFields.push(key as string)
        }
    }

    if (changedFields.length === 0) {
        return false
    }

    return changedFields
}

const getUpdateOrganiztionMutationVariables = (
    updatedOrganizationState: UpdateOrganizationStateInterface,
    organizationId: string
) => {
    /** If parent organization id is undefined */
    if (!updatedOrganizationState.parentOrganizationId?.value) {
        const OrganizationsUpdateInput: OrganizationsUpdateInput = {
            organizations: [
                {
                    organizationID: organizationId,
                    name: updatedOrganizationState.organizationName,
                },
            ],
        }
        return OrganizationsUpdateInput
    } else {
        /** parent organization id has been changed */
        const OrganizationsUpdateInput: OrganizationsUpdateInput = {
            organizations: [
                {
                    organizationID: organizationId,
                    name: updatedOrganizationState.organizationName,
                    parentOrganizationID:
                        updatedOrganizationState.parentOrganizationId?.value,
                },
            ],
        }
        return OrganizationsUpdateInput
    }
}

export {
    disableSubmitUpdateOrganization,
    getActivationUpdateOrganizationStatus,
    initialUpdateOrganizationModalState,
    getUpdatedOrganizationValues,
    getUpdateOrganiztionMutationVariables,
}
