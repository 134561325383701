import { graphql } from "../../generated"

export const WELL_UPDATE_ATTRIBUTES = graphql(`
    mutation wellUpdateAttributes(
        $WellUpdateAttributesInput: WellUpdateAttributesInput!
    ) {
        wellUpdateAttributes(input: $WellUpdateAttributesInput) {
            well {
                ...WellIdentificationFields
            }
        }
    }
`)
