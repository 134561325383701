import { FunctionComponent } from "react"
import ModalHeader from "../../shared/modalHeader"
import { TrexNavigator } from "../../../classes/navigator/navigator"
import { USER_SETTINGS_ABS_ROUTE } from "../../.."
import { useNavigate } from "react-router-dom"
import { useViewerContext } from "../../../context/ViewerContext"
import UpdateTimezoneFormComponent from "./form"

const UpdateProfileTimezone: FunctionComponent = () => {
    const navigate = useNavigate()
    const { getViewer } = useViewerContext()
    const { userId, identity } = getViewer()
    return (
        <>
            <ModalHeader
                title="User Timezone"
                trexNavigator={
                    new TrexNavigator(
                        { navigateTo: USER_SETTINGS_ABS_ROUTE },
                        navigate
                    )
                }
            />
            <UpdateTimezoneFormComponent
                userId={userId}
                timezone={identity.zoneInfo}
            />
        </>
    )
}

export default UpdateProfileTimezone
