import { FunctionComponent, useEffect, useState } from "react"
import styles from "./actionHeader.module.scss"
import Input from "../../../../shared/input"
import Button from "../../../../shared/button"
import { canRoleAddAlertRule } from "../../../../../util/rolePermissions/canRole"
import { useViewerContext } from "../../../../../context/ViewerContext"
import { AlertRulesTableDataI } from "../tableTypes"
import { Table } from "@tanstack/react-table"
import { METRIC_COLUMN_ID, AUTHOR_COLUMN_ID } from "../column"
import { useBreakPointContext } from "../../../../../context/breakPointContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faChevronDoubleDown,
    faChevronDoubleUp,
} from "@fortawesome/pro-solid-svg-icons"
import { useNavigate } from "react-router-dom"

interface AlertRulesActionHeaderI {
    table: Table<AlertRulesTableDataI>
    globalFilter: string
}

const AlertRulesActionHeader: FunctionComponent<AlertRulesActionHeaderI> = ({
    table,
    globalFilter,
}) => {
    const navigate = useNavigate()
    const { role: viewerRole } = useViewerContext().getViewer()
    const canAddAlertRule = canRoleAddAlertRule(viewerRole)

    const breakPoint = useBreakPointContext()
    const is_LE_mediumBreakPoint = breakPoint === "sm" || breakPoint === "md"

    // state
    const [showActions, setShowActions] = useState(true)

    // font awesome icon
    const fontAwesomeIcon = showActions
        ? faChevronDoubleUp
        : faChevronDoubleDown

    // styling classes
    // in mobile
    const outerClass =
        is_LE_mediumBreakPoint && !showActions
            ? styles.outerClosed
            : styles.outer

    // table
    // columns
    const metricColumn = table.getColumn(METRIC_COLUMN_ID)
    const authorColumn = table.getColumn(AUTHOR_COLUMN_ID)
    // unique metrics
    const m = metricColumn?.getFacetedUniqueValues().keys()
    const uniqueMetrics = m ? Array.from(m).sort() : []
    // unique authors
    const a = authorColumn?.getFacetedUniqueValues().keys()
    const uniqueAuthors = a ? Array.from(a).sort() : []
    // filter values
    const metricFilterValue = (metricColumn?.getFilterValue() as string) || ""
    const authorFilterValue = (authorColumn?.getFilterValue() as string) || ""

    // effect hook
    // if we resize from mobile => desktop sizing we need to setShowActions to true
    useEffect(() => {
        if (!is_LE_mediumBreakPoint) {
            setShowActions(true)
        }
    }, [is_LE_mediumBreakPoint])

    return (
        <div className={outerClass}>
            {is_LE_mediumBreakPoint && (
                <Button
                    handleButtonClick={() => setShowActions(!showActions)}
                    status="primary"
                    customButtonClassName={styles.mobileButton}
                >
                    <FontAwesomeIcon icon={fontAwesomeIcon} color="#fff" />
                </Button>
            )}
            {showActions && (
                <div className={styles.container}>
                    <div className={styles.containerLeft}>
                        {canAddAlertRule && (
                            <div className={styles.addAlertButton}>
                                <Button
                                    handleButtonClick={() => {
                                        navigate("addRule")
                                    }}
                                    status="primary"
                                    condition="default"
                                >
                                    Add Alert Rule
                                </Button>
                            </div>
                        )}
                        <div className={styles.metricFilter}>
                            <Input
                                onChange={(e) => {
                                    metricColumn?.setFilterValue(e.target.value)
                                }}
                                type="datalist"
                                orientation="horizontal"
                                placeHolder="Filter By Metric"
                                datalistId="alertRuleMetric"
                                options={uniqueMetrics}
                                specializedClass="noMessage"
                                value={metricFilterValue}
                            />
                        </div>
                        <div>
                            <Input
                                onChange={(e) => {
                                    authorColumn?.setFilterValue(e.target.value)
                                }}
                                type="datalist"
                                orientation="horizontal"
                                placeHolder="Filter By Author"
                                datalistId="alertRuleAuthor"
                                options={uniqueAuthors}
                                specializedClass="noMessage"
                                value={authorFilterValue}
                            />
                        </div>
                    </div>
                    <div className={styles.containerRight}>
                        <div className={styles.globalFilter}>
                            <Input
                                value={globalFilter}
                                onChange={(e) => {
                                    table.setGlobalFilter(e.target.value)
                                }}
                                type="text"
                                orientation="horizontal"
                                placeHolder="Search Columns"
                                specializedClass="noMessage"
                                inputCustomClass={styles.searchFilter}
                            />
                        </div>
                        <Button
                            handleButtonClick={() => {
                                table.setGlobalFilter("")
                            }}
                            status="secondary"
                            condition="default"
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AlertRulesActionHeader
