import * as React from "react"
import * as PopoverPrimitive from "@radix-ui/react-popover"
import styles from "./popover.module.scss"
import classNames from "classnames"

export interface RadixPopoverI {
    triggerChildren: React.ReactNode
    popoverContent: React.ReactNode
    contentClassname?: string
    align?: "start" | "center" | "end"
    side?: "top" | "right" | "bottom" | "left"
    triggerStyles?: string
    removePopoverButtonStyling?: boolean
}

const Popover: React.FunctionComponent<RadixPopoverI> = ({
    triggerChildren,
    popoverContent,
    contentClassname,
    side = "top",
    align = "center",
    removePopoverButtonStyling = false,
    triggerStyles,
}) => {
    return (
        <PopoverPrimitiveRoot>
            <PopoverTrigger
                className={classNames(
                    triggerStyles && triggerStyles,
                    removePopoverButtonStyling && styles.removeButtonStyling
                )}
            >
                {triggerChildren}
            </PopoverTrigger>
            <PopoverContent
                side={side}
                align={align}
                className={contentClassname}
            >
                {popoverContent}
            </PopoverContent>
        </PopoverPrimitiveRoot>
    )
}

export default Popover

const PopoverPrimitiveRoot = PopoverPrimitive.Root
const PopoverTrigger = PopoverPrimitive.Trigger
const PopoverArrow = PopoverPrimitive.Arrow

const PopoverContent = React.forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ children, ...props }, ref) => (
    <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
            {...props}
            ref={ref}
            className={classNames(
                styles.popoverContent,
                props.className && props.className
            )}
        >
            {children}
            <PopoverArrow className={styles.popoverArrow} width={10} />
        </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
))
PopoverContent.displayName = PopoverPrimitive.Content.displayName
