const debounce = <T extends (...args: unknown[]) => void>(
    func: T,
    delay: number
) => {
    let timer: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>) => {
        clearTimeout(timer)
        timer = setTimeout(() => func.apply(this, args), delay)
    }
}

export { debounce }
