import {
    FunctionComponent,
    useContext,
    createContext,
    useState,
    useEffect,
} from "react"
import {
    ViewerContextInterface,
    ViewerInitialStateType,
    ViewerProviderInterface,
    ViewerStateInterface,
} from "../types/ViewerTypes"
import { createUserOrganizationObject } from "../util/viewerUtil"
import { getFragmentData } from "../generated"
import {
    IDENTITY_FIELDS_FRAGMENT,
    IDENTITY_NORTH_AMERICAN_PHONE_NUMBER_FRAGMENT,
} from "../graphql/fragments/Identity"
import { useQuery } from "@apollo/client"
import { VIEWER } from "../graphql/queries/viewer"
import { ORGANIZATION_WITH_DESCENDANTS_FIELDS_FRAGMENT } from "../graphql/fragments/organizations"

// Create context
const ViewerContext = createContext<undefined | ViewerContextInterface>(
    undefined
)

// Create provider
const ViewerProvider: FunctionComponent<ViewerProviderInterface> = ({
    children,
}) => {
    const [viewerState, setViewerState] =
        useState<ViewerInitialStateType>(undefined)
    const { data } = useQuery(VIEWER)

    useEffect(() => {
        // This effect hook is used to set state for the results of viewer data
        if (!data) return

        const organizationsAndDescendantsFields = getFragmentData(
            ORGANIZATION_WITH_DESCENDANTS_FIELDS_FRAGMENT,
            data.viewer.organization
        )

        const organizationAndDescendants = createUserOrganizationObject(
            organizationsAndDescendantsFields
        )

        const identityFields = getFragmentData(
            IDENTITY_FIELDS_FRAGMENT,
            data.viewer.identity
        )

        const phoneNumber = getFragmentData(
            IDENTITY_NORTH_AMERICAN_PHONE_NUMBER_FRAGMENT,
            identityFields?.telephoneNumber
        )

        if (!identityFields) {
            throw new Error(
                "Viewer Provider - Identity Fields returned as null or undefined from viewer query.  "
            )
        }

        const initialState: ViewerStateInterface = {
            organizationAndDescendants: organizationAndDescendants,
            currentOrganization: {
                label: organizationsAndDescendantsFields.name,
                value: organizationsAndDescendantsFields.id,
            },
            identity: identityFields,
            userId: data.viewer.id,
            role: data.viewer.role,
            smsConsent: identityFields.smsConsent,
            emailConsent: identityFields.emailConsent,
            getPhoneNumber: (formatted = false) => {
                if (phoneNumber) {
                    if (formatted) {
                        return phoneNumber.formatted
                    }

                    return phoneNumber.concise
                }
            },
        }

        setViewerState(initialState)
    }, [data])

    const getViewer = () => {
        if (viewerState === undefined) {
            throw new Error(
                "Viewer Provider - Viewer state  was undefined. ViewerState should have been set be the result of viewer query before children were rendered."
            )
        }
        return viewerState
    }

    // Context Values
    const contextValues = {
        getViewer,
    }

    return (
        <ViewerContext.Provider value={contextValues}>
            {viewerState && children}
            {!viewerState && <></>}
        </ViewerContext.Provider>
    )
}

// use context hook
const useViewerContext = () => {
    const context = useContext(ViewerContext)

    if (context === undefined) {
        throw new Error(
            " UseViewerContext error.  Viewer Context must be used within a child  Viewer Provider"
        )
    }

    return context
}

export { ViewerProvider, useViewerContext }
