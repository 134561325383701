import { graphql } from "../../generated"

export const NOTIFICATION_FRAG_MY_NOTIFICATIONS = graphql(`
    fragment NotficationFragMyNotifications on AlertNotification {
        id
        read
        subscriptionID
        threshold
        silenced
        event {
            id
            triggeredValue
            triggeredTime {
                unixMilliseconds
            }
            resolvedTime {
                unixMilliseconds
            }
            alertRule {
                id
                name
                metric {
                    displayName
                    metric
                }
                condition {
                    unit
                    operator
                    threshold2
                }
            }
            well {
                name
                id
            }
        }
    }
`)
