import React, { FunctionComponent } from "react"
import { isMobile, isTablet } from "react-device-detect"
import ToolTip, { RadixToolTipI } from "../tooltip"
import Popover, { RadixPopoverI } from "../popover"

interface ToolTip_PopoverI {
    triggerChildren: React.ReactNode
    content: React.ReactNode
    tooltip?: Omit<RadixToolTipI, "triggerChildren" | "tooltipContent">
    popover?: Omit<RadixPopoverI, "triggerChildren" | "popoverContent">
}

const ToolTip_Popover: FunctionComponent<ToolTip_PopoverI> = (props) => {
    if (isMobile || isTablet) {
        return (
            <Popover
                {...props.popover}
                triggerChildren={props.triggerChildren}
                popoverContent={props.content}
            />
        )
    }

    return (
        <ToolTip
            {...props.tooltip}
            tooltipContent={props.content}
            triggerChildren={props.triggerChildren}
        />
    )
}
export default ToolTip_Popover
