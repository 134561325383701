/** Well Controls.  */
const WELL_CONTROLS_INVALID_TIMER = "Invalid entry"

interface WellControlsErrorMessagesInterface {
    timerState: {
        invalidTimer: "Invalid entry"
    }
    smartTimerState: {
        invalidValue: "Invalid entry"
    }
}

/** Error Objects */
const wellControlsErrorMessages: WellControlsErrorMessagesInterface = {
    timerState: {
        invalidTimer: WELL_CONTROLS_INVALID_TIMER,
    },
    smartTimerState: {
        invalidValue: WELL_CONTROLS_INVALID_TIMER,
    },
}

/** Invite Users Modal */

const INVITE_USER_FIRST_NAME = "Enter a first name"
const INVITE_USER_LAST_NAME = "Enter a last name"
const INVITE_USER_EMAIL = "Please enter a valid email"
// const INVITE_USER_ROLE = ''
const INVITE_USER_MOBILE_PHONE = "Enter number in xxx-xxx-xxxx format"
const INVITE_USER_ORGANIZATION = "Select an organization"

interface InviteUsersErrorMessagesInterface {
    newUserErrorState: {
        firstName: "Enter a first name"
        lastName: "Enter a last name"
        email: "Please enter a valid email"
        mobileNumber: "Enter number in xxx-xxx-xxxx format"
        // role: string | undefined
        organization: "Select an organization"
    }
}

const inviteUserErrorMessages: InviteUsersErrorMessagesInterface = {
    newUserErrorState: {
        firstName: INVITE_USER_FIRST_NAME,
        lastName: INVITE_USER_LAST_NAME,
        email: INVITE_USER_EMAIL,
        mobileNumber: INVITE_USER_MOBILE_PHONE,
        // role: ,
        organization: INVITE_USER_ORGANIZATION,
    },
}

export { wellControlsErrorMessages, inviteUserErrorMessages }
