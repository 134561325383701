import { FunctionComponent, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { SUBSCRIPTION_ADD } from "../../../../../../graphql/mutations/subscription_add"
import styles from "./copySubscriptionToUser.module.scss"
import { subscriptionsToAlertSubAddInput } from "../transferSubscriptionFormUtil/api"
import { GET_SUBSCRIPTIONS_FOR_ORG } from "../../../../../../graphql/queries/subscriptionsForOrg"
import { useNotificationsContext } from "../../../../context/notificationsContext"
import { navigateAfterMutation } from "../../../../navigate"
import {
    TransferSubscriptionFormType,
    TransferSubscriptionFormContainerComponentI,
} from "../transferSubscriptionFormUtil/types"
import TransferSubscriptionForm from "../form/form"
import { GET_SUBSCRIPTIONS_FOR_USER } from "../../../../../../graphql/queries/subscriptionsForUser"

const CopyToUserFormContainer: FunctionComponent<
    TransferSubscriptionFormContainerComponentI
> = ({ toUserOptions, subsToTransfer: subscriptions }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const notificationsContext = useNotificationsContext()

    // state
    const [copyToUser, setCopyToUser] =
        useState<TransferSubscriptionFormType>(undefined)
    const [mutationErrorBanner, setMutationErrorBanner] = useState(false)

    // mutation - to perform a subscription copy we take the subscription(s) from one user and add it to another
    const [subscriptionCopy, { loading: copySubscriptionLoading }] =
        useMutation(SUBSCRIPTION_ADD)

    // handlers
    const handleSubmitCopy = () => {
        if (!copyToUser) {
            // submit is disabled if no user is selected
            return
        }

        const addSubscriptionsInput = subscriptionsToAlertSubAddInput(
            subscriptions,
            copyToUser.value
        )

        subscriptionCopy({
            variables: {
                AlertSubscriptionsAddInput: { inputs: addSubscriptionsInput },
            },
            // refetch the subscriptions for the org and user to update the cache
            refetchQueries: [
                GET_SUBSCRIPTIONS_FOR_USER,
                {
                    query: GET_SUBSCRIPTIONS_FOR_ORG,
                    variables: {
                        input: {
                            orgIDs: [
                                notificationsContext.selectedOrganization.value,
                            ],
                        },
                    },
                },
            ],
        })
            .then(() => {
                navigate(navTo.to, navTo.options)
            })
            .catch((e) => {
                console.error(e)
                setMutationErrorBanner(true)
            })
    }

    // constants
    const navTo = navigateAfterMutation(pathname)
    const subscriptionsCount = subscriptions.length
    const filteredToUserOptions = toUserOptions.filter((toUser) => {
        // check if the subscription is already subscribed to the user
        return (
            subscriptions.findIndex((s) => s.subscriber.id === toUser.value) ===
            -1
        )
    })
    const s = subscriptions.length > 1 ? "subscriptions" : "subscription"
    const formDescription = (
        <>
            {subscriptionsCount} {s} will be{" "}
            <span className={styles.bodyBoldText}>copied to</span> the below
            user:
        </>
    )

    return (
        <TransferSubscriptionForm
            toUserOptions={filteredToUserOptions}
            selectedUser={copyToUser}
            mutationLoading={copySubscriptionLoading}
            mutationErrorBanner={{
                showErrorBanner: mutationErrorBanner,
                errMessage: (
                    <div>
                        There was problem copying subscriptions to the new user.
                        Click{" "}
                        <u
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(-1)}
                        >
                            here
                        </u>{" "}
                        or close the modal to be redirected.
                    </div>
                ),
            }}
            formDescription={formDescription}
            handleSubmitTransfer={handleSubmitCopy}
            handleSetSelectedUser={(e) => {
                if (!e) {
                    setCopyToUser(undefined)
                }
                if (e) {
                    setCopyToUser({ label: e?.label, value: e?.value })
                }
            }}
        />
    )
}

export default CopyToUserFormContainer
