import { graphql } from "../../generated"

export const GET_ORGANIZATIONS = graphql(`
    query GetOrganizations(
        $first: Int
        $last: Int
        $after: String
        $before: String
    ) {
        organizations(
            first: $first
            last: $last
            after: $after
            before: $before
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                endCursor
                startCursor
            }
            edges {
                node {
                    ...OrganizationWithDescendantsFields
                }
            }
            totalCount
        }
    }
`)
