import { graphql } from "../../generated"

export const GET_USERS = graphql(`
    query GetUsers(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $filter: SubjectFilter
    ) {
        users(
            first: $first
            last: $last
            after: $after
            before: $before
            filter: $filter
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                endCursor
                startCursor
            }
            edges {
                node {
                    id
                    role
                    identity {
                        ...IdentityFields
                    }
                    organization {
                        ...OrganizationWithDescendantsFields
                    }
                }
            }
            totalCount
        }
    }
`)
