import { FunctionComponent } from "react"
// components
import Button, { ButtonConditonType } from "../button"
// styles
import styles from "./modalFooter.module.scss"

export interface ModalFooterComponentInterface {
    handleAdvanceClick: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void
    disableAdvance: boolean
    advanceText: string
    handlePreviousClick?: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void
    previousText?: string
    disablePrevious?: boolean
    previousButtonCondition?: ButtonConditonType
}

const ModalFooter: FunctionComponent<ModalFooterComponentInterface> = ({
    handleAdvanceClick,
    handlePreviousClick,
    disableAdvance,
    previousText,
    advanceText,
    disablePrevious,
    previousButtonCondition,
}) => {
    return (
        <div
            className={
                handlePreviousClick
                    ? styles.twoButtonFooter
                    : styles.oneButtonFooter
            }
        >
            {handlePreviousClick && (
                <Button
                    handleButtonClick={handlePreviousClick}
                    status="secondary"
                    condition={previousButtonCondition}
                    disabled={disablePrevious}
                >
                    {previousText}
                </Button>
            )}
            <Button
                handleButtonClick={handleAdvanceClick}
                status="primary"
                disabled={disableAdvance}
            >
                {advanceText}
            </Button>
        </div>
    )
}

export default ModalFooter
