import { graphql } from "../../generated"
export const INSTALLATION_FIELDS_FRAGMENT = graphql(`
    fragment InstallationFields on DiscreteControlInstallation {
        createdAt {
            ...DateTimeFields
        }
        wiringMode
        active
    }
`)
