import { graphql } from "../../generated"

export const GET_METRICS = graphql(`
    query Metrics($AlertRuleMetricsInput: AlertRuleMetricsInput) {
        metrics(input: $AlertRuleMetricsInput) {
            metrics {
                ...AlertRuleMetricDefinitionFields
            }
        }
    }
`)
