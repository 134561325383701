import { AlertRuleOperator, Unit } from "../../../../../generated/graphql"
import { convertDurationUnit } from "../../../../../util/duration/duration"
import { DurationUnitT } from "../../../../../util/duration/types"
import { FormValidationReturn } from "../../../../../util/formValidation/types"
import { objectHasNonUndefinedFields } from "../../../../../util/objects/objects"
import { AlertRuleFormErrorI, AlertRuleFormI } from "./alertRuleFormTypes"

// Form validation notes:
// thresholds:
// - threshold && threshold2 must be a number greater than or equal to 0
// - threshold must be within spec of min and max (inclusive for min and max)
// - If alert rule operator is outside range:  threshold2 > threshold
// - If alert rule unit is Duration: windowTotalSeconds > threshold
// - in our code  we should only validate threshold2 if the operator is outside range. The back end sets threshold2 to 0 if the operator is not outside range. We can get validation errors where threshold2 is less than min

// rule name:
// - must not exceed 40 characters.
// - Rule name must not be empty

const getErrorsFromAlertRuleForm = (
    form: AlertRuleFormI | undefined
): AlertRuleFormErrorI => {
    const errors: AlertRuleFormErrorI = {
        threshold: undefined,
        threshold2: undefined,
        ruleName: undefined,
    }

    if (!form) {
        return errors
    }

    const { condition } = form

    // Validate threshold
    const thresholdValidation = isThresholdValid(
        condition.threshold,
        condition.min,
        condition.max,
        condition.thresholdTimeUnit,
        condition.unit,
        form.windowTotalSeconds
    )
    if (!thresholdValidation.valid) {
        errors.threshold = thresholdValidation.errMsg
    }

    // we should only validate threshold2 if the operator is outside range. The back end sets threshold2 to 0 if the operator is not outside range. We can get validation errors where threshold2 is less than min
    let threshold2Validation = {
        valid: true,
        errMsg: "",
    }
    if (form.condition.operator === AlertRuleOperator.OutsideRange) {
        threshold2Validation = isThresholdValid(
            condition.threshold2,
            condition.min,
            condition.max,
            condition.thresholdTimeUnit,
            condition.unit,
            form.windowTotalSeconds
        )
    }

    if (!threshold2Validation.valid) {
        errors.threshold2 = threshold2Validation.errMsg
    }

    // Additional validation for OutsideRange
    const bothThresholds = areThresholdsValidTogether(
        condition.threshold,
        condition.threshold2,
        condition.operator
    )

    if (!bothThresholds.valid) {
        errors.threshold = bothThresholds.errMsg
    }

    // Validate rule name
    const ruleNameValidation = isRuleNameValid(form.ruleName)
    if (!ruleNameValidation.valid) {
        errors.ruleName = ruleNameValidation.errMsg
    }

    return errors
}

const isAlertRuleFormDisabled = (form: AlertRuleFormI | undefined): boolean => {
    if (!form) {
        return true
    }

    const errors = getErrorsFromAlertRuleForm(form)
    return objectHasNonUndefinedFields(errors)
}

// EXPORTS
export { isAlertRuleFormDisabled, getErrorsFromAlertRuleForm }

const MAX_ALERT_RULE_NAME_LENGTH = 40

// function for rule name validation
const isRuleNameValid = (ruleName: string): FormValidationReturn => {
    if (ruleName.length > MAX_ALERT_RULE_NAME_LENGTH) {
        return {
            valid: false,
            errMsg: `Rule name must not exceed ${MAX_ALERT_RULE_NAME_LENGTH} characters`,
        }
    }

    if (ruleName.length === 0) {
        return {
            valid: false,
            errMsg: "Rule name cannot be empty",
        }
    }

    return {
        valid: true,
        errMsg: "",
    }
}

// Validation for threshold values
const isThresholdValid = (
    threshold: number,
    thresholdMin: number | undefined | null,
    thresholdMax: number | undefined | null,
    thresholdTimeUnit: DurationUnitT | null,
    unit: Unit,
    windowTotalSeconds: number
): FormValidationReturn => {
    const validations = [
        isThresholdValidNumber(threshold),
        isThresholdWithinSpec(
            threshold,
            thresholdTimeUnit,
            thresholdMin,
            thresholdMax,
            unit
        ),
    ]

    if (unit === Unit.Duration && thresholdTimeUnit) {
        validations.push(
            isThresholdValidForUnitDuration(
                threshold,
                thresholdTimeUnit,
                windowTotalSeconds
            )
        )
    }

    const failedValidation = validations.find((v) => !v.valid)
    return failedValidation ?? { valid: true, errMsg: "" }
}

// threshold validation helper
const isThresholdValidNumber = (
    threshold: number | string
): FormValidationReturn => {
    if (typeof threshold !== "number" || threshold < 0 || isNaN(threshold)) {
        return {
            valid: false,
            errMsg: "Must be greater than or equal to 0",
        }
    }
    return {
        valid: true,
        errMsg: "",
    }
}

// threshold validation helper
const isThresholdWithinSpec = (
    threshold: number,
    thresholdTimeUnit: DurationUnitT | null,
    min: number | undefined | null,
    max: number | undefined | null,
    unit: Unit
): FormValidationReturn => {
    let thresholdValue = threshold

    switch (unit) {
        // if unit is duration - we must conver the threshold to seconds to compare with min and max which are returned in seconds from the api.
        case Unit.Duration: {
            if (thresholdTimeUnit) {
                const { value: thresholdSeconds } = convertDurationUnit(
                    { unit: thresholdTimeUnit, value: threshold },
                    "s"
                )
                thresholdValue = thresholdSeconds
            }
            break
        }
        // if unit is percentage - we must convert from an percentage to a ratio to match the return from the api.
        case Unit.Percentage: {
            thresholdValue = threshold / 100
            break
        }
    }

    if (min && thresholdValue < min) {
        return {
            valid: false,
            errMsg: `Use a larger threshold`,
        }
    }

    if (max && thresholdValue > max) {
        return {
            valid: false,
            errMsg: `Use a smaller threshold`,
        }
    }

    return {
        valid: true,
        errMsg: "",
    }
}

// threshold validation helper
// If Unit is a duration  - threshold must be less than window total seconds
const isThresholdValidForUnitDuration = (
    threshold: number,
    thresholdTimeUnit: DurationUnitT,
    windowTotalSeconds: number
): FormValidationReturn => {
    const { value: thresholdSeconds } = convertDurationUnit(
        {
            unit: thresholdTimeUnit,
            value: threshold,
        },
        "s"
    )

    if (thresholdSeconds >= windowTotalSeconds) {
        return {
            valid: false,
            errMsg: "Must be less than the time range",
        }
    }

    return {
        valid: true,
        errMsg: "",
    }
}

// validation for both thresholds together
// if alert rule opearator is outside range, threshold2 must be greater than threshold
const areThresholdsValidTogether = (
    threshold: number,
    threshold2: number,
    alertRuleOperator: AlertRuleOperator
): FormValidationReturn => {
    if (
        alertRuleOperator === AlertRuleOperator.OutsideRange &&
        threshold2 <= threshold
    ) {
        return {
            valid: false,
            errMsg: "Min must be less than max",
        }
    }

    return {
        valid: true,
        errMsg: "",
    }
}
