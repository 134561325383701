const isEmailVerifiedAndConsenting = (
    email: string | undefined,
    emailVerified: boolean | undefined,
    emailConsent: boolean | undefined
): boolean => {
    return Boolean(email) && Boolean(emailVerified) && Boolean(emailConsent)
}

const isPhoneVerifiedAndConsenting = (
    phoneNumber: string | undefined | null,
    phoneVerified: boolean | undefined,
    phoneConsent: boolean | undefined
): boolean => {
    return (
        Boolean(phoneNumber) && Boolean(phoneVerified) && Boolean(phoneConsent)
    )
}

export { isEmailVerifiedAndConsenting, isPhoneVerifiedAndConsenting }
