import { graphql } from "../../generated"

export const RULE_PREVIEW_EVALUATE = graphql(`
    query RulePreviewEvaluate($input: RulePreviewEvaluateInput!) {
        rulePreviewEvaluate(input: $input) {
            events {
                ...AlertEventFields
            }
        }
    }
`)
