import { graphql } from "../../generated"

export const UNLINK_WELL = graphql(`
    mutation WellUnlink($WellUnlinkInput: WellUnlinkInput!) {
        wellUnlink(input: $WellUnlinkInput) {
            well {
                ...WellIdentificationFields
                alert {
                    ...WellAlertFields
                }
            }
        }
    }
`)
