import React, { FunctionComponent, useState } from "react"
import { useAddWellsContext } from "../../../../../context/addWellsContext"
import Input from "../../../../shared/input"
import { PreviewAddNewFooter } from "../footer/previewFooters"
import { parseInputToFloat } from "../../../../../util/InputValidation/inputValidation"
import styles from "./previewAddNew.module.scss"
import { objectHasNonUndefinedFields } from "../../../../../util/objects/objects"
import {
    getWellAttributesFormErrors,
    isWellAttributesFormValid,
} from "../../../../../util/wellAttributes/formValidation"
import {
    WellAttributesErrorFormI,
    WellAttributesFormI,
} from "../../../../../util/wellAttributes/wellAttributesTypes"

interface PreviewAddNewCompentStateI {
    newWellAttributes: WellAttributesFormI
    newWellAttributesErrors: WellAttributesErrorFormI
    disableFooter: boolean
}

const PreviewAddNew: FunctionComponent = () => {
    const {
        handleAddWellToDisplayPanel,
        handleCloseActionPanelPreview,
        getAddWellsWellData,
    } = useAddWellsContext()

    // initial state of form
    const addWellsWellData = getAddWellsWellData()
    const initialNewWellAttributes = { ...addWellsWellData.WellAttributesForm }

    const [componentState, setComponentState] =
        useState<PreviewAddNewCompentStateI>({
            newWellAttributes: initialNewWellAttributes,
            newWellAttributesErrors: {
                WellName: undefined,
                Latitude: undefined,
                Longitude: undefined,
                apiNumber: undefined,
            },
            disableFooter: true,
        })

    /** Handle change of inputs. well name, latitude + longittude  */
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target

        // create a new object for wellAttributesForm
        let upatedAttribute: string | number
        switch (name as keyof WellAttributesFormI) {
            case "WellName":
                upatedAttribute = e.target.value
                break
            case "Latitude":
            case "Longitude":
                upatedAttribute = parseInputToFloat(e)
                break
            default:
                throw new Error(
                    "Add wells modal - add wells action panel preview add new - handle Input change error."
                )
        }

        const newWellAttributes: WellAttributesFormI = {
            ...componentState.newWellAttributes,
            [name]: upatedAttribute,
        }

        // Errors Form : If there are currently errors...
        // ... Remove them if user is correcting them.
        // Newly created errors should not update error state.
        // They  will disable submit button and will be visible when the input is blurred.

        let errorsForm = {
            ...componentState.newWellAttributesErrors,
        }

        if (objectHasNonUndefinedFields(errorsForm) === true) {
            errorsForm = getWellAttributesFormErrors(newWellAttributes)
        }

        const isFormValid = isWellAttributesFormValid(newWellAttributes)
        const disableFormSubmit = !isFormValid

        setComponentState((prevState) => ({
            ...prevState,
            disableFooter: disableFormSubmit,
            newWellAttributes: newWellAttributes,
            newWellAttributesErrors: errorsForm,
        }))
    }

    const handleInputBlur = () => {
        const updatedErrorData = getWellAttributesFormErrors({
            ...componentState.newWellAttributes,
        })

        setComponentState((prevState) => ({
            ...prevState,
            newWellAttributesErrors: updatedErrorData,
        }))
    }

    const submitWellApiWellData = () => {
        // submit new well to display panel - addWellModalId needs to be included to meet AddWellsWellDataInterface
        handleAddWellToDisplayPanel({
            addWellModalId: addWellsWellData.addWellModalId,
            WellAttributesForm: componentState.newWellAttributes,
        })
    }

    const { newWellAttributes, disableFooter, newWellAttributesErrors } =
        componentState

    return (
        <div className={styles.container}>
            <div className={styles.informationContainer}>
                <div className={styles.textRow}>
                    <div className={styles.textHeading}>API #:</div>
                    <div className={styles.subtext}>
                        {newWellAttributes.apiNumber || "N/A"}
                    </div>
                </div>
                <Input
                    orientation="vertical"
                    type="text"
                    value={newWellAttributes.WellName}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    label="Well Name *"
                    disabled={false}
                    name="WellName"
                    message={newWellAttributesErrors.WellName}
                    specializedClass={"default"}
                />
                <div className={styles.rowContainer}>
                    <div className={styles.padRight19}>
                        <Input
                            orientation="vertical"
                            type="number"
                            value={newWellAttributes.Latitude}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            label="Latitude *"
                            inputCustomClass="add-well-action-panel-coordinate-input"
                            disabled={false}
                            name="Latitude"
                            message={newWellAttributesErrors.Latitude}
                            specializedClass={
                                newWellAttributesErrors.Latitude
                                    ? "danger"
                                    : "default"
                            }
                        />
                    </div>
                    <div>
                        <Input
                            orientation="vertical"
                            type="number"
                            value={newWellAttributes.Longitude}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            label="Longitude *"
                            inputCustomClass="add-well-action-panel-coordinate-input"
                            disabled={false}
                            name="Longitude"
                            message={newWellAttributesErrors.Longitude}
                            specializedClass={
                                newWellAttributesErrors.Longitude
                                    ? "danger"
                                    : "default"
                            }
                        />
                    </div>
                </div>
            </div>
            <PreviewAddNewFooter
                onCancelFooterClick={handleCloseActionPanelPreview}
                onAddFooterClick={submitWellApiWellData}
                disabled={disableFooter}
            />
        </div>
    )
}

export default PreviewAddNew
