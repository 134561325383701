import { GroupBase, StylesConfig } from "react-select"
import { LabelValueOptionType } from "../types/commonTypes"

const disabledBackground = "#eceff1"
const disabledColor = "#111"
const color = "#37474f"
const background = "#fff"
const selected = "#64b5f6"
const border = "#cfd8dc"
const focused = "#f5f5f5"

export const baseReactSelectStyles: StylesConfig<
    LabelValueOptionType,
    false,
    GroupBase<LabelValueOptionType>
> = {
    control: (provided, state) => ({
        ...provided,
        boxSizing: "border-box",
        outline: "none",
        padding: ".0 .25rem",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "4px",
        fontWeight: "400",
        fontSize: "16px",
        height: "32px",
        borderColor: border,
        color: state.isDisabled ? disabledColor : color,
        backgroundColor: state.isDisabled ? disabledBackground : "none",
        boxShadow: state.isFocused
            ? "0px 0px 0px 3.2px rgba(0, 123, 255, 0.25)"
            : "none",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? selected
            : state.isFocused
            ? focused
            : "transparent",

        color: state.isSelected ? "white" : "inherit",
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? disabledColor : color,
        backgroundColor: state.isDisabled ? disabledBackground : background,
    }),
}
