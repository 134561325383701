import React, { FunctionComponent, ReactNode } from "react"
import styles from "./radixDropDownMenu.module.scss"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"

interface RadixDropDownMenuI {
    triggerChildren: ReactNode
    children: React.ReactNode
    triggerStyles?: string
    contentStyles?: string
    contentSide?: "left" | "right" | "top" | "bottom"
    sideOffset?: number
}

const RadixDropDownMenu: FunctionComponent<RadixDropDownMenuI> = ({
    triggerChildren,
    triggerStyles = styles.triggerDefault,
    contentStyles = styles.menuContentDefault,
    contentSide = "left",
    sideOffset = 5,
    children,
}) => {
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger className={triggerStyles} asChild>
                {triggerChildren}
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    className={contentStyles}
                    sideOffset={sideOffset}
                    side={contentSide}
                >
                    {children}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    )
}

export default RadixDropDownMenu
