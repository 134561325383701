import { graphql } from "../../generated"

export const ALERT_RULE_UPDATE = graphql(`
    mutation rulesUpdate($input: RuleUpdateInput!) {
        ruleUpdate(input: $input) {
            rule {
                ...AlertRuleFields
            }
        }
    }
`)
