import { ColumnFiltersState, SortingState } from "@tanstack/react-table"
import { urlSearchParamsToObject } from "../../util/urlUtil"
import {
    columnFiltersToObj,
    parseToSortState,
    sortStateToObj,
} from "../../util/urlUtil/tanStack"
import {
    CustomUrlMappingGenericType,
    TableStateType,
    TableUrlQueryParams,
} from "./tableTypes"

const getTableStateFromUrlParams = (
    searchParams: URLSearchParams,
    defaultState: TableStateType,
    pageSizeSelectorArray: number[],
    customUrlMappings: CustomUrlMappingGenericType = {}
) => {
    const currentUrlObj = urlSearchParamsToObject(
        searchParams,
        true
    ) as TableUrlQueryParams
    const tableState = { ...defaultState }

    // Handle page
    if (currentUrlObj.page) {
        const parsedPage = parseInt(currentUrlObj.page)
        if (!isNaN(parsedPage) && parsedPage > 0) {
            tableState.pageIndex = parsedPage - 1
        }
    }

    // Handle size
    if (currentUrlObj.size) {
        const parsedSize = parseInt(currentUrlObj.size)
        if (!isNaN(parsedSize) && parsedSize > 0) {
            const maxPageSize = Math.max(...pageSizeSelectorArray)
            tableState.size =
                parsedSize >= maxPageSize ? maxPageSize : parsedSize
        }
    }

    // Handle sorting
    if (currentUrlObj.sortdesc) {
        const sortDesc = parseToSortState(currentUrlObj.sortdesc, true, ",")
        tableState.sorting.push(...sortDesc)
    }
    if (currentUrlObj.sortasc) {
        const sortAsc = parseToSortState(currentUrlObj.sortasc, false, ",")
        tableState.sorting.push(...sortAsc)
    }

    // Handle search
    if (currentUrlObj.search) {
        tableState.search = currentUrlObj.search
    }

    // Handle custom fields
    // if the key is found in the url, call the handler with the value and the table state
    Object.entries(customUrlMappings).forEach(([key, handler]) => {
        if (typeof key === "string") {
            const keyLower = key.toLowerCase()
            if (currentUrlObj[keyLower]) {
                handler(currentUrlObj[keyLower] as string, tableState)
            }
        }
    })

    return tableState
}

const tableStateToUrlSearchParams = (
    paginationState: {
        pageIndex: number
        pageSize: number
    },
    sortingState: SortingState,
    columnFilters: ColumnFiltersState,
    globalFilter: string
): URLSearchParams => {
    let urlObj: TableUrlQueryParams = {
        page: String(paginationState.pageIndex + 1),
        size: String(paginationState.pageSize),
    }

    const sortState = sortStateToObj(sortingState)
    const columnFilter = columnFiltersToObj(columnFilters)

    urlObj = { ...urlObj, ...columnFilter, ...sortState }
    if (globalFilter) {
        urlObj = { ...urlObj, search: globalFilter }
    }
    const newParams = new URLSearchParams(urlObj as Record<string, string>)
    return newParams
}

export { getTableStateFromUrlParams, tableStateToUrlSearchParams }
