import { FunctionComponent } from "react"
import Button from "../../../shared/button"
import "./editFooter.scss"

interface ActionPanelEditFooter {
    onCancelFooterClick: () => void
    onUpdateFooterClick: () => void
    disabled: boolean
}

const ActionPanelEditFooter: FunctionComponent<ActionPanelEditFooter> = ({
    onCancelFooterClick,
    onUpdateFooterClick,
    disabled,
}) => {
    return (
        <div className="action-panel-edit-footer-container">
            <Button
                handleButtonClick={onCancelFooterClick}
                status={"secondary"}
            >
                Cancel
            </Button>
            <Button
                disabled={disabled}
                handleButtonClick={onUpdateFooterClick}
                status={"primary"}
            >
                Update
            </Button>
        </div>
    )
}

export default ActionPanelEditFooter
