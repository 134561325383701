import { useQuery } from "@apollo/client"
import { FunctionComponent, ReactNode } from "react"
import { Outlet } from "react-router-dom"
import { GET_SUBSCRIPTIONS_FOR_USER } from "../../../../graphql/queries/subscriptionsForUser"
import {
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../shared/graphQlResponse"
import styles from "./mySubscriptions.module.scss"
import { getFragmentData } from "../../../../generated"
import { SUBSCRIPTION_FIELDS_FRAGMENT } from "../../../../graphql/fragments/subscription"
import { getMySubscriptionsTableFromSubscriptionsFrag } from "../subscriptions_GraphQL/subscriptionsFragment"
import MySubscriptionsTable from "./table/table"
import SubscriptionsEmptyState from "../sharedComponents/table/emptyState"

const MySubscriptions: FunctionComponent = () => {
    const { error, loading, data } = useQuery(GET_SUBSCRIPTIONS_FOR_USER, {
        fetchPolicy: "network-only",
    })

    const renderContent = (): ReactNode => {
        if (error) {
            console.error("error", error)
            return (
                <ErrorMessage
                    outSideDivStyle={styles.error}
                    message="There was a problem fetching your subscriptions."
                />
            )
        }

        if (loading) {
            return <SyncLoaderComponent outSideDivStyle={styles.loader} />
        }

        if (data) {
            const subscriptions = data.subscriptionsForUser.subscriptions.map(
                (sub) => getFragmentData(SUBSCRIPTION_FIELDS_FRAGMENT, sub)
            )

            const formatted =
                getMySubscriptionsTableFromSubscriptionsFrag(subscriptions)

            if (formatted.length === 0) {
                return (
                    <SubscriptionsEmptyState
                        viewerAction="self"
                        message=" No subscriptions found"
                    />
                )
            }

            return <MySubscriptionsTable data={formatted} />
        }

        return <></>
    }

    return (
        <>
            <Outlet />
            <div className={styles.container}>{renderContent()}</div>
        </>
    )
}
export default MySubscriptions
