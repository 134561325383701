import { graphql } from "../../generated"

export const GET_SUBSCRIPTIONS_FOR_USER = graphql(`
    query GetSubscriptionsForUser {
        subscriptionsForUser {
            subscriptions {
                ...SubscriptionFields
            }
        }
    }
`)
