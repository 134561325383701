import { graphql } from "../../generated"

export const ADD_WELLS = graphql(`
    mutation AddWells($addWellsInput: AddWellsInput!) {
        addWells(input: $addWellsInput) {
            wells {
                ...WellIdentificationFields
            }
        }
    }
`)
