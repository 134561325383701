import { getFragmentData } from "../../../../../generated"
import { AlertRuleFieldsFragment } from "../../../../../generated/graphql"
import { ALERT_RULE_CONDITION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleCondition"
import { ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleMetricDefinition"
import { DURATION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/duration"
import { AlertRuleFormI } from "../alertRuleFormUtil/alertRuleFormTypes"
import { getInitialAlertRuleFormCondition } from "./alertRuleConditionFragment"
import { getWindowTotalSecondsFromTemplate } from "./alertRuleTemplateFragment"
import { ALERT_TEMPLATE_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleTemplate"

const getAlertRuleFormFromAlertRuleFragment = (
    rule: AlertRuleFieldsFragment
): AlertRuleFormI => {
    const windowFragment = getFragmentData(
        DURATION_FIELDS_FRAGMENT,
        rule.window
    )

    const alertRuleMetricDefinitionFragment = getFragmentData(
        ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT,
        rule.metric
    )

    // get condition fragment
    let conditionFragment = getFragmentData(
        ALERT_RULE_CONDITION_FIELDS_FRAGMENT,
        rule.condition
    )

    // get condition min and max from alert rule template. It does not exists on alert rule when it is queried from the api.
    const templateCondition = alertRuleMetricDefinitionFragment.templates?.find(
        (template) => {
            const templateFrag = getFragmentData(
                ALERT_TEMPLATE_FIELDS_FRAGMENT,
                template
            )

            if (
                getWindowTotalSecondsFromTemplate(templateFrag) ===
                windowFragment.totalSeconds
            )
                return (
                    getWindowTotalSecondsFromTemplate(templateFrag) ===
                    windowFragment.totalSeconds
                )
        }
    )

    // if template condition exists, update the condition fragment with min and max
    if (templateCondition) {
        const templateFragment = getFragmentData(
            ALERT_TEMPLATE_FIELDS_FRAGMENT,
            templateCondition
        )

        const c = getFragmentData(
            ALERT_RULE_CONDITION_FIELDS_FRAGMENT,
            templateFragment.condition
        )

        conditionFragment = {
            ...conditionFragment,
            min: c.min,
            max: c.max,
        }
    }

    // get condition object for form
    const formCondition = getInitialAlertRuleFormCondition(conditionFragment)

    return {
        description: rule.description,
        metric: alertRuleMetricDefinitionFragment.metric,
        ruleName: rule.name,
        condition: formCondition,
        windowTotalSeconds: windowFragment.totalSeconds,
        evaluationGroup: rule.evaluationGroup,
    }
}

export { getAlertRuleFormFromAlertRuleFragment }
