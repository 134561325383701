import { FunctionComponent, ReactNode } from "react"
import styles from "./wellAttributes.module.scss"
import Button from "../../shared/button"
import { useNavigate, Outlet, useOutletContext } from "react-router-dom"
import {
    InstallationFieldsFragment,
    WellIdentificationFieldsFragment,
} from "../../../generated/graphql"
import { IwpOutletContextI } from "../../../pages/individualWellPage"
import { WellAttributesFormI } from "../../../util/wellAttributes/wellAttributesTypes"
import classNames from "classnames"
import {
    getWiringModeInitials,
    wiringModeUiDisplayObject,
} from "../../../util/installation"
import {
    canRoleUpdateWellAttributes,
    canRoleUpdateWellWiringMode,
} from "../../../util/rolePermissions/canRole"
import { useViewerContext } from "../../../context/ViewerContext"
import { getWellAttributesFormFromWellIdentificationFragment } from "../../../util/wellAttributes"
import { UpdateWiringModeModalLocationState } from "../updateWellWiringModeModal/updateWellWiringModeModalTypes"
import { ApolloError } from "@apollo/client"

export interface WellAttributesComponentI {
    wellIdentificationFragment: WellIdentificationFieldsFragment | undefined
    installationFragment: InstallationFieldsFragment | null | undefined
    windowWidth: number
}

const WellAttributes: FunctionComponent = () => {
    const iwpContext = useOutletContext()
    const {
        wellAttributes: {
            wellIdentificationFragment,
            windowWidth,
            installationFragment,
        },
        loading,
        error,
    } = iwpContext as IwpOutletContextI

    const navigate = useNavigate()
    const { getViewer } = useViewerContext()
    const { role } = getViewer()

    // handlers
    const handleNavigateToUpdateAttributes = () => {
        let locationState: WellAttributesFormI | undefined = undefined

        if (wellIdentificationFragment) {
            locationState = getWellAttributesFormFromWellIdentificationFragment(
                wellIdentificationFragment
            )
        }

        navigate(`edit`, {
            state: locationState,
        })
    }

    const handleNavigateToUpdateInstallation = () => {
        let locationState: UpdateWiringModeModalLocationState | undefined =
            undefined

        if (wellIdentificationFragment && installationFragment) {
            locationState = {
                wellIdentificationFragment: wellIdentificationFragment,
                wiringMode: installationFragment.wiringMode,
            }
        }
        navigate(`editInstallation`, {
            state: locationState,
        })
    }

    //  transform wiring mode into value to be displayed. i.e - NORMALL_OPEN => Normally Open (N.O)
    let wiringModeDisplay: ReactNode = ""
    if (installationFragment && installationFragment.wiringMode) {
        wiringModeDisplay = (
            <>
                <span>
                    {wiringModeUiDisplayObject[installationFragment.wiringMode]}
                </span>
                &nbsp;
                <span>
                    {getWiringModeInitials(installationFragment.wiringMode)}
                </span>
            </>
        )
    }

    // component rendering
    const accessToUpdateWellAttributes = canRoleUpdateWellAttributes(role)
    const accessToUpdateWellWiringMode = canRoleUpdateWellWiringMode(role)
    // constants
    const apiUnknown = wellIdentificationFragment?.apiNumber.unknown
    const apiNumberFormatted =
        wellIdentificationFragment?.apiNumber.format || ""
    const apiDisplayValue = apiUnknown ? "No API Number" : apiNumberFormatted
    const isWellLinked = wellIdentificationFragment?.linked ? true : false

    const wellAttributesBody =
        windowWidth > 768 ? (
            <WellAttributesBody
                wellIdentificationFragment={wellIdentificationFragment}
                wiringModeDisplay={wiringModeDisplay}
                handleNavigateToUpdateInstallation={
                    handleNavigateToUpdateInstallation
                }
                isQueryLoading={loading}
                error={error}
                accessToUpdateWiringMode={accessToUpdateWellWiringMode}
                apiDisplayValue={apiDisplayValue}
                isWellLinked={isWellLinked}
            />
        ) : (
            <MobileWellAttributesBody
                wellIdentificationFragment={wellIdentificationFragment}
                wiringModeDisplay={wiringModeDisplay}
                handleNavigateToUpdateInstallation={
                    handleNavigateToUpdateInstallation
                }
                isQueryLoading={loading}
                error={error}
                accessToUpdateWiringMode={accessToUpdateWellWiringMode}
                apiDisplayValue={apiDisplayValue}
                isWellLinked={isWellLinked}
            />
        )

    if (error) {
        return (
            <IwpWellAttributesError>
                {wellAttributesBody}
            </IwpWellAttributesError>
        )
    }

    return (
        <>
            <Outlet />
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.headerText}>Well Attributes</div>
                    {accessToUpdateWellAttributes && (
                        <Button
                            handleButtonClick={handleNavigateToUpdateAttributes}
                            status="primary"
                            condition="default"
                            disabled={loading}
                        >
                            Edit
                        </Button>
                    )}
                </div>
                {wellAttributesBody}
            </div>
        </>
    )
}

export default WellAttributes

export interface WellAttributesBodyComponentI {
    wellIdentificationFragment: WellIdentificationFieldsFragment | undefined
    wiringModeDisplay: ReactNode
    handleNavigateToUpdateInstallation: () => void
    isQueryLoading: boolean
    error: ApolloError | undefined
    accessToUpdateWiringMode: boolean
    apiDisplayValue: string
    isWellLinked: boolean
}

const WellAttributesBody: FunctionComponent<WellAttributesBodyComponentI> = ({
    wellIdentificationFragment,
    wiringModeDisplay,
    handleNavigateToUpdateInstallation,
    isQueryLoading,
    error,
    accessToUpdateWiringMode,
    apiDisplayValue,
    isWellLinked,
}) => {
    const isThereError = error ? true : false

    return (
        <>
            <div className={styles.bodyContentOne}>
                <div className={styles.cOne_leftContainer}>
                    <div className={styles.bodyContentTitle}>Well Name *</div>
                    <div className={styles.bodyContentValue}>
                        {wellIdentificationFragment?.name}
                    </div>
                </div>
                <div className={styles.cOne_centerContainer}>
                    <div className={styles.bodyContentTitle}>API # * </div>
                    <div className={styles.bodyContentValue}>
                        {apiDisplayValue}
                    </div>
                </div>
            </div>
            <div className={styles.bodyContentTwo}>
                <div className={styles.cTwo_leftContainer}>
                    <div className={styles.bodyContentTitle}>Latitude *</div>
                    <div className={styles.bodyContentValue}>
                        {wellIdentificationFragment?.wellGeoLocation.latitude}
                    </div>
                </div>
                <div className={styles.cTwo_centerContainer}>
                    <div className={styles.bodyContentTitle}>Longitude *</div>
                    <div className={styles.bodyContentValue}>
                        {wellIdentificationFragment?.wellGeoLocation.longitude}
                    </div>
                </div>
            </div>
            <div className={styles.bodyContentThree}>
                <div className={styles.cThree_LeftContainer}>
                    <div className={styles.cThree_titleValueContainer}>
                        <div className={styles.bodyContentTitle}>
                            Relay Configuration
                        </div>
                        <div className={styles.bodyContentValue}>
                            {wiringModeDisplay}
                        </div>
                    </div>
                    {accessToUpdateWiringMode && (
                        <Button
                            handleButtonClick={
                                handleNavigateToUpdateInstallation
                            }
                            status="primary"
                            condition="default"
                            disabled={
                                isQueryLoading || isThereError || !isWellLinked
                            }
                        >
                            Edit
                        </Button>
                    )}
                </div>
                <div className={styles.cThree_rightContainer}>
                    <div className={styles.cThree_titleValueContainer}>
                        <div className={styles.bodyContentTitle}>
                            Organization
                        </div>
                        <div className={styles.bodyContentValue}>
                            {wellIdentificationFragment?.organization.name}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const MobileWellAttributesBody: FunctionComponent<
    WellAttributesBodyComponentI
> = ({
    wellIdentificationFragment,
    wiringModeDisplay,
    handleNavigateToUpdateInstallation,
    isQueryLoading,
    error,
    accessToUpdateWiringMode,
    apiDisplayValue,
    isWellLinked,
}) => {
    const isThereError = error ? true : false

    return (
        <>
            <div className={styles.mobileBodyRow}>
                <div className={styles.mobileBodyLeft}>
                    <div className={styles.bodyContentTitle}>Well Name *</div>
                    <div className={styles.bodyContentValue}>
                        {wellIdentificationFragment?.name}
                    </div>
                </div>
                <div className={styles.mobileBodyRight}>
                    <div className={styles.bodyContentTitle}>API # * </div>
                    <div
                        className={classNames(
                            styles.bodyContentValue,
                            styles.noWrap
                        )}
                    >
                        {apiDisplayValue}
                    </div>
                </div>
            </div>
            <div className={styles.mobileBodyRow}>
                <div className={styles.mobileBodyLeft}>
                    <div className={styles.bodyContentTitle}>Latitude *</div>
                    <div className={styles.bodyContentValue}>
                        {wellIdentificationFragment?.wellGeoLocation.latitude}
                    </div>
                </div>
                <div className={styles.mobileBodyRight}>
                    <div className={styles.bodyContentTitle}>Longitude *</div>
                    <div className={styles.bodyContentValue}>
                        {wellIdentificationFragment?.wellGeoLocation.longitude}
                    </div>
                </div>
            </div>
            <div className={styles.mobileBodyRow}>
                <div
                    className={
                        accessToUpdateWiringMode
                            ? styles.mobileBodyLeft
                            : styles.mobileBodyLeft_FullWidth
                    }
                >
                    <div className={styles.bodyContentTitle}>
                        Relay Configuration
                    </div>
                    <div className={styles.bodyContentValue}>
                        {wiringModeDisplay}
                    </div>
                </div>
                {accessToUpdateWiringMode && (
                    // if this is removed (^accessToUpdateWiringMode) also adjust the styling on  <div className={ accessToUpdateWiringMode ? styles.mobileBodyLeft:  styles.mobileBodyLeft_FullWidth}>
                    // allows with of above div to be 100%
                    <div className={styles.mobileBodyRight}>
                        <div className={styles.editWiringButton}>
                            <Button
                                handleButtonClick={
                                    handleNavigateToUpdateInstallation
                                }
                                status="primary"
                                condition="default"
                                disabled={
                                    isQueryLoading ||
                                    isThereError ||
                                    !isWellLinked
                                }
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.mobileBodyRow}>
                <div className={styles.mobileBodyLeft_FullWidth}>
                    <div className={styles.bodyContentTitle}>Organization</div>
                    <div className={styles.bodyContentValue}>
                        {wellIdentificationFragment?.organization.name}
                    </div>
                </div>
            </div>
        </>
    )
}

const IwpWellAttributesError: FunctionComponent<{
    children: React.ReactNode
}> = ({ children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerText}>Well Attributes</div>
            </div>
            <div className={styles.error}>
                There was a problem fetching this well.
            </div>
            {children}
        </div>
    )
}
