import { graphql } from "../../../generated"

export const DND_SETTING_CREATE = graphql(`
    mutation DndSettingCreate($dndSettingCreateInput: DnDSettingCreateInput!) {
        dnDSettingCreate(input: $dndSettingCreateInput) {
            setting {
                ...UserSettingsDnDSetting
            }
        }
    }
`)

export const DND_SETTING_UPDATE = graphql(`
    mutation DndSettingUpdate($dndSettingUpdateInput: DnDSettingUpdateInput!) {
        dnDSettingUpdate(input: $dndSettingUpdateInput) {
            settings {
                ...UserSettingsDnDSetting
            }
        }
    }
`)

export const DND_SETTINGS_ACTIVATE = graphql(`
    mutation DndSettingsActivate(
        $dndSettingsActivateInput: DnDSettingsActivateInput!
    ) {
        dnDSettingActivate(input: $dndSettingsActivateInput) {
            settings {
                ...UserSettingsDnDSetting
            }
        }
    }
`)

export const DND_SETTINGS_DEACTIVATE = graphql(`
    mutation DndSettingsDeactivate(
        $dndSettingsDeactivateInput: DnDSettingsDeactivateInput!
    ) {
        dnDSettingDeactivate(input: $dndSettingsDeactivateInput) {
            settings {
                ...UserSettingsDnDSetting
            }
        }
    }
`)
