import { FunctionComponent, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalHeader from "../../../../shared/modalHeader"
import { TrexNavigator } from "../../../../../classes/navigator/navigator"
import ModalFooter from "../../../../shared/modalFooter"
import styles from "./removeSilence.module.scss"
import { useMutation, useQuery } from "@apollo/client"
import { SUBSCRIPTION_UPDATE } from "../../../../../graphql/mutations/subscriptions_update"
import { SUBSCRIPTION_FOR_ID } from "../../../../../graphql/queries/subscriptionForIDs"
import { getFragmentData } from "../../../../../generated"
import { SUBSCRIPTION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/subscription"
import {
    EmptyState,
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../../shared/graphQlResponse"
import { SubscriptionFieldsFragment } from "../../../../../generated/graphql"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/wellIdentification"
import { ALERT_RULE_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRule"
import { SUBJECT_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/subject"
import { IDENTITY_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/Identity"
import { ManageSubscriptionViewerActionT } from "../../manageSubscriptions/manageSubscriptionFormUtil/types"
import { navigateAfterMutation } from "../../../navigate"

interface RemoveSilenceSubscriptionProps {
    viewerAction: ManageSubscriptionViewerActionT
}

const RemoveSilenceSubscription: FunctionComponent<
    RemoveSilenceSubscriptionProps
> = ({ viewerAction }) => {
    const navigate = useNavigate()
    const { key: locationKey } = useLocation()
    const urlSlugs = useParams()
    const subscriptionId = urlSlugs.subscriptionId as string

    // query
    const { error, loading, data } = useQuery(SUBSCRIPTION_FOR_ID, {
        variables: {
            AlertSubscriptionsForIDsInput: {
                ids: [subscriptionId],
            },
        },
    })

    // subscription data
    const subscription = getFragmentData(
        SUBSCRIPTION_FIELDS_FRAGMENT,
        data?.subscriptionsForIDs?.subscriptions[0]
    )

    // rendering
    let removeSilenceComponent = <></>

    if (loading) {
        removeSilenceComponent = (
            <SyncLoaderComponent outSideDivStyle={styles.queryLoading} />
        )
    } else if (error) {
        removeSilenceComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="We had a problem while fetching your silence settings."
            />
        )
    } else if (!subscription) {
        removeSilenceComponent = (
            <EmptyState outSideDivStyle={styles.queryEmpty}>
                <> No subscription was found to remove silence.</>
            </EmptyState>
        )
    } else {
        // the api will not return the subscriptinon if the viewer does not have access to the subscription.
        removeSilenceComponent = (
            <RemoveSilenceFormComp
                subscription={subscription}
                viewerAction={viewerAction}
            />
        )
    }

    return (
        <div className={styles.container}>
            <ModalHeader
                title={"Remove Silence"}
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
            />
            {removeSilenceComponent}
        </div>
    )
}

export default RemoveSilenceSubscription

interface RemoveSilenceFormCompI {
    subscription: SubscriptionFieldsFragment
    viewerAction: ManageSubscriptionViewerActionT
}

const RemoveSilenceFormComp: FunctionComponent<RemoveSilenceFormCompI> = ({
    subscription,
    viewerAction,
}) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [updateSubscriptionError, setUpdateSubscriptionError] =
        useState(false)

    // mutation
    const [updateSubscriptionMutation, { loading: mutationLoading }] =
        useMutation(SUBSCRIPTION_UPDATE)

    // handlers
    const handleSubmitUpdate = async () => {
        const subscribedWells = subscription.subscribedWells.map((well) => {
            const w = getFragmentData(WELL_IDENTIFICATION_FIELDS_FRAGMENT, well)
            return w.id
        })

        await updateSubscriptionMutation({
            variables: {
                AlertSubscriptionUpdateInput: {
                    disturb: subscription.disturb,
                    email: subscription.email,
                    id: subscription.id,
                    includeNewWells: subscription.includeNewWells,
                    phone: subscription.phone,
                    subscribedWells: subscribedWells,
                    silenceUntil: {
                        day: 1,
                        hour: 1,
                        minute: 1,
                        month: 1,
                        year: 1971,
                    },
                },
            },
        })
            .then(() => {
                navigate(navTo.to, navTo.options)
            })
            .catch((error) => {
                console.error("Update subscription mutation error: ", error)
                setUpdateSubscriptionError(true)
                return
            })
    }

    // constants
    const navTo = navigateAfterMutation(pathname)
    const isSubmitDisabled = mutationLoading || updateSubscriptionError
    const alertRuleName = getFragmentData(
        ALERT_RULE_FIELDS_FRAGMENT,
        subscription.alertRule
    ).name

    let subscriberFullName = ""
    if (viewerAction === "admin") {
        const subscriber = getFragmentData(
            SUBJECT_FIELDS_FRAGMENT,
            subscription?.subscriber
        )

        const identity = getFragmentData(
            IDENTITY_FIELDS_FRAGMENT,
            subscriber.identity
        )

        subscriberFullName = identity?.fullName || ""
    }

    if (updateSubscriptionError) {
        return (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="There was a problem updating your subscription."
            />
        )
    }

    return (
        <>
            <div className={styles.body}>
                <div className={styles.title}>
                    Are you sure you want to remove silence from this
                    subscription?
                </div>
                {viewerAction === "admin" && (
                    <div className={styles.subscriptionInfo}>
                        <span className={styles.label}>Subscriber:</span>
                        <span>{subscriberFullName}</span>
                    </div>
                )}
                <div className={styles.subscriptionInfo}>
                    <span className={styles.label}>Rule:</span>
                    <span>{alertRuleName}</span>
                </div>
            </div>
            <ModalFooter
                advanceText="Confirm"
                handleAdvanceClick={handleSubmitUpdate}
                disableAdvance={isSubmitDisabled}
                previousText="Cancel"
                handlePreviousClick={() => {
                    navigate(-1)
                }}
                disablePrevious={isSubmitDisabled}
            />
        </>
    )
}
