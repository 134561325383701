import { FunctionComponent, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalHeader from "../../../shared/modalHeader"
import { TrexNavigator } from "../../../../classes/navigator/navigator"
import ModalFooter from "../../../shared/modalFooter"
import styles from "./silenceSubscription.module.scss"
import { SUBSCRIPTION_FOR_ID } from "../../../../graphql/queries/subscriptionForIDs"
import { useMutation, useQuery } from "@apollo/client"
import {
    EmptyState,
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../shared/graphQlResponse"
import { SUBSCRIPTION_UPDATE } from "../../../../graphql/mutations/subscriptions_update"
import { getFragmentData } from "../../../../generated"
import { SUBSCRIPTION_FIELDS_FRAGMENT } from "../../../../graphql/fragments/subscription"
import { ALERT_RULE_FIELDS_FRAGMENT } from "../../../../graphql/fragments/alertRule"
import { SubscriptionFieldsFragment } from "../../../../generated/graphql"
import { DurationUnitT } from "../../../../util/duration/types"
import { parseNumberInput } from "../../../../util/InputValidation/inputValidation"
import Input from "../../../shared/input"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons"
import { getDateNowPlusDuration } from "../../../../util/duration/duration"
import { ManageSilenceFormI } from "./manageSilenceFormUtil/types"
import { getManageSilenceMutationValues } from "./manageSilenceFormUtil/api"
import Button from "../../../shared/button"
import { dateToLocalDateTimeDisplay } from "../../../../util/datesAndTimes/datesAndTimes"
import { isManageSilenceSubmitDisabled } from "./manageSilenceFormUtil/validation"
import {
    NOTIFICATIONS_ADMIN_REMOVE_SILENCE_CHILD,
    NOTIFICATIONS_REMOVE_SILENCE_CHILD,
} from "../../../.."
import { silenceDurationUnitOptions } from "./manageSilenceFormUtil/constants"
import { SUBJECT_FIELDS_FRAGMENT } from "../../../../graphql/fragments/subject"
import { IDENTITY_FIELDS_FRAGMENT } from "../../../../graphql/fragments/Identity"
import { ManageSubscriptionViewerActionT } from "../manageSubscriptions/manageSubscriptionFormUtil/types"
import ToolTip_Popover from "../../../shared/toolTip/toolTip_popover"

interface ManageSubscriptionSilenceProps {
    viewerAction: ManageSubscriptionViewerActionT
}

const ManageSubsriptionSilence: FunctionComponent<
    ManageSubscriptionSilenceProps
> = ({ viewerAction }) => {
    const urlSlugs = useParams()
    const subscriptionId = urlSlugs.subscriptionId as string
    const navigate = useNavigate()
    const { key: locationKey } = useLocation()

    // fetch
    const { error, loading, data } = useQuery(SUBSCRIPTION_FOR_ID, {
        variables: {
            AlertSubscriptionsForIDsInput: {
                ids: [subscriptionId],
            },
        },
    })

    // subscription data
    const subscription = getFragmentData(
        SUBSCRIPTION_FIELDS_FRAGMENT,
        data?.subscriptionsForIDs.subscriptions[0]
    )

    const alertRule = getFragmentData(
        ALERT_RULE_FIELDS_FRAGMENT,
        subscription?.alertRule
    )

    // rendering
    let silenceComponent = <></>

    if (loading) {
        silenceComponent = (
            <SyncLoaderComponent outSideDivStyle={styles.queryLoading} />
        )
    } else if (error) {
        silenceComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="We had a problem while fetching your silence settings."
            />
        )
    } else if (!subscription) {
        silenceComponent = (
            <EmptyState outSideDivStyle={styles.queryEmpty}>
                <> No subscription was found to silence.</>
            </EmptyState>
        )
    } else {
        // the api will not return the subscriptinon if the viewer does not have access to the subscription.
        silenceComponent = (
            <ManageSilenceFormComp
                subscription={subscription}
                viewerAction={viewerAction}
            />
        )
    }

    return (
        <div className={styles.container}>
            <ModalHeader
                title="Silence Subscription"
                subTitle={alertRule?.name}
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
            />
            {silenceComponent}
        </div>
    )
}

export default ManageSubsriptionSilence

interface ManageSilenceComponetI {
    subscription: SubscriptionFieldsFragment
    viewerAction: ManageSubscriptionViewerActionT
}

const ManageSilenceFormComp: FunctionComponent<ManageSilenceComponetI> = ({
    subscription,
    viewerAction,
}) => {
    const navigate = useNavigate()

    // state
    const [mutationError, setMutationError] = useState(false)

    const [form, setForm] = useState<ManageSilenceFormI>({
        timeUnit: "m",
        timeValue: "",
    })

    // state to update so our timer can re-render the component every minute. This is necessary to keep the silenceUntil value accurate
    const [, setCurrentTime] = useState(new Date())
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date())
        }, 60000) // 60000 milliseconds = 1 minute

        return () => clearInterval(timer)
    }, [])

    // mutation
    const [subscriptionsUpdateMutation, { loading: mutationLoading }] =
        useMutation(SUBSCRIPTION_UPDATE)

    // handlers
    const handleTimeValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseNumberInput(e)
        setForm({ ...form, timeValue: value as number })
    }

    const handleTimeUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const targetUnit = e.target.value as DurationUnitT
        setForm({ ...form, timeUnit: targetUnit })
    }

    const handleSubmitManageSilence = async () => {
        await subscriptionsUpdateMutation({
            variables: {
                AlertSubscriptionUpdateInput: getManageSilenceMutationValues(
                    form,
                    subscription
                ),
            },
        })
            .then(() => {
                navigate(-1)
            })
            .catch((error) => {
                console.error(
                    "subscription update - update silence mutation error: ",
                    error
                )
                setMutationError(true)
            })
    }

    let silenceUnitlDisplay = ""
    let initialSilenceSettingDisplay = ""

    if (typeof form.timeValue === "number") {
        const silenceUntil = getDateNowPlusDuration({
            unit: form.timeUnit,
            value: form.timeValue,
        })
        // TODO: The time is in local but, we would also like to display a timezone description here
        silenceUnitlDisplay = dateToLocalDateTimeDisplay(silenceUntil, "-")
    }

    // we are updating the silence setting if the initial silence setting is greater than now. Else user is just adding a new silence setting
    const isFormUpdateSilence =
        subscription.silenceUntil.unixMilliseconds > new Date().getTime()

    if (isFormUpdateSilence) {
        const initialSilenceSetting = new Date(
            subscription.silenceUntil.unixMilliseconds
        )
        // TODO: The time is in local but, we would also like to display a timezone description here
        initialSilenceSettingDisplay = dateToLocalDateTimeDisplay(
            initialSilenceSetting,
            "-"
        )
    }

    let subscriberFullName = ""
    if (viewerAction === "admin") {
        const subscriber = getFragmentData(
            SUBJECT_FIELDS_FRAGMENT,
            subscription?.subscriber
        )

        const identity = getFragmentData(
            IDENTITY_FIELDS_FRAGMENT,
            subscriber.identity
        )

        subscriberFullName = identity?.fullName || ""
    }

    // rendering
    if (mutationError) {
        return (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="There was a problem updating your silence settings."
            />
        )
    }

    return (
        <>
            <div
                className={classNames(
                    styles.bodyContainer,
                    !isFormUpdateSilence && styles.addSilenceBodyContainer
                )}
            >
                {viewerAction === "admin" && (
                    <div className={styles.subscriptionDetails}>
                        <div
                            className={classNames(
                                styles.subscriberLabel,
                                styles.titleText
                            )}
                        >
                            Subscriber:
                        </div>
                        <span>{subscriberFullName}</span>
                    </div>
                )}
                {isFormUpdateSilence && (
                    <div
                        className={classNames(
                            styles.flexRow,
                            styles.initalSilenceRow
                        )}
                    >
                        <span className={styles.titleText}>
                            {`Current Silence is set until: ${initialSilenceSettingDisplay}`}
                        </span>
                    </div>
                )}
                <div className={styles.flexRow}>
                    <div
                        className={classNames(
                            styles.titleText,
                            styles.silenceLabel
                        )}
                    >
                        <span className={styles.marginRight4}>
                            {isFormUpdateSilence && "Update "} Silence Duration:
                        </span>
                        <ToolTip_Popover
                            content={
                                "Notifications will not be sent to sms or email during a silenced period"
                            }
                            triggerChildren={
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    className={styles.fai}
                                />
                            }
                            popover={{
                                removePopoverButtonStyling: true,
                            }}
                        />
                    </div>
                    <Input
                        type="number"
                        onChange={handleTimeValueChange}
                        value={form.timeValue as number}
                        orientation="horizontal"
                        specializedClass="noMessage"
                        inputCustomClass={styles.valueInput}
                        min={0}
                    />
                    <Input
                        type="select"
                        onChange={handleTimeUnitChange}
                        options={silenceDurationUnitOptions}
                        orientation="horizontal"
                        value={form.timeUnit}
                        inputCustomClass={styles.unitInput}
                        specializedClass="noMessage"
                    />
                </div>
                <div
                    className={classNames(
                        styles.bodyText,
                        styles.silenceUntilRow
                    )}
                >
                    <span className={styles.marginRight8}>
                        {isFormUpdateSilence && (
                            <span> Updated silence will expire on:</span>
                        )}
                        {!isFormUpdateSilence && (
                            <span> Silence will expire on: </span>
                        )}
                    </span>
                    {silenceUnitlDisplay}
                </div>
                {isFormUpdateSilence && (
                    <div className={styles.removeSilenceRow}>
                        <Button
                            condition="default"
                            status="secondary"
                            handleButtonClick={() => {
                                const route =
                                    viewerAction === "admin"
                                        ? `../${NOTIFICATIONS_ADMIN_REMOVE_SILENCE_CHILD}`
                                        : `../${NOTIFICATIONS_REMOVE_SILENCE_CHILD}`

                                navigate(route, {
                                    relative: "path",
                                })
                            }}
                            disabled={false}
                        >
                            Remove Silence
                        </Button>
                    </div>
                )}
            </div>
            <ModalFooter
                advanceText={isFormUpdateSilence ? "Save" : "Confirm"}
                disableAdvance={
                    mutationLoading ||
                    mutationError ||
                    isManageSilenceSubmitDisabled(form.timeValue)
                }
                handleAdvanceClick={handleSubmitManageSilence}
            />
        </>
    )
}
