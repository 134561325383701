import { FunctionComponent, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useWellControlsContext } from "../../../context/wellControlsContext"
// components
import WellControlsConfirmation from "./wellControlsConfirmation"
import ModalHeader from "../../shared/modalHeader"
import WellControlsNavigation from "./wellControlsNavigation"
import ModalFooter, {
    ModalFooterComponentInterface,
} from "../../shared/modalFooter"
import WellControlRunMode from "./wellControlRunMode"
import Alert from "../../shared/alert"
import WellControlsHistory from "./wellControlsHistory"
//types
import { WellControlStateInterface } from "../../../types/wellControlModalTypes"
//utils
import {
    disableHistoryEdit,
    disableRunModeChangeAdvance,
    disableWellControlSubmission,
    getAddWellControlVariablesInput,
} from "../../../util/wellControlUtil/wellControlsUtils"
//  scss
import "./wellControls.scss"
// graphql
import { useMutation, useQuery } from "@apollo/client"
import { ADD_WELL_CONTROL_SETTING } from "../../../graphql/mutations/control_settings"
import { getFragmentData } from "../../../generated"
//util
import { getWellControlSettingFragmentFieldsUnion } from "../../../util/ControlSettingUtil"
import { GET_CONTROL_SETTINGS_HISTORY } from "../../../graphql/queries/controlSettingHistory"
import { CONTROL_SETTING_FIELDS_FRAGMENT } from "../../../graphql/fragments/wellControlSetting"
import { TrexNavigator } from "../../../classes/navigator/navigator"
import { useViewerContext } from "../../../context/ViewerContext"
import {
    NORMAL_DISTRIBUTION_PARAMETERS_FIELDS_FRAGMENT,
    WELL_CONFIGURATION_REVISION_PARAMETERS_FIELDS_FRAGMENT,
} from "../../../graphql/fragments/wellConfigurationRevision"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../../graphql/fragments/wellIdentification"

const WellControls: FunctionComponent = () => {
    // UseNavigate - used to close modal & navigate back to dashboard.
    const navigate = useNavigate()
    const viewerContext = useViewerContext()
    const { role: userRole } = viewerContext.getViewer()
    const { key: locationKey } = useLocation()

    // wellId from URL - used query individual well via graphQL.
    const { wellId } = useParams()

    if (!wellId) {
        throw new Error("wellId not resolved from url.")
    }

    const wellControlSettingHistoryCount = 4

    const { error, loading, data } = useQuery(GET_CONTROL_SETTINGS_HISTORY, {
        variables: {
            wellControlInput: { wellID: wellId },
            historyCount: wellControlSettingHistoryCount,
            wellInput: { id: wellId },
        },
    })

    // GraphQL mutaion -  Add well control setting.
    const [addWellControlSettingMutation, { loading: mutationLoading }] =
        useMutation(ADD_WELL_CONTROL_SETTING, {
            // get control setting history needs to be refetched so that the new control setting is displayed in the history.
            refetchQueries: [
                {
                    query: GET_CONTROL_SETTINGS_HISTORY,
                    variables: {
                        wellControlInput: { wellID: wellId },
                        historyCount: wellControlSettingHistoryCount,
                        wellInput: { id: wellId },
                    },
                },
            ],
        })

    useEffect(() => {
        if (!data) {
            // data has not returned yet
            return
        } else {
            const controlSettingHistory = data.controlHistory.nodes?.map(
                (node) => {
                    return getFragmentData(
                        CONTROL_SETTING_FIELDS_FRAGMENT,
                        node
                    )
                }
            )
            if (!controlSettingHistory) {
                throw new Error(
                    "Control setting history should have at least one control setting."
                )
            }
            // Used to set initial well control state for 'run mode'. Additonally sets state for 'timer' & 'smart' mode when applicable.
            setWellControlStateOnInitialQuery(controlSettingHistory[0])
        }
    }, [data])

    const {
        handleApplyControlSettingEdit,
        handleApplyControlSettingHistory,
        handleApplyRunModeChange,
        handleExitSubmitRunModeChange,
        setWellControlStateOnInitialQuery,
        setWellControlStateToError,
        wellControlState,
    } = useWellControlsContext()

    if (error) {
        throw new Error(
            ` Graphql GET_CONTROL_SETTINGS_HISTORY error: ${error}.`
        )
    }

    if (loading) {
        return <></>
    }

    if (mutationLoading) {
        return <></>
    }

    if (!data) {
        throw new Error(
            "Graphql GET_CONTROL_SETTINGS_HISTORY returned undefined data."
        )
    }

    const controlSettingHistory = data.controlHistory.nodes?.map((node) => {
        return getFragmentData(CONTROL_SETTING_FIELDS_FRAGMENT, node)
    })

    if (!controlSettingHistory) {
        throw new Error(
            "Control setting history should have at least one control setting. "
        )
    }

    // constants
    const { runMode: initialRunMode } =
        getWellControlSettingFragmentFieldsUnion(controlSettingHistory[0])

    if (!data.well) {
        throw new Error("Well data is undefined.")
    }

    const { name: wellName } = getFragmentData(
        WELL_IDENTIFICATION_FIELDS_FRAGMENT,
        data.well
    )

    const wellConfigurationRevisionFragment = getFragmentData(
        WELL_CONFIGURATION_REVISION_PARAMETERS_FIELDS_FRAGMENT,
        data.well.configRevision
    )

    const normalDistributionParametersFragment = getFragmentData(
        NORMAL_DISTRIBUTION_PARAMETERS_FIELDS_FRAGMENT,
        wellConfigurationRevisionFragment.stateChangeParameters.prior
    )

    /**
     * Submit Well Controls
     * read state of modal to apply well control setting mutation
     * navigate back to where user came from upon success
     *
     */

    const handleSubmitWellControls = async (
        wellControlState: WellControlStateInterface
    ) => {
        const inputVariables = getAddWellControlVariablesInput(
            wellControlState,
            userRole,
            wellId
        )

        // call addWellControlSetting mutation.
        await addWellControlSettingMutation({
            variables: {
                addWellControlSettingInput: inputVariables,
            },
        })
            .then(() => {
                // Upon successful mutation. Navigate back to the dashboard.
                navigate(-1)
            })
            .catch((error) => {
                console.error("Well controls mutation error:", error)
                setWellControlStateToError()
            })
    }

    const { wellControlModalView, wellControlConfirmation, mutationError } =
        wellControlState.UiState

    let body: React.ReactElement
    let footerProps = {} as ModalFooterComponentInterface

    // Render UI state of modal dependent on wellControlConfirmation state
    switch (wellControlConfirmation) {
        case true:
            body = (
                <>
                    <WellControlsConfirmation changeFrom={initialRunMode} />
                    {mutationError && (
                        <Alert
                            alertType="danger"
                            isCloseable={false}
                            message={
                                <div>
                                    There was a problem updating your control
                                    setting. Click{" "}
                                    <u
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate("/dashboard")}
                                    >
                                        here
                                    </u>{" "}
                                    or close the modal to be redirected to the
                                    dashboard.
                                </div>
                            }
                        />
                    )}
                </>
            )

            footerProps = {
                advanceText: "Submit",
                handleAdvanceClick: () =>
                    handleSubmitWellControls(wellControlState),
                disableAdvance: disableWellControlSubmission(
                    mutationLoading,
                    mutationError
                ),
                previousText: "Previous",
                handlePreviousClick: handleExitSubmitRunModeChange,
                disablePrevious: disableWellControlSubmission(
                    mutationLoading,
                    mutationError
                ),
            }

            break
        case false:
            if (wellControlModalView === "Control") {
                body = (
                    <WellControlRunMode
                        normalDistributionParametersFragment={
                            normalDistributionParametersFragment
                        }
                    />
                )
                // footer props for control UI state
                footerProps = {
                    advanceText: "Apply",
                    handleAdvanceClick: handleApplyRunModeChange,
                    disableAdvance: disableRunModeChangeAdvance(
                        initialRunMode,
                        wellControlState
                    ),
                    previousText: undefined,
                    handlePreviousClick: undefined,
                }
            } else {
                body = (
                    <WellControlsHistory
                        history={controlSettingHistory}
                        historyCount={wellControlSettingHistoryCount}
                    />
                )
                // footer props for history UI state
                footerProps = {
                    advanceText: "Apply",
                    handleAdvanceClick: () =>
                        handleApplyControlSettingHistory(
                            wellControlState.UiState.selectedHistoryIndex,
                            controlSettingHistory
                        ),
                    disableAdvance:
                        wellControlState.UiState.selectedHistoryIndex ===
                        undefined
                            ? true
                            : false,
                    previousText: "Edit",
                    handlePreviousClick: () =>
                        handleApplyControlSettingEdit(
                            wellControlState.UiState.selectedHistoryIndex,
                            controlSettingHistory
                        ),
                    disablePrevious: disableHistoryEdit(
                        wellControlState.UiState.selectedHistoryIndex,
                        controlSettingHistory,
                        userRole
                    ),
                }
            }
            break
    }

    const {
        advanceText,
        disableAdvance,
        previousText,
        handleAdvanceClick,
        handlePreviousClick,
        disablePrevious,
    } = footerProps

    return (
        <div className="wellControl-modal-container">
            <ModalHeader
                title={"Well Controls"}
                subTitle={wellName}
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
            />
            {!wellControlConfirmation && <WellControlsNavigation />}
            {body}
            <ModalFooter
                advanceText={advanceText}
                disableAdvance={disableAdvance}
                previousText={previousText}
                handleAdvanceClick={handleAdvanceClick}
                handlePreviousClick={handlePreviousClick}
                disablePrevious={disablePrevious}
            />
        </div>
    )
}

export default WellControls
