import { graphql } from "../../generated"

export const SUBSCRIPTION_FOR_ID = graphql(`
    query SubscriptionForIDs(
        $AlertSubscriptionsForIDsInput: AlertSubscriptionsForIDsInput!
    ) {
        subscriptionsForIDs(input: $AlertSubscriptionsForIDsInput) {
            subscriptions {
                ...SubscriptionFields
            }
        }
    }
`)
