import { getFragmentData } from "../../../generated"
import {
    WellControlObservation,
    WellControlStateFieldsFragment,
} from "../../../generated/graphql"
import { WELL_CONTROL_OBSERVATION_STATE_FIELDS_FRAGMENT } from "../../../graphql/fragments/wellState"
import { WellStateObservationUiDisplayType } from "./types"

/**
 * Before changing this function or other functions in this directory understand this may be used in various parts of the UI.
 * This function can be called on wellControlStateFragment and will return the observation state.
 */
const wellObservationStateToDipslay = (
    wellState: WellControlStateFieldsFragment
): WellStateObservationUiDisplayType => {
    const observationFragment = getFragmentData(
        WELL_CONTROL_OBSERVATION_STATE_FIELDS_FRAGMENT,
        wellState.observation
    )

    switch (observationFragment.state) {
        case WellControlObservation.Running:
            return "Running"
        case WellControlObservation.Stopped:
            return "Stopped"
        case WellControlObservation.Unknown:
            // well is linked and status is unknown
            return "Unknown"
        default:
            throw new Error(
                "getWellStatus in wellIndexTableUtil file - observationFragment did not return a known value for wellControlObservationState"
            )
    }
}

export { wellObservationStateToDipslay }
