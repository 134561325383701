import React, { FunctionComponent, useState } from "react"
import { useAddWellsContext } from "../../context/addWellsContext"
import { useLocation, useNavigate } from "react-router-dom"
// components
import { DisplayPanelListView } from "./addWellsDisplayPanel"
import {
    ActionPanelEdit,
    ActionPanelPreview,
    ActionPanelEmptyState,
} from "./addWellsActionPanel"
import AddWellsConfirmation from "./addWellsConfirmation"
import WellApiSearch from "./WellApiNumberForm"
import ModalHeader, {
    ModalHeaderComponentInterface,
} from "../shared/modalHeader"
import ModalFooter, {
    ModalFooterComponentInterface,
} from "../shared/modalFooter"
import Alert from "../shared/alert"
// scss
import styles from "./addWells.module.scss"
//utils
import {
    canMoveOnToConfirmation,
    formatAddWellsMutaionVariables,
} from "../../util/addWellsUtils/addWellsUtil"
//graphql
import { AddWellsMutationVariables } from "../../generated/graphql"
import { useMutation } from "@apollo/client"
import { ADD_WELLS } from "../../graphql/mutations/add_wells"
import { GET_INDEX_WELLS } from "../../graphql/queries/wells"
import { TrexNavigator } from "../../classes/navigator/navigator"

const AddWells: FunctionComponent = () => {
    // react router navigate
    const navigate = useNavigate()
    const { key: locationKey, pathname } = useLocation()

    const [addWellsErrorBanner, setAddWellsErrorBanner] = useState(false)

    const {
        addWellsState: {
            addWellsInterfaceState,
            displayPanelArray,
            actionPanel,
            selectedOrganization,
        },
        handleAdvanceToConfirmation,
        handleConfirmationPrevious,
    } = useAddWellsContext()

    // Handle submission of add wells modal
    const [addWellMutation, { loading: mutationLoading }] =
        useMutation(ADD_WELLS)

    /** Handle submission of add wells modal */
    const handleAddWellSubmission = async () => {
        if (selectedOrganization === undefined) {
            throw new Error(
                "selected organization value was not properly passed to add wells variables"
            )
        }

        const addWellsVariables: AddWellsMutationVariables =
            formatAddWellsMutaionVariables(
                displayPanelArray,
                selectedOrganization.value
            )

        await addWellMutation({
            variables: addWellsVariables,
            // Get index well is refetched to fetch the well that was just added.
            refetchQueries: [GET_INDEX_WELLS],
        })
            .then(() => {
                // Upon successful mutation. Navigate back to the dashboard.
                navigate(-1)
            })
            .catch((error) => {
                console.error("Add well mutation error: ", error)
                setAddWellsErrorBanner(true)
            })
    }

    const modalHeaderProps: ModalHeaderComponentInterface = {
        title: "",
        trexNavigator: new TrexNavigator(
            {
                navigateTo: -1,
                locationKey: locationKey,
                pathName: pathname,
            },
            navigate
        ),
    }
    let modalFooterProps = {} as ModalFooterComponentInterface
    let body: React.ReactElement
    let AddWellApiActionPanel: React.ReactElement
    let disableAdvanceFooter: boolean
    let disablePreviousFooter: boolean

    switch (addWellsInterfaceState) {
        case "addNew":
            switch (actionPanel) {
                case "edit":
                    AddWellApiActionPanel = <ActionPanelEdit />
                    break
                case "preview":
                    AddWellApiActionPanel = <ActionPanelPreview />
                    break
                default:
                    AddWellApiActionPanel = <ActionPanelEmptyState />
            }

            modalHeaderProps.title = "Add Well Sites"
            body = (
                <>
                    <WellApiSearch />
                    <div className={styles.bodyContainer}>
                        <div className={styles.bodyLeft}>
                            <DisplayPanelListView />
                        </div>
                        <div className={styles.bodyRight}>
                            {AddWellApiActionPanel}
                        </div>
                    </div>
                </>
            )

            modalFooterProps = {
                handleAdvanceClick: handleAdvanceToConfirmation,
                disableAdvance: !canMoveOnToConfirmation(
                    displayPanelArray.length,
                    selectedOrganization
                ),
                advanceText: "Next",
            }

            break
        case "confirm":
            modalHeaderProps.title = "Confirm Well Sites"
            body = (
                <>
                    <AddWellsConfirmation />
                    {addWellsErrorBanner && (
                        <Alert
                            isCloseable={false}
                            message={
                                <div>
                                    There was a problem adding your well(s).
                                    Click{" "}
                                    <u
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate("/dashboard")}
                                    >
                                        here
                                    </u>{" "}
                                    or close the modal to be redirected to the
                                    dashboard.
                                </div>
                            }
                            alertType="danger"
                        />
                    )}
                </>
            )

            // should footer be disabled
            if (addWellsErrorBanner || mutationLoading) {
                disableAdvanceFooter = true
                disablePreviousFooter = true
            } else {
                disableAdvanceFooter = false
                disablePreviousFooter = false
            }

            // set footer props
            modalFooterProps = {
                disableAdvance: disableAdvanceFooter,
                disablePrevious: disablePreviousFooter,
                handleAdvanceClick: handleAddWellSubmission,
                handlePreviousClick: handleConfirmationPrevious,
                advanceText: "Submit",
                previousText: "Previous",
            }

            break
    }

    return (
        <div className={styles.container}>
            <ModalHeader
                title={modalHeaderProps.title}
                trexNavigator={modalHeaderProps.trexNavigator}
            />
            {body}
            <ModalFooter
                advanceText={modalFooterProps.advanceText}
                disableAdvance={modalFooterProps.disableAdvance}
                handleAdvanceClick={modalFooterProps.handleAdvanceClick}
                handlePreviousClick={modalFooterProps.handlePreviousClick}
                previousText={modalFooterProps.previousText}
                disablePrevious={modalFooterProps.disablePrevious}
            />
        </div>
    )
}

export default AddWells
