import { graphql } from "../../generated"

export const VIEWER = graphql(`
    query Viewer {
        viewer {
            organization {
                ...OrganizationWithDescendantsFields
            }
            identity {
                ...IdentityFields
            }
            role
            id
        }
    }
`)
