import { TableStateType } from "../../../table/tableTypes"
import { getTableStateFromUrlParams } from "../../../table/tableUtil"
import {
    METRIC_COLUMN_ID,
    SUBSCRIBED_WELLS_COLUMN_ID,
    SUBSCRIBER_COLUMN_ID,
    RULE_NAME_COLUMN_ID,
} from "./column"
import { SubscriptionsAdminUrlToStateMappings } from "./tableTypes"

const subscriptionsAdminPageSizeSelector = [15, 25, 50]

const subscriptionsAdminTableUrlMapping: SubscriptionsAdminUrlToStateMappings =
    {
        [METRIC_COLUMN_ID]: (value: string, state: TableStateType) => {
            state.filter.push({ id: METRIC_COLUMN_ID, value })
        },
        [SUBSCRIBED_WELLS_COLUMN_ID]: (
            value: string,
            state: TableStateType
        ) => {
            state.filter.push({ id: SUBSCRIBED_WELLS_COLUMN_ID, value })
        },
        [SUBSCRIBER_COLUMN_ID]: (value: string, state: TableStateType) => {
            state.filter.push({ id: SUBSCRIBER_COLUMN_ID, value })
        },
        [RULE_NAME_COLUMN_ID]: (value: string, state: TableStateType) => {
            state.filter.push({ id: RULE_NAME_COLUMN_ID, value })
        },
    }

const getSubscriptionAdminStateFromUrlParams = (
    searchParams: URLSearchParams,
    customMapping: SubscriptionsAdminUrlToStateMappings
): TableStateType => {
    const defaultState: TableStateType = {
        pageIndex: 0,
        size: subscriptionsAdminPageSizeSelector[0],
        sorting: [],
        search: "",
        filter: [],
    }

    return getTableStateFromUrlParams(
        searchParams,
        defaultState,
        subscriptionsAdminPageSizeSelector,
        customMapping
    )
}
export {
    getSubscriptionAdminStateFromUrlParams,
    subscriptionsAdminPageSizeSelector,
    subscriptionsAdminTableUrlMapping,
}
