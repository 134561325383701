import { graphql } from "../../generated"

export const SUBSCRIPTION_ADD = graphql(`
    mutation SubscriptionAdd(
        $AlertSubscriptionsAddInput: AlertSubscriptionsAddInput!
    ) {
        subscriptionsAdd(input: $AlertSubscriptionsAddInput) {
            subscriptions {
                ...SubscriptionFields
            }
        }
    }
`)
