import { ColumnFiltersState, SortingState } from "@tanstack/react-table"

/**
 * Parse a value (string) to TanStack ColumnSort objects.
 *
 * @param value - value from url query param.
 * @param desc - A boolean indicating whether the sorting order is descending (true) or ascending (false).
 * @param delimiter - The delimiter used to separate different sorting parameters within the param string.
 * @returns An array of ColumnSort objects representing the parsed sorting configuration.
 */
const parseToSortState = (
    value: string,
    desc: boolean,
    delimiter: string
): SortingState => {
    return value.split(delimiter).map((id) => ({ id, desc }))
}

// currently only viable for single sorting
const sortStateToObj = (
    sortState: SortingState
): { sortasc?: string; sortdesc?: string } => {
    if (sortState.length === 0) {
        return {}
    }

    const sort = sortState[0]
    return {
        [sort.desc ? "sortdesc" : "sortasc"]: sort.id,
    }
}

const columnFiltersToObj = (columnFilters: ColumnFiltersState) => {
    const obj = {} as Record<string, string>
    columnFilters.forEach((filter) => {
        const key = filter.id
        obj[key] = String(filter.value)
    })
    return obj
}

export { parseToSortState, sortStateToObj, columnFiltersToObj }
