import { FunctionComponent, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
// styles
import styles from "./wellControlsUnlink.module.scss"
// components
import ModalHeader from "../../shared/modalHeader"
import ModalFooter from "../../shared/modalFooter"
import Alert from "../../shared/alert"
// queries & types
import { useMutation, useQuery } from "@apollo/client"
import { getFragmentData } from "../../../generated"
import { UNLINK_WELL } from "../../../graphql/mutations/unlink_well"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../../graphql/fragments/wellIdentification"
import { GET_SINGLE_WELL } from "../../../graphql/queries/well"
import { TrexNavigator } from "../../../classes/navigator/navigator"
import { INDIVIDUAL_WELL_PAGE_QUERY } from "../../../graphql/queries/individualWellPage"
import {
    iwpQueryParam_equipStatsFrom,
    iwpQueryParam_equipStatsTo,
    iwpQueryParam_historyCount,
} from "../../../pages/individualWellPage"

export type UnlinkUiStateType = {
    errorBanner: boolean
}

const WellControlsUnlink: FunctionComponent = () => {
    const navigate = useNavigate()
    const { key: locationKey } = useLocation()
    const { wellId: wellIdFromUrl } = useParams()

    if (!wellIdFromUrl) {
        throw new Error("wellId not resolved from url.")
    }

    const [unlinkWell, { loading: mutationLoading }] = useMutation(
        UNLINK_WELL,
        {
            refetchQueries: [
                {
                    query: INDIVIDUAL_WELL_PAGE_QUERY,
                    variables: {
                        wellInput: { id: wellIdFromUrl },
                        equipStats_from: iwpQueryParam_equipStatsFrom,
                        equipStats_to: iwpQueryParam_equipStatsTo,
                        historyCount: iwpQueryParam_historyCount,
                        wellControlInput: { wellID: wellIdFromUrl },
                    },
                },
            ],
        }
    )

    const initialLinkUiState: UnlinkUiStateType = {
        errorBanner: false,
    }
    const [unlinkUiState, setUnlinkUiState] =
        useState<UnlinkUiStateType>(initialLinkUiState)

    const { error, loading, data } = useQuery(GET_SINGLE_WELL, {
        variables: {
            wellInput: { id: wellIdFromUrl },
        },
    })

    if (loading || mutationLoading) {
        return <></>
    }

    if (error) {
        throw new Error(`GET_SINGLE_WELL error: ${error}`)
    }

    if (!data) return <></>
    const well = getFragmentData(WELL_IDENTIFICATION_FIELDS_FRAGMENT, data.well)
    if (!well) return <></>

    const {
        name: wellName,
        apiNumber: { format: wellApiNumber },
        linked,
    } = well

    const handleSubmitUnlinkToken = async () => {
        await unlinkWell({
            variables: {
                WellUnlinkInput: {
                    wellBy: {
                        id: wellIdFromUrl,
                    },
                },
            },
        })
            .then(() => {
                // Upon successful mutation. Navigate back to the dashboard.
                navigate(-1)
            })
            .catch((error) => {
                console.error("Unlinking mutation error:", error)
                setUnlinkUiState({
                    errorBanner: true,
                })
            })
    }

    const isSubmitDisabled = (linked: boolean) => {
        if (linked) {
            // if token is valid && well is not currently link return false - ( not disabled.)
            return false
        } else {
            // disable
            return true
        }
    }

    const disableSubmit = isSubmitDisabled(linked)

    return (
        <div className={styles.container}>
            <ModalHeader
                title="Unlink Well"
                subTitle={wellName}
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
            />
            <div className={styles.bodyTitle}>
                Are you sure you want to unlink this well?
            </div>
            <div className={styles.wellName}>{wellName}</div>
            <div className={styles.wellApi}>{wellApiNumber}</div>
            {unlinkUiState.errorBanner && (
                <Alert
                    message={
                        <div>
                            There was a problem updating your control setting.
                            Click{" "}
                            <u
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/dashboard")}
                            >
                                here
                            </u>{" "}
                            or close the modal to be redirected to the
                            dashboard.
                        </div>
                    }
                    alertType={"danger"}
                    isCloseable={false}
                />
            )}
            <ModalFooter
                advanceText="Unlink"
                disableAdvance={disableSubmit}
                handleAdvanceClick={handleSubmitUnlinkToken}
            />
        </div>
    )
}

export default WellControlsUnlink
