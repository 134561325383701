import { FunctionComponent, useEffect, useState } from "react"

import {
    WiringModeComponentStateI,
    UpdateWiringModeModalLocationState,
} from "./updateWellWiringModeModalTypes"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalHeader from "../../shared/modalHeader"
import { TrexNavigator } from "../../../classes/navigator/navigator"
import styles from "./updateWellWiringMode.module.scss"
import Input from "../../shared/input"
import { useMutation } from "@apollo/client"
import { WELL_UPDATE_WIRING_MODE } from "../../../graphql/mutations/update_well_wiring_mode"
import { getModalLocationState } from "./updateWellWiringModeModalUtil"
import ModalFooter from "../../shared/modalFooter"
import { DiscreteControlWiringMode } from "../../../generated/graphql"
import Alert from "../../shared/alert"

const UpdateWellWiringModeModal: FunctionComponent = () => {
    const navigate = useNavigate()

    const location = useLocation()
    const locationKey = location.key

    const locationState = location.state as UpdateWiringModeModalLocationState
    const { wellId: urlParamWellId } = useParams()
    if (!urlParamWellId) {
        throw new Error("wellId not resolved from url.")
    }

    // mutations
    const [wellUpdateWiringMode, { loading: mutationLoading }] = useMutation(
        WELL_UPDATE_WIRING_MODE
    )

    // component state && renderingConstants
    const { wiringMode: initialWiringMode, wellName } =
        getModalLocationState(locationState)

    // state
    const [componentState, setUpdateInstallationModalState] =
        useState<WiringModeComponentStateI>({
            updatedWiringMode: initialWiringMode,
            disableSubmit: true,
        })
    const [errorBanner, setErrorBanner] = useState<boolean>(false)

    // effect hook
    useEffect(() => {
        // This effect hook is used if there is no location state to populate the form.
        // Users will be redirected back to there attributes
        if (locationState === null) {
            navigate(`/wellAttributes/${urlParamWellId}`)
        }
        // Because we are not allowing unknown to be a wiring mode option, we need to set the explicitally set the initial value of unknown ( when it occurs) to normally open.
        if (
            componentState.updatedWiringMode ===
            DiscreteControlWiringMode.Unknown
        ) {
            setUpdateInstallationModalState({
                disableSubmit: false,
                updatedWiringMode: DiscreteControlWiringMode.NormallyOpen,
            })
        }
    }, [locationState])

    // handlers
    const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = e.target.value as DiscreteControlWiringMode
        const disableSubmit =
            initialWiringMode === selectedOption ? true : false

        setUpdateInstallationModalState({
            updatedWiringMode: selectedOption,
            disableSubmit,
        })
    }

    const handleSubmit = async () => {
        await wellUpdateWiringMode({
            variables: {
                WellUpdateWiringModeInput: {
                    wellBy: {
                        id: urlParamWellId,
                    },
                    wiringMode: componentState.updatedWiringMode,
                },
            },
        })
            .then(() => {
                // Upon successful mutation. Navigate back to the attrributes page
                navigate(`/wellAttributes/${urlParamWellId}`)
            })
            .catch((error) => {
                console.error("update wiring mode error:", error)
                setErrorBanner(true)
            })
    }

    return (
        <div className={styles.container}>
            <ModalHeader
                title={"Edit Installation"}
                subTitle={wellName}
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
            />
            <div className={styles.inputContainer}>
                <div>
                    <Input
                        type="select"
                        label="Relay Configuration"
                        value={componentState.updatedWiringMode}
                        options={[
                            // this order matters, the first value here needs to be the first value for initial state when wiring mode is unknown (useEffect hook).
                            {
                                value: DiscreteControlWiringMode.NormallyOpen,
                                label: "Normally Open",
                            },
                            {
                                value: DiscreteControlWiringMode.NormallyClosed,
                                label: "Normally Closed",
                            },
                        ]}
                        onChange={handleInputChange}
                        orientation="vertical"
                        inputCustomClass="well-control-link-text"
                    />
                </div>
            </div>
            {errorBanner && (
                <Alert
                    message={
                        <div>
                            There was a problem updating your well&apos;s wiring
                            mode. Close the modal to be redirected back to the
                            attributes page.
                        </div>
                    }
                    alertType={"danger"}
                    isCloseable={false}
                />
            )}
            <ModalFooter
                advanceText="Submit"
                handleAdvanceClick={handleSubmit}
                disableAdvance={
                    componentState.disableSubmit ||
                    mutationLoading ||
                    errorBanner
                }
            />
        </div>
    )
}

export default UpdateWellWiringModeModal
