import { FunctionComponent, ReactNode, useState } from "react"
import styles from "./deleteRule.module.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalHeader from "../../../../shared/modalHeader"
import { TrexNavigator } from "../../../../../classes/navigator/navigator"
import ModalFooter from "../../../../shared/modalFooter"
import { useMutation, useQuery } from "@apollo/client"
import { GET_ALERT_RULES_BY_ID } from "../../../../../graphql/queries/rulesById"
import {
    ErrorMessage,
    SyncLoaderComponent,
    EmptyState,
} from "../../../../shared/graphQlResponse"
import { getFragmentData } from "../../../../../generated"
import { ALERT_RULE_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRule"
import { ALERT_RULE_DELETE } from "../../../../../graphql/mutations/alertRule_delete"
import { GET_ALERT_RULES } from "../../../../../graphql/queries/rules"
import { NOTIFICATIONS_RULES_ABS_ROUTE } from "../../../../.."
import Alert from "../../../../shared/alert"
import { useNotificationsContext } from "../../../context/notificationsContext"

const DeleteRule: FunctionComponent = () => {
    // get rule id from useParams
    const navigate = useNavigate()
    const notificationsContext = useNotificationsContext()
    const { key: locationKey } = useLocation()
    const urlSlugs = useParams()
    const ruleId = urlSlugs.ruleId as string

    // query
    const { error, loading, data } = useQuery(GET_ALERT_RULES_BY_ID, {
        variables: {
            input: { ids: [ruleId] },
        },
    })

    // mutation
    const [deleteRuleMutation, { loading: mutationLoading }] = useMutation(
        ALERT_RULE_DELETE,
        {
            variables: { input: { IDs: [ruleId] } },
        }
    )

    // state
    const [deleteRuleError, setDeleteRuleError] = useState(false)

    // handlers
    const handleSubmitDelete = async () => {
        await deleteRuleMutation({
            variables: { input: { IDs: [ruleId] } },
            refetchQueries: [
                {
                    query: GET_ALERT_RULES,
                    variables: {
                        RulesFilter: {
                            OrganizationIDs: [
                                notificationsContext.selectedOrganization.value,
                            ],
                        },
                    },
                },
            ],
        })
            .then(() => {
                navigate(NOTIFICATIONS_RULES_ABS_ROUTE)
            })
            .catch((error) => {
                console.error("Delete alert rule mutation error: ", error)
                setDeleteRuleError(true)
                return
            })
    }

    // rule data
    const r = data?.rulesByIDs?.rules && data?.rulesByIDs?.rules[0]
    const rule = getFragmentData(ALERT_RULE_FIELDS_FRAGMENT, r)

    // constants
    const isSubmitDisabled =
        loading || Boolean(error) || mutationLoading || deleteRuleError || !rule

    // rendering
    let deleteAlertRuleComponent: ReactNode

    if (loading) {
        deleteAlertRuleComponent = (
            <SyncLoaderComponent outSideDivStyle={styles.queryLoading} />
        )
    }
    if (error) {
        deleteAlertRuleComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="We had a problem while fetching your alert rule information."
            />
        )
    }
    if (deleteRuleError) {
        deleteAlertRuleComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="There was a problem deleting your rule."
            />
        )
    }
    if (!rule) {
        deleteAlertRuleComponent = (
            <EmptyState outSideDivStyle={styles.queryEmpty}>
                <> No alert rule with this id was found.</>
            </EmptyState>
        )
    }
    if (rule && !deleteRuleError) {
        deleteAlertRuleComponent = (
            <>
                <div className={styles.title}>
                    Are you sure you want to delete this rule?
                </div>
                <div className={styles.ruleName}>
                    <span className={styles.label}>Rule Name:</span>
                    <span>{rule.name}</span>
                </div>
                <div className={styles.ruleDescription}>
                    <span className={styles.label}>Rule Description:</span>
                    <span>{rule.description}</span>
                </div>
                <Alert
                    alertType="danger"
                    isCloseable={false}
                    message={`If you click "Confirm" you will delete this rule and ALL subscriptions to it for ALL users in the organization.`}
                />
            </>
        )
    }

    return (
        <div className={styles.container}>
            <ModalHeader
                title={"Delete Rule"}
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
            />
            <div className={styles.body}>{deleteAlertRuleComponent}</div>
            <ModalFooter
                advanceText="Confirm"
                handleAdvanceClick={handleSubmitDelete}
                disableAdvance={isSubmitDisabled}
                previousText="Cancel"
                handlePreviousClick={() => {
                    navigate(-1)
                }}
                disablePrevious={isSubmitDisabled}
            />
        </div>
    )
}

export default DeleteRule
