import { FunctionComponent, useEffect, useState } from "react"
import { WellIdentificationFieldsFragment } from "../../../../generated/graphql"
import { getWellConfigurationMenuOptionsByRole } from "../../../../util/rolePermissions/getByRole"
import Dropdown from "../../../shared/dropDown"
import { useViewerContext } from "../../../../context/ViewerContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGear } from "@fortawesome/pro-solid-svg-icons"

import { useNavigate } from "react-router-dom"
import { IwpSubNavNavControlsI, SubNavDropDownI } from "../subNavigationTypes"

interface WellConfigurationMenuI {
    wellIdentificationfragment: WellIdentificationFieldsFragment
    subNavControls: IwpSubNavNavControlsI
    setSubNavControls: React.Dispatch<
        React.SetStateAction<IwpSubNavNavControlsI>
    >
    dropDownMenus: SubNavDropDownI
    setDropDownMenus: React.Dispatch<React.SetStateAction<SubNavDropDownI>>
    windowWidth: number
    mobileBreakPoint: number
}

const WellConfigurationMenu: FunctionComponent<WellConfigurationMenuI> = ({
    wellIdentificationfragment,
    dropDownMenus,
    setDropDownMenus,
    windowWidth,
    mobileBreakPoint,
}) => {
    const navigate = useNavigate()
    const { getViewer } = useViewerContext()
    const { role: viewerRole } = getViewer()
    const [mobileView, setMobileView] = useState(
        windowWidth > mobileBreakPoint ? false : true
    )
    const dropDownMenuId = "wellConfigMenuId"

    useEffect(() => {
        if (windowWidth > mobileBreakPoint) {
            setMobileView(false)
        }
        if (windowWidth < mobileBreakPoint) {
            setMobileView(true)
        }
    }, [windowWidth])

    return (
        <Dropdown
            options={getWellConfigurationMenuOptionsByRole(
                viewerRole,
                wellIdentificationfragment.linked
            )}
            dropDownButtonClass="dropDown-button-class"
            dropDownOptionsContainerClass="dropDown-options-container"
            dropDownOptionClass="dropDown-option"
            positionClass={mobileView ? "iwp-sub-nav-mobile" : "iwp-sub-nav"}
            menuOpen={dropDownMenus.configurationMenu}
            handleDropDownMenuClick={(e) => {
                e.stopPropagation()
                const updatedDropDownMenus = {
                    ...dropDownMenus,
                    configurationMenu: !dropDownMenus.configurationMenu,
                    subPageMenu: false,
                }
                setDropDownMenus(updatedDropDownMenus)
            }}
            handleDropDownOptionClick={(e) => {
                e.stopPropagation()
                const targetId = (e.currentTarget as HTMLElement).id
                switch (targetId) {
                    case `${dropDownMenuId}_wellControls`:
                        navigate(`controls/edit`, { relative: "path" })
                        break
                    case `${dropDownMenuId}_link`:
                        navigate(`controls/link`, { relative: "path" })
                        break
                    case `${dropDownMenuId}_unlink`:
                        navigate(`controls/unlink`, { relative: "path" })
                        break
                    default:
                        throw new Error(
                            "Unexpected Behavior in well control drop down function"
                        )
                }
                setDropDownMenus({
                    configurationMenu: false,
                    subPageMenu: false,
                })
            }}
            id={dropDownMenuId}
        >
            {!mobileView && (
                <div
                    style={{
                        fontWeight: "400",
                        font: "16px",
                        color: "#1e88e5",
                        paddingRight: "8px",
                    }}
                >
                    Well Config
                </div>
            )}
            <FontAwesomeIcon color="#1e88e5" icon={faGear} />
        </Dropdown>
    )
}

export default WellConfigurationMenu
