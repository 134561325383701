import { graphql } from "../../generated"

export const SUBSCRIPTIONS_DELETE = graphql(`
    mutation subscriptionDelete(
        $AlertSubscriptionsDeleteInput: AlertSubscriptionsDeleteInput!
    ) {
        subscriptionsDelete(input: $AlertSubscriptionsDeleteInput) {
            ids
        }
    }
`)
