import { FallbackProps } from "react-error-boundary"
import { Link } from "react-router-dom"
import Button from "../../shared/button"
import { FunctionComponent } from "react"
import styles from "./rootLevelErrorBoundary.module.scss"

const RootLevelErrorBoundary: FunctionComponent<FallbackProps> = ({
    error,
    resetErrorBoundary,
}) => {
    const errorMessage =
        process.env.NODE_ENV === "development"
            ? `Error Message: ${error.message}`
            : ""

    return (
        <div className={styles.container}>
            <div className={styles.errorContainer}>
                <div className={styles.title}>Something went wrong </div>
                <div className={styles.errorMessage}> {errorMessage}</div>
                <div className={styles.buttonContainer}>
                    <Button
                        handleButtonClick={resetErrorBoundary}
                        condition={"default"}
                        status="primary"
                    >
                        Retry
                    </Button>
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        handleButtonClick={resetErrorBoundary}
                        condition={"default"}
                        status="primary"
                    >
                        <Link className={styles.link} to="/">
                            Go back dashboard
                        </Link>
                    </Button>
                </div>

                <div className={styles.footerText}>
                    If the problem persists please reach out to
                    support@petropower.com
                </div>
            </div>
        </div>
    )
}

export default RootLevelErrorBoundary
