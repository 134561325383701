import { FunctionComponent, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import styles from "./updateOrganizationsModal.module.scss"
import ModalHeader, {
    ModalHeaderComponentInterface,
} from "../shared/modalHeader"
import ModalFooter, {
    ModalFooterComponentInterface,
} from "../shared/modalFooter"
import Alert from "../shared/alert"
import {
    disableSubmitUpdateOrganization,
    getActivationUpdateOrganizationStatus,
    getUpdateOrganiztionMutationVariables,
    getUpdatedOrganizationValues,
    initialUpdateOrganizationModalState,
} from "../../util/updateOrganizationModalUtil"
import { UPDATE_ORGANIZATIONS } from "../../graphql/mutations/update_organizations"
import { useMutation } from "@apollo/client"
import { DEACTIVATE_ORGANIZATIONS } from "../../graphql/mutations/deactivate_organization"
import UpdateOrganizationBody from "./updateOrganizationBody"
import { ACTIVATE_ORGANIZATIONS } from "../../graphql/mutations/activate_organizations"
import { useViewerContext } from "../../context/ViewerContext"
import { OrganizationOptionType } from "../../types/ViewerTypes"
import {
    UpdateOrganizationModalStateInterface,
    UpdateOrganizationStateInterface,
} from "../../types/adminOrganizationTypes/updateOrganizationModalTypes"
import { TrexNavigator } from "../../classes/navigator/navigator"

const UpdateOrganizationModal: FunctionComponent = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const locationState = location.state
    let initialOrganizationState = {} as UpdateOrganizationStateInterface
    const organizationId = params.organizationId

    const { getViewer } = useViewerContext()
    const { organizationAndDescendants } = getViewer()

    if (!organizationId) {
        throw new Error(
            "Update Organization Modal, organizationId was falsy and should not be."
        )
    }

    if (locationState === null) {
        initialOrganizationState = {
            organizationName: "",
            organizationActivationStatus: false,
            parentOrganizationId: undefined,
        }
    } else {
        const parentOrganizationOption = organizationAndDescendants.find(
            (org) => org.value === locationState.parentId
        )

        initialOrganizationState = {
            organizationName: locationState.name,
            parentOrganizationId: parentOrganizationOption,
            organizationActivationStatus: locationState.activationStatus,
        }
    }

    /**
     * State
     */
    const [updateOrganizationModalState, setUpdateOrganizationModalState] =
        useState<UpdateOrganizationModalStateInterface>({
            ...initialUpdateOrganizationModalState,
            organizationState: initialOrganizationState,
        })

    const [updateOrganizationErrorBanner, updateEditOrganizationErrorBanner] =
        useState(false)

    /** use useEffect to redirect back to organizations table if there is not locationState */
    useEffect(() => {
        if (locationState === null) {
            navigate("/admin/organizations")
        }
    }, [locationState])

    /**
     * Mutations
     */

    const [updateOrganizationMutation, { loading: mutationLoading }] =
        useMutation(UPDATE_ORGANIZATIONS)

    const [
        deactivateOrganizationMutation,
        { loading: deactivateOrganizationLoading },
    ] = useMutation(DEACTIVATE_ORGANIZATIONS)

    const [
        activateOrganizationMutation,
        { loading: activateOrganizationLoading },
    ] = useMutation(ACTIVATE_ORGANIZATIONS)

    /**
     * Handlers
     */
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target

        const updatedOrganization: UpdateOrganizationStateInterface = {
            ...updateOrganizationModalState.organizationState,
            [name]: value,
        }

        const disableSubmit = disableSubmitUpdateOrganization(
            initialOrganizationState,
            updatedOrganization
        )

        setUpdateOrganizationModalState((prevState) => ({
            ...prevState,
            organizationState: updatedOrganization,
            disableSubmit: disableSubmit,
        }))
    }

    const handleChangeParentOrganization = (
        value: OrganizationOptionType | null
    ) => {
        if (!value) {
            throw new Error(
                "Error:  handleChangeParentOrganization. Should not be setting organization to null."
            )
        }

        const updatedOrganization: UpdateOrganizationStateInterface = {
            ...updateOrganizationModalState.organizationState,
            parentOrganizationId: value,
        }

        const disableSubmit = disableSubmitUpdateOrganization(
            initialOrganizationState,
            updatedOrganization
        )

        setUpdateOrganizationModalState((prevState) => ({
            ...prevState,
            organizationState: updatedOrganization,
            disableSubmit: disableSubmit,
        }))
    }

    const handleChangeOrganizationActivationStatus = () => {
        let updatedOrganizationActivationStatus: boolean

        const { organizationActivationStatus } =
            updateOrganizationModalState.organizationState

        if (organizationActivationStatus) {
            updatedOrganizationActivationStatus = false
        } else {
            updatedOrganizationActivationStatus = true
        }

        const updatedOrganization: UpdateOrganizationStateInterface = {
            ...updateOrganizationModalState.organizationState,
            organizationActivationStatus: updatedOrganizationActivationStatus,
        }

        const disableSubmit = disableSubmitUpdateOrganization(
            initialOrganizationState,
            updatedOrganization
        )

        setUpdateOrganizationModalState((prevState) => ({
            ...prevState,
            organizationState: updatedOrganization,
            disableSubmit: disableSubmit,
        }))
    }

    const handleSubmitEditOrganization = async () => {
        const { organizationState: updatedOrganizationState } =
            updateOrganizationModalState

        const shouldUpdateActivationStatus =
            getActivationUpdateOrganizationStatus(
                initialOrganizationState.organizationActivationStatus,
                updatedOrganizationState.organizationActivationStatus
            )

        const shouldUpdateOrganizationValues = getUpdatedOrganizationValues(
            initialOrganizationState,
            updatedOrganizationState
        )

        try {
            const mutationPromises = []
            if (shouldUpdateActivationStatus === "activateOrganization") {
                const activatePromise = activateOrganizationMutation({
                    variables: {
                        OrganizationsActivateInput: {
                            organizationIDs: [organizationId],
                        },
                    },
                })
                mutationPromises.push(activatePromise)
            } else if (
                shouldUpdateActivationStatus === "deactivateOrganization"
            ) {
                const deactivatePromise = deactivateOrganizationMutation({
                    variables: {
                        OrganizationsDeactivateInput: {
                            organizationIDs: [organizationId],
                        },
                    },
                })
                mutationPromises.push(deactivatePromise)
            }

            if (shouldUpdateOrganizationValues !== false) {
                const organizationVariables =
                    getUpdateOrganiztionMutationVariables(
                        updatedOrganizationState,
                        organizationId
                    )

                const updatePromise = updateOrganizationMutation({
                    variables: {
                        OrganizationsUpdateInput: organizationVariables,
                    },
                })
                mutationPromises.push(updatePromise)
            }

            await Promise.all(mutationPromises)

            navigate(-1)
        } catch (error) {
            console.error("Update organization mutation error", error)
            updateEditOrganizationErrorBanner(true)
        }
    }

    /**
     * Props
     */
    const modalHeaderProps: ModalHeaderComponentInterface = {
        title: "Update Organization",
        trexNavigator: new TrexNavigator(
            {
                navigateTo: -1,
                locationKey: location.key,
                pathName: location.pathname,
            },
            navigate
        ),
    }

    const modalFooterProps: ModalFooterComponentInterface = {
        advanceText: "Submit",
        disableAdvance:
            updateOrganizationModalState.disableSubmit ||
            mutationLoading ||
            activateOrganizationLoading ||
            deactivateOrganizationLoading ||
            updateOrganizationErrorBanner,
        handleAdvanceClick: handleSubmitEditOrganization,
    }

    return (
        <div className={styles.container}>
            <ModalHeader {...modalHeaderProps} />
            <UpdateOrganizationBody
                handleInputChange={handleInputChange}
                modalState={updateOrganizationModalState}
                handleChangeOrganizationActivationStatus={
                    handleChangeOrganizationActivationStatus
                }
                organizations={organizationAndDescendants}
                handleParentOrgChange={handleChangeParentOrganization}
            />
            {updateOrganizationErrorBanner && (
                <Alert
                    isCloseable={false}
                    message={
                        <div>
                            There was a problem updating the organization. Click{" "}
                            <u
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/admin/organizations")}
                            >
                                here
                            </u>{" "}
                            or close the modal to be redirected to the admin
                            page.
                        </div>
                    }
                    alertType="danger"
                />
            )}
            <ModalFooter {...modalFooterProps} />
        </div>
    )
}

export default UpdateOrganizationModal
