import { graphql } from "../../generated"

export const ALERT_TEMPLATE_FIELDS_FRAGMENT = graphql(`
    fragment AlertRuleTemplateFields on AlertRuleTemplate {
        description
        name
        metric
        evaluationGroup
        window {
            ...DurationFields
        }
        condition {
            ...AlertRuleConditionFields
        }
    }
`)
