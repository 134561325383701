import { FunctionComponent } from "react"
import { useAddWellsContext } from "../../../../../context/addWellsContext"
import { PreviewAddedFooter } from "../footer/previewFooters"
import WellApiInfoDisplay from "./wellApiInfoDisplay"
import styles from "./previewAdded.module.scss"

/**
 * This component represents the UI state when a well has been added to the display panel, and a user has selected that well.
 * Users should be able to see the details of the well that will be added.
 */

const PreviewAdded: FunctionComponent = () => {
    const {
        handleCloseActionPanelPreview,
        handleSetActionPanelEdit,
        getAddWellsWellData,
    } = useAddWellsContext()

    // context hook to get addWellsWellData
    const addWellsWellData = getAddWellsWellData()

    return (
        <div className={styles.container}>
            <WellApiInfoDisplay wellData={addWellsWellData} />
            <PreviewAddedFooter
                onCloseFooterClick={handleCloseActionPanelPreview}
                onEditFooterClick={() =>
                    handleSetActionPanelEdit(addWellsWellData)
                }
            />
        </div>
    )
}

export default PreviewAdded
