import React, { useState, FunctionComponent } from "react"
import { createPortal } from "react-dom"
import "./modal.scss"

interface ModalProps {
    children: React.ReactNode | React.ReactNode[]
}

const Modal: FunctionComponent<ModalProps> = ({ children }) => {
    const modalRootElement = document.getElementById(
        "modal-root"
    ) as HTMLElement
    const [modalRoot] = useState<HTMLElement | null>(modalRootElement)

    if (modalRoot) {
        return createPortal(
            <div id="modal-overlay" className="modal-overlay">
                <div className="modal-content">{children}</div>
            </div>,
            modalRoot
        )
    } else {
        throw new Error("ModalContainer/index. modalRoot unefined.")
    }
}

export default Modal
