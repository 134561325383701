import { graphql } from "../../generated"

export const ALERT_RULE_ADD = graphql(`
    mutation AddRules($input: RulesAddInput!) {
        rulesAdd(input: $input) {
            rules {
                ...AlertRuleFields
            }
        }
    }
`)
