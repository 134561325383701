import { graphql } from "../../generated"

export const WELL_IDENTIFICATION_FIELDS_FRAGMENT = graphql(`
    fragment WellIdentificationFields on Well {
        id
        name
        linked
        apiNumber {
            format
            unknown
        }
        wellGeoLocation: location {
            latitude
            longitude
        }
        organization {
            id
            name
        }
    }
`)
