import { Outlet } from "react-router-dom"
import TagManager from "react-gtm-module"
import ReactGA from "react-ga4"
import NavigationBar from "../../navigationBar"
import { useViewerContext } from "../../../context/ViewerContext"

import { useEffect } from "react"

const TrexParentRoute = () => {
    const { getViewer } = useViewerContext()
    const { currentOrganization } = getViewer()

    // Set up ReactGA
    // Adding variable to GTM for filtering internal traffic based on top level org
    const orgName = currentOrganization.label.toLowerCase()
    const internalOrgs = ["root", "petropower"]
    const trafficType = internalOrgs.includes(orgName) ? "internal" : "external"
    const tagManagerArgs = {
        gtmId: "GTM-WKRDL54",
        dataLayer: {
            traffic_type: trafficType,
        },
    }
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const checkDataLayer = (window as any)?.dataLayer
        if (!checkDataLayer) {
            TagManager.initialize(tagManagerArgs)
            // Initializing Google Analytics tracker now that we have GTM initialized
            ReactGA.initialize("G-BZXLJJMCPN")
        }
    }, [tagManagerArgs])

    return (
        <>
            <NavigationBar />
            <Outlet />
        </>
    )
}

export default TrexParentRoute
