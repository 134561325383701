import { FunctionComponent } from "react"
import styles from "./organization.module.scss"

const EmptyState: FunctionComponent = () => {
    return (
        <div className={styles.emptyState}>
            You do not have any organizations to view. If this is unexpected,
            please reach out to{" "}
            <a href="mailto:support@petropower.com">support@petropower.com</a>
        </div>
    )
}

export default EmptyState
