import { graphql } from "../../generated"

export const FRESHDESK_JWT_CREATE = graphql(`
    mutation FreshdeskJWTCreate {
        freshDeskJWTCreate {
            jwt {
                encoded
                payload {
                    expiration {
                        unixMilliseconds
                    }
                }
            }
        }
    }
`)
