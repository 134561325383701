import {
    AlertRuleConditionFieldsFragment,
    AlertRuleOperator,
    Unit,
} from "../../../../../generated/graphql"
import { AlertRuleFormConditionI } from "../alertRuleFormUtil/alertRuleFormTypes"
import { secondsToLargestWholeDurationUnit } from "../../../../../util/duration/duration"
import { ratioToPercentage } from "../../../../../util/numbers/numbers"
import { formatAlertMetricValueWithUnit } from "./alertRuleMetricFragment"

// This function takes an AlertRuleConditionFieldsFragment as input and returns an AlertRuleFormConditionI.
// When the unit is a duration, it converts the threshold(s) to the highest unit of time, up to a day
// For example, 3600s => 1hr, 86400s => 1d.
const getInitialAlertRuleFormCondition = (
    conditionFragment: AlertRuleConditionFieldsFragment
): AlertRuleFormConditionI => {
    switch (conditionFragment.unit) {
        case Unit.Percentage: {
            // thresholds should be converted from ratios to percentages for the form
            const updatedCondition: AlertRuleConditionFieldsFragment = {
                ...conditionFragment,
                threshold: ratioToPercentage(conditionFragment.threshold),
            }
            if (conditionFragment.threshold2) {
                updatedCondition.threshold2 = ratioToPercentage(
                    conditionFragment.threshold2
                )
            }

            return {
                ...updatedCondition,
                thresholdTimeUnit: null,
            }
        }
        case Unit.CountPerSecond:
        case Unit.Voltage:
        case Unit.Count:
        case Unit.StrokesPerMinute:
        case Unit.Unspecified:
            // thresholdTimeUnit set to null if Unit is not a duration
            return {
                ...conditionFragment,
                thresholdTimeUnit: null,
            }
        case Unit.Duration: {
            const updatedCondition: AlertRuleConditionFieldsFragment = {
                ...conditionFragment,
            }

            // update the threshold  && unit
            const { unit: thresholdTimeUnit, value: thresholdValue } =
                secondsToLargestWholeDurationUnit(
                    conditionFragment.threshold,
                    "d"
                )

            // if AlertRuleOperator is OutsideRange, update  threshold2
            if (conditionFragment.operator === AlertRuleOperator.OutsideRange) {
                const { value: threshold2Value } =
                    secondsToLargestWholeDurationUnit(
                        conditionFragment.threshold2,
                        "d"
                    )

                updatedCondition.threshold2 = threshold2Value
            }

            return {
                ...updatedCondition,
                thresholdTimeUnit: thresholdTimeUnit,
                threshold: thresholdValue,
            }
        }
    }
}

/**
 * format alert rule condition thresholds for display. Used in notifications table
 *
 * @param threshold - the threshold value
 * @param threshold2 - the second threshold value for OutsideRange operator
 * @param unit - alert rule unit
 * @param operator - alert rule operator
 * @returns formatted diplay string
 */
const formatAlertRuleThresholdForDisplay = (
    threshold: number,
    threshold2: number,
    unit: Unit,
    operator: AlertRuleOperator
): string => {
    const formattedThreshold = formatAlertMetricValueWithUnit(
        threshold,
        unit,
        "LargestSingleUnit"
    )
    switch (operator) {
        case AlertRuleOperator.GreaterThan:
            return `> ${formattedThreshold}`
        case AlertRuleOperator.LessThan:
            return `< ${formattedThreshold}`
        case AlertRuleOperator.OutsideRange: {
            const formattedThreshold2 = formatAlertMetricValueWithUnit(
                threshold2,
                unit,
                "LargestSingleUnit"
            )
            return `Outside: ${formattedThreshold} - ${formattedThreshold2}`
        }
        case AlertRuleOperator.Unspecified:
        case AlertRuleOperator.FluctuationPercent:
        default:
            return formattedThreshold
    }
}

export { getInitialAlertRuleFormCondition, formatAlertRuleThresholdForDisplay }
