import { useQuery } from "@apollo/client"
import { FunctionComponent, ReactNode } from "react"
import { Outlet } from "react-router-dom"
import {
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../../shared/graphQlResponse"
import styles from "./subscriptionsByRule.module.scss"
import { getFragmentData } from "../../../../../generated"
import { SUBSCRIPTION_FIELDS_FOR_ADMIN_SUBSCRIPTION_TABLES } from "../../../../../graphql/fragments/subscription"
import SubscriptionsByRuleTable from "./table/table"
import { GET_SUBSCRIPTIONS_FOR_ORG } from "../../../../../graphql/queries/subscriptionsForOrg"
import { getSubscriptionsAdminTableFromSubscriptionsFrag } from "../../subscriptions_GraphQL/subscriptionsFragment"
import { useNotificationsContext } from "../../../context/notificationsContext"
import SubscriptionsEmptyState from "../../sharedComponents/table/emptyState"

const SubscriptionsByRule: FunctionComponent = () => {
    const selectedOrganization = useNotificationsContext().selectedOrganization
    const { error, loading, data } = useQuery(GET_SUBSCRIPTIONS_FOR_ORG, {
        variables: {
            input: {
                orgIDs: [selectedOrganization.value],
            },
        },
        fetchPolicy: "network-only",
    })

    let body: ReactNode = <></>
    if (error) {
        console.error("error", error)
        body = (
            <ErrorMessage
                outSideDivStyle={styles.error}
                message="There was a problem fetching subscriptions by rule."
            />
        )
    } else if (loading) {
        body = <SyncLoaderComponent outSideDivStyle={styles.loader} />
    } else if (data) {
        const subscriptions = data.subscriptionsForOrgs.subscriptions.map(
            (sub) =>
                getFragmentData(
                    SUBSCRIPTION_FIELDS_FOR_ADMIN_SUBSCRIPTION_TABLES,
                    sub
                )
        )
        const formatted =
            getSubscriptionsAdminTableFromSubscriptionsFrag(subscriptions)
        if (formatted.length === 0) {
            body = (
                <SubscriptionsEmptyState
                    viewerAction="admin"
                    message=" No subscriptions found"
                />
            )
        } else {
            body = (
                <SubscriptionsByRuleTable
                    key={selectedOrganization.value}
                    data={formatted}
                />
            )
        }
    }

    return (
        <>
            <Outlet />
            <div className={styles.container}>{body}</div>
        </>
    )
}
export default SubscriptionsByRule
