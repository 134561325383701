import {
    ManageSubscriptionFormI,
    ManageSubscriptionViewerActionT,
} from "../manageSubscriptionFormUtil/types"
import { FunctionComponent } from "react"
import styles from "./silenceSettings.module.scss"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons"
import Button from "../../../../../components/shared/button"
import { dateToLocalDateTimeDisplay } from "../../../../../util/datesAndTimes/datesAndTimes"
import { getDateNowPlusDuration } from "../../../../../util/duration/duration"
import Input from "../../../../shared/input"
import { silenceDurationUnitOptions } from "../../manageSilence/manageSilenceFormUtil/constants"
import { useNavigate } from "react-router-dom"
import {
    NOTIFICATIONS_ADMIN_REMOVE_SILENCE_CHILD,
    NOTIFICATIONS_REMOVE_SILENCE_CHILD,
} from "../../../../.."
import ToolTip_Popover from "../../../../shared/toolTip/toolTip_popover"

const SilenceSettings: FunctionComponent<{
    handleTimeValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleTimeUnitChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
    subscriptionForm: ManageSubscriptionFormI
    viewerAction: ManageSubscriptionViewerActionT
}> = ({
    handleTimeValueChange,
    handleTimeUnitChange,
    subscriptionForm,
    viewerAction,
}) => {
    const navigate = useNavigate()
    const { initialSilenceUnixMs, silenceForm } = subscriptionForm

    // constants for display
    let initialSilenceSettingDisplay = ""
    let silenceUntilDisplay = ""

    const unixMilliSecondsNow = new Date().getTime()
    // active silence exists if the initial silence unix time stamp is greater than the current time
    const activeSilenceExists = initialSilenceUnixMs > unixMilliSecondsNow

    if (activeSilenceExists) {
        // TODO: The time is in local but, we would also like to display a timezone description here.
        const initialSilenceSettingDateObj = new Date(initialSilenceUnixMs)
        initialSilenceSettingDisplay = dateToLocalDateTimeDisplay(
            initialSilenceSettingDateObj,
            "-"
        )
    }
    // show updated silence if a value is entered from user
    if (typeof silenceForm.timeValue === "number") {
        const silenceUntilValue = getDateNowPlusDuration({
            unit: silenceForm.timeUnit,
            value: silenceForm.timeValue,
        })
        silenceUntilDisplay = dateToLocalDateTimeDisplay(silenceUntilValue, "-")
    }

    // rendering
    return (
        <div className={styles.silenceContainer}>
            {activeSilenceExists && (
                <div
                    className={classNames(
                        styles.flexRow,
                        styles.initalSilenceRow
                    )}
                >
                    <span className={styles.titleText}>
                        {`Current Silence is set until: ${initialSilenceSettingDisplay}`}
                    </span>
                </div>
            )}
            <div className={styles.flexRow}>
                <div
                    className={classNames(
                        styles.silenceTitleText,
                        styles.silenceLabel
                    )}
                >
                    <span className={styles.marginRight4}>
                        {activeSilenceExists && "Update "} Silence Duration:
                    </span>
                    <ToolTip_Popover
                        content={
                            <>
                                Stop alert notifications for this subscription
                                from a length of time starting now.
                            </>
                        }
                        triggerChildren={
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className={styles.fai}
                            />
                        }
                        popover={{
                            removePopoverButtonStyling: true,
                        }}
                    />
                </div>
                <Input
                    type="number"
                    onChange={handleTimeValueChange}
                    value={silenceForm.timeValue as number}
                    orientation="horizontal"
                    specializedClass="noMessage"
                    inputCustomClass={styles.silenceValueInput}
                    min={0}
                />
                <Input
                    type="select"
                    onChange={handleTimeUnitChange}
                    options={silenceDurationUnitOptions}
                    orientation="horizontal"
                    value={silenceForm.timeUnit}
                    inputCustomClass={styles.silenceUnitInput}
                    specializedClass="noMessage"
                />
            </div>
            <div
                className={classNames(
                    styles.silenceBodyText,
                    styles.silenceUntilRow
                )}
            >
                <span className={styles.marginRight8}>
                    {activeSilenceExists &&
                        typeof silenceForm.timeValue === "number" && (
                            <span> Updated silence will expire on:</span>
                        )}

                    {activeSilenceExists &&
                        typeof silenceForm.timeValue === "string" && (
                            <span> Silence will not be updated</span>
                        )}

                    {!activeSilenceExists && (
                        <span> Silence will expire on: </span>
                    )}
                </span>
                {silenceUntilDisplay}
            </div>
            {activeSilenceExists && (
                <Button
                    status="secondary"
                    condition="default"
                    handleButtonClick={() => {
                        const route =
                            viewerAction === "admin"
                                ? `../${NOTIFICATIONS_ADMIN_REMOVE_SILENCE_CHILD}`
                                : `../${NOTIFICATIONS_REMOVE_SILENCE_CHILD}`

                        navigate(route, {
                            relative: "path",
                        })
                    }}
                    disabled={false}
                    customButtonClassName={styles.removeSilence}
                >
                    Remove Silence
                </Button>
            )}
        </div>
    )
}

export default SilenceSettings
