import { graphql } from "../../generated"

export const DATE_TIME_FIELDS_FRAGMENT = graphql(`
    fragment DateTimeFields on DateTime {
        year
        month
        hour
        day
        minute
        second
        unixSeconds
        unixMilliseconds
        unixNanoseconds
        timeZone
        utcOffset {
            ...DurationFields
        }
        format(layout: "%d")
    }
`)
