import { Row } from "@tanstack/react-table"
import { WellIndexTableDataI } from "../tableTypes"

// write test
const alertTypePriority = {
    InfoAlert: -1,
    ControlAlert: 1,
    SensorAlert: 2,
    CommunicationsAlert: 3,
    PowerLossAlert: 4,
}
// sort alerts in order from highest priority to lowest priority
function sortAlerts(
    rowA: Row<WellIndexTableDataI>,
    rowB: Row<WellIndexTableDataI>
) {
    const alertA = rowA.original.alert?.__typename
    const alertB = rowB.original.alert?.__typename

    const priorityA = alertA === undefined ? 0 : alertTypePriority[alertA]
    const priorityB = alertB === undefined ? 0 : alertTypePriority[alertB]

    // Compare priorities and return the result
    if (priorityA > priorityB) {
        return -1
    } else if (priorityA < priorityB) {
        return 1
    } else {
        return 0
    }
}
export { sortAlerts }
