import React, { FunctionComponent, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faX,
    faCircleInfo,
    faTriangleExclamation,
    faCircleExclamation,
    faCircleCheck,
} from "@fortawesome/pro-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import classNames from "classnames"
import "./alert.scss"

// default alert component - uncontrolled
export interface AlertComponentI {
    message: string | React.ReactNode
    alertType: "info" | "danger" | "success" | "warning"
    isCloseable: boolean
    customAlertClass?: string
    useCustomLeftIcon?: boolean
}

const Alert: FunctionComponent<AlertComponentI> = (props) => {
    const [isVisible, setIsVisible] = useState(true)

    const handleClose = () => {
        setIsVisible(false)
    }

    return <BaseAlert {...props} isVisible={isVisible} onClose={handleClose} />
}

export default Alert

export interface BaseAlertComponentI extends AlertComponentI {
    isVisible: boolean
    onClose: () => void
}

// controlled alert component.
const AlertControlledComp: FunctionComponent<BaseAlertComponentI> = (props) => {
    return <BaseAlert {...props} />
}

export { AlertControlledComp }

const BaseAlert: FunctionComponent<BaseAlertComponentI> = ({
    message,
    isCloseable,
    alertType,
    customAlertClass,
    useCustomLeftIcon,
    isVisible,
    onClose,
}) => {
    let leftIcon: IconProp
    let leftIconColor: string
    let className: string

    switch (alertType) {
        case "info":
            leftIcon = faCircleInfo
            className = "info"
            leftIconColor = "#90A4AE"
            break
        case "danger":
            leftIcon = faTriangleExclamation
            className = "danger"
            leftIconColor = "#F44336"
            break
        case "success":
            leftIcon = faCircleCheck
            className = "success"
            leftIconColor = "#4CAF50"
            break
        case "warning":
            leftIcon = faCircleExclamation
            className = "warning"
            leftIconColor = "#FDD835"
            break
        default:
            leftIcon = faCircleInfo
            className = "info"
            leftIconColor = "#90A4AE"
            break
    }

    const classes = classNames("alert-class", className, customAlertClass)

    if (!isVisible) {
        return <></>
    }

    return (
        <div className={classes}>
            <div className="alert-left-icon-text-container">
                {!useCustomLeftIcon && (
                    <FontAwesomeIcon
                        color={leftIconColor}
                        icon={leftIcon}
                        className="alert-left-icon"
                    />
                )}
                <div>{message}</div>
            </div>
            {isCloseable && (
                <FontAwesomeIcon
                    onClick={onClose}
                    color="#1E88E5"
                    icon={faX}
                    className="alert-right-icon"
                />
            )}
        </div>
    )
}
