import { FunctionComponent } from "react"
import styles from "./AdminHeader.module.scss"
import classNames from "classnames"
import { TableColumnInterface } from "../../../../types/tableTypes"
import { UserDataInterface } from "../../../../pages/admin/users/userTableTypes"
import { OrganizationDataInterface } from "../../../../pages/admin/organizations/organizationTableTypes"

interface AdminTableHeaderProps {
    config:
        | TableColumnInterface<OrganizationDataInterface>[]
        | TableColumnInterface<UserDataInterface>[]
}

const AdminTableHeaders: FunctionComponent<AdminTableHeaderProps> = ({
    config,
}) => {
    const renderedHeaders = config.map((column) => {
        const headerCustomClass = column.headerCustomClass

        const headerClass = classNames(
            styles.tableHeaderClass,
            headerCustomClass && styles[headerCustomClass]
        )

        return (
            <th className={headerClass} key={column.id}>
                {column.label}
            </th>
        )
    })

    return <tr>{renderedHeaders}</tr>
}

export default AdminTableHeaders
