import { FunctionComponent, useEffect, useState } from "react"
import styles from "./subNavigation.module.scss"
import { faSidebar } from "@fortawesome/pro-solid-svg-icons"
import Button from "../../shared/button"
import { useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { WellIdentificationFieldsFragment } from "../../../generated/graphql"
import { SubNavDropDownI, IwpSubNavNavControlsI } from "./subNavigationTypes"
import { getInitialSelectedNavigationalControl } from "./subNavigationUtils"
import WellConfigurationMenu from "./WellConfigurationMenu"
import IwpSubPageNavigation from "./IwpSubPageNavigation"

interface IwpSubNavigationPropsI {
    wellIdentificationfragment: WellIdentificationFieldsFragment | undefined
    toogleStatusBar: () => void
    windowWidth: number
}

const IwpSubNavigation: FunctionComponent<IwpSubNavigationPropsI> = ({
    wellIdentificationfragment,
    toogleStatusBar,
    windowWidth,
}) => {
    const { pathname } = useLocation()

    const [subNavControls, setSubNavControls] = useState<IwpSubNavNavControlsI>(
        getInitialSelectedNavigationalControl(pathname)
    )
    const [dropDownMenus, setDropDownMenus] = useState<SubNavDropDownI>({
        configurationMenu: false,
        subPageMenu: false,
    })

    const mobileBreakPoint = 768

    useEffect(() => {
        const closeConfigurationMenu = () => {
            setDropDownMenus({
                configurationMenu: false,
                subPageMenu: false,
            })
        }

        document.addEventListener("click", closeConfigurationMenu)

        return () => {
            document.removeEventListener("click", closeConfigurationMenu)
        }
    }, [])

    return (
        <div className={styles.subnavigation}>
            <div className={styles.subnavigationLeft}>
                <div className={styles.sideBarIcon}>
                    <Button
                        handleButtonClick={toogleStatusBar}
                        status="secondary"
                    >
                        <FontAwesomeIcon
                            style={{ width: "24px", height: "24px" }}
                            icon={faSidebar}
                        />
                    </Button>
                </div>
                <div className={styles.subnavigationLeftText}>
                    {wellIdentificationfragment?.name}
                </div>
            </div>
            <div className={styles.subnavigationRight}>
                <div className={styles.wellConfigMenu}>
                    {wellIdentificationfragment && (
                        <WellConfigurationMenu
                            subNavControls={subNavControls}
                            setSubNavControls={setSubNavControls}
                            dropDownMenus={dropDownMenus}
                            setDropDownMenus={setDropDownMenus}
                            wellIdentificationfragment={
                                wellIdentificationfragment
                            }
                            windowWidth={windowWidth}
                            mobileBreakPoint={mobileBreakPoint}
                        />
                    )}
                </div>
                {wellIdentificationfragment && (
                    <IwpSubPageNavigation
                        subNavControls={subNavControls}
                        setSubNavControls={setSubNavControls}
                        dropDownMenus={dropDownMenus}
                        setDropDownMenus={setDropDownMenus}
                        wellIdentificationfragment={wellIdentificationfragment}
                        windowWidth={windowWidth}
                        mobileBreakPoint={mobileBreakPoint}
                    />
                )}
            </div>
        </div>
    )
}

export default IwpSubNavigation
