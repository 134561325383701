import { FunctionComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/pro-solid-svg-icons"
import { TrexNavigator } from "../../../classes/navigator/navigator"
// icons & scss
import styles from "./modalHeader.module.scss"

export interface ModalHeaderComponentInterface {
    title: string
    subTitle?: string
    trexNavigator: TrexNavigator
}

const ModalHeader: FunctionComponent<ModalHeaderComponentInterface> = ({
    title,
    subTitle,
    trexNavigator,
}) => {
    return (
        <div className={styles.container}>
            <div>
                <div className={styles.title}>{title}</div>
                <div className={styles.subTitle}>{subTitle}</div>
            </div>
            <FontAwesomeIcon
                className={styles.closeIcon}
                color="#455A64"
                icon={faXmark}
                onClick={() => trexNavigator.trexNavigate()}
                id="addWellsClose"
            />
        </div>
    )
}

export default ModalHeader
