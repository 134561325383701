import { createColumnHelper } from "@tanstack/react-table"
import { WellIndexTableDataI } from "../tableTypes"
import {
    RunModeCell,
    RunTimeCell,
    SpmCell,
    WellAlertCell,
    WellNameCell,
} from "../cell"
import { sortAlerts } from "./sort"
import WellIndexDropDownMenu from "../cell/wellIndexDropDownMenu"

// constants

// Note: column ids are used as keys to style table columns.
// If the column headers are changed in this file, they should be updated in: table.module.scss , header.module.scss
// Note: The column ids are used to keep the urlState in sync with the application state and the column id value will be reflected in the url
// example: ?organization="PetroPower"
// NOTE: The current implementation of the table will not keep urlState in sync with application state for multisort - to enable mutliSort updates will need to be made, start with the clickHandler that toggles sort ~ /header/index.tsx - <HeaderSortIcon/>

export const ALERT_COLUMN_ID = "alert"
export const WELL_NAME_COLUMN_ID = "wellName"
export const RUN_MODE_COLUMN_ID = "runMode"
export const WELL_STATUS_COLUMN_ID = "wellStatus"
export const RUN_TIME_COLUMN_ID = "runTime"
export const SPM_COLUMN_ID = "spm"
export const RUN_TIME_WEEKLY_TRENDS_COLUMN_ID = "runTimeWeeklyTrends"
export const MENU_COLUMN_ID = "menu"
export const ORGANIZATION_COLUMN_ID = "organization"

const columnHelper = createColumnHelper<WellIndexTableDataI>()

const wellIndexColumns = [
    columnHelper.accessor("alert", {
        id: ALERT_COLUMN_ID,
        header: "",
        cell: (info) => <WellAlertCell wellAlert={info.getValue()} />,
        enableSorting: true,
        sortingFn: sortAlerts, // This custom sorting function will sort from highest priority to lowest priority => asc === high to low
        sortDescFirst: false, // first sort should be asc
        enableMultiSort: false,
    }),
    columnHelper.accessor("wellName", {
        id: WELL_NAME_COLUMN_ID,
        header: "Well Name",
        cell: ({ row }) => (
            <WellNameCell
                wellId={row.original.id}
                wellLinkedStatus={row.original.wellLinkedStatus}
                wellName={row.original.wellName}
            />
        ),
        enableSorting: true,
        sortingFn: "text",
        enableMultiSort: false,
    }),

    columnHelper.accessor("runMode", {
        id: RUN_MODE_COLUMN_ID,
        header: "Run Mode",
        cell: (info) => (
            <RunModeCell
                linkTo={`controls/${info.row.original.id}/edit`}
                runMode={info.getValue()}
            />
        ),
        enableSorting: true,
        sortUndefined: 1,
        sortingFn: "basic",
        enableMultiSort: false,
        sortDescFirst: false,
    }),
    columnHelper.accessor("wellStatus", {
        id: WELL_STATUS_COLUMN_ID,
        header: "Well Status",
        cell: (info) => info.getValue(),
        enableSorting: true,
        sortingFn: "basic",
        enableMultiSort: false,
    }),
    columnHelper.accessor("runTime", {
        id: RUN_TIME_COLUMN_ID,
        header: "Run Time",
        cell: (info) => <RunTimeCell runTimeSeconds={info.getValue()} />,
        enableColumnFilter: true,
        enableSorting: true,
        enableMultiSort: false,
        sortingFn: "basic",
        sortUndefined: -1,
        sortDescFirst: true,
    }),
    columnHelper.accessor("spm", {
        id: SPM_COLUMN_ID,
        header: "SPM",
        cell: (info) => <SpmCell spm={info.getValue()} />,
        enableSorting: true,
        enableMultiSort: false,
        sortingFn: "basic",
        sortUndefined: -1,
        sortDescFirst: true,
    }),
    columnHelper.accessor("runTimeWeeklyTrends", {
        header: "Run Time Weekly Trends",
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
        enableMultiSort: false,
        enableSorting: false,
        id: RUN_TIME_WEEKLY_TRENDS_COLUMN_ID,
    }),
    columnHelper.display({
        id: MENU_COLUMN_ID,
        header: "",
        cell: ({ row }) => {
            const wellId = row.original.id
            const wellLinkedStatus = row.original.wellLinkedStatus
            return (
                <WellIndexDropDownMenu
                    wellLinkedStatus={wellLinkedStatus}
                    wellId={wellId}
                />
            )
        },
    }),
    columnHelper.accessor("organization", {
        id: ORGANIZATION_COLUMN_ID,
        header: "",
        cell: () => {
            return <></>
        },
        enableHiding: true,
    }),
]

export default wellIndexColumns
