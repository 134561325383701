import { useMemo } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { MyNotificationsTableDataI } from "../tableTypes"
import { ActionMenu } from "../../../../../shared/tanStack/cell/actionsMenu"
import { getMyNotificationsMenuByRole } from "../../../../../../util/rolePermissions/getByRole"
import { useViewerContext } from "../../../../../../context/ViewerContext"
import {
    AlertMetricStatusIcon,
    MyNotificationsMarkAllAsRead,
    MyNotificationsMarkAsRead,
    MyNotificationsTimeStampsCell,
} from "../cell"
import { formatAlertMetricValueWithUnit } from "../../../../rules/manageAlertRule/alerts_GraphQL/alertRuleMetricFragment"
import { formatAlertRuleThresholdForDisplay } from "../../../../rules/manageAlertRule/alerts_GraphQL/alertRuleConditionFragment"
import { Link } from "react-router-dom"
import { sortAlertNotifications } from "./sort"
import { SubjectRole } from "../../../../../../generated/graphql"
import ToolTip from "../../../../../shared/toolTip/tooltip"
import styles from "./column.module.scss"
import ToolTip_Popover from "../../../../../shared/toolTip/toolTip_popover"
import classNames from "classnames"

// constants

// Note: column ids are used as keys to style table columns.
// If the column headers are changed in this file, they should be updated in: table.module.scss , header.module.scss
// Note: The column ids are used to keep the urlState in sync with the application state and the column id value will be reflected in the url
// example: /?wellName="PumpMoreOil"
// NOTE: The current implementation of the table will not keep urlState in sync with application state for multisort - to enable mutliSort updates will need to be made, start with the clickHandler that toggles sort ~ /header/index.tsx - <HeaderSortIcon/>

export const RULE_NAME_COLUMN_ID = "ruleName"
export const METRIC_DISPLAY_NAME_COLUMN_ID = "metric"
export const WELL_NAME_COLUMN_ID = "well"
export const ALERT_START_COLUMN_ID = "alertStart"
export const ALERT_STOP_COLUMN_ID = "alertStop"
export const READ_NOTIFICATION_COLUMN_ID = "readNotification"
export const ALERT_RULE_METRIC_COLUMN_ID = "status"
export const TRIGGER_VALUE_COLUMN_ID = "triggerValue"
export const TRIGGER_THRESHOLD_COLUMN_ID = "triggerThreshold"

// table columns
const columnHelper = createColumnHelper<MyNotificationsTableDataI>()

export const myNotificationsColumns = [
    columnHelper.accessor("notificationRead", {
        id: READ_NOTIFICATION_COLUMN_ID,
        header: ({ table }) => {
            // get unique values for read column
            const uniqueReadValues = table
                ?.getColumn(READ_NOTIFICATION_COLUMN_ID)
                ?.getFacetedUniqueValues()

            // if unique values does not have false, then all notifications are read
            const allNotificationsRead = useMemo(() => {
                if (uniqueReadValues?.has(false)) {
                    return false
                }
                return true
            }, [uniqueReadValues])

            return (
                <MyNotificationsMarkAllAsRead
                    allNotificationsRead={allNotificationsRead}
                />
            )
        },
        cell: ({ row }) => {
            const notificationId = row.original.id
            const read = row.original.notificationRead

            return (
                <MyNotificationsMarkAsRead
                    notificationId={notificationId}
                    read={read}
                />
            )
        },
        enableSorting: false,
    }),
    columnHelper.accessor("alertRuleMetric", {
        id: ALERT_RULE_METRIC_COLUMN_ID,
        header: "Status",
        cell: ({ row }) => {
            const {
                alertRuleMetric,
                eventResolvedTime,
                notificationRead,
                notificationSilenced,
            } = row.original

            const notificationWasResolved = Boolean(eventResolvedTime)

            return (
                <AlertMetricStatusIcon
                    alertRuleMetric={alertRuleMetric}
                    resolved={notificationWasResolved}
                    wasSilenced={notificationSilenced}
                    notificationRead={notificationRead}
                />
            )
        },
        enableSorting: true,
        sortingFn: sortAlertNotifications,
    }),
    columnHelper.accessor("ruleName", {
        id: RULE_NAME_COLUMN_ID,
        header: "Rule Name",
        enableMultiSort: false,
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: "alphanumeric",
        cell: ({ row }) => {
            const viewer = useViewerContext().getViewer()
            const ruleSlug = [
                SubjectRole.PpoAdmin,
                SubjectRole.OrgAdmin,
            ].includes(viewer.role)
                ? "updateRule"
                : "viewRule"

            const { alertRuleId, ruleName } = row.original

            return (
                <ToolTip
                    tooltipContent={<div>{ruleName}</div>}
                    triggerChildren={
                        <Link
                            style={{ color: "#1e88e5", textDecoration: "none" }}
                            className={classNames(
                                styles.triggerChildren,
                                styles.link
                            )}
                            to={`${alertRuleId}/${ruleSlug}`}
                        >
                            <span>{ruleName}</span>
                        </Link>
                    }
                />
            )
        },
    }),
    columnHelper.accessor("metricDisplayName", {
        id: METRIC_DISPLAY_NAME_COLUMN_ID,
        header: "Metric",
        enableMultiSort: false,
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: "alphanumeric",
        cell: ({ row }) => {
            const metric = row.original.metricDisplayName
            return (
                <ToolTip_Popover
                    content={<div>{metric}</div>}
                    triggerChildren={
                        <div className={styles.triggerChildren}>{metric}</div>
                    }
                    popover={{
                        removePopoverButtonStyling: true,
                        triggerStyles: styles.triggerStyles,
                    }}
                />
            )
        },
    }),
    columnHelper.accessor("wellName", {
        id: WELL_NAME_COLUMN_ID,
        header: "Well Name",
        enableMultiSort: false,
        enableSorting: true,
        cell: ({ row }) => {
            const { wellName, wellId } = row.original

            return (
                <ToolTip
                    tooltipContent={<div>{wellName}</div>}
                    triggerChildren={
                        <Link
                            className={styles.link}
                            to={`/wellDetails/${wellId}`}
                        >
                            <span>{wellName}</span>
                        </Link>
                    }
                />
            )
        },
    }),
    columnHelper.accessor("alertTriggerValue", {
        id: TRIGGER_VALUE_COLUMN_ID,
        header: "Trigger Value",
        enableMultiSort: false,
        enableSorting: true,
        cell: ({ row }) => {
            // format trigger value for display
            const { alertTriggerValue, alertRuleConditionUnit } = row.original

            const triggerValueWithUnit = formatAlertMetricValueWithUnit(
                alertTriggerValue,
                alertRuleConditionUnit,
                "LargestSingleUnit"
            )

            return <span>{triggerValueWithUnit}</span>
        },
    }),

    columnHelper.accessor("alertMetricThreshold", {
        id: TRIGGER_THRESHOLD_COLUMN_ID,
        header: "Threshold",
        enableMultiSort: false,
        enableSorting: true,
        cell: ({ row }) => {
            // format alert rule threshold for display
            const displayThreshold = formatAlertRuleThresholdForDisplay(
                row.original.alertMetricThreshold,
                row.original.alertMetricThreshold2,
                row.original.alertRuleConditionUnit,
                row.original.alertRuleOperator
            )

            return <span>{displayThreshold}</span>
        },
    }),
    columnHelper.accessor("eventTriggerTime", {
        id: ALERT_START_COLUMN_ID,
        header: "Alert Start",
        enableMultiSort: false,
        enableSorting: true,
        sortDescFirst: false,
        cell: ({ row }) => {
            const unixMilliseconds = row.original.eventTriggerTime
            return (
                <MyNotificationsTimeStampsCell
                    unixMilliseconds={unixMilliseconds}
                />
            )
        },
    }),
    columnHelper.accessor("eventResolvedTime", {
        id: ALERT_STOP_COLUMN_ID,
        header: "Alert Stop",
        enableMultiSort: false,
        enableSorting: true,
        sortDescFirst: false,
        cell: ({ row }) => {
            const unixMilliseconds = row.original.eventResolvedTime
            return (
                <MyNotificationsTimeStampsCell
                    unixMilliseconds={unixMilliseconds}
                />
            )
        },
    }),
    columnHelper.display({
        id: "menu",
        header: "Actions",
        cell: ({ row }) => {
            const { role } = useViewerContext().getViewer()
            const subscriptionId = row.original.subscriptionId

            return (
                <ActionMenu
                    menuOptions={getMyNotificationsMenuByRole(
                        role,
                        subscriptionId
                    )}
                />
            )
        },
    }),
]
