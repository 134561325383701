import { graphql } from "../../generated"

export const ADD_WELL_CONTROL_SETTING = graphql(`
    mutation AddControlSetting(
        $addWellControlSettingInput: AddWellControlSettingInput!
    ) {
        addWellControlSetting(input: $addWellControlSettingInput) {
            well {
                ...WellIdentificationFields
                configRevision {
                    ...WellConfigurationRevisionParameters
                }
                controlSetting {
                    ...ControlSettingFields
                }
            }
        }
    }
`)
