import { graphql } from "../../generated"

export const GET_ALERT_RULES = graphql(`
    query GetAlertRules($RulesFilter: RulesFilter) {
        rules(filter: $RulesFilter) {
            rules {
                ...AlertRuleFields
            }
        }
    }
`)
