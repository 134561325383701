import { FunctionComponent, ReactNode, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styles from "./alertRuleActions.module.scss"
import Button from "../../../../shared/button"
import { AlertRuleFormType } from "../form"
import { RULE_PREVIEW_EVALUATE } from "../../../../../graphql/queries/rulePreviewEvaluate"
import { ApolloError, useLazyQuery } from "@apollo/client"
import {
    RulePreviewEvaluateQuery,
    SubjectRole,
} from "../../../../../generated/graphql"
import { AlertRuleFormI } from "../alertRuleFormUtil/alertRuleFormTypes"
import {
    EmptyState,
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../../shared/graphQlResponse"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faX } from "@fortawesome/pro-solid-svg-icons"
import { getRulePreviewEvaluateQueryVars } from "../alertRuleFormUtil/api"
import { getFragmentData } from "../../../../../generated"
import { ALERT_EVENT_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertEvent"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/wellIdentification"
import { useNotificationsContext } from "../../../context/notificationsContext"
import { ALERT_RULE_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRule"
import { ALERT_RULE_CONDITION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleCondition"
import { formatAlertMetricValueWithUnit } from "../alerts_GraphQL/alertRuleMetricFragment"
import { useViewerContext } from "../../../../../context/ViewerContext"

interface AlertRuleFormActionsI {
    formAction: AlertRuleFormType
    ruleForm: AlertRuleFormI
    disabledPreviewAlert: boolean
    disabledDeleteRule: boolean
}

const AlertRuleFormActions: FunctionComponent<AlertRuleFormActionsI> = ({
    formAction,
    ruleForm,
    disabledPreviewAlert,
    disabledDeleteRule,
}) => {
    const notificationsContext = useNotificationsContext()
    const selectedOrganizationId =
        notificationsContext.selectedOrganization.value
    const { role: viewerRole } = useViewerContext().getViewer()

    const navigate = useNavigate()
    const urlSlugs = useParams()
    const ruleId = urlSlugs.ruleId as string

    // state
    const [showPreview, setShowPreview] = useState<boolean>(false)

    // query
    const [previewQuery, { error, loading, data }] = useLazyQuery(
        RULE_PREVIEW_EVALUATE
    )

    // constants
    const isUpdateForm =
        Boolean(formAction === "UPDATE") && viewerRole !== SubjectRole.OrgMember
    const previewButtonText = showPreview
        ? "Refresh Preview"
        : "Preview Alert Rule"

    useEffect(() => {
        // reset the preview state when the form changes - per the load on influx db we want the user to manually select the preview button
        // we should the preview here because we don't want the user to have state on the modal that does not reflect what is in the preview.
        setShowPreview(false)
    }, [ruleForm.condition, ruleForm.windowTotalSeconds, ruleForm.metric])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.actionContainer}>
                    <div className={styles.actionContainerLeft}>
                        <Button
                            handleButtonClick={() => {
                                setShowPreview(true)
                                previewQuery({
                                    variables: {
                                        input: getRulePreviewEvaluateQueryVars(
                                            ruleForm,
                                            selectedOrganizationId
                                        ),
                                    },
                                    // network only so we always get the latest data
                                    fetchPolicy: "network-only",
                                })
                            }}
                            status="primary"
                            condition="default"
                            customButtonClassName={styles.marginRight16}
                            disabled={loading || disabledPreviewAlert}
                        >
                            {previewButtonText}
                        </Button>
                        {isUpdateForm && (
                            <Button
                                handleButtonClick={() =>
                                    navigate(
                                        `/notifications/rules/${ruleId}/deleteRule`
                                    )
                                }
                                status="primary"
                                condition="alert"
                                disabled={loading || disabledDeleteRule}
                            >
                                Delete Rule
                            </Button>
                        )}
                    </div>
                    {showPreview && (
                        <Button
                            handleButtonClick={() => setShowPreview(false)}
                            status="secondary"
                            condition="default"
                        >
                            <FontAwesomeIcon color="#455a64" icon={faX} />
                        </Button>
                    )}
                </div>
                {(showPreview && (
                    <PreviewAlertState
                        ruleForm={ruleForm}
                        error={error}
                        loading={loading}
                        data={data}
                    />
                )) || (
                    <div className={styles.previewDescription}>
                        Preview the wells currently alerting under the
                        configured rule.
                    </div>
                )}
            </div>
        </>
    )
}

export default AlertRuleFormActions

interface PreviewAlertStateI {
    error: ApolloError | undefined
    loading: boolean
    data: RulePreviewEvaluateQuery | undefined
    ruleForm: AlertRuleFormI
}

const PreviewAlertState: FunctionComponent<PreviewAlertStateI> = ({
    error,
    loading,
    data,
    ruleForm,
}) => {
    let body: ReactNode = <></>

    if (error) {
        console.error(error)
        body = (
            <ErrorMessage
                outSideDivStyle={styles.error}
                message="There was an issue previewing your rule. Please try again."
            />
        )
    }
    if (loading) {
        body = <SyncLoaderComponent outSideDivStyle={styles.loader} />
    } else if (data) {
        // else if is important here because on refresh we want to show the loader
        if (data.rulePreviewEvaluate.events.length === 0) {
            body = <EmptyAlertsEvents />
        } else {
            const events = getFragmentData(
                ALERT_EVENT_FIELDS_FRAGMENT,
                data.rulePreviewEvaluate.events
            )

            body = (
                <div className={styles.previewDataContainer}>
                    <div className={styles.previewRowContainer}>
                        <div
                            className={classNames(
                                styles.previewTitle,
                                styles.padWellName
                            )}
                        >
                            Well Name
                        </div>
                        <div className={styles.previewTitle}>
                            Current Metric Value
                        </div>
                    </div>
                    {events.map((event) => {
                        // get well name for display
                        const well = getFragmentData(
                            WELL_IDENTIFICATION_FIELDS_FRAGMENT,
                            event.well
                        )

                        // get trigger value with unit for display
                        const eventAlertRule = getFragmentData(
                            ALERT_RULE_FIELDS_FRAGMENT,
                            event.alertRule
                        )

                        const alertRuleCondition = getFragmentData(
                            ALERT_RULE_CONDITION_FIELDS_FRAGMENT,
                            eventAlertRule.condition
                        )

                        // if the previewed alert rule is a condition of type duration -- set the trigger value to the same duration unit as the threshold on the rule that is being previewed
                        const targetDurationUnit =
                            ruleForm.condition.thresholdTimeUnit

                        const displayTriggerValueWithUnit =
                            formatAlertMetricValueWithUnit(
                                event.triggeredValue,
                                alertRuleCondition.unit,
                                targetDurationUnit
                            )

                        return (
                            <div
                                key={well.id}
                                className={styles.previewRowContainer}
                            >
                                <div className={styles.padWellName}>
                                    {well.name}
                                </div>
                                <div>{displayTriggerValueWithUnit}</div>
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    return <div className={styles.previewOuterContainer}>{body}</div>
}

const EmptyAlertsEvents: FunctionComponent = () => {
    return (
        <EmptyState outSideDivStyle={styles.emptyStateContainer}>
            <div className={styles.emptyTitle}>
                No wells are alerting for this rule configuration.
            </div>
        </EmptyState>
    )
}
