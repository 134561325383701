import { WellIdentificationFieldsFragment } from "../../generated/graphql"
import { WellAttributesFormI } from "./wellAttributesTypes"

// This helper function extracts information about the API number from the interface  WellAttributesFormI
//  returns an object with two properties: `unknown` (boolean): Indicates whether the API number is unknown (`true` if it is a boolean in the WellAttributesForm).
//- `format` (string): The formatted API number if it is a string in the WellAttributesForm, otherwise an empty string.
const getApiNumberFromWellAttributes = (
    wellAttributesForm: WellAttributesFormI
) => {
    const { apiNumber } = wellAttributesForm

    return {
        apiUnknown: typeof apiNumber === "boolean" ? true : false,
        apiNumberFormatted: typeof apiNumber === "string" ? apiNumber : "",
    }
}

// Helpers for getting well attributes form from wellIdentificationFragment
const getApiNumberFromWellIdentificationFragment = (
    fragment: WellIdentificationFieldsFragment
) => {
    const apiUnknown = fragment.apiNumber.unknown
    const apiFormatted = fragment.apiNumber.format
    const apiNumber = apiUnknown ? false : apiFormatted
    return apiNumber
}

const getWellAttributesFormFromWellIdentificationFragment = (
    fragment: WellIdentificationFieldsFragment
): WellAttributesFormI => {
    const apiNumber = getApiNumberFromWellIdentificationFragment(fragment)

    return {
        Latitude: fragment.wellGeoLocation.latitude,
        Longitude: fragment.wellGeoLocation.longitude,
        apiNumber: apiNumber,
        WellName: fragment.name,
    }
}
//  end helpers for getting well attributes form from wellIdentificationFragment

export {
    getWellAttributesFormFromWellIdentificationFragment,
    getApiNumberFromWellAttributes,
}
