import { getFragmentData } from "../../../../../generated"
import {
    AlertRuleMetric,
    AlertRuleMetricDefinitionFieldsFragment,
    Unit,
} from "../../../../../generated/graphql"
import { ALERT_TEMPLATE_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleTemplate"
import { DURATION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/duration"
import { LabelValueOptionType } from "../../../../../types/commonTypes"
import {
    convertDurationUnit,
    convertSecondsToLargestSingleDurationUnit,
    durationUnitValueToDisplay,
} from "../../../../../util/duration/duration"
import { DurationUnitT } from "../../../../../util/duration/types"
import {
    ratioToPercentage,
    toDecimal,
} from "../../../../../util/numbers/numbers"
import { createAlertRuleWindowInputOption } from "../form/formUtil"

export interface LabelMetricEnumOptionType {
    label: string
    value: AlertRuleMetric
}
// Note:  This function is used in ManageAlertRuleForm metric input/select.
// The function handler: handleMetricChange  in add rule --  utilizes the value of the select options to update alert rule metric. Consider this before updating this function.
const getMetricOptionsFromMetrics = (
    metrics: AlertRuleMetricDefinitionFieldsFragment[]
): LabelMetricEnumOptionType[] => {
    return metrics
        .filter((metric) => metric.metric !== AlertRuleMetric.Unspecified)
        .map((metric) => ({
            label: metric.displayName,
            value: metric.metric,
        }))
}

// This function accepts an AlertRuleMetricDefinitionFieldsFragment as input and returns an array of LabelValueOptionType.
const getWindowOptionsFromMetric = (
    alertRuleMetric: AlertRuleMetricDefinitionFieldsFragment
): LabelValueOptionType[] => {
    if (!alertRuleMetric || !alertRuleMetric.templates) {
        return []
    }
    const windowOptions = alertRuleMetric.templates.map((template) => {
        const t = getFragmentData(ALERT_TEMPLATE_FIELDS_FRAGMENT, template)
        const windowFragment = getFragmentData(
            DURATION_FIELDS_FRAGMENT,
            t.window
        )

        const windowOption = createAlertRuleWindowInputOption(
            windowFragment.totalSeconds
        )

        return windowOption
    })

    return windowOptions
}

/**
 *
 * Format alert metric value with unit for display - used in notifications dashboard, alert rule previews, etc.
 *
 * @param alertMetricValue - the value of the metric, incoming duration values must be in seconds
 * @param alertRuleConditionUnit - the unit on the alert rule condition which the metric is tied to
 * @param targetDurationType - the target duration type to convert the metric value to
 * @returns
 */
const formatAlertMetricValueWithUnit = (
    alertMetricValue: number, // incoming duration values must be in seconds
    alertRuleConditionUnit: Unit,
    targetDurationType: DurationUnitT | "LargestSingleUnit" | null
): string => {
    // convert metric value to a decimal rounded to 2 places with removed trailing zeros
    const metricValueDecimal = toDecimal(alertMetricValue, 2)

    switch (alertRuleConditionUnit) {
        case Unit.Duration: {
            switch (targetDurationType) {
                case "s":
                case "m":
                case "h":
                case "d": {
                    const durationResult = convertDurationUnit(
                        { value: metricValueDecimal, unit: "s" },
                        targetDurationType,
                        { places: 2, type: "decimal" }
                    )
                    const display = durationUnitValueToDisplay(durationResult)

                    return `${display.value} ${display.unitLabel}`
                }
                case "LargestSingleUnit": {
                    const largestDurationUnit =
                        convertSecondsToLargestSingleDurationUnit(
                            metricValueDecimal
                        )

                    const display =
                        durationUnitValueToDisplay(largestDurationUnit)

                    return `${display.value} ${display.unitLabel}`
                }
                default:
                    return `${metricValueDecimal} s`
            }
        }
        case Unit.Percentage:
            return `${ratioToPercentage(metricValueDecimal)}%`
        case Unit.CountPerSecond:
        case Unit.Voltage:
        case Unit.StrokesPerMinute:
        case Unit.Count:
        default:
            return metricValueDecimal.toString()
    }
}

export {
    getWindowOptionsFromMetric,
    getMetricOptionsFromMetrics,
    formatAlertMetricValueWithUnit,
}
