import { Checkbox, Toggle } from "../../../../shared/selectionControls"
import {
    ManageSubscriptionFormI,
    UpdateSubscriptionFormI,
} from "../manageSubscriptionFormUtil/types"
import { FunctionComponent, useMemo } from "react"
import styles from "./form.module.scss"
import Select, {
    ActionMeta,
    GroupBase,
    MultiValue,
    SingleValue,
    StylesConfig,
} from "react-select"
import { LabelValueOptionType } from "../../../../../types/commonTypes"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons"
import MultiSelect, {
    MultiSelectOptionT,
} from "../../../../shared/input/react-select/multiSelect"
import Button from "../../../../../components/shared/button"
import SilenceSettings from "./silenceSettings"
import {
    isEmailVerifiedAndConsenting,
    isPhoneVerifiedAndConsenting,
} from "../../../../../graphql/identity/identity"
import ToolTip_Popover from "../../../../shared/toolTip/toolTip_popover"

export type ManageSubscriptionFormType =
    | "AddSelf"
    | "AddAdmin"
    | "UpdateSelf"
    | "UpdateAdmin"

interface BaseI {
    handleUpdateSubscriptionPreferences: (
        inputId: keyof ManageSubscriptionFormI,
        value: boolean
    ) => void
    handleSelectedWellsChange: (
        newValue: MultiValue<MultiSelectOptionT>,
        actionMeta: ActionMeta<MultiSelectOptionT>
    ) => void
    handleSelectAllWells: (upatedSelect: boolean) => void
    ruleOptions: LabelValueOptionType[]
    wellOptions: LabelValueOptionType[]
    formType: ManageSubscriptionFormType
}

export interface ManageSub_Add_CompI extends BaseI {
    handleRuleNameChange: (e: SingleValue<LabelValueOptionType>) => void
    form: ManageSubscriptionFormI
    formType: "AddSelf"
}

// only available to orgAdmin or ge.
export interface ManageSub_AddAdmin_CompI extends BaseI {
    handleRuleNameChange: (e: SingleValue<LabelValueOptionType>) => void
    handleSubscriberChange: (e: SingleValue<LabelValueOptionType>) => void
    subscriberOptions: LabelValueOptionType[]
    form: ManageSubscriptionFormI
    formType: "AddAdmin"
}

export interface ManageSub_Update_CompI extends BaseI {
    form: UpdateSubscriptionFormI
    formType: "UpdateSelf"
    handleSilenceValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleSilenceUnitChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

export interface ManageSub_UpdateAdmin_CompI extends BaseI {
    form: UpdateSubscriptionFormI
    formType: "UpdateAdmin"
    handleSilenceValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleSilenceUnitChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
    subscriberOptions: LabelValueOptionType[]
}

const ManageSubscriptionForm: FunctionComponent<
    | ManageSub_Add_CompI
    | ManageSub_AddAdmin_CompI
    | ManageSub_Update_CompI
    | ManageSub_UpdateAdmin_CompI
> = ({ ...props }) => {
    const {
        handleUpdateSubscriptionPreferences,
        handleSelectedWellsChange,
        handleSelectAllWells,
        ruleOptions,
        form,
        formType,
    } = props

    // form type constants
    const isUpdateForm = formType === "UpdateSelf" || formType === "UpdateAdmin"
    const isAdminForm = formType === "AddAdmin" || formType === "UpdateAdmin"

    const emailIsVerifiedAndConsenting = isEmailVerifiedAndConsenting(
        form.subscriber.email,
        form.subscriber.emailVerified,
        form.subscriber.emailConsent
    )

    const adminEmailMessage =
        "Disabled: User must verify an email and provide consent in user settings."
    const selfEmailMessage =
        "Disabled: Add a verified email and provide consent in user settings."

    const customerEmail =
        form.subscriber.email && emailIsVerifiedAndConsenting
            ? form.subscriber.email
            : isAdminForm
            ? adminEmailMessage
            : selfEmailMessage

    const phoneNumIsVerifiedAndConsenting = isPhoneVerifiedAndConsenting(
        form.subscriber.phone,
        form.subscriber.phoneVerified,
        form.subscriber.phoneConsent
    )

    const adminPhoneMessage =
        "Disabled: User must verify a phone number and provide consent in user settings."
    const selfPhoneMessage =
        "Disabled: Add a verified phone number and provide consent in user settings."

    const customerPhoneNumber =
        form.subscriber.phone && phoneNumIsVerifiedAndConsenting
            ? form.subscriber.phone
            : isAdminForm
            ? adminPhoneMessage
            : selfPhoneMessage

    //checkbox ids
    // const includeNewWells_checkboxId: keyof ManageSubscriptionFormI =
    //     "includeNewWells"
    const email_toggleId: keyof ManageSubscriptionFormI = "enableEmail"
    const text_toggleId: keyof ManageSubscriptionFormI = "enableText"
    const byPassDnd_toggleId: keyof ManageSubscriptionFormI = "byPassDnd"

    const areAllWellsSelected = useMemo(() => {
        return form.wellOptions.every((well) => well.isSelected)
    }, [form.wellOptions])

    return (
        <div className={styles.bodyContainer}>
            {isAdminForm && (
                <div className={styles.selectContainer}>
                    <span className={styles.titleLabel}>Subscriber:</span>
                    <Select
                        options={props.subscriberOptions}
                        isSearchable={true}
                        isDisabled={isUpdateForm}
                        onChange={(e) => {
                            // we can safely type assert props to AddAdmin here b/c the select will be disabled when it is an update form
                            const p = props as ManageSub_AddAdmin_CompI
                            p.handleSubscriberChange(e)
                        }}
                        value={{
                            label: form.subscriber.label,
                            value: form.subscriber.id,
                        }}
                        placeholder={"Select rule"}
                        styles={ruleNameSelectStyles}
                    />
                </div>
            )}
            {
                <div className={styles.selectContainer}>
                    <span className={styles.titleLabel}>Rule Name:</span>
                    <Select
                        options={ruleOptions}
                        isSearchable={true}
                        isDisabled={isUpdateForm}
                        onChange={(e) => {
                            // we can safely type assert props to Add | AddAdmin here b/c the select will be disabled when it's an update form
                            const p = props as
                                | ManageSub_AddAdmin_CompI
                                | ManageSub_Add_CompI

                            p.handleRuleNameChange(e)
                        }}
                        value={form.rule}
                        placeholder={"Select rule"}
                        styles={ruleNameSelectStyles}
                    />
                </div>
            }
            <div className={styles.wellsContainer}>
                <span className={styles.titleLabel}>Well(s):</span>
                <div>
                    <MultiSelect
                        onChange={handleSelectedWellsChange}
                        options={form.wellOptions}
                    />
                </div>
                <Button
                    handleButtonClick={() => {
                        const updatedSelect = !areAllWellsSelected
                        return handleSelectAllWells(updatedSelect)
                    }}
                    status="secondary"
                    condition="default"
                >
                    {areAllWellsSelected
                        ? "Deselect all wells"
                        : "Select all wells"}
                </Button>
            </div>
            {/* <div className={styles.includeNewWells}>
                <Checkbox
                    checkboxLabel="Automatically include new wells that are added in the future."
                    checked={subscriptionForm.includeNewWells}
                    handleCheckBoxClick={(e) => {
                        handleUpdateSubscriptionPreferences(
                            includeNewWells_checkboxId,
                            (e.target as HTMLInputElement).checked
                        )
                    }}
                    id={includeNewWells_checkboxId}
                    disabled={false}
                />
            </div> */}
            <div
                className={classNames(
                    styles.communicationsChannelContainer,
                    styles.marginBottom16
                )}
            >
                <div
                    className={classNames(
                        styles.commChannelTitle,
                        styles.marginBottom8
                    )}
                >
                    Communications Channel
                    <span
                        className={styles.commChanSecondaryText}
                    >{` (How would you like to be notified?)`}</span>
                    {/*note: do not delete the space in the span */}
                </div>
                <div
                    className={classNames(styles.flexRow, styles.marginBottom8)}
                >
                    <div className={styles.checkboxLabel}>
                        <Toggle
                            toggleLabel="Web:"
                            checked={true}
                            handleToggleClick={() => {
                                return
                            }}
                            disabled={true}
                            customToggleLabelClass={styles.disabled}
                        />
                    </div>
                    <span>{`Web notifications dashboard - ALWAYS ON`}</span>
                </div>
                <div
                    className={classNames(styles.flexRow, styles.marginBottom8)}
                >
                    <div className={styles.checkboxLabel}>
                        <Toggle
                            toggleLabel="Email:"
                            checked={form.enableEmail}
                            handleToggleClick={() => {
                                const updatedToggleValue = !form.enableEmail

                                handleUpdateSubscriptionPreferences(
                                    email_toggleId,
                                    updatedToggleValue
                                )
                            }}
                            disabled={!emailIsVerifiedAndConsenting}
                            customToggleLabelClass={
                                !emailIsVerifiedAndConsenting
                                    ? styles.disabled
                                    : ""
                            }
                        />
                    </div>
                    <span>{customerEmail}</span>
                </div>
                <div
                    className={classNames(styles.flexRow, styles.marginBottom8)}
                >
                    <div className={styles.checkboxLabel}>
                        <Toggle
                            toggleLabel="Text:"
                            checked={form.enableText}
                            handleToggleClick={() => {
                                const updatedToggleValue = !form.enableText
                                handleUpdateSubscriptionPreferences(
                                    text_toggleId,
                                    updatedToggleValue
                                )
                            }}
                            disabled={!phoneNumIsVerifiedAndConsenting}
                            customToggleLabelClass={
                                !phoneNumIsVerifiedAndConsenting
                                    ? styles.disabled
                                    : ""
                            }
                        />
                    </div>
                    <span>{customerPhoneNumber}</span>
                </div>
            </div>
            <div className={classNames(styles.flexRow, styles.byPass)}>
                <Checkbox
                    checkboxLabel={`This subscription should bypass "Do Not Disturb" hours.`}
                    checked={form.byPassDnd}
                    handleCheckBoxClick={(e) => {
                        handleUpdateSubscriptionPreferences(
                            byPassDnd_toggleId,
                            (e.target as HTMLInputElement).checked
                        )
                    }}
                    disabled={false}
                />
                <div className={styles.marginLeft4}>
                    <ToolTip_Popover
                        content={
                            <>
                                During “Do Not Disturb” hours we do not send
                                notifications. You can bypass that setting for
                                this subscription. See user settings to update
                                “Do Not Disturb” hours.
                            </>
                        }
                        triggerChildren={
                            <FontAwesomeIcon
                                className={styles.fai}
                                icon={faCircleInfo}
                            />
                        }
                        popover={{ removePopoverButtonStyling: true }}
                    />
                </div>
            </div>
            {isUpdateForm && (
                <SilenceSettings
                    subscriptionForm={form}
                    handleTimeUnitChange={props.handleSilenceUnitChange}
                    handleTimeValueChange={props.handleSilenceValueChange}
                    viewerAction={isAdminForm ? "admin" : "self"}
                />
            )}
        </div>
    )
}

export default ManageSubscriptionForm

const ruleNameSelectStyles: StylesConfig<
    LabelValueOptionType,
    false,
    GroupBase<LabelValueOptionType>
> = {
    control: (provided, state) => ({
        ...provided,
        boxSizing: "border-box",
        outline: "none",
        padding: ".0 .25rem",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: state.isFocused ? "#64b5f6" : "#cfd8dc",
        borderRadius: "4px",
        backgroundColor: "white",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#37474f",
        height: "32px",
        boxShadow: state.isFocused
            ? "0px 0px 0px 3.2px rgba(0, 123, 255, 0.25)"
            : "none",
        width: "300px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? "#64b5f6"
            : state.isFocused
            ? "#f5f5f5"
            : "transparent",

        color: state.isSelected ? "white" : "inherit",
    }),
}
