import { FunctionComponent, ReactNode } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ModalHeader from "../../../../shared/modalHeader"
import { TrexNavigator } from "../../../../../classes/navigator/navigator"
import { ApolloError, useQuery } from "@apollo/client"
import {
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../../shared/graphQlResponse"
import {
    SUBSCRIPTIONS_ADD_QUERY,
    SUBSCRIPTIONS_ADMIN_ADD_QRY,
} from "../../../../../graphql/queries/subscriptionsAddQuery"
import styles from "./addSubscriptions.module.scss"
import AddSubscriptionForm from "./formContainer"
import { useNotificationsContext } from "../../../context/notificationsContext"
import {
    ManageSubscriptionSubcriberOptionsT,
    ManageSubscriptionViewerActionT,
} from "../manageSubscriptionFormUtil/types"
import { useViewerContext } from "../../../../../context/ViewerContext"
import { canRoleAdminSubscriptions } from "../../../../../util/rolePermissions/canRole"
import { UnauthorizedModalBody } from "../../../../UnauthorizedModal"
import {
    SubscriptionAddQryQuery,
    SubscriptionAdminAddQryQuery,
} from "../../../../../generated/graphql"

interface AddSubscriptionI {
    viewerAction: ManageSubscriptionViewerActionT
}

const AddSubscription: FunctionComponent<AddSubscriptionI> = ({
    viewerAction,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const notificationsContext = useNotificationsContext()
    const {
        role: viewerRole,
        identity: viewerIdentity,
        userId: viewerUserId,
        getPhoneNumber,
    } = useViewerContext().getViewer()

    let error: ApolloError | undefined
    let loading: boolean | undefined
    let data: SubscriptionAddQryQuery | SubscriptionAdminAddQryQuery | undefined

    if (viewerAction === "admin") {
        const qry = useQuery(SUBSCRIPTIONS_ADMIN_ADD_QRY, {
            variables: {
                orgId: notificationsContext.selectedOrganization.value,
                usersFirst: 500,
            },
            fetchPolicy: "network-only",
        })

        error = qry.error
        loading = qry.loading
        data = qry.data
    } else {
        const qry = useQuery(SUBSCRIPTIONS_ADD_QUERY, {
            variables: {
                orgId: notificationsContext.selectedOrganization.value,
            },
            fetchPolicy: "network-only",
        })

        error = qry.error
        loading = qry.loading
        data = qry.data
    }

    // check the viewers attempted action and their permissions.
    if (viewerAction === "admin" && !canRoleAdminSubscriptions(viewerRole)) {
        return <UnauthorizedModalBody />
    }

    let addSubscriptionFormComponent: ReactNode
    if (loading) {
        addSubscriptionFormComponent = (
            <SyncLoaderComponent outSideDivStyle={styles.queryLoading} />
        )
    } else if (error) {
        addSubscriptionFormComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="We had a problem while fetching your subscription information."
            />
        )
    } else {
        const ruleOptions = data?.rules?.rules?.map((r) => {
            return { label: r.name, value: r.id }
        })
        const wellOptions = data?.wells?.nodes?.map((w) => {
            return { label: w.name, value: w.id }
        })

        // get subscriber options based on the viewer action
        let subscriberOptions: ManageSubscriptionSubcriberOptionsT[]
        switch (viewerAction) {
            case "admin": {
                // we can safely assume that the data is of type SubscriptionAdminAddQryQuery because we check viewer action when we call the query above
                const users = (data as SubscriptionAdminAddQryQuery).users
                    ?.nodes

                subscriberOptions = users
                    .filter((item) => item.identity) // filter users without identity
                    .map((u) => {
                        return {
                            label: u.identity?.fullName as string,
                            id: u.id,
                            email: u.identity?.email,
                            emailVerified: u.identity?.emailVerified || false,
                            emailConsent: u.identity?.emailConsent || false,
                            phone: u.identity?.telephoneNumber?.formatted,
                            phoneVerified:
                                u.identity?.telephoneNumberVerified || false,
                            phoneConsent: u.identity?.smsConsent || false,
                        }
                    })
                break
            }
            case "self":
            default:
                // in the case of self add subscription, the viewer is the subscriber
                subscriberOptions = [
                    {
                        label: viewerIdentity.fullName,
                        id: viewerUserId,
                        email: viewerIdentity.email,
                        emailVerified: viewerIdentity.emailVerified,
                        emailConsent: viewerIdentity.emailConsent,
                        phone: getPhoneNumber(true),
                        phoneVerified: viewerIdentity.telephoneNumberVerified,
                        phoneConsent: viewerIdentity.smsConsent,
                    },
                ]
        }

        if (!ruleOptions || ruleOptions.length === 0) {
            addSubscriptionFormComponent = (
                <div className={styles.empty}>
                    You do not have any rules to create subscriptions for.
                </div>
            )
        } else if (!wellOptions || wellOptions.length === 0) {
            addSubscriptionFormComponent = (
                <div className={styles.empty}>
                    You do not have any wells to create subscriptions for.
                </div>
            )
        } else if (subscriberOptions.length === 0) {
            addSubscriptionFormComponent = (
                <div className={styles.empty}>
                    You do not have any users to create subscriptions for.
                </div>
            )
        } else {
            addSubscriptionFormComponent = (
                <AddSubscriptionForm
                    ruleOptions={ruleOptions}
                    wellOptions={wellOptions}
                    viewerAction={viewerAction}
                    subscriberOptions={subscriberOptions}
                />
            )
        }
    }

    // The modal footer is in the AddSubscriptionForm component
    // The submit function needs access to the form state to submit the add mutation
    return (
        <div className={styles.container}>
            <ModalHeader
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: location.key,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
                title="Add Subscription"
            />
            {addSubscriptionFormComponent}
        </div>
    )
}

export default AddSubscription
