import { graphql } from "../../generated"

export const SUBSCRIPTION_UPDATE = graphql(`
    mutation subscriptionUpdate(
        $AlertSubscriptionUpdateInput: AlertSubscriptionUpdateInput!
    ) {
        subscriptionUpdate(input: $AlertSubscriptionUpdateInput) {
            subscription {
                ...SubscriptionFields
            }
        }
    }
`)
