import { FunctionComponent } from "react"
import Button from "../../shared/button"
import "./emptyState.scss"
import Alert from "../../shared/alert"
import { useNavigate } from "react-router-dom"

const EmptyState: FunctionComponent = () => {
    const navigate = useNavigate()
    return (
        <div className="empty-state-container">
            <div className="empty-state-card">
                <img
                    src="./assets/pumpjack.svg"
                    alt="Image of a pump jack"
                    className="empty-state-card-image-container"
                />
                <div className="empty-state-card-primary-text">
                    Things look a little empty. Let&apos;s add some wells!
                </div>
                <div className="empty-state-card-secondary-text">
                    To begin, click the Add Well button
                    <br /> or contact us to help you get started.
                </div>
                <Button
                    handleButtonClick={() => {
                        navigate("/dashboard/addWells")
                    }}
                    status="primary"
                >
                    Add Well
                </Button>
                <Alert
                    message="Have your Well Name, API # and Lat/Long ready to enter."
                    alertType="info"
                    isCloseable={false}
                    customAlertClass="empty-state-card-banner"
                />
            </div>
        </div>
    )
}

export default EmptyState
