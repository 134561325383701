import React, { FunctionComponent, useState, useEffect } from "react"
import { WellIndexTableDataI } from "../tableTypes"
import { Column, Table } from "@tanstack/react-table"
import { debounce } from "../../../util/debounce"
import Button from "../../shared/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faChevronDoubleDown,
    faChevronDoubleUp,
} from "@fortawesome/pro-solid-svg-icons"
import styles from "./actionHeader.module.scss"
import Input from "../../shared/input"
import { useNavigate } from "react-router-dom"

interface WellIndexActionHeaderI {
    canAddWell: boolean
    globalFilter: string
    organizationColumn: Column<WellIndexTableDataI, unknown> | undefined
    table: Table<WellIndexTableDataI>
}

const WellIndexActionHeader: FunctionComponent<WellIndexActionHeaderI> = ({
    canAddWell,
    globalFilter,
    organizationColumn,
    table,
}) => {
    const o = organizationColumn?.getFacetedUniqueValues().keys()
    const uniqueOrganizations = o ? Array.from(o).sort() : []
    const initialBrowserWidth = window.innerWidth
    const mobileBreakpoint = 768
    const [showMobileView, setShowMobileView] = useState(
        initialBrowserWidth < mobileBreakpoint
    )

    // handlers
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        table.setGlobalFilter(e.target.value)
    }

    const handleOrganizationChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        organizationColumn?.setFilterValue(e.target.value)
    }

    // handle mobile vs desktop view
    const handleResize = () => {
        setShowMobileView(window.innerWidth < mobileBreakpoint)
    }

    useEffect(() => {
        const debouncedHandleResize = debounce(handleResize, 40)
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    }, [])

    return (
        <Component
            canAddWell={canAddWell}
            globalFilter={globalFilter}
            uniqueOrganizations={uniqueOrganizations}
            organizationFilterValue={organizationColumn?.getFilterValue() || ""}
            handleSearchChange={handleSearchChange}
            handleOrganizationChange={handleOrganizationChange}
            showMobileView={showMobileView}
        />
    )
}

export default WellIndexActionHeader

interface ComponentI {
    canAddWell: boolean
    globalFilter: string
    uniqueOrganizations: string[]
    handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleOrganizationChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    showMobileView: boolean
    organizationFilterValue: unknown
}

const Component: FunctionComponent<ComponentI> = ({
    canAddWell,
    globalFilter,
    showMobileView,
    uniqueOrganizations,
    handleOrganizationChange,
    handleSearchChange,
    organizationFilterValue,
}) => {
    const navigate = useNavigate()
    const [showActions, setShowActions] = useState(true)
    const fontAwesomeIcon = showActions
        ? faChevronDoubleUp
        : faChevronDoubleDown

    const outerClass =
        !showMobileView || showActions ? styles.outer : styles.outerClosed
    const showOrganizationFilter = uniqueOrganizations.length > 1

    useEffect(() => {
        // Reset showActions when switching between mobile and desktop views
        if (!showMobileView) {
            setShowActions(true)
        }
    }, [showMobileView])

    return (
        <div className={outerClass}>
            {showMobileView && (
                <Button
                    handleButtonClick={() => setShowActions(!showActions)}
                    status="primary"
                >
                    <FontAwesomeIcon icon={fontAwesomeIcon} color="#fff" />
                </Button>
            )}
            {showActions && (
                <div className={styles.container}>
                    <div className={styles.filtersContainer}>
                        {canAddWell && (
                            <Button
                                handleButtonClick={() => {
                                    navigate("addWells")
                                }}
                                status="primary"
                                condition="default"
                            >
                                Add Well
                            </Button>
                        )}
                        {showOrganizationFilter && (
                            <Input
                                onChange={handleOrganizationChange}
                                type="datalist"
                                orientation="horizontal"
                                placeHolder="Filter Organizations"
                                datalistId="organizations"
                                options={uniqueOrganizations}
                                specializedClass="noMessage"
                                inputCustomClass={styles.organizationFilter}
                                value={organizationFilterValue as string}
                            />
                        )}
                    </div>
                    <Input
                        value={globalFilter || ""}
                        onChange={handleSearchChange}
                        type="text"
                        orientation="horizontal"
                        placeHolder="Search Columns"
                        specializedClass="noMessage"
                        inputCustomClass={styles.searchFilter}
                    />
                </div>
            )}
        </div>
    )
}
