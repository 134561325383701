import { graphql } from "../../generated"

export const SUBSCRIPTIONS_ADD_QUERY = graphql(`
    query SubscriptionAddQry($orgId: ID!) {
        rules(filter: { OrganizationIDs: [$orgId] }) {
            rules {
                id
                name
            }
        }
        wells(
            first: 1000
            filter: {
                organizationID: $orgId
                includeChildOrgs: true
                linked: true
            }
        ) {
            nodes {
                id
                name
            }
        }
    }
`)

export const SUBSCRIPTIONS_ADMIN_ADD_QRY = graphql(`
    query SubscriptionAdminAddQry($orgId: ID!, $usersFirst: Int) {
        rules(filter: { OrganizationIDs: [$orgId] }) {
            rules {
                id
                name
            }
        }
        wells(
            first: 1000
            filter: {
                organizationID: $orgId
                includeChildOrgs: true
                linked: true
            }
        ) {
            nodes {
                id
                name
            }
        }
        users(
            first: $usersFirst
            filter: { active: true, organizationIDs: [$orgId] }
        ) {
            nodes {
                identity {
                    fullName
                    email
                    telephoneNumber {
                        ... on NorthAmericanPhoneNumber {
                            concise: formatted(format: CONCISE)
                            formatted
                        }
                    }
                    telephoneNumberVerified
                    smsConsent
                    emailVerified
                    emailConsent
                }
                organization {
                    id
                }
                id
            }
        }
    }
`)
