/**
 * Force formatting when subject types into well api number input
 * Forces the input to insert dashes at positions [2, 6, 12, 15] (0 indexed).
 * This will result in the following formats
 *  xx-xxx-xxxxx
 *  xx-xxx-xxxxx-xx-xx
 */
export const formatWellApiNumberInput = (
    e: React.ChangeEvent<HTMLInputElement>
) => {
    let value = e.target.value
    const dashPositions = new Set([2, 6, 12, 15])

    // Replace non-numeric characters that aren't dashes used at correct position
    value = value.replace(/[^0-9]/g, (char, index) => {
        return dashPositions.has(index) && char === "-" ? char : ""
    })

    // Autofill dashes if users don't type them at certain positions
    dashPositions.forEach((pos) => {
        if (value.length > pos && value.charAt(pos) !== "-") {
            value = `${value.slice(0, pos)}-${value.slice(pos)}`
        }
    })

    // Limit to 14 digits
    value = value.slice(0, 18)
    return value
}
