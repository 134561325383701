/**
 * Checks if an object has any fields that are not equal to undefined.
 * @param obj - The object to be checked.
 * @returns True if the object has non-undefined fields, false otherwise.
 */
const objectHasNonUndefinedFields = <T extends object>(obj: T): boolean => {
    for (const key in obj) {
        if (
            Object.prototype.hasOwnProperty.call(obj, key) &&
            obj[key] !== undefined
        ) {
            return true
        }
    }
    return false
}

export { objectHasNonUndefinedFields }
