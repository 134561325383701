import { getFragmentData } from "../../../../generated"
import {
    SubscriptionFieldsForAdminSubscriptionTablesFragment,
    SubscriptionFieldsFragment,
} from "../../../../generated/graphql"
import {
    IDENTITY_FIELDS_FRAGMENT,
    IDENTITY_NORTH_AMERICAN_PHONE_NUMBER_FRAGMENT,
} from "../../../../graphql/fragments/Identity"
import { ALERT_RULE_FIELDS_FRAGMENT } from "../../../../graphql/fragments/alertRule"
import { ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT } from "../../../../graphql/fragments/alertRuleMetricDefinition"
import { SUBJECT_FIELDS_FRAGMENT } from "../../../../graphql/fragments/subject"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../../../graphql/fragments/wellIdentification"
import {
    isEmailVerifiedAndConsenting,
    isPhoneVerifiedAndConsenting,
} from "../../../../graphql/identity/identity"
import { LabelValueOptionType } from "../../../../types/commonTypes"
import { unixMsIsGreaterOrEqualToNow } from "../../../../util/datesAndTimes/datesAndTimes"
import { SubscriptionsAdminTableDataI } from "../adminSubscriptionsViews/tableTypes"
import { UpdateSubscriptionFormI } from "../manageSubscriptions/manageSubscriptionFormUtil/types"
import {
    MySubscriptionsTableDataI,
    mySubscriptionsWellNameSep,
} from "../mySubscriptions/table/tableTypes"

const getMySubscriptionsTableFromSubscriptionsFrag = (
    subscriptions: SubscriptionFieldsFragment[]
): MySubscriptionsTableDataI[] => {
    const mySubscriptionsTable: MySubscriptionsTableDataI[] = []
    for (let i = 0; i < subscriptions.length; i++) {
        const subscription = subscriptions[i]

        const alertRule = getFragmentData(
            ALERT_RULE_FIELDS_FRAGMENT,
            subscription.alertRule
        )

        const metric = getFragmentData(
            ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT,
            alertRule.metric
        )

        const subscribedWells = getFragmentData(
            WELL_IDENTIFICATION_FIELDS_FRAGMENT,
            subscription.subscribedWells
        )

        // ex: wellNames = "well1|well2|well3"
        const wellNames = subscribedWells
            .map((well) => well.name)
            .join(mySubscriptionsWellNameSep)

        const silenceUntilUnixMs = unixMsIsGreaterOrEqualToNow(
            subscription.silenceUntil.unixMilliseconds
        )
            ? subscription.silenceUntil.unixMilliseconds
            : false

        mySubscriptionsTable.push({
            id: subscription.id,
            ruleName: alertRule.name,
            metric: metric.displayName,
            ruleDescription: alertRule.description,
            subscribedWells: wellNames,
            subscriptionSilencedUntil: silenceUntilUnixMs,
        })
    }

    return mySubscriptionsTable
}

const getSubscriptionsAdminTableFromSubscriptionsFrag = (
    subscriptions: SubscriptionFieldsForAdminSubscriptionTablesFragment[]
): SubscriptionsAdminTableDataI[] => {
    const subscriptionsByRuleTable: SubscriptionsAdminTableDataI[] = []

    for (let i = 0; i < subscriptions.length; i++) {
        const s = subscriptions[i]

        const silenceUntilUnixMs = unixMsIsGreaterOrEqualToNow(
            s.silenceUntil.unixMilliseconds
        )
            ? s.silenceUntil.unixMilliseconds
            : false

        // email can be On, Off, or Disabled
        const email = isEmailVerifiedAndConsenting(
            s.subscriber.identity?.email,
            s.subscriber.identity?.emailVerified,
            s.subscriber.identity?.emailConsent
        )
            ? s.email
            : "DISABLED"

        // phone can be On, Off, or Disabled
        const phone = isPhoneVerifiedAndConsenting(
            s.subscriber.identity?.telephoneNumber?.e164,
            s.subscriber.identity?.telephoneNumberVerified,
            s.subscriber.identity?.smsConsent
        )
            ? s.phone
            : "DISABLED"

        // ex: wellNames = "well1|well2|well3"
        const wellNames = s.subscribedWells
            .map((well) => well.name)
            .join(mySubscriptionsWellNameSep)

        subscriptionsByRuleTable.push({
            id: s.id,
            metric: s.alertRule.metric.displayName,
            email: email,
            phone: phone,
            subscriber: s.subscriber.identity?.fullName || "",
            subscribedWells: wellNames,
            ruleName: s.alertRule.name,
            subscriptionSilencedUntil: silenceUntilUnixMs,
        })
    }

    return subscriptionsByRuleTable
}

const getUpdateSubscriptionFormFromSubscriptionFrag = (
    subscription: SubscriptionFieldsFragment,
    availableWells: LabelValueOptionType[]
): UpdateSubscriptionFormI => {
    const alertRule = getFragmentData(
        ALERT_RULE_FIELDS_FRAGMENT,
        subscription.alertRule
    )

    const subscribedWells = getFragmentData(
        WELL_IDENTIFICATION_FIELDS_FRAGMENT,
        subscription.subscribedWells
    )

    const wellOptions = availableWells.map((well) => {
        const isSubscribed = subscribedWells.findIndex((subscribedWell) => {
            return subscribedWell.id === well.value
        })

        if (isSubscribed !== -1) {
            return {
                label: well.label,
                value: well.value,
                isDisabled: false,
                isSelected: true,
            }
        } else {
            return {
                label: well.label,
                value: well.value,
                isDisabled: false,
                isSelected: false,
            }
        }
    })

    const subscriber = getFragmentData(
        SUBJECT_FIELDS_FRAGMENT,
        subscription.subscriber
    )

    const identity = getFragmentData(
        IDENTITY_FIELDS_FRAGMENT,
        subscriber.identity
    )

    const phoneNumber = getFragmentData(
        IDENTITY_NORTH_AMERICAN_PHONE_NUMBER_FRAGMENT,
        identity?.telephoneNumber
    )

    const form: UpdateSubscriptionFormI = {
        rule: {
            label: alertRule.name,
            value: alertRule.id,
        },
        wellOptions: wellOptions,
        // includeNewWells: subscription.includeNewWells,
        enableEmail: subscription.email,
        enableText: subscription.phone,
        byPassDnd: subscription.disturb,
        subscriptionId: subscription.id,
        silenceForm: {
            timeUnit: "m",
            timeValue: "",
        },
        initialSilenceUnixMs: subscription.silenceUntil.unixMilliseconds,
        subscriber: {
            label: identity?.fullName || "",
            id: subscriber.id,
            email: identity?.email,
            phone: phoneNumber?.formatted,
            emailVerified: identity?.emailVerified || false,
            emailConsent: identity?.emailConsent || false,
            phoneVerified: identity?.telephoneNumberVerified || false,
            phoneConsent: identity?.smsConsent || false,
        },
    }

    return form
}
export {
    getMySubscriptionsTableFromSubscriptionsFrag,
    getSubscriptionsAdminTableFromSubscriptionsFrag,
    getUpdateSubscriptionFormFromSubscriptionFrag,
}
